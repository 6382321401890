import { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

const InputPopover = ({
  title,
  value,
  setValue,
  handleSave,
  handleIClick,
  inputType,
  placeholder,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (e) => {
    setShow(!show);
    setTarget(e.target);
    handleIClick();
  };

  return (
    <div ref={ref} className='select-pop'>
      <button
        className='icn-btn'
        //   onClick={(e) => handleClick(e, mainOrder, 'order_status')}
        onClick={(e) => handleClick(e)}
      >
        <i className='fas fa-pencil-alt pointer ms-1 edit-icn'></i>
      </button>

      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover id='popover-contained'>
          <Popover.Content>
            <p className='font-semibold mb-3'>{title}</p>
            <input
              type={inputType}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className='form-control'
              placeholder={placeholder}
            />
            <div className='footer'>
              <button onClick={() => setShow(false)}>Cancel</button>
              <button
                onClick={() => {
                  handleSave(setShow);
                }}
              >
                Save
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default InputPopover;
