import { Spinner } from 'react-bootstrap';

const LoadingContent = ({ name }) => {
  return (
    <div className='col-sm-12 my-7 d-flex align-items-center justify-content-center'>
      <Spinner animation='border' size='sm' />
      <span className='ms-2 text-gray-600'>{name ? name : 'loading...'}</span>
    </div>
  );
};

export default LoadingContent;
