import axios from 'axios';
import React, { Component } from 'react'
import { Modal, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';

export class BranchLabMod extends Component {

  state = {
    branchEditId: null,
    branchName: '',
    branchDesc: '',
    branchLab: '',

    // branches: null,
    // labs: null,
    // locations: null,

    labLocation: '',
    labName: '',
    labLogo: null,
    labExistingLogo: '',
    labDesc: '',
    tIsActive: true,
    labEditId: null,

    showLabModal: false,
    removeDeleteAcces: true,
  };


  componentDidMount() {
    const user_details = localStorage.getItem('user_details');
    if (user_details) {
      const userRole = JSON.parse(user_details);
      if(userRole.is_superuser || userRole.groups[0].name === 'Admin') {
        this.setState({ removeDeleteAcces: false });
        // console.log('object');
      }
    }
  }

  handleEditBranch = (branch) => {
    this.setState({
      branchEditId: branch.id,
      branchName: branch.name,
      branchDesc: branch.description,
      branchLab: branch.lab.id,
    })
  }
  handleEditLab = (lab) => {
    this.setState({
      labLocation: lab.locDetail.id,
      labName: lab.name,
      labLogo: null,
      labExistingLogo: lab.logo,
      labDesc: lab.description ? lab.description : '',
      tIsActive: lab.is_active,
      labEditId: lab.id,
    })
  }

  handleDeleteBranch = (branch) => {
    const { fetchBranch } = this.props;
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/diagnostic/branch/${branch.id}/`)
      .then((resp) => {
        fetchBranch();
        toast.success(`Branch deleted successfully.`, { autoClose: 3000, });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleDeleteLab = (lab) => {
    const { fetchLab } = this.props;
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab/${lab.id}/`)
      .then((resp) => {
        fetchLab();
        toast.success(`Lab deleted successfully.`, { autoClose: 3000, });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleCancleBranch = () => {
    this.setState({
      branchEditId: null,
      branchName: '',
      branchDesc: '',
      branchLab: '',
    })
  };
  handleCancleLab = () => {
    this.setState({
      labLocation: '',
      labName: '',
      labLogo: null,
      labExistingLogo: '',
      labDesc: '',
      tIsActive: false,
      labEditId: null,
    })
  };


  addEditBranch = () => {
    const { branchEditId, branchName, branchDesc, branchLab, } = this.state;
    const { fetchBranch } = this.props;

    if (!branchName) toast.error('Please fill the branch name.', { autoClose: 3000 })
    else if (!branchLab) toast.error('Please select a lab.', { autoClose: 3000 })
    else {
      if (!branchEditId) {
        const postData = {
          "name": branchName,
          "lab": branchLab,
          "description": branchDesc
        }
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/diagnostic/branch/`, postData)
          .then((resp) => {
            fetchBranch();
            this.handleCancleBranch();
            toast.success('New Lab has been added successfully.', { autoClose: 3000 });
            this.handleCloseBranchModal()
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const postData = {
          "name": branchName,
          "lab": branchLab
        }
        if (branchDesc) postData["description"] = branchDesc;
        axios
          .put(`${process.env.REACT_APP_BASE_URL}/diagnostic/branch/${branchEditId}/`, postData)
          .then((resp) => {
            fetchBranch();
            this.handleCancleBranch();
            toast.success('New Lab has been added successfully.', { autoClose: 3000 });
            this.handleCloseBranchModal()
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  addEditLab = () => {
    const { labLocation, labName, labLogo, labEditId, labDesc, tIsActive } = this.state;
    const { fetchLab } = this.props;

    if (!labName) toast.error('Please fill the lab name.', { autoClose: 3000 })
    else if (!labLocation) toast.error('Please select a location.', { autoClose: 3000 })
    else if(!labLogo && !labEditId) toast.error('Please upload an image.', { autoClose: 3000 })
    else if (labLogo && labLogo.size > 1024000) toast.error('Image can not be more than 1MB.', { autoClose: 3000 })
    else {
      let postData = {
        "name": labName,
        "location": parseInt(labLocation),
        "description": labDesc,
        "is_active": tIsActive
      }

      if (!labEditId) {
        // postData["description"] = labDesc;
        postData["logo"] = labLogo;
        
        let postFormData = new FormData();
        for (const key in postData) {
          console.log(key, postData[key]);
          postFormData.append(key, postData[key]);
        }
        
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab/`, postFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((resp) => {
            fetchLab();
            this.handleCancleLab();
            toast.success('New Lab has been added successfully.', { autoClose: 3000 })
            this.setState({ showLabModal: false });
          })
          .catch((error) => {
            console.log(error);
          });
          
      } else {
        // if (labDesc) postData["description"] = labDesc;
        if (labLogo) postData["logo"] = labLogo;
        
        let postFormData = new FormData();
        for (const key in postData) {
          postFormData.append(key, postData[key]);
        }
        
        axios
          .put(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab/${labEditId}/`, postFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((resp) => {
            fetchLab();
            this.handleCancleLab();
            toast.success('Lab has been updated successfully.', { autoClose: 3000 });
            this.setState({ showLabModal: false });
            
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }


  handleCloseBranchModal = () => {
    const { setStateP } = this.props;
    this.setState({ showLabModal: false })
    setStateP({ showBranchLabModal: false })
  }

  render() {
    const { showBranchLabModal, labs, branches, locations } = this.props;
    const { branchEditId, branchName, branchDesc, branchLab, removeDeleteAcces } = this.state;
    const { labLocation, labName, labLogo, labExistingLogo, labEditId, labDesc, tIsActive, showLabModal } = this.state;
    return (
      <>
        <Modal
          show={showBranchLabModal}
          onHide={() => this.handleCloseBranchModal()}
          animation={true}
          size='xl'
        >
          <ModalBody>
            <div className='row page-test-details'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='edit-order'>
                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                      <h5 className='mb-0'> </h5>
                      <button className='btn btn-sm btn-danger'
                        onClick={() => this.handleCloseBranchModal()} >
                        Close
                      </button>
                    </div>
                    <hr />
                    <div className='card-body pt-2 pb-0'>
                      <h5 className='mb-5'>{branchEditId ? 'Edit' : 'Add New'} Branch</h5>
                      <form onSubmit={e => { e.preventDefault() }}>
                        <div className='form-group mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter branch area name *'
                            value={branchName}
                            onChange={(e) => this.setState({ branchName: e.target.value })}
                          />
                        </div>
                        <div className='form-group mb-3 d-flex'>
                          <select
                            className='form-control me-3'
                            onChange={(e) => this.setState({ branchLab: e.target.value })}
                            value={branchLab}
                          >
                            <option value=''>Select lab *</option>
                            {labs &&
                              labs.map((lab, i) => (
                                <option value={lab.id} key={i}>
                                  {lab.name}
                                </option>
                              ))}
                          </select>
                          <div className="">
                            <button className='btn btn-primary font-weight-bolder fs-2 px-6 py-0 h-100 justify-content-center'
                              onClick={e => { e.preventDefault(); this.setState({ showLabModal: true }); }}>
                              +
                            </button>
                          </div>
                        </div>
                        <div className='form-group mb-3'>
                          <textarea
                            className='form-control'
                            placeholder='Enter branch description'
                            rows='4'
                            value={branchDesc}
                            onChange={(e) => this.setState({ branchDesc: e.target.value })}
                          ></textarea>
                        </div>
                        <div className='form-group mb-3 mb-2'>
                          <button className='btn btn-primary me-3'
                          onClick={this.addEditBranch}
                          >
                            {branchEditId ? 'Edit' : 'Add'} Branch
                          </button>
                          {branchEditId && (
                            <button
                              className='btn btn-danger'
                              onClick={this.handleCancleBranch}
                              type='button'
                            >
                              Clear
                            </button>
                          )}
                          {!branchEditId && (
                            <button
                              className='btn btn-danger'
                              onClick={this.handleCancleBranch}
                              type='button'
                            >
                              Clear
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                    <hr />
                    <div className='card-body pt-2 pb-0 table-responsive' style={{ maxHeight: '460px' }}>
                      <table className='table table-bordered'>
                        <thead>
                          <tr>
                            <th>Idx</th>
                            <th style={{ minWidth: '100px' }}>Name</th>
                            <th style={{ minWidth: '100px' }}>Lab</th>
                            <th style={{ minWidth: '200px' }}>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {branches &&
                            branches.map((branch, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{branch.name}</td>
                                <td>{branch.lab.name}</td>
                                <td>{branch.description ? branch.description : '-'}</td>
                                <td>
                                  <button
                                    className='btn btn-primary mr-3 btn-sm'
                                    onClick={() => this.handleEditBranch(branch)}
                                  >
                                    Edit
                                  </button>
                                  {!removeDeleteAcces && <button
                                    className='btn btn-danger btn-sm'
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          'Are you sure to delete this branch?'
                                        )
                                      )
                                        this.handleDeleteBranch(branch);
                                    }}
                                  >
                                    Delete
                                  </button>}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          show={showLabModal}
          onHide={() => this.setState({ showLabModal: false })}
          animation={true}
          size='xl'
        >
          <ModalBody>
            <div className='row page-test-details'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='edit-order'>
                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                      <h5 className='mb-0'> </h5>
                      <button className='btn btn-sm btn-danger'
                        onClick={() => this.setState({ showLabModal: false })} >
                        Close
                      </button>
                    </div>
                    <hr />
                    <div className='card-body pt-2 pb-0'>
                      <h5 className='mb-5'>{labEditId ? 'Edit' : 'Add New'} Lab</h5>
                      <form onSubmit={e => { e.preventDefault() }}>
                        <div className='form-group mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter lab name *'
                            value={labName}
                            onChange={(e) => this.setState({ labName: e.target.value })}
                          />
                        </div>
                        <div className='form-group mb-3 d-flex'>
                          <select
                            className='form-control'
                            onChange={(e) => this.setState({ labLocation: e.target.value })}
                            value={labLocation}
                          >
                            <option value=''>Select location *</option>
                            {locations &&
                              locations.map((loc, i) => (
                                <option value={loc.id} key={i}>
                                  {loc.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className='form-group mb-3'>
                          <div className='custom-file'>
                            <input
                              type='file'
                              className='custom-file-input'
                              accept='image/png, image/gif, image/jpeg'
                              onChange={(e) => { this.setState({ labLogo: e.target.files[0] }) }}
                            />
                            <label className='custom-file-label'>
                              {labLogo ? labLogo.name : (labExistingLogo ? (labExistingLogo.length > 85 ? (labExistingLogo.slice(0, 83) + '...') : labExistingLogo) : `Select Lab Image`)}
                            </label>
                          </div>
                        </div>
                        <div className='form-group mb-3'>
                          <textarea
                            className='form-control'
                            placeholder='Enter lab description'
                            rows='4'
                            value={labDesc}
                            onChange={(e) => this.setState({ labDesc: e.target.value })}
                          ></textarea>
                        </div>


                      <div className='form-group mb-6 d-flex align-items-center'>
                        <p className='m-0 fs-5 fw-bold me-3'>Is Active</p>
                        <div className='form-check form-switch form-check-custom form-check-solid'>
                          <input
                            type='checkbox'
                            name='is_active'
                            defaultChecked={tIsActive}
                            onChange={() =>
                              this.setState({
                                tIsActive: !this.state.tIsActive,
                              })
                            }
                            checked={tIsActive ? 'checked' : ''}
                            className='form-check-input w-50px'
                          />
                        </div>
                      </div>


                        <div className='form-group mb-3 mb-2'>
                          <button className='btn btn-primary me-3'
                          onClick={this.addEditLab}
                          >
                            {labEditId ? 'Edit' : 'Add'} Lab
                          </button>
                          {labEditId && (
                            <button
                              className='btn btn-danger'
                              onClick={this.handleCancleLab}
                              type='button'
                            >
                              Clear
                            </button>
                          )}
                          {!labEditId && (
                            <button
                              className='btn btn-danger'
                              onClick={this.handleCancleLab}
                              type='button'
                            >
                              Clear
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                    <hr />
                    <div className='card-body pt-2 pb-0 table-responsive' style={{ maxHeight: '410px' }}>
                      <table className='table table-bordered'>
                        <thead>
                          <tr>
                            <th>Idx</th>
                            <th style={{ minWidth: '100px' }}>Name</th>
                            <th style={{ minWidth: '100px' }}>Locations</th>
                            <th style={{ minWidth: '100px' }}>Logo</th>
                            <th style={{ minWidth: '200px' }}>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {labs &&
                            labs.map((lab, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{lab.name}</td>
                                {/* <td>{lab.locDetail.name}</td> */}
                                <td>-----</td>
                                <td className=''>
                                  {lab.logo ? (
                                    <img
                                      src={lab.logo}
                                      alt=''
                                      width='30px'
                                      height='30px'
                                      className='shadow'
                                    />
                                  ) : (
                                    <div
                                      className='d-flex justify-content-center align-items-center border border-2 rounded'
                                      style={{ width: '30px', height: '30px' }}
                                    >
                                      <i
                                        className='fas fa-file-medical-alt'
                                        style={{ fontSize: '12px', borderRadius: 5 }}
                                      />
                                    </div>
                                  )}
                                </td>
                                {lab.description ? <td>{lab.description}</td> : <td className='px-4'>-</td>}
                                <td>
                                  <button
                                    className='btn btn-primary mr-3 btn-sm'
                                    onClick={() => this.handleEditLab(lab)}
                                  >
                                    Edit
                                  </button>
                                  {!removeDeleteAcces && <button
                                    className='btn btn-danger btn-sm'
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          'Are you sure to delete this lab?'
                                        )
                                      )
                                        this.handleDeleteLab(lab);
                                    }}
                                  >
                                    Delete
                                  </button>}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export default BranchLabMod;
