import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BtnLoading from '../components/BtnLoading';
import HeaderToolbar from '../components/HeaderToolbar';
import PatientDob from '../components/PatientDob';
import { AuthContext } from '../context/AuthContext';

class AddUser extends Component {
  state = {
    group: [],
    email: '',
    username: '',
    first_name: '',
    last_name: '',
    password: '',
    confirm_password: '',
    user_group: '',
    is_active: true,
    is_superuser: false,
    is_staff: false,
    submitLoading: false,

    labs: [],
    selectedLabs: [],
    patientDob: null,
    dobInputType: 'text',
    patientGender: '',
  };

  setPatientDob = (val) => {
    this.setState({ patientDob: val });
  };

  fetchLab = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab/?limit=100`)
      .then((resp) => {
        // console.log(resp.data)
        this.setState({ labs: resp.data.results });
      })
      .catch((e) => {});
  };

  fetchGroup = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user_management/group/`)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ group: resp.data.results });
      });
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Users', link: '/users' },
      { name: 'Add User', link: '' },
    ]);
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (userRole.is_superuser) {
      this.fetchGroup();
      this.fetchLab();
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (userRole.groups[0].name !== 'Admin') {
        this.props.history.push('/orders');
      } else {
        this.fetchLab();
        this.fetchGroup();
      }
    }

    // if (userRole.groups[0].name !== 'Admin') {
    //   this.props.history.push('/order');
    // } else {
    //   this.fetchGroup();
    // }
  }

  handleRole = (e) => {
    // console.log(e.target.value);
    this.setState({ user_group: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let numberPattern = /^01\d{9}$/;
    let emailPattern =
      /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
    if (!numberPattern.test(this.state.username)) {
      toast.error(`Please enter a valid phone number.`, {
        autoClose: 3000,
      });
    } else if (this.state.first_name.trim() === '') {
      toast.error(`Please enter firstname.`, {
        autoClose: 3000,
      });
    } else if (this.state.patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else if (this.state.patientDob === '' || this.state.patientDob === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    }
    // else if (!emailPattern.test(this.state.email)) {
    //   toast.error(`Please enter a valid email.`, {
    //     autoClose: 3000,
    //   });
    // }
    else if (this.state.password === '') {
      toast.error(`Please enter a password.`, {
        autoClose: 3000,
      });
    } else if (this.state.confirm_password === '') {
      toast.error(`Please enter confirm password.`, {
        autoClose: 3000,
      });
    } else if (this.state.password !== this.state.confirm_password) {
      toast.error(`Password and confirm password does not match.`, {
        autoClose: 3000,
      });
    } else if (this.state.user_group === '') {
      toast.error(`Please select a role.`, {
        autoClose: 3000,
      });
    } else {
      this.setState({ submitLoading: true });
      const postData = {
        username: this.state.username,
        password1: this.state.password,
        password2: this.state.password,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/auth/registration`, {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          // console.log('i am in success', data);

          if (data.username) {
            toast.error(`${data.username[0]}`, {
              autoClose: 3000,
            });
            this.setState({ submitLoading: false });
            return;
          } else if (data.password1) {
            if (data.password1[2]) {
              toast.error(`${data.password1[2]}`, {
                autoClose: 3000,
              });
            } else if (data.password1[1]) {
              toast.error(`${data.password1[1]}`, {
                autoClose: 3000,
              });
            } else if (data.password1[0]) {
              toast.error(`${data.password1[0]}`, {
                autoClose: 3000,
              });
            }
            this.setState({ submitLoading: false });
          } else if (data.key) {
            // console.log(data.key);
            const myHeaders = new Headers({
              'Content-Type': 'application/json',
              Authorization: `Token ${data.key}`,
            });

            fetch(`${process.env.REACT_APP_BASE_URL}/auth/user/`, {
              method: 'GET',
              headers: myHeaders,
            })
              .then((resp) => resp.json())
              .then((data) => {
                // console.log(data.pk);
                axios
                  .get(
                    `${process.env.REACT_APP_BASE_URL}/user_management/user/${data.pk}`
                  )
                  .then((resp) => {
                    // console.log(resp.data);
                    const strToInt = parseInt(this.state.user_group);

                    const postUser = {
                      email: this.state.email,
                      username: resp.data.username,
                      first_name: this.state.first_name,
                      last_name: this.state.last_name,
                      is_staff: this.state.is_staff,
                      is_active: this.state.is_active,
                      // is_superuser:
                      //   this.state.user_group === '1' ? true : false,
                      password: resp.data.password,
                      groups: [strToInt],
                    };

                    const postPatient = {
                      is_account: true,
                      user: resp.data.id,
                      full_name: `${this.state.first_name} ${this.state.last_name}`,
                      email: this.state.email,
                      lab_permit:
                        this.state.user_group == '5'
                          ? this.state.selectedLabs.join(', ')
                          : '',
                      dob: moment(this.state.patientDob).format('YYYY-MM-DD'),
                      sex: this.state.patientGender,
                    };

                    axios
                      .put(
                        `${process.env.REACT_APP_BASE_URL}/user_management/user/${resp.data.id}/`,
                        postUser
                      )
                      .then((resp) => {
                        // console.log('edituser: ',resp.data);
                        // console.log(postPatient);
                        axios
                          .post(
                            `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
                            postPatient
                          )
                          .then((resp) => {
                            // console.log('ptn: ',resp.data);
                            this.props.history.push('/users');
                            toast.success(`New user created successfully.`, {
                              autoClose: 3000,
                            });
                            this.setState({ submitLoading: false });
                          })
                          .catch((err) => {
                            console.log('ptn: ', err.response);
                            this.setState({ submitLoading: false });
                          });
                      })
                      .catch((error) => {
                        console.log(error.response);
                        this.setState({ submitLoading: false });
                      });
                  });
              });
          }
        })
        .catch((error) => {
          console.log('I am in error ', error);
          this.setState({ submitLoading: false });
        });

      // console.log(postData);
    }
  };

  handleLab = (e) => {
    const value = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    // const newVal = value.map((val) => {
    //   return { lab: val, };
    // });
    this.setState({ selectedLabs: value });
  };

  render() {
    const {
      group,
      email,
      username,
      first_name,
      last_name,
      password,
      is_active,
      confirm_password,
      submitLoading,
      labs,
      user_group,
      dobInputType,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Add New User' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card py-8 px-8 py-lg-15 px-lg-10'>
                  <div className='row justify-content-center'>
                    <div className='col-xl-9'>
                      <h5 className='text-dark font-weight-bold mb-10'>
                        User's Details:
                      </h5>
                      <form onSubmit={this.handleSubmit}>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Phone Number *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={username}
                              onChange={(e) =>
                                this.setState({ username: e.target.value })
                              }
                              placeholder='01XXXXXX'
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            First Name *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={first_name}
                              onChange={(e) =>
                                this.setState({ first_name: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Last Name
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={last_name}
                              onChange={(e) =>
                                this.setState({ last_name: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Date Of Birth *
                          </label>
                          <div className='col-lg-9 col-xl-9 patient-dob'>
                            {/* <input
                              type={dobInputType}
                              className='mb-5 form-control'
                              placeholder='Enter Patient Date of Birth'
                              onFocus={() =>
                                this.setState({ dobInputType: 'date' })
                              }
                              onBlur={() =>
                                this.setState({ dobInputType: 'text' })
                              }
                              value={this.state.patientDob}
                              onChange={(e) => {
                                this.setState({ patientDob: e.target.value });
                              }}
                            /> */}
                            <PatientDob
                              patientDob={this.state.patientDob}
                              setPatientDob={this.setPatientDob}
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Gender *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <select
                              className='form-select form-control form-control-solid form-control-lg'
                              // value={user_group || ''}
                              value={this.state.patientGender}
                              onChange={(e) =>
                                this.setState({ patientGender: e.target.value })
                              }
                            >
                              <option value=''>Select user gender</option>
                              <option value='male'>Male</option>
                              <option value='female'>Female</option>
                            </select>
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Email
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='email'
                              value={email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Password *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={password}
                              onChange={(e) =>
                                this.setState({ password: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Confirm Password *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={confirm_password}
                              onChange={(e) =>
                                this.setState({
                                  confirm_password: e.target.value,
                                })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Role *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <select
                              className='form-select form-control form-control-solid form-control-lg'
                              // value={user_group || ''}
                              onChange={this.handleRole}
                            >
                              <option value=''>User Role</option>
                              {group.length !== 0 &&
                                group.map((group, i) => (
                                  <option key={i} value={group.id}>
                                    {group.name === 'Lab Partner'
                                      ? 'Partner'
                                      : group.name}
                                  </option>
                                ))}
                            </select>
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label checkbox checkbox-success'>
                            Is Active
                          </label>
                          <div className='col-lg-9 col-xl-9 d-flex align-items-center'>
                            <input
                              type='checkbox'
                              name='is_active'
                              onChange={() =>
                                this.setState({
                                  is_active: !this.state.is_active,
                                })
                              }
                              defaultChecked={is_active}
                            />
                          </div>
                        </div>
                        {/* <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label checkbox checkbox-success'>
                            Is Staff
                          </label>
                          <div className='col-lg-9 col-xl-9 d-flex align-items-center'>
                            <input
                              type='checkbox'
                              name='is_staff'
                              defaultChecked={is_staff}
                              onChange={() =>
                                this.setState({
                                  is_staff: !this.state.is_staff,
                                })
                              }
                            />
                          </div>
                        </div> */}
                        {user_group === '5' && (
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Select Labs
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <select
                                multiple
                                className='form-control form-control-solid form-control-lg custom_scrollbar'
                                onChange={this.handleLab}
                              >
                                {labs.length !== 0 &&
                                  labs.map((lab, i) => (
                                    <option key={i} value={lab.id}>
                                      {lab.name}
                                    </option>
                                  ))}
                              </select>
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                        )}
                        <div className='d-flex justify-content-end border-top pt-5 mt-2'>
                          <div>
                            <Link
                              to='/users'
                              type='submit'
                              className='btn btn-success font-weight-bolder px-9 py-4 me-3'
                            >
                              Go Back
                            </Link>
                          </div>
                          <div>
                            {!submitLoading ? (
                              <button
                                type='submit'
                                className='btn btn-primary font-weight-bolder px-9 py-4'
                              >
                                Submit
                              </button>
                            ) : (
                              <BtnLoading
                                name='Submit...'
                                btnClass='primary px-9 py-4'
                              />
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddUser;
AddUser.contextType = AuthContext;
