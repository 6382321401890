import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Blank from '../assets/img/blank.png';

export const MedicineDetailsForm = ({
  medicine,
  uploadMedicineImage,
  setState,
  onSubmitOp,
  submitBtnText,
  loading,
}) => {

  const imgInputRef = useRef();
  const catImgInputRef = useRef();

  let isFirstTimeCat = true;

  const [Categories, setCategories] = useState([]);
  const [CategoriesCount, setCategoriesCount] = useState(0);
  const [CategoriesNext, setCategoriesNext] = useState(null);
  const [CategoriesLoading, setCategoriesLoading] = useState(false);

  // add category 

  const [CategoryId, setCategoryId] = useState(0);
  const [CategoryName, setCategoryName] = useState('');
  const [CategoryImage, setCategoryImage] = useState(null);
  const [CategoryParent, setCategoryParent] = useState('0');
  const [CategoryDescription, setCategoryDescription] = useState('');
  const [CategoryModalDisplay, setCategoryModalDisplay] = useState(false);

  const [CategoryDeleting, setCategoryDeleting] = useState(false);
  const [CategoryDeleteId, setCategoryDeleteId] = useState(0);
  const [CategoryAddEdit, setCategoryAddEdit] = useState(false);



  const fetchMedCategories = (url) => {
    setCategoriesLoading(true);
    axios
      .get(url)
      .then((resp) => {
        // console.log(resp.data.results);
        if (!isFirstTimeCat) {
          setCategories([...Categories, ...resp.data.results]);
        } else {
          setCategories([...resp.data.results]);
          isFirstTimeCat = false;
        }
        setCategoriesCount(resp.data.count);
        setCategoriesNext(resp.data.next);
        setCategoriesLoading(false);

        if (!!resp.data.next) fetchMedCategories(resp.data.next);

      })
      .catch(err => {
        console.log('category err: ', err);
        setCategories([]);
        setCategoriesLoading(false);
      })
  }

  useEffect(() => {
    setCategories([]);
    fetchMedCategories(`${process.env.REACT_APP_BASE_URL}/medicine/category/?limit=100&ofset=0`)
  }, [])


  const resetCategoryData = () => {
    setCategoryId(0);
    setCategoryName('');
    setCategoryImage(null);
    setCategoryParent('0');
    setCategoryDescription('');
    setCategoryAddEdit(false);
  }

  const handleAddCategory = () => {
    if (!CategoryName) toast.error('Fill category name please.', { autoClose: 3000 });

    else if (CategoryImage && CategoryImage <= 1024000) toast.error('Image size must be less than 1MB.', { autoClose: 3000 });

    else {
      let categoryData = { name: CategoryName, }

      if (CategoryDescription) categoryData.description = CategoryDescription;
      if (CategoryParent !== '0') categoryData.parent = parseInt(CategoryParent);

      console.log(categoryData);
      setCategoryAddEdit(true);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/medicine/category/`,
          categoryData
        ).then(response => {
          // console.log('catgory response: ', response.data);
          setCategoryAddEdit(false);

          if (response.status === 201) {
            const catId = response.data.id;
            if (CategoryImage && CategoryImage.size <= 1024000) {
              let catImage = new FormData();
              catImage.append('image', CategoryImage);

              setCategoryAddEdit(true);
              axios
                .put(
                  `${process.env.REACT_APP_BASE_URL}/medicine/category/${catId}/`,
                  catImage
                ).then(response => {
                  // console.log('category image response: ', response.data);
                  setCategoryAddEdit(false);
                  toast.success('Image added to recently created category.', { autoClose: 3000 });

                }).catch(error => {
                  console.log(error);
                  setCategoryAddEdit(false);
                  toast.error('Unable to add image to recently created category.', { autoClose: 3000 });
                });

            }
            toast.success('Category Added Successfully.', { autoClose: 3000 });
            resetCategoryData();
            setCategories([]);
            isFirstTimeCat = true;
            fetchMedCategories(`${process.env.REACT_APP_BASE_URL}/medicine/category/?limit=100&ofset=0`);
            setCategoryModalDisplay(false);
          } else {
            setCategoryAddEdit(false);
            console.log('category add error: ', response);
            toast.error('Unable to add category, try again.', { autoClose: 3000 });
          }
        })
        .catch(err => {
          setCategoryAddEdit(false);
          console.log('category add error: ', err);
          toast.error('Unable to add category, try again.', { autoClose: 3000 });
        })
    }

  }

  const handleEditCategory = () => {
    if (!CategoryName) toast.error('Fill category name please.', { autoClose: 3000 });

    else if (CategoryImage && CategoryImage <= 1024000) toast.error('Image size must be less than 1MB.', { autoClose: 3000 });

    else {
      let categoryData = { name: CategoryName, description: null, parent: null }

      if (CategoryDescription) categoryData.description = CategoryDescription;
      if (CategoryParent !== '0') categoryData.parent = parseInt(CategoryParent);

      setCategoryAddEdit(true);
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/medicine/category/${CategoryId}/`,
          categoryData
        ).then(response => {
          setCategoryAddEdit(false);
          console.log('catgory response: ', response);

          if (CategoryImage && CategoryImage.size <= 1024000) {
            let catImage = new FormData();
            catImage.append('image', CategoryImage);

            setCategoryAddEdit(true);
            axios
              .put(
                `${process.env.REACT_APP_BASE_URL}/medicine/category/${CategoryId}/`,
                catImage
              ).then(response => {
                setCategoryAddEdit(false);
                console.log('category image response: ', response);
                // toast.success('Image added to recently created category.', { autoClose: 3000 });
                toast.success('Category Added Successfully.', { autoClose: 3000 });
                resetCategoryData();
                setCategories([]);
                isFirstTimeCat = true;
                fetchMedCategories(`${process.env.REACT_APP_BASE_URL}/medicine/category/?limit=100&ofset=0`);

              }).catch(error => {
                setCategoryAddEdit(false);
                console.log(error);
                toast.error('Unable to add image to recently created category.', { autoClose: 3000 });
              });

          }
          else {
            setCategoryAddEdit(false);
            toast.success('Category Added Successfully.', { autoClose: 3000 });
            resetCategoryData();
            setCategories([]);
            isFirstTimeCat = true;
            fetchMedCategories(`${process.env.REACT_APP_BASE_URL}/medicine/category/?limit=100&ofset=0`);

          }

        })
        .catch(err => {
          setCategoryAddEdit(false);
          console.log('category add error: ', err);
          toast.error('Unable to add category, try again.', { autoClose: 3000 });
        })
    }

  }


  const deleteCategory = (catId) => {
    setCategoryDeleteId(catId);
    setCategoryDeleting(true);

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/medicine/category/${catId}/`
      )
      .then((resp) => {
        // console.log(resp);
        setCategoryDeleteId(0);
        setCategoryDeleting(false);
        toast.success(`Category deleted successfully.`, { autoClose: 3000, });
        setCategories(Categories.filter(item => item.id !== catId));
      })
      .catch((error) => {
        setCategoryDeleteId(0);
        setCategoryDeleting(false);
        console.log(error.response);
        toast.success(`Unable to delete category, try again..`, { autoClose: 3000, });
      });
  }


  const handleEditPress = (category) => {
    console.log(category);
    setCategoryId(category.id);
    setCategoryImage(category.image);
    setCategoryDescription(category.description);
    setCategoryName(category.name);
    setCategoryParent(category.parent)
  }


  return (
    <>
      <form onSubmit={e => { e.preventDefault(); onSubmitOp() }}>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Name * </label>
          <div className='col-lg-9 col-xl-9'>
            <input value={medicine.name} placeholder='Medicine Name' className='form-control form-control-solid form-control-lg' type='text'
              onChange={(e) => setState({ medicine: { ...medicine, name: e.target.value } })}
            />
          </div>
        </div>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Strength </label>
          <div className='col-lg-9 col-xl-9'>
            <input value={medicine.strength} placeholder='Medicine Strength' className='form-control form-control-solid form-control-lg' type='text'
              onChange={(e) => setState({ medicine: { ...medicine, strength: e.target.value } })}
            />
          </div>
        </div>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Form * </label>
          <div className='col-lg-9 col-xl-9'>
            <input value={medicine.form} placeholder='Medicine Form' className='form-control form-control-solid form-control-lg' type='text'
              onChange={(e) => setState({ medicine: { ...medicine, form: e.target.value } })}
            />
          </div>
        </div>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Unit </label>
          <div className='col-lg-9 col-xl-9'>
            <input value={medicine.unit} placeholder='Medicine Unit' className='form-control form-control-solid form-control-lg' type='text'
              onChange={(e) => setState({ medicine: { ...medicine, unit: e.target.value } })}
            />
          </div>
        </div>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Rx. Req </label>
          <div className='col-lg-9 col-xl-9'>
            {/* <input value={medicine.rx_req} placeholder='Medicine Rx. Req' className='form-control  form-control-lg' type='text'
              onChange={(e) => setState({ medicine: { ...medicine, rx_req: e.target.value } })}
            /> */}
            <div className="form-control-solid form-control-lg form-check form-switch form-check-custom form-check-solid form-control form-control-solid form-control-lg" style={{ padding: '0.825rem 1.5rem' }} >
              <input className="form-check-input form-check-custom" type="checkbox" value=""
                checked={medicine ? (medicine.rx_req === 'TRUE' ? true : false) : false}
                onChange={e => {
                  e.target.checked ? setState({ medicine: { ...medicine, rx_req: 'TRUE' } }) : setState({ medicine: { ...medicine, rx_req: 'FALSE' } });
                }} id="flexSwitchDefault" />
              <label className="form-check-label text-placeholder-color ms-5" htmlFor="flexSwitchDefault">
                {medicine ? (medicine.rx_req ? medicine.rx_req : 'No entry for rx_req yet') : 'No entry for rx_req yet'}
              </label>
            </div>
          </div>
        </div>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Rob </label>
          <div className='col-lg-9 col-xl-9'>
            {/* <input value={medicine.rob} placeholder='Medicine Rob' className='form-control form-control-solid form-control-lg' type='text'
              onChange={(e) => setState({ medicine: { ...medicine, rob: e.target.value } })}
            /> */}
            <div className="form-control-solid form-control-lg form-check form-switch form-check-custom form-check-solid form-control form-control-solid form-control-lg" style={{ padding: '0.825rem 1.5rem' }} >
              <input className="form-check-input form-check-custom" type="checkbox" value=""
                checked={medicine ? (medicine.rob === 'TRUE' ? true : false) : false}
                onChange={e => {
                  e.target.checked ? setState({ medicine: { ...medicine, rob: 'TRUE' } }) : setState({ medicine: { ...medicine, rob: 'FALSE' } });
                }} id="flexSwitchDefault" />
              <label className="form-check-label text-placeholder-color ms-5" htmlFor="flexSwitchDefault">
                {medicine ? (medicine.rob ? medicine.rob : 'No entry for rob yet') : 'No entry for rob yet'}
              </label>
            </div>
          </div>
        </div>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Price * </label>
          <div className='col-lg-9 col-xl-9'>
            <input value={medicine.price} placeholder='Medicine Price' className='form-control form-control-solid form-control-lg' type='number'
              onChange={(e) => setState({ medicine: { ...medicine, price: e.target.value } })}
            />
          </div>
        </div>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Discount Price * </label>
          <div className='col-lg-9 col-xl-9'>
            <input value={medicine.discount_price} placeholder='Medicine Discount Price' className='form-control form-control-solid form-control-lg' type='number'
              onChange={(e) => setState({ medicine: { ...medicine, discount_price: e.target.value } })}
            />
          </div>
        </div>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Generic * </label>
          <div className='col-lg-9 col-xl-9'>
            <input value={medicine.generic} placeholder='Medicine Generic' className='form-control form-control-solid form-control-lg' type='text'
              onChange={(e) => setState({ medicine: { ...medicine, generic: e.target.value } })}
            />
          </div>
        </div>
        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Company * </label>
          <div className='col-lg-9 col-xl-9'>
            <input value={medicine.company} placeholder='Medicine Company' className='form-control form-control-solid form-control-lg' type='text'
              onChange={(e) => setState({ medicine: { ...medicine, company: e.target.value } })}
            />
          </div>
        </div>
        <div className=" form-group row mb-3">
          {/* <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Image </label>
          <div className='col-lg-9 col-xl-9'>
            <input placeholder='Medicine Image' className='form-control form-control-solid form-control-lg' type='file'
              onChange={(e) => setState({ medicine: { ...medicine, name: e.target.value } })}
            />
          </div> */}

          <label className='col-xl-3 col-lg-3 col-form-label'>
            Medicine Image
          </label>
          <div className='col-lg-9 col-xl-9'>
            {medicine.image && <div
              className='image-input image-input-outline'
              id='kt_profile_avatar'
              style={medicine.image ?
                { backgroundImage: `url("${medicine.image}")`, }
                : { backgroundImage: `url(${Blank})` }
              } >
              <div className='image-input-wrapper'>
                <img src={medicine.image} alt="nothing" className="w-100 h-100" />
              </div>
            </div>}
            <div className='custom-file-med form-control form-control-solid form-control-lg' onClick={e => imgInputRef.current.click()}>
              <input
                ref={imgInputRef}
                type='file'
                className='custom-file-med-input d-none'
                accept='image/x-png,image/gif,image/jpeg'
                onChange={(e) => setState({ uploadMedicineImage: e.target.files[0] })}
              />
              <label className='custom-file-med-label d-flex align-items-center text-placeholder-color'>
                {uploadMedicineImage ? uploadMedicineImage.name : (medicine.image ? 'Change Medicine Image' : 'Upload Medicine Image')}
                <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow position-absolute d-none" style={{ top: '-12px', left: '-12px', height: 'auto', width: 'auto', padding: '5px 7px', backgroundColor: '#FFF5' }} >
                  <i className="fas fa-times icon-xs text-muted"></i>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Category * </label>
          <div className='col-lg-9 col-xl-9'>
            {/* <input placeholder='Medicine Category' className='form-control form-control-solid form-control-lg' type='text'
              onChange={(e) => setState({ medicine: { ...medicine, category: { name: e.target.value } } })}
            /> */}
            <div className="d-flex">
              <select className="form-select form-select-solid category-select me-3"
                onChange={e => {
                  console.log(e.target.value); // need to change here
                  setState({ medicine: { ...medicine, category: parseInt(e.target.value) } });
                }}
                value={parseInt(medicine.category) ? `${medicine.category}` : '0'} >
                <option className="text-placeholder-color" value='0' disabled={true} >{CategoriesLoading ? ('Loading category Data') : ('Select a category')}</option>
                {!!Categories.length && Categories.map((category, index) => (
                  <option key={index} value={`${category.id}`} >{category.name}</option>
                ))
                }
              </select>
              <div className="">
                <button onClick={e => { e.preventDefault(); setCategoryModalDisplay(true); }} className='btn btn-primary font-weight-bolder fs-2 px-6 py-0 h-100 justify-content-center' >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className=" form-group row mb-3">
          <label className='col-xl-3 col-lg-3 col-form-label'>Medicine Comment </label>
          <div className='col-lg-9 col-xl-9'>
            {/* <input placeholder='Medicine Comment' className='form-control form-control-solid form-control-lg' type='text'
            onChange={(e) => setState({ medicine: { ...medicine,comment: e.target.value} })}
            /> */}
            <textarea value={medicine.comment} placeholder="Medicine Comment" className="form-control form-control-solid form-control-lg " rows="3" id="medicineTextArea"
              onChange={(e) => setState({ medicine: { ...medicine, comment: e.target.value } })} ></textarea>
          </div>
        </div>
        <div className=" form-group row my-5">
          <label className='col-xl-3 col-lg-3 col-form-label' />
          <div className='col-lg-9 col-xl-9'>
            <button type='submit' className='btn btn-primary font-weight-bolder px-9 py-4 d-flex' disabled={loading} >
              <div>{submitBtnText ? submitBtnText : 'Add Medicine'}</div>
              {loading && <div className="ms-5">
                <Spinner animation='border' size='sm' />
              </div>}
            </button>
          </div>
        </div>

      </form>

      <div className="mt-0 py-10 px-10 category-modal-wrapper" style={{ display: CategoryModalDisplay ? 'block' : 'none' }}  >
        <div className="card pt-8 p-10">
          <div className="row mb-4">
            <label className='col-xl-3 col-lg-3' />
            <div className='col-lg-9 col-xl-9 d-flex justify-content-end'>
              <button className='btn btn-danger font-weight-bolder px-9 py-4' onClick={e => { e.preventDefault(); setCategoryModalDisplay(false) }} >
                Close
              </button>
            </div>
          </div>

          <form onSubmit={e => { e.preventDefault(); CategoryId ? handleEditCategory() : handleAddCategory() }} >
            <div className=" form-group row mb-3">
              <label className='col-xl-3 col-lg-3 col-form-label'>Category Name * </label>
              <div className='col-lg-9 col-xl-9'>
                <input value={CategoryName} placeholder='Category Name' className='form-control form-control-solid form-control-lg' type='text'
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </div>
            </div>

            <div className=" form-group row mb-3">
              <label className='col-xl-3 col-lg-3 col-form-label'>Category Description </label>
              <div className='col-lg-9 col-xl-9'>
                <textarea value={CategoryDescription} placeholder="Category Description" className="form-control form-control-solid form-control-lg " rows="3" id="medicineTextArea"
                  onChange={(e) => setCategoryDescription(e.target.value)} ></textarea>
              </div>
            </div>

            <div className=" form-group row mb-3">
              <label className='col-xl-3 col-lg-3 col-form-label'>Category Image </label>
              <div className='col-lg-9 col-xl-9'>
                <div className='custom-file-med form-control form-control-solid form-control-lg' onClick={e => catImgInputRef.current.click()}>
                  <input
                    ref={catImgInputRef}
                    type='file'
                    className='custom-file-med-input d-none'
                    accept='image/x-png,image/gif,image/jpeg'
                    onChange={(e) => setCategoryImage(e.target.files[0])}
                  />
                  <label className='custom-file-med-label d-flex align-items-center text-placeholder-color'>
                    {CategoryImage ? ((typeof CategoryImage == 'string') ? (CategoryImage.length < 50 ? CategoryImage : CategoryImage.substring(0, 50) + '...') : (CategoryImage.name.length < 50 ? CategoryImage.name : CategoryImage.name.substring(0, 50) + '...')) : 'Upload Medicine Image'}
                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow position-absolute d-none" style={{ top: '-12px', left: '-12px', height: 'auto', width: 'auto', padding: '5px 7px', backgroundColor: '#FFF5' }} >
                      <i className="fas fa-times icon-xs text-muted"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className=" form-group row mb-3">
              <label className='col-xl-3 col-lg-3 col-form-label'>Category Parent </label>
              <div className='col-lg-9 col-xl-9'>
                <select className="form-select form-select-solid category-select"
                  onChange={e => {
                    console.log(e.target.value) // need to change here
                    setCategoryParent(e.target.value)
                  }}
                  value={CategoryParent} >
                  <option className="text-placeholder-color" value='0' disabled={true} >{CategoriesLoading ? 'Loading category Data' : 'Select a parent category'}</option>
                  {!!Categories.length && Categories.map((category, index) => (
                    <option key={index} value={`${category.id}`} >{category.name}</option>
                  ))
                  }
                </select>
              </div>
            </div>

            <div className=" form-group row mt-5 mb-5">
              <label className='col-xl-3 col-lg-3 col-form-label' />
              <div className='col-lg-9 col-xl-9'>
                <button type='submit' className='btn btn-primary font-weight-bolder px-9 py-4' disabled={CategoryAddEdit} >
                  {CategoryId ? 'Update Category' : 'Add Category'}
                  {CategoryAddEdit && <span className="ms-5">
                    <Spinner animation='border' size='sm' />
                  </span>}
                </button>
                <button type='button' onClick={e => { e.preventDefault(); resetCategoryData() }} className='btn btn-primary font-weight-bolder px-9 py-4 mx-5' >
                  Clear
                </button>
              </div>
            </div>

          </form>

          {/* list of category */}
          <div className="border border-2 px-5 py-2 overflow-auto">
            <div className="" style={{ maxHeight: '250px', }} >
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                <thead>
                  <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 category-list">
                    {/* <th className="w-10px pe-2 sorting_disabled" rowSpan="1" colSpan="1" aria-label="" style={{ width: '29.25px' }}>
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                        <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                      </div>
                    </th> */}
                    <th className="min-w-150px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending" style={{ width: '205px' }}>Name</th>
                    <th className="min-w-75px sorting" tabIndex="1" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Image: nothing to do, just watch image." style={{ width: '150px' }}>Image</th>
                    <th className="min-w-125px sorting" tabIndex="1" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Form: activate to sort column ascending" style={{ width: '150px' }}>Description</th>
                    <th className="min-w-125px sorting" tabIndex="2" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending" style={{ width: '150px' }}>Parent</th>
                    <th className="min-w-125px sorting" tabIndex="5" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Category: activate to sort column ascending" style={{ width: '150px' }}>Action</th>
                    {/* <th className="text-end min-w-70px sorting_disabled" rowSpan="1" colSpan="1" aria-label="Actions" style={{ width: '118.422px' }}>Category</th> */}
                  </tr>
                </thead>
                <tbody className="fw-bold text-gray-600">
                  {Categories.length && Categories.map((category, index) => (
                    <tr key={index} className="category-list">
                      {/* <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input className="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td> */}
                      <td>
                        <div className="text-gray-600 text-hover-primary mb-0">{category.name}</div>
                      </td>
                      <td>
                        <div className="text-gray-600 text-hover-primary mb-0">
                          <div className="w-50px rounded d-flex justify-content-center align-items-center" style={{ border: '1px solid #7C839950', padding: '5px 1px', height: '30px' }} >
                            {category.image ?
                              <img src={category.image} alt="No category" className="h-100 mw-75 rounded" />
                              : <i className='fas fa-pills fs-2' />
                            }
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="text-gray-600 text-hover-primary mb-0">{category.description ? category.description : 'Not available'}</div>
                      </td>
                      <td>
                        <div className="text-gray-600 text-hover-primary mb-0">{category.parent ? category.parent : 'Not available'}</div>
                      </td>
                      <td >
                        <div onClick={e => {
                          e.preventDefault();
                          if (loading) console.log('wait')
                          else handleEditPress(category);
                          // console.log('click2: ', this.state);
                        }} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#kt_modal_edit">

                          <span className="svg-icon svg-icon-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none">
                              <path opacity="0.3"
                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                fill="black" />
                              <path
                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                fill="black" />
                            </svg>
                          </span>
                        </div>
                        <div onClick={e => {
                          if (loading) console.log('wait')
                          else {
                            if (window.confirm(" Are you sure to delete this medicine category? ")) deleteCategory(category.id);
                          }
                        }} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">

                          <span className="svg-icon svg-icon-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none">
                              <path
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="black" />
                              <path opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="black" />
                              <path opacity="0.5"
                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="black" />
                            </svg>
                          </span>

                        </div>
                        {(CategoryDeleteId === category.id && CategoryDeleting) && <div className="btn btn-icon btn-sm ms-2">
                          <Spinner animation='border' size='sm' /> <span className="ms-2 text-gray-600" style={{ fontFamily: 'Poppins' }} ></span>
                        </div>}

                      </td>

                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default MedicineDetailsForm
