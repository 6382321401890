import axios from 'axios';
import AsyncSelect from 'react-select/async';

const SelectUser = ({
  setUserDetails,
  setUserPatients,
  setHaveOrder,
  setMobile,
  editId,
  setUserOldAddress,
  setPreviousAddresses,
  setSelectedPreviousAddress,
  setIsLabPartner,
}) => {
  const checkHaveOrder = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/user-order-exist/${id}`)
      .then((resp) => {
        // console.log(resp.data);
        setHaveOrder(resp.data.exist);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (e) => {
    // console.log(e);
    setUserDetails(e);
    setMobile(e.username);

    if (e.groups.length !== 0) {
      // console.log(e.groups[0] === 5 ? 'true' : 'false');
      if (e.groups[0] === 5) {
        setIsLabPartner(true);
      } else {
        setIsLabPartner(false);
      }
    } else {
      setIsLabPartner(false);
    }

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient-with-info/?user=${e.id}&limit=300`
      )
      .then((resp) => {

        const results = resp.data.results
          .filter((patient) => patient.is_active)
          .map((patient) => {
            return {
              ...patient,
              value: patient.full_name,
              label: patient.full_name,
            };
          });
        // console.log(results);
        setUserPatients(results);

        const userPatient = results.filter((patient) => patient.is_account);
        // console.log(userPatient);
        if (userPatient.length !== 0) {
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${userPatient[0].id}&status=true&ordering=-id`
            )
            .then((resp) => {
              if (resp.data.results.length !== 0) {
                const tempAddrs = resp.data.results.filter(adrs => adrs.status)
                setUserOldAddress(tempAddrs, 0);
                setPreviousAddresses(tempAddrs);
                setSelectedPreviousAddress(0, false);
              } else {
                // console.log(resp.data.results);
                axios
                  .get(
                    `${process.env.REACT_APP_BASE_URL}/user_management/address/?user=${e.id}&status=true&ordering=-id`
                  )
                  .then((resp) => {
                    // console.log(resp.data);
                    if (resp.data.results.length !== 0) {
                      const tempAddrsByUser = resp.data.results.filter(adrs => adrs.status)
                      setUserOldAddress(tempAddrsByUser, 0);
                      setPreviousAddresses(tempAddrsByUser);
                      setSelectedPreviousAddress(0, false);
                    }
                  })
                  .catch((err) => {
                    console.log(err.response);
                  });
              }
            })
            .catch((error) => console.log(error.response));
        }
      });

    checkHaveOrder(e.id);
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      // resolve(filterUsers(inputValue));
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/user_management/user/?search=${inputValue}`
        )
        .then((resp) => {
          // console.log(resp.data);
          const users = resp.data.results.map((user) => {
            return {
              ...user,
              value: user.username,
              label: user.username,
              // username: user.username,
              // id: user.id,
              // first_name: user.first_name,
              // last_name: user.last_name,
            };
          });
          resolve(users);
        });
    });

  return (
    <AsyncSelect
      onChange={handleChange}
      // defaultOptions={users}
      loadOptions={promiseOptions}
      placeholder={'Search User'}
      isDisabled={editId}
    />
  );
};

export default SelectUser;
