import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderToolbar from '../components/HeaderToolbar';
import LoadingContent from '../components/LoadingContent';
import { AuthContext } from '../context/AuthContext';

class CuponDetails extends Component {
  state = {
    cupon: null,
    loading: false,
  };

  fetchCuponDetails = (id) => {
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/cupon-get/${id}`)
      .then((resp) => {
        console.log(resp.data);
        this.setState({ cupon: resp.data });
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.log(error.response);
        if (error.response.status === 404) {
          toast.error(`Sorry, no cupon found.`, {
            autoClose: 3000,
          });
          this.props.history.push('/cupon');
        }
      });
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Cupons', link: '/cupon' },
      { name: 'Cupon Details', link: '' },
    ]);
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchCuponDetails(this.props.match.params.id);
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Lab Partner' ||
        userRole.groups[0].name === 'External'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchCuponDetails(this.props.match.params.id);
      }
    }
  }

  cctName = (array) => {
    const cctName = array.map((i) => {
      return i.test.name;
    });
    return cctName.join(', ');
  };

  ccpName = (array) => {
    const cctName = array.map((i) => {
      return i.package.name;
    });
    return cctName.join(', ');
  };

  cclName = (array) => {
    const cctName = array.map((i) => {
      return i.lab.name;
    });
    return cctName.join(', ');
  };

  ccaName = (array) => {
    const cctName = array.map((i) => {
      return i.address.district;
    });
    return cctName.join(', ');
  };

  render() {
    const { cupon, loading } = this.state;

    return (
      <>
        <HeaderToolbar title='Coupon Details' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card py-8 px-8 py-lg-15 px-lg-10'>
                  <div className='row justify-content-center'>
                    <div className='col-xl-9'>
                      <div className='card-header border-0 pt-5 px-0 d-flex align-items-center justify-content-between mb-1'>
                        <h3 className='card-title align-items-start flex-column m-0'>
                          <span className='card-label font-weight-bolder text-dark'>
                            Cupon Details
                          </span>
                        </h3>
                        <div className='card-toolbar'>
                          <Link to='/cupon' className='btn btn-primary'>
                            Go Back
                          </Link>
                        </div>
                      </div>
                      {!loading ? (
                        <>
                          {cupon && (
                            <form>
                              <div className='form-group row fv-plugins-icon-container mb-3'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                  Name
                                </label>
                                <div className='col-lg-9 col-xl-9'>
                                  <input
                                    maxLength='49'
                                    className='form-control form-control-solid form-control-lg'
                                    type='text'
                                    value={cupon.name}
                                    disabled
                                  />
                                  <div className='fv-plugins-message-container' />
                                </div>
                              </div>
                              <div className='form-group row fv-plugins-icon-container mb-3'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                  Code
                                </label>
                                <div className='col-lg-9 col-xl-9'>
                                  <input
                                    maxLength='49'
                                    className='form-control form-control-solid form-control-lg'
                                    type='text'
                                    value={cupon.code}
                                    disabled
                                  />
                                  <div className='fv-plugins-message-container' />
                                </div>
                              </div>
                              <div className='form-group row fv-plugins-icon-container mb-3'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                  Rate
                                </label>
                                <div className='col-lg-9 col-xl-9'>
                                  <input
                                    maxLength='49'
                                    className='form-control form-control-solid form-control-lg'
                                    type='text'
                                    value={cupon.rate}
                                    disabled
                                  />
                                  <div className='fv-plugins-message-container' />
                                </div>
                              </div>
                              <div className='form-group row fv-plugins-icon-container mb-3'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                  Expire Date
                                </label>
                                <div className='col-lg-9 col-xl-9'>
                                  <input
                                    maxLength='49'
                                    className='form-control form-control-solid form-control-lg'
                                    type='text'
                                    value={moment(cupon.expired_at).format(
                                      'MMM DD, YYYY'
                                    )}
                                    disabled
                                  />
                                  <div className='fv-plugins-message-container' />
                                </div>
                              </div>
                              <div className='form-group row fv-plugins-icon-container mb-3'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                  Criteria
                                </label>
                                <div className='col-lg-9 col-xl-9'>
                                  <input
                                    maxLength='49'
                                    className='form-control form-control-solid form-control-lg'
                                    type='text'
                                    value={cupon.criteria}
                                    disabled
                                  />
                                  <div className='fv-plugins-message-container' />
                                </div>
                              </div>
                              <div className='form-group row fv-plugins-icon-container mb-3'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                  Is Active
                                </label>
                                <div className='col-lg-9 col-xl-9'>
                                  <input
                                    maxLength='49'
                                    className='form-control form-control-solid form-control-lg'
                                    type='text'
                                    value={cupon.is_active}
                                    disabled
                                  />
                                  <div className='fv-plugins-message-container' />
                                </div>
                              </div>
                              <div className='form-group row fv-plugins-icon-container mb-3'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                  New User Only
                                </label>
                                <div className='col-lg-9 col-xl-9'>
                                  <input
                                    maxLength='49'
                                    className='form-control form-control-solid form-control-lg'
                                    type='text'
                                    value={cupon.new_user_only}
                                    disabled
                                  />
                                  <div className='fv-plugins-message-container' />
                                </div>
                              </div>
                              <div className='form-group row fv-plugins-icon-container mb-3'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                  Collection Fees
                                </label>
                                <div className='col-lg-9 col-xl-9'>
                                  <input
                                    maxLength='49'
                                    className='form-control form-control-solid form-control-lg'
                                    type='text'
                                    value={cupon.is_collection_fee}
                                    disabled
                                  />
                                  <div className='fv-plugins-message-container' />
                                </div>
                              </div>
                              <div className='form-group row fv-plugins-icon-container mb-3'>
                                <label className='col-xl-3 col-lg-3 col-form-label'>
                                  Material Fees
                                </label>
                                <div className='col-lg-9 col-xl-9'>
                                  <input
                                    maxLength='49'
                                    className='form-control form-control-solid form-control-lg'
                                    type='text'
                                    value={cupon.is_material_fee}
                                    disabled
                                  />
                                  <div className='fv-plugins-message-container' />
                                </div>
                              </div>
                              {cupon.is_material_fee && (
                                <div className='form-group row fv-plugins-icon-container mb-3'>
                                  <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Material Percentage
                                  </label>
                                  <div className='col-lg-9 col-xl-9'>
                                    <input
                                      maxLength='49'
                                      className='form-control form-control-solid form-control-lg'
                                      type='text'
                                      value={cupon.material_rate_percentage}
                                      disabled
                                    />
                                    <div className='fv-plugins-message-container' />
                                  </div>
                                </div>
                              )}
                              {cupon.cct.length !== 0 && (
                                <div className='form-group row fv-plugins-icon-container mb-3'>
                                  <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Selected Tests
                                  </label>
                                  <div
                                    className='col-lg-9 col-xl-9 pt-4'
                                    style={testScroll}
                                  >
                                    {cupon.cct.map((test, i) => (
                                      <div
                                        key={i}
                                        style={{ fontWeight: '500' }}
                                      >
                                        {i + 1}. {test.test.name}
                                      </div>
                                    ))}
                                    <div className='fv-plugins-message-container' />
                                  </div>
                                </div>
                              )}
                              {cupon.ccp.length !== 0 && (
                                <div className='form-group row fv-plugins-icon-container mb-3'>
                                  <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Selected Packages
                                  </label>
                                  <div
                                    className='col-lg-9 col-xl-9 pt-4 '
                                    style={testScroll}
                                  >
                                    {cupon.ccp.map((test, i) => (
                                      <div
                                        key={i}
                                        style={{ fontWeight: '500' }}
                                      >
                                        {i + 1}. {test.package.name}
                                      </div>
                                    ))}
                                    <div className='fv-plugins-message-container' />
                                  </div>
                                </div>
                              )}
                              {cupon.ccl.length !== 0 && (
                                <div className='form-group row fv-plugins-icon-container mb-3'>
                                  <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Selected Labs
                                  </label>
                                  <div className='col-lg-9 col-xl-9'>
                                    <input
                                      maxLength='49'
                                      className='form-control form-control-solid form-control-lg'
                                      type='text'
                                      value={this.cclName(cupon.ccl)}
                                      disabled
                                    />
                                    <div className='fv-plugins-message-container' />
                                  </div>
                                </div>
                              )}
                              {cupon.cca.length !== 0 && (
                                <div className='form-group row fv-plugins-icon-container mb-3'>
                                  <label className='col-xl-3 col-lg-3 col-form-label'>
                                    Selected Address
                                  </label>
                                  <div className='col-lg-9 col-xl-9'>
                                    <input
                                      maxLength='49'
                                      className='form-control form-control-solid form-control-lg'
                                      type='text'
                                      value={this.ccaName(cupon.cca)}
                                      disabled
                                    />
                                    <div className='fv-plugins-message-container' />
                                  </div>
                                </div>
                              )}
                            </form>
                          )}
                        </>
                      ) : (
                        <LoadingContent name={'Loading Details...'} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const testScroll = {
  maxHeight: '700px',
  overflow: 'scroll',
  fontWeight: '500',
  color: '#3F4254',
};

export default CuponDetails;
CuponDetails.contextType = AuthContext;
