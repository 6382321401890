import moment from 'moment';
import { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import AngleDown from '../../assets/img/angle-down.png';

const SeeAllPopover = (props) => {
  const {
    title,
    listToShow,
    renderListItem,
    disableRightAction = true,
    rightActionText,
    handleRightAction,
    handleIClick,
    leftActionText,
    handleLeftAction,
  } = props;

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (e) => {
    setShow(!show);
    setTarget(e.target);
    handleIClick();
  };

  return (
    <div
      ref={ref}
      className='select-pop'
      tabIndex={-1}
      onBlur={() => setShow(false)}
    >
      {listToShow.length > 1 && (
        <div
          className='d-flex flex-row align-items-center pointer'
          onClick={(e) => handleClick(e)}
        >
          <div className='text-dark text-center'>Show All</div>
          <div style={{ marginLeft: '0px', marginBottom: '1px' }}>
            <img
              className='pointer'
              src={AngleDown}
              alt=''
              style={{
                width: '15px',
                height: '8px',
                opacity: 0.8,
                transform: 'rotate(-90deg) scaleY(1.1) scaleX(0.8)',
              }}
            />
          </div>
        </div>
      )}

      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover id='popover-contained'>
          <Popover.Content>
            <p className='font-semibold mb-3'>{title}</p>
            <div
              className='text-dim-dark'
              style={{ maxHeight: '250px', overflow: 'auto' }}
            >
              {listToShow.map((item, i) => renderListItem(item, i))}
            </div>
            <div className='new-footer d-flex justify-content-between mt-10'>
              <button
                className='btn btn-sm btn-cancel'
                onClick={() => {
                  setShow(false);
                  if (handleLeftAction) handleLeftAction();
                }}
              >
                {leftActionText || `Cancel`}
              </button>

              {!disableRightAction && (
                <button
                  className='btn btn-sm btn-primary btn-save'
                  onClick={() => {
                    setShow(false);
                    if (handleRightAction) handleRightAction();
                  }}
                >
                  {rightActionText || `Save`}
                </button>
              )}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default SeeAllPopover;
