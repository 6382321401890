import axios from 'axios';
import React, { Component } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import warningLogo from '../assets/img/warning.svg';
import { AuthContext } from '../context/AuthContext';

import Pagination from '../components/Pagination/Pagination';
import HeaderToolbar from './HeaderToolbar';

class Users extends Component {
  state = {
    users: null,
    user_id: null,
    group: [],
    showModal: false,
    deleteId: null,
    next: null,
    offset: 10,
    limit: 10,
    totalUsers: null,

    count: 0,
    pagination: 1,
    searchText: '',
    loading: false,

    showFilterDrop: false,
  };

  fetchUsers = ({ limit, pagination }, seatchText, otherfilters = '') => {
    let fetchuserUrl = `${
      process.env.REACT_APP_BASE_URL
    }/user_management/user-list/?page=1&limit=${limit}&offset=${
      (pagination - 1) * limit
    }${otherfilters}`;
    if (!!seatchText) {
      fetchuserUrl = `${
        process.env.REACT_APP_BASE_URL
      }/user_management/user-list/?page=1&search=${seatchText}&limit=${limit}&offset=${
        (pagination - 1) * limit
      }${otherfilters}`;
    }

    this.setState({ loading: true });
    axios
      .get(fetchuserUrl)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ users: resp.data.results });
        this.setState({ next: resp.data.next, count: resp.data.count });
        this.setState({ totalUsers: resp.data.count });
        this.setState({ loading: false });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({ loading: false });
      });
  };

  fetchGroup = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user_management/group/`)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ group: resp.data.results });
      });
  };

  groupIdToName = (grp_id) => {
    const array = this.state.group.filter((group) => {
      return group.id === grp_id;
    });
    const group = array[0];
    // return group ? group.name : 'User';

    if (group) {
      // return group.name;
      if (group.name === 'Lab Partner') {
        return 'Partner';
      } else {
        return group.name;
      }
    } else {
      return 'User';
    }
  };

  componentDidMount() {
    this.context.setPageTitle('Users');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Users', link: '/users' },
    ]);
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchUsers(this.state);
      this.fetchGroup();
      if (user_details) {
        const user = JSON.parse(user_details);
        this.setState({ user_id: user.id });
      }
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (userRole.groups[0].name !== 'Admin') {
        this.props.history.push('/orders');
      } else {
        this.fetchUsers(this.state);
        this.fetchGroup();
        if (user_details) {
          const user = JSON.parse(user_details);
          this.setState({ user_id: user.id });
        }
      }
    }
  }

  handleFltrByGroup = (e) => {
    this.setState({ showFilterDrop: false });
    if (e.target.value === '') {
      this.props.history.push(`/users`);
      this.fetchUsers(this.state, this.state.searchText);
    } else {
      this.props.history.push(`/users?groups__name=${e.target.value}`);
      this.fetchUsers(
        this.state,
        this.state.searchText,
        `&groups__name=${e.target.value}`
      );
      // this.filterByStatus('orderdetail__order_status', e.target.value);
      // this.setState({ loading: false });
    }
  };

  deleteUser = (user) => {
    // console.log(user.id);

    this.setState({ showModal: !this.state.showModal });
    this.setState({ deleteId: user.id });

    // axios
    //   .delete(
    //     `${process.env.REACT_APP_BASE_URL}/user_management/user/${user.id}/`
    //   )
    //   .then((resp) => {
    //     // console.log(resp.data);
    //     this.setState({
    //       users: this.state.users.filter((use) => use.id !== user.id),
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
  };

  handleModalDlt = () => {
    // console.log(this.state.deleteId);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/${this.state.deleteId}/`
      )
      .then((resp) => {
        this.setState({
          users: this.state.users.filter(
            (use) => use.id !== this.state.deleteId
          ),
        });
        this.setState({ showModal: false });
        toast.success(`User deleted successfully.`, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  loadMore = () => {
    this.setState({ offset: this.state.offset + 10 });
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/?limit=${this.state.limit}&offset=${this.state.offset}&ofset=0&page=1`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ users: [...this.state.users, ...resp.data.results] });
        this.setState({ next: resp.data.next });
      });
  };

  handleSearch = (searchText) => {
    this.setState({ searchText: searchText, pagination: 1 });
    if (!searchText)
      this.fetchUsers({ limit: this.state.limit, pagination: 1 });
    else
      this.fetchUsers({ limit: this.state.limit, pagination: 1 }, searchText);
  };

  handlePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    if (this.state.searchText)
      this.fetchUsers(
        { limit: this.state.limit, pagination: pageno },
        this.state.searchText
      );
    else this.fetchUsers({ limit: this.state.limit, pagination: pageno });
    // this.fetchUsers({ limit: this.state.limit, pagination: pageno });
  };

  getPartnerId = (user) => {
    if (user.patient.length === 0) {
      return '-';
    } else {
      const userPatient = user.patient.filter((patient) => patient.is_account);
      if (userPatient.length !== 0) {
        if (userPatient[0].partner_id) {
          return userPatient[0].partner_id;
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    }
  };

  havePartnerId = (user) => {
    if (user.patient.length === 0) {
      return false;
    } else {
      const userPatient = user.patient.filter((patient) => patient.is_account);
      if (userPatient.length !== 0) {
        if (userPatient[0].partner_id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  handlePartnerIdCopy = (user) => {
    const textField = document.createElement('textarea');
    textField.innerText = this.getPartnerId(user);
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toast.success('Partner ID has been copied!');
  };

  render() {
    const {
      users,
      user_id,
      showModal,
      count,
      limit,
      pagination,
      loading,
      // next,
      // totalUsers,
      searchText,
      showFilterDrop,
      group,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Users' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      {/* <span className='card-label font-weight-bolder text-dark'>
                  All Users
                </span> */}
                      <div className='d-flex align-items-center position-relative my-1'>
                        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <rect
                              opacity='0.5'
                              x='17.0365'
                              y='15.1223'
                              width='8.15546'
                              height='2'
                              rx='1'
                              transform='rotate(45 17.0365 15.1223)'
                              fill='black'
                            ></rect>
                            <path
                              d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                              fill='black'
                            ></path>
                          </svg>
                        </span>
                        <input
                          type='text'
                          data-kt-customer-table-filter='search'
                          className='form-control form-control-solid w-250px ps-15'
                          placeholder='Search user by mobile'
                          // ref={this.searchRef}
                          // disabled={loading}
                          value={searchText}
                          onChange={(e) => this.handleSearch(e.target.value)}
                        />
                      </div>
                      <span className='text-muted mt-3 font-weight-bold fs-7 ps-1'>
                        {`Total  ${count ? count + '+ ' : '00'} ${
                          count ? `users` : `user`
                        } ${searchText && `found`}`}
                      </span>
                    </h3>
                    <div className='card-toolbar'>
                      <div className='position-relative'>
                        <button
                          onClick={() =>
                            this.setState({ showFilterDrop: !showFilterDrop })
                          }
                          type='button'
                          className={`btn btn-light-primary me-3 ${
                            showFilterDrop ? 'show menu-dropdown' : ''
                          }`}
                        >
                          <span className='svg-icon svg-icon-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <path
                                d='M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          Filter
                        </button>

                        <div
                          className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px menu-user-dropdown ${
                            showFilterDrop ? 'show' : ''
                          }`}
                        >
                          <div className='px-7 py-5'>
                            <div className='fs-4 text-dark fw-bolder'>
                              Filter Options
                            </div>
                          </div>
                          <div className='separator border-gray-200'></div>
                          <div className='px-7 py-5'>
                            <div
                              style={{
                                maxHeight: '250px',
                                overflowY: 'scroll',
                              }}
                            >
                              <div className='mb-7'>
                                <label className='form-label fs-5 fw-bold mb-1'>
                                  Filter By User Group:
                                </label>
                                <select
                                  className='form-select form-select-md form-select-solid fw-bolder'
                                  onChange={this.handleFltrByGroup}
                                >
                                  <option value=''>Select Option</option>
                                  <option value=''>All</option>
                                  {group &&
                                    group.map((grp, idx) => (
                                      <option key={idx} value={grp.name}>
                                        {grp.name === 'Lab Partner'
                                          ? 'Partner'
                                          : grp.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className='d-flex justify-content-end mt-7'>
                              <button
                                onClick={() =>
                                  this.setState({ showFilterDrop: false })
                                }
                                type='reset'
                                className='btn btn-light btn-active-light-primary me-2'
                              >
                                Close
                              </button>
                              <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={() => {
                                  this.fetchUsers(
                                    this.state,
                                    this.state.searchText
                                  );
                                  this.props.history.push('/users');
                                  this.setState({ showFilterDrop: false });
                                }}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Link to='/user-add' className='btn btn-primary'>
                        Add New User
                      </Link>
                    </div>
                  </div>
                  <div className='card-body pt-2 pb-0 mt-3'>
                    <div className='table-responsive custom_scrollbar'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle fs-6 no-footer gy-5'>
                        <thead className='text-gray-600'>
                          <tr className='text-start text-gray-600 fw-bolder fs-7 no-footer text-uppercase gs-0'>
                            <th className='min-w-50px'>Idx</th>
                            {/* <th className='p-0 min-w-150px'></th> */}
                            <th className='min-w-140px'>Details</th>
                            <th className='min-w-140px'>Role</th>
                            <th className='min-w-110px'>Partner Id</th>
                            <th className='min-w-110px'>Status</th>
                            <th className='min-w-150px'>Actions</th>
                          </tr>
                        </thead>
                        <tbody className='fw-normal text-gray-500'>
                          {users &&
                            users.map((user, i) => (
                              <tr key={i}>
                                <td className='align-start fs-6 py-1'>
                                  <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                    #{i + 1}
                                  </span>
                                </td>
                                {/* <td className='align-start fs-6 fw-bold text-center py-1'>
                                  <div className='symbol symbol-50 symbol-light mr-2'>
                                    <span className='symbol-label'>
                                      <img
                                        src='https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/avatars/001-boy.svg'
                                        className='h-50 align-self-center'
                                        alt=''
                                      />
                                    </span>
                                  </div>
                                </td> */}
                                <td className='align-start fs-6'>
                                  <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                    {user.first_name
                                      ? `${user.first_name} ${user.last_name} (${user.username})`
                                      : user.username}
                                  </span>
                                </td>
                                <td className='align-start fs-6'>
                                  <span className='text-muted font-weight-bold d-block font-size-lg'>
                                    {user.is_superuser
                                      ? 'Admin'
                                      : this.groupIdToName(user.groups[0])}
                                  </span>
                                </td>
                                <td className='align-start fs-6'>
                                  {this.getPartnerId(user)}
                                  {this.havePartnerId(user) ? (
                                    <i
                                      className='fa fa-copy pointer ms-2 text-green'
                                      onClick={() =>
                                        this.handlePartnerIdCopy(user)
                                      }
                                    ></i>
                                  ) : (
                                    ''
                                  )}
                                </td>
                                <td className='align-start fs-6'>
                                  <span className='text-muted font-weight-bold d-block font-size-lg'>
                                    {user.is_active ? (
                                      <span className='text-green'>Active</span>
                                    ) : (
                                      <span className='text-danger'>
                                        Inactive
                                      </span>
                                    )}
                                  </span>
                                </td>
                                <td className='align-start fs-6'>
                                  <>
                                    {user_id === user.id ? (
                                      <button
                                        disabled
                                        className='btn label label-lg btn-light-primary label-inline btn-sm me-3'
                                      >
                                        Edit
                                      </button>
                                    ) : (
                                      <Link
                                        to={`/user/${user.id}`}
                                        className='btn label label-lg btn-light-primary label-inline btn-sm me-3'
                                      >
                                        Edit
                                      </Link>
                                    )}
                                    {user_id === user.id ? (
                                      <button
                                        disabled
                                        className='btn label label-lg btn-light-danger label-inline btn-sm'
                                      >
                                        Delete
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => this.deleteUser(user)}
                                        // onClick={() => {
                                        //   if (
                                        //     window.confirm(
                                        //       'Are you sure to delete this user?'
                                        //     )
                                        //   )
                                        //     this.deleteUser(user);}}
                                        className='btn label label-lg btn-light-danger label-inline btn-sm'
                                      >
                                        Delete
                                      </button>
                                    )}
                                  </>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {/* <p className='text-center mb-15 mt-5'>
                <button className='btn btn-primary mb-0' disabled={next ? '' : 'disabled'}
                  onClick={this.loadMore} >
                  SHow More
                </button>
              </p> */}
                    <div className='row justify-content-end mt-3 mb-10'>
                      {loading && (
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                          <Spinner animation='border' size='sm' />{' '}
                          <span
                            className='ms-2 text-gray-600'
                            style={{ fontFamily: 'Poppins' }}
                          >
                            loading, Please wait...
                          </span>
                        </div>
                      )}
                      <Pagination
                        onPageChange={(pageno) =>
                          this.handlePaginationPress(pageno)
                        }
                        totalCount={count}
                        pageSize={limit}
                        currentPage={pagination}
                        siblingCount={1}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                show={showModal}
                onHide={() => this.setState({ showModal: !showModal })}
                animation={false}
                centered
                className='delete_confirmation'
              >
                <div className='modal-body mx-auto'>
                  <p className='text-center icon mb-0'>
                    <img src={warningLogo} alt='' />
                  </p>
                  <div className='details'>
                    <h5>Are you sure?</h5>
                    <p>Once delete, it will be permanently deleted.</p>
                  </div>
                  <p className='buttons mb-0 text-center'>
                    <button
                      className='btn btn-primary me-2'
                      onClick={() => this.setState({ showModal: !showModal })}
                    >
                      No
                    </button>
                    <button
                      className='btn btn-danger ml-1'
                      onClick={this.handleModalDlt}
                    >
                      Yes
                    </button>
                  </p>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const trStyle = {
  borderTop: '1px solid #d4d4d4',
};

export default Users;
Users.contextType = AuthContext;
