import moment from 'moment';
import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';

const CampainModal = ({ showCampainModal, setShowCampainModal, mainOrder }) => {
  return (
    <Modal
      show={showCampainModal}
      onHide={() => setShowCampainModal(false)}
      animation={false}
    >
      <ModalBody>
        <div className='campain-modal'>
          <div className='d-flex justify-content-between align-items-center mb-5'>
            <h5 className='m-0'>Campain Details</h5>
            <button
              className='btn btn-sm btn-danger'
              onClick={() => setShowCampainModal(false)}
            >
              Close
            </button>
          </div>
          <div>
            <p className='mb-2'>
              <strong>Campain Id: </strong>
              {mainOrder.ordercampaign !== null &&
                mainOrder.ordercampaign.campain_id}
            </p>
            <p className='mb-2'>
              <strong>Class Name: </strong>
              {mainOrder.ordercampaign !== null &&
                mainOrder.ordercampaign.class_name}
            </p>
            <p className='mb-2'>
              <strong>Roll: </strong>
              {mainOrder.ordercampaign !== null && mainOrder.ordercampaign.roll}
            </p>
            <p className='m-0'>
              <strong>Created At: </strong>
              {mainOrder.ordercampaign !== null &&
                moment(mainOrder.ordercampaign.created_at).format(
                  'DD MMM YYYY; hh:mm A'
                )}
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CampainModal;
