import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BtnLoading from '../components/BtnLoading';
import HeaderToolbar from '../components/HeaderToolbar';
import { AuthContext } from '../context/AuthContext';

class AddCupon extends Component {
  state = {
    group: [
      { id: 'perchantange', name: 'perchantange' },
      { id: 'static', name: 'Static' },
    ],
    name: '',
    code: '',
    rate: 0,
    criteria: '',
    description: '',
    is_active: true,
    new_user_only: true,
    expired_at: '',
    is_collection_fee: false,
    is_material_fee: false,
    material_rate_percentage: 0,
    is_code_range: false,
    code_start: '',
    code_end: '',
    is_branch: false,
    cct: [],
    ccp: [],
    ccl: [],
    cca: [],
    ccb: [],
    labs: [],
    branches: [],
    tests: [],
    address: [],
    packages: [],
    testLoading: true,
    submitLoading: false,
  };

  fetchLab = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab/?limit=100`)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ labs: resp.data.results });
      });
  };

  fetchBranch = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/branch-get/?page=1&limit=200&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ branches: resp.data.results });
      });
  };

  fetchDiagnostic = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/?ordering=id&limit=5000000`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ tests: resp.data.results });
        this.setState({ testLoading: false });
      });
  };

  fetchPackage = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/`)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ packages: resp.data.results });
      });
  };

  fetchAddress = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user_management/address/`)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ address: resp.data.results });
      });
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Cupons', link: '/cupon' },
      { name: 'Add Cupon', link: '' },
    ]);
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchLab();
      this.fetchDiagnostic();
      this.fetchPackage();
      this.fetchAddress();
      this.fetchBranch();
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Lab Partner' ||
        userRole.groups[0].name === 'External' ||
        userRole.groups[0].name === 'Order Manager'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchLab();
        this.fetchDiagnostic();
        this.fetchPackage();
        this.fetchAddress();
        this.fetchBranch();
      }
    }

    // if (userRole.groups[0].name !== 'Admin') {
    //   this.props.history.push('/order');
    // } else {
    //   this.fetchGroup();
    // }
  }

  handleCriteria = (e) => {
    this.setState({ criteria: e.target.value });
  };

  handleTest = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    const newVal = value.map((val) => {
      return {
        test: val,
      };
    });
    // console.log(newVal);
    this.setState({ cct: newVal });
  };

  handlePackage = (e) => {
    // console.log(e.target.value);
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    const newVal = value.map((val) => {
      return {
        package: val,
      };
    });
    // console.log(newVal);
    this.setState({ ccp: newVal });
  };

  handleLab = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    const newVal = value.map((val) => {
      return {
        lab: val,
      };
    });
    this.setState({ ccl: newVal });
  };

  handleBranch = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    const newVal = value.map((val) => {
      return {
        branch: val,
      };
    });
    this.setState({ ccb: newVal });
  };

  handleAddress = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    const newVal = value.map((val) => {
      return {
        address: val,
      };
    });
    this.setState({ cca: newVal });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.name.trim() === '') {
      toast.error(`Please enter a coupon name.`, {
        autoClose: 3000,
      });
    } else if (this.state.code.trim() === '') {
      toast.error(`Please enter a cupon code.`, {
        autoClose: 3000,
      });
    } else if (this.state.rate === 0) {
      toast.error(`Please enter a rate.`, {
        autoClose: 3000,
      });
    } else if (this.state.expired_at === '') {
      toast.error(`Please select an expire date.`, {
        autoClose: 3000,
      });
    } else if (this.state.criteria === '') {
      toast.error(`Please select a criteria.`, {
        autoClose: 3000,
      });
    } else {
      this.setState({ submitLoading: true });
      const postCupon = {
        name: this.state.name,
        code: this.state.code,
        rate: this.state.rate,
        criteria: this.state.criteria,
        description: this.state.description,
        new_user_only: this.state.new_user_only,
        is_active: this.state.is_active,
        expired_at: this.state.expired_at,
        is_collection_fee: this.state.is_collection_fee,
        is_material_fee: this.state.is_material_fee,
        is_code_range: this.state.is_code_range,
        is_branch: this.state.is_branch,
        code_start: parseInt(this.state.code_start),
        code_end: this.state.code_end,
        cct: this.state.cct,
        ccp: this.state.ccp,
        ccl: this.state.ccl,
        cca: this.state.cca,
        ccb: this.state.ccb,
      };

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/diagnostic/cupon/`, postCupon)
        .then((resp) => {
          toast.success(`Cupon has been added successfully.`, {
            autoClose: 3000,
          });
          this.props.history.push('/cupon');
          this.setState({ submitLoading: false });
        })
        .catch((error) => {
          console.log(error.response);
          this.setState({ submitLoading: false });
        });

      // console.log(postData);
    }
  };

  render() {
    const {
      group,
      name,
      code,
      rate,
      is_active,
      new_user_only,
      expired_at,
      labs,
      tests,
      packages,
      testLoading,
      is_collection_fee,
      is_material_fee,
      material_rate_percentage,
      is_code_range,
      code_start,
      code_end,
      is_branch,
      branches,
      submitLoading,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Add Coupon' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card py-8 px-8 py-lg-15 px-lg-10'>
                  <div className='row justify-content-center'>
                    <div className='col-xl-9'>
                      <h5 className='text-dark font-weight-bold mb-10'>
                        Add Cupon
                      </h5>
                      <form onSubmit={this.handleSubmit}>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Name *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              maxLength='49'
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={name}
                              onChange={(e) =>
                                this.setState({ name: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Code *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              maxLength='49'
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={code}
                              onChange={(e) =>
                                this.setState({ code: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div
                          className='form-group row fv-plugins-icon-container mb-3'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Is Code Range
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                className='form-check-input w-50px'
                                type='checkbox'
                                defaultChecked={is_code_range}
                                onChange={() =>
                                  this.setState({
                                    is_code_range: !this.state.is_code_range,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {is_code_range && (
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Code Range *
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <div className='d-flex mb-2'>
                                <input
                                  min='1'
                                  className='form-control form-control-solid form-control-lg me-2'
                                  type='number'
                                  value={code_start}
                                  onChange={(e) =>
                                    this.setState({
                                      code_start: e.target.value,
                                    })
                                  }
                                  placeholder='code start'
                                />
                                <input
                                  max='10000'
                                  className='form-control form-control-solid form-control-lg ms-2'
                                  type='number'
                                  value={code_end}
                                  onChange={(e) =>
                                    this.setState({ code_end: e.target.value })
                                  }
                                  placeholder='code end'
                                />
                              </div>
                              <small>
                                Code range will be{' '}
                                <span className='test-success'>
                                  {' '}
                                  {code + code_start} - {code + code_end}
                                </span>
                              </small>
                            </div>
                          </div>
                        )}
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Rate *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='number'
                              value={rate}
                              onChange={(e) =>
                                this.setState({ rate: e.target.value })
                              }
                              min='0'
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Expire Date *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='date'
                              value={expired_at}
                              onChange={(e) =>
                                this.setState({ expired_at: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Criteria *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <select
                              className='form-control form-control-solid form-control-lg'
                              // value={user_group || ''}
                              onChange={this.handleCriteria}
                            >
                              <option value=''>Select Criteria</option>
                              {group.length !== 0 &&
                                group.map((group, i) => (
                                  <option key={i} value={group.id}>
                                    {group.name}
                                  </option>
                                ))}
                            </select>
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label checkbox checkbox-success'>
                            Is Active
                          </label>
                          <div className='col-lg-9 col-xl-9 d-flex align-items-center'>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                className='form-check-input w-50px'
                                type='checkbox'
                                name='is_active'
                                onChange={() =>
                                  this.setState({
                                    is_active: !this.state.is_active,
                                  })
                                }
                                defaultChecked={is_active}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label checkbox checkbox-success'>
                            New User Only
                          </label>
                          <div className='col-lg-9 col-xl-9 d-flex align-items-center'>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                type='checkbox'
                                name='new_user_only'
                                defaultChecked={new_user_only}
                                onChange={() =>
                                  this.setState({
                                    new_user_only: !this.state.new_user_only,
                                  })
                                }
                                className='form-check-input w-50px'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label checkbox checkbox-success'>
                            Collection Fees
                          </label>
                          <div className='col-lg-9 col-xl-9 d-flex align-items-center'>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                type='checkbox'
                                name='new_user_only'
                                defaultChecked={is_collection_fee}
                                onChange={() =>
                                  this.setState({
                                    is_collection_fee:
                                      !this.state.is_collection_fee,
                                  })
                                }
                                className='form-check-input w-50px'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label checkbox checkbox-success'>
                            Material Fees
                          </label>
                          <div className='col-lg-9 col-xl-9 d-flex align-items-center'>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                type='checkbox'
                                name='new_user_only'
                                defaultChecked={is_material_fee}
                                onChange={() =>
                                  this.setState({
                                    is_material_fee:
                                      !this.state.is_material_fee,
                                  })
                                }
                                className='form-check-input w-50px'
                              />
                            </div>
                          </div>
                        </div>
                        {is_material_fee && (
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Material Percentage *
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <input
                                className='form-control form-control-solid form-control-lg'
                                type='number'
                                value={material_rate_percentage}
                                onChange={(e) =>
                                  this.setState({
                                    material_rate_percentage: e.target.value,
                                  })
                                }
                                min='0'
                                max='100'
                              />
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                        )}
                        <div
                          className='form-group row fv-plugins-icon-container mb-3'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Is Branch
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                className='form-check-input w-50px'
                                type='checkbox'
                                defaultChecked={is_branch}
                                onChange={() =>
                                  this.setState({
                                    is_branch: !this.state.is_branch,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className='form-group row fv-plugins-icon-container mb-3'></div>

                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Select Tests
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <select
                              multiple
                              className='form-control form-control-solid form-control-lg custom_scrollbar'
                              onChange={this.handleTest}
                            >
                              {tests.length !== 0 &&
                                tests.map((test, i) => (
                                  <option key={i} value={test.id}>
                                    {test.name}
                                  </option>
                                ))}
                            </select>
                            <div className='fv-plugins-message-container' />
                            {testLoading && (
                              <p className='m-0 mt-2 font-size-lg text-primary'>
                                Test loading, Please wait...
                              </p>
                            )}
                          </div>
                        </div>

                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Select Packages
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <select
                              multiple
                              className='form-control form-control-solid form-control-lg custom_scrollbar'
                              onChange={this.handlePackage}
                            >
                              {packages.length !== 0 &&
                                packages.map((packagee, i) => (
                                  <option key={i} value={packagee.id}>
                                    {packagee.name}
                                  </option>
                                ))}
                            </select>
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>

                        {!is_branch ? (
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Select Labs
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <select
                                multiple
                                className='form-control form-control-solid form-control-lg custom_scrollbar'
                                onChange={this.handleLab}
                              >
                                {labs.length !== 0 &&
                                  labs.map((lab, i) => (
                                    <option key={i} value={lab.id}>
                                      {lab.name}
                                    </option>
                                  ))}
                              </select>
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                        ) : (
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Select Branch
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <select
                                multiple
                                className='form-control form-control-solid form-control-lg custom_scrollbar'
                                onChange={this.handleBranch}
                              >
                                {branches.length !== 0 &&
                                  branches.map((branch, i) => (
                                    <option value={branch.id} key={i}>
                                      {branch.name} &mdash; {branch.lab.name}
                                    </option>
                                  ))}
                              </select>
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                        )}

                        {/* <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Select Address
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <select
                              multiple
                              className='form-control form-control-solid form-control-lg custom_scrollbar'
                              onChange={this.handleAddress}
                            >
                              {address.length !== 0 &&
                                address.map((addres, i) => (
                                  <option key={i} value={addres.id}>
                                    {addres.district}/{addres.thana}
                                  </option>
                                ))}
                            </select>
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div> */}

                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label checkbox checkbox-success'></label>
                          <div className='col-lg-9 col-xl-9 d-flex align-items-center'>
                            <p className='m-0 font-size-lg text-danger'>
                              ** If you put all blank then you will give all
                              access.
                            </p>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end border-top pt-10 mt-15'>
                          <div>
                            <Link
                              to='/cupon'
                              type='submit'
                              className='btn btn-success font-weight-bolder px-9 py-4 me-3'
                            >
                              Go Back
                            </Link>
                          </div>
                          <div>
                            {!submitLoading ? (
                              <button
                                type='submit'
                                className='btn btn-primary font-weight-bolder px-9 py-4'
                              >
                                Submit
                              </button>
                            ) : (
                              <BtnLoading
                                name='Submit...'
                                btnClass='primary px-9 py-4'
                              />
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddCupon;
AddCupon.contextType = AuthContext;
