import { useState } from 'react';
import { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import AmarLabLogo from '../assets/img/logo.png';

const Aside = ({ asideOpen, setAsideClose }) => {
  const [isHomeActive, setIsHomeActive] = useState(false);
  const location = useLocation();
  const [showHome, setShowHome] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showSupervisor, setShowSupervisor] = useState(false);
  const [showLabDiscount, setShowLabDiscount] = useState(false);
  const [isAccount, setIsAccount] = useState(false);

  useEffect(() => {
    if (location.pathname === '/') {
      setIsHomeActive(true);
    } else {
      setIsHomeActive(false);
    }
  }, [location]);

  useEffect(() => {
    const user_details = localStorage.getItem('user_details');
    if (user_details) {
      const userRole = JSON.parse(user_details);
      // console.log(userRole);
      if (userRole.is_superuser) {
        setShowHome(true);
        // setShowUsers(true)
      } else {
        if (userRole.groups.length === 0) {
          setShowHome(false);
        } else if (
          userRole.groups[0].name === 'Admin' ||
          userRole.groups[0].name === 'Order Manager' ||
          userRole.groups[0].name === 'Supervisor'
        ) {
          setShowHome(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    const user_details = localStorage.getItem('user_details');
    if (user_details) {
      const userRole = JSON.parse(user_details);
      // console.log(userRole);
      if (userRole.is_superuser) {
        setShowLabDiscount(true);
      } else {
        if (userRole.groups.length === 0) {
          setShowLabDiscount(false);
        } else if (
          userRole.groups[0].name === 'Admin' ||
          userRole.groups[0].name === 'Order Manager' ||
          userRole.groups[0].name === 'Supervisor' ||
          userRole.groups[0].name === 'Lab Partner'
        ) {
          setShowLabDiscount(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    const user_details = localStorage.getItem('user_details');
    if (user_details) {
      const userRole = JSON.parse(user_details);
      // console.log(userRole);
      if (userRole.is_superuser) {
        setShowUsers(true);
      } else {
        if (userRole.groups.length === 0) {
          setShowUsers(false);
        } else if (userRole.groups[0].name === 'Admin') {
          setShowUsers(true);
        } else if (userRole.groups[0].name === 'Supervisor') {
          setShowSupervisor(true);
        } else if (userRole.groups[0].name === 'Accounts') {
          setIsAccount(true);
        }
      }
    }
  }, []);

  return (
    <div
      className={`aside pb-5 pt-5 pt-lg-0 ${
        asideOpen ? ' drawer drawer-start drawer-on' : ''
      }`}
      id="kt_aside"
    >
      <div className="aside-logo py-8" id="kt_aside_logo">
        <Link to="/" className="d-flex align-items-center">
          <img alt="Logo" src={AmarLabLogo} className="h-45px logo" />
        </Link>
      </div>
      <div className="aside-menu flex-column-fluid">
        <div className="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1">
          <div className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold">
            {(showLabDiscount || isAccount) && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <Link
                  className={`menu-link menu-center ${
                    isHomeActive ? 'active' : ''
                  }`}
                  to="/"
                >
                  <span className="menu-icon me-0">
                    <i className="fas fa-home fs-2" />
                  </span>
                  <span className="menu-title">Home</span>
                </Link>
              </div>
            )}
            <div className="menu-item py-2" onClick={setAsideClose}>
              <NavLink className="menu-link menu-center" to="/orders">
                <span className="menu-icon me-0">
                  <i className="fas fa-list fs-2" />
                </span>
                <span className="menu-title">Orders</span>
              </NavLink>
            </div>
            {showHome && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/quick-orders">
                  <span className="menu-icon me-0">
                    <i className="fas fa-tasks fs-2" />
                  </span>
                  <span className="menu-title">Quick Orders</span>
                </NavLink>
              </div>
            )}
            {showHome && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/cupon">
                  <span className="menu-icon me-0">
                    <i className="fa fa-gift fs-2" />
                  </span>
                  <span className="menu-title">Coupon</span>
                </NavLink>
              </div>
            )}
            {showLabDiscount && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink
                  className="menu-link menu-center"
                  to="/lab-discount"
                  style={{ width: '100%' }}
                >
                  <span className="menu-icon me-0">
                    <i className="fas fa-hand-holding-usd fs-2" />
                  </span>
                  <span className="menu-title">Lab Discount</span>
                </NavLink>
              </div>
            )}

            {(showUsers || showSupervisor) && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/branch">
                  <span className="menu-icon me-0">
                    <i className="fa fa-flask fs-2" />
                  </span>
                  <span className="menu-title">Branch & Lab</span>
                </NavLink>
              </div>
            )}


            {(showUsers || showSupervisor) && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/tests">
                  <span className="menu-icon me-0">
                    <i className="fa fa-vial fs-2" />
                  </span>
                  <span className="menu-title">Tests</span>
                </NavLink>
              </div>
            )}

            {showUsers && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/medicines">
                  <span className="menu-icon me-0">
                    <i className="fas fa-pills fs-2" />
                  </span>
                  <span className="menu-title">Medicine</span>
                </NavLink>
              </div>
            )}

            {showUsers && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/press">
                  <span className="menu-icon me-0">
                    <i className="far fa-newspaper fs-2" />
                  </span>
                  <span className="menu-title">Press</span>
                </NavLink>
              </div>
            )}

            {showHome && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/reference">
                  <span className="menu-icon me-0">
                    <i className="fas fa-bookmark fs-2" />
                  </span>
                  <span className="menu-title">Reference</span>
                </NavLink>
              </div>
            )}
            {showHome && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/channels">
                  <span className="menu-icon me-0">
                    <i className="fas fa-exchange-alt fs-2" />
                  </span>
                  <span className="menu-title">Channels</span>
                </NavLink>
              </div>
            )}

            <div className="menu-item py-2" onClick={setAsideClose}>
              <NavLink className="menu-link menu-center" to="/app-promotion">
                <span className="menu-icon me-0">
                  <i className="fab fa-app-store" />
                </span>
                <span className="menu-title">App Promotion</span>
              </NavLink>
            </div>

            {showUsers && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/users">
                  <span className="menu-icon me-0">
                    <i className="fas fa-users fs-2" />
                  </span>
                  <span className="menu-title">Users</span>
                </NavLink>
              </div>
            )}

            {showHome && (
              <div className="menu-item py-2" onClick={setAsideClose}>
                <NavLink className="menu-link menu-center" to="/bulk-uploader">
                  <span className="menu-icon me-0">
                    <i className="fas fa-file-upload fs-2" />
                  </span>
                  <span className="menu-title">Bulk Uploader</span>
                </NavLink>
              </div>
            )}
            

            <i
              className="fas fa-times text-primary d-lg-none"
              style={toggleCloseIcon}
              onClick={setAsideClose}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

const toggleCloseIcon = {
  position: 'absolute',
  fontSize: '20px',
  right: '20px',
};

export default Aside;
