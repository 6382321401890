import axios from 'axios';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import InputPopover from './Dropdown/InputPopover';
import PaymentMethodPopover from './Dropdown/PaymentMethodPopover';
import { AuthContext } from '../context/AuthContext';

const AccountOrderTable = ({ order, labs }) => {
  const { user_details } = useContext(AuthContext);
  const [mainOrder, setMainOrder] = useState(order);
  const [orderId, setOrderId] = useState(null);
  const [orderDetailsId, setOrderDetailsId] = useState(null);

  const [paymentClientArray, setPaymentClientArray] = useState([]);
  const [paymentAmarlabArray, setPaymentAmarlabArray] = useState([]);
  const [paymentClientPrice, setPaymentClientPrice] = useState(0);
  const [paymentClientPriceDue, setPaymentClientPriceDue] = useState(0);
  const [paymentAmarlabPrice, setPaymentAmarlabPrice] = useState(0);
  const [paymentAmarlabPriceDue, setPaymentAmarlabPriceDue] = useState(0);

  const [orderLabs, setOrderLabs] = useState([]);

  useEffect(() => {
    const clientArray = mainOrder.orderpaymentmethod.filter(
      (method) => method.payment_method_source === 'client'
    );

    const amarlabArray = mainOrder.orderpaymentmethod.filter(
      (method) => method.payment_method_source === 'amarlab'
    );

    const clientPrice = mainOrder.orderpaymentmethod
      .filter((method) => method.payment_method_source === 'client')
      .filter((method) => method.payment_method !== 'due')
      .reduce((total, item) => total + parseInt(item.payment_amount), 0);

    const amarlabPrice = mainOrder.orderpaymentmethod
      .filter((method) => method.payment_method_source === 'amarlab')
      .filter((method) => method.payment_method !== 'due')
      .reduce((total, item) => total + parseInt(item.payment_amount), 0);

    const clientPriceDue = mainOrder.orderpaymentmethod
      .filter((method) => method.payment_method_source === 'client')
      .filter((method) => method.payment_method === 'due')
      .reduce((total, item) => total + parseInt(item.payment_amount), 0);

    const amarlabPriceDue = mainOrder.orderpaymentmethod
      .filter((method) => method.payment_method_source === 'amarlab')
      .filter((method) => method.payment_method === 'due')
      .reduce((total, item) => total + parseInt(item.payment_amount), 0);

    setPaymentClientArray(clientArray);
    setPaymentAmarlabArray(amarlabArray);
    setPaymentClientPrice(clientPrice);
    setPaymentAmarlabPrice(amarlabPrice);
    setPaymentClientPriceDue(clientPriceDue);
    setPaymentAmarlabPriceDue(amarlabPriceDue);

    // const clientPriceMain = mainOrder.orderpaymentmethod
    //   .filter((method) => method.payment_method_source === 'client')
    //   .filter((method) => method.payment_method !== 'due')
    //   .reduce((total, item) => total + parseInt(item.payment_amount), 0);

    // const clientPriceDue = mainOrder.orderpaymentmethod
    //   .filter((method) => method.payment_method_source === 'client')
    //   .filter((method) => method.payment_method === 'due')
    //   .reduce((total, item) => total + parseInt(item.payment_amount), 0);
    // console.log(clientPriceMain);
    // console.log(clientPriceDue);
  }, [mainOrder]);

  useEffect(() => {
    const orderLabs = mainOrder.orderitem.map((item) => {
      return {
        id: item.purchasable_order_item.testitem_purchasable_oi.branch.lab.id,
        lab: item.purchasable_order_item.testitem_purchasable_oi.branch.lab
          .name,
      };
    });

    const ids = orderLabs.map((o) => o.id);
    const labssss = orderLabs.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );

    // console.log(labssss);
    setOrderLabs(labssss);
  }, []);

  const [labInvoice, setLabInvoice] = useState(
    mainOrder.orderdetail ? mainOrder.orderdetail.lab_invoice : ''
  );
  const [payableToDiag, setPayableToDiag] = useState(
    mainOrder.orderdetail
      ? parseInt(mainOrder.orderdetail.payable_to_diagnostic)
      : ''
  );

  const [clientPaymentMethod, setclientPaymentMethod] = useState('');
  const [clientPaymentAmount, setclientPaymentAmount] = useState('');
  const [clientPaymentNote, setclientPaymentNote] = useState(null);
  const [clientPaymentlab, setclientPaymentLab] = useState('');

  const [editIdAccounts, setEditIdAccounts] = useState(null);
  const [editPaymentMethod, setEditPaymentMethod] = useState('');
  const [editPaymentAmount, setEditPaymentAmount] = useState('');
  const [editPaymentNote, setEditPaymentNote] = useState(null);
  const [editPaymentLab, setEditPaymentLab] = useState('');
  const [existingPaymentMethod, setExistingPaymentMethod] = useState('');

  const _toSpace = (string) => {
    let newStr = string.replace(/_/g, ' ');
    return newStr;
  };

  const idToLabName = (id) => {
    const getSingle = labs.filter((lab) => lab.id === parseInt(id));
    if (getSingle.length !== 0) {
      return getSingle[0].name;
    } else {
      return id;
    }
    // console.log(getSingle);
  };

  const getSingleOrderTree = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-tree/${id}`)
      .then((resp) => {
        // console.log(resp.data);
        setMainOrder(resp.data);
      });
  };

  const postOrderDetail = (postDate, setShow) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-detail/`, postDate)
      .then((resp) => {
        // console.log(resp.data);
        getSingleOrderTree(resp.data.order);
        toast.success(`Saved sucessfully.`);
        if (setShow) setShow(false);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        if (setShow) setShow(false);
      });
  };

  const putOrderDetail = (postDate, id, setShow) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/order-detail/${id}/`,
        postDate
      )
      .then((resp) => {
        // console.log(resp.data);
        getSingleOrderTree(resp.data.order);
        toast.success(`Saved sucessfully.`);
        if (setShow) setShow(false);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        if (setShow) setShow(false);
      });
  };

  const handleLabInvoice = (setShow) => {
    if (labInvoice === '' || labInvoice === null) {
      toast.error(`Please enter lab invoice.`);
    } else {
      const postOrderDetails = {
        lab_invoice: labInvoice,
        order: orderId,
      };
      const putOrderDetails = {
        lab_invoice: labInvoice,
      };

      if (orderDetailsId === null) {
        postOrderDetail(postOrderDetails, setShow);
        // console.log(postOrderDetails);
      } else {
        putOrderDetail(putOrderDetails, orderDetailsId, setShow);
        // console.log(putOrderDetails);
      }
    }
  };

  const handlePayableToDiag = (setShow) => {
    if (payableToDiag === '' || payableToDiag === null) {
      toast.error(`Please enter lab invoice.`);
    } else {
      const postOrderDetails = {
        payable_to_diagnostic: payableToDiag,
        order: orderId,
      };
      const putOrderDetails = {
        payable_to_diagnostic: payableToDiag,
      };

      if (orderDetailsId === null) {
        postOrderDetail(postOrderDetails, setShow);
        // console.log(postOrderDetails);
      } else {
        putOrderDetail(putOrderDetails, orderDetailsId, setShow);
        // console.log(putOrderDetails);
      }
    }
  };

  const postPaymentMethod = (data, setShow) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/payment-method-accounts/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`Saved sucessfully.`);
        getSingleOrderTree(resp.data.order);
        if (setShow) setShow(false);
        setclientPaymentMethod('');
        setclientPaymentAmount('');
        setclientPaymentNote('');
        setclientPaymentLab('');
      })
      .catch((error) => {
        console.log(error.response);
        if (setShow) setShow(false);
      });
  };

  const handleClientPayment = (setShow) => {
    // console.log(123);

    // console.log(order);
    // alert('yes come');
    // console.log('the lsit is = ', paymentMethodChoice);
    // console.log('the list is: ', paymentClientArray, clientPaymentMethod);
    for (let x = 0; x < paymentClientArray.length; x++) {
      if (paymentClientArray[x].payment_method === clientPaymentMethod) {
        // alert('already taken this bro,');
        // when exist this toaster will exicute,
        toast.error(`Sorry, payment method already taken.`);
        return;
      }
    }

    if (clientPaymentMethod === '' || clientPaymentMethod === null) {
      toast.error(`Please enter payment method.`);
    } else if (clientPaymentAmount === '' || clientPaymentAmount === null) {
      toast.error(`Please enter payment amount.`);
    } else {
      const postData = {
        payment_method: clientPaymentMethod,
        payment_method_source: 'client',
        order: orderId,
        payment_amount: clientPaymentAmount,
        payment_note: clientPaymentNote,
        created_by: user_details.id,
      };
      // console.log(postData);

      postPaymentMethod(postData, setShow);
    }
  };

  const handleAmarlabPayment = (setShow) => {
    for (let x = 0; x < paymentAmarlabArray.length; x++) {
      if (paymentAmarlabArray[x].payment_method === clientPaymentMethod) {
        // alert('already taken this bro,');
        // when exist this toaster will exicute,
        toast.error(`Sorry, payment method already taken.`);
        return;
      }
    }

    if (clientPaymentMethod === '' || clientPaymentMethod === null) {
      toast.error(`Please enter payment method.`);
    } else if (clientPaymentlab === '' || clientPaymentlab === null) {
      toast.error(`Please select lab.`);
    } else if (clientPaymentAmount === '' || clientPaymentAmount === null) {
      toast.error(`Please enter payment amount.`);
    } else {
      const postData = {
        payment_method: clientPaymentMethod,
        payment_method_source: 'amarlab',
        order: orderId,
        payment_amount: clientPaymentAmount,
        payment_note: clientPaymentNote,
        created_by: user_details.id,
        lab: clientPaymentlab,
      };

      postPaymentMethod(postData, setShow);
    }
  };

  const putPaymentMethod = (data, id, setShow) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/payment-method-accounts/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`Saved sucessfully.`);
        getSingleOrderTree(resp.data.order);
        if (setShow) setShow(false);
        setEditPaymentMethod('');
        setEditPaymentAmount('');
        setEditPaymentNote('');
        setEditIdAccounts(null);
        setEditPaymentLab('');
      })
      .catch((error) => {
        console.log(error.response);
        if (setShow) setShow(false);
      });
  };

  const handleClientPaymentEdit = (setShow) => {
    if (existingPaymentMethod !== editPaymentMethod) {
      for (let x = 0; x < paymentClientArray.length; x++) {
        if (paymentClientArray[x].payment_method === editPaymentMethod) {
          // alert('already taken this bro,');
          // when exist this toaster will exicute,
          toast.error(`Sorry, payment method already taken.`);
          return;
        }
      }
    }

    if (editPaymentMethod === '' || editPaymentMethod === null) {
      toast.error(`Please enter payment method.`);
    } else if (editPaymentAmount === '' || editPaymentAmount === null) {
      toast.error(`Please enter payment amount.`);
    } else {
      const putData = {
        payment_method: editPaymentMethod,
        payment_method_source: 'client',
        payment_amount: editPaymentAmount,
        payment_note: editPaymentNote,
        created_by: user_details.id,
      };
      // console.log(putPaymentMethod);

      putPaymentMethod(putData, editIdAccounts, setShow);
    }
  };

  const handleAmarlabPaymentEdit = (setShow) => {
    if (existingPaymentMethod !== editPaymentMethod) {
      for (let x = 0; x < paymentAmarlabArray.length; x++) {
        if (paymentAmarlabArray[x].payment_method === editPaymentMethod) {
          // alert('already taken this bro,');
          // when exist this toaster will exicute,
          toast.error(`Sorry, payment method already taken.`);
          return;
        }
      }
    }

    if (editPaymentMethod === '' || editPaymentMethod === null) {
      toast.error(`Please enter payment method.`);
    } else if (editPaymentLab === '' || editPaymentLab === null) {
      toast.error(`Please select a lab.`);
    } else if (editPaymentAmount === '' || editPaymentAmount === null) {
      toast.error(`Please enter payment amount.`);
    } else {
      const putData = {
        payment_method: editPaymentMethod,
        payment_method_source: 'amarlab',
        payment_amount: editPaymentAmount,
        payment_note: editPaymentNote,
        created_by: user_details.id,
        lab: editPaymentLab,
      };
      // console.log(putPaymentMethod);

      putPaymentMethod(putData, editIdAccounts, setShow);
    }
  };

  const handleIClick = (order) => {
    // console.log(order);

    setOrderId(order.id);
    setOrderDetailsId(order.orderdetail ? order.orderdetail.id : null);
  };

  const handlePaymentMethod = (item) => {
    // console.log(item);
    setEditPaymentMethod(item.payment_method);
    setEditPaymentAmount(parseInt(item.payment_amount));
    setEditPaymentNote(item.payment_note);
    setEditIdAccounts(item.id);
    setEditPaymentLab(item.lab);
    setExistingPaymentMethod(item.payment_method);
  };

  const [paymentMethodChoice] = useState([
    {
      name: 'Select method',
      id: '',
    },
    {
      name: 'Cash On Delivery',
      id: 'cash_on_delivery',
    },
    {
      name: 'Due',
      id: 'due',
    },
    {
      name: 'Online Payment',
      id: 'online_payment',
    },
  ]);

  return (
    <>
      <tr className=''>
        <td className='ps-3'>#{mainOrder.order_id}</td>
        <td>
          {moment(mainOrder.created_at).format('DD MMM YYYY')},
          <br />
          {moment(mainOrder.created_at).format('hh:mm A')}
        </td>
        <td>{parseInt(mainOrder.total_price)} tk</td>
        <td>
          {mainOrder.orderdiscount ? (
            <p>
              Discount: {parseInt(mainOrder.orderdiscount.discount_price)} tk
              <br />
              Collection:{' '}
              {parseInt(mainOrder.orderdiscount.collection_discount_price)} tk
              <br />
              Material:{' '}
              {parseInt(mainOrder.orderdiscount.material_discount_price)} tk
            </p>
          ) : (
            '—'
          )}
        </td>
        <td>{parseInt(mainOrder.collection_fee)} tk</td>
        <td>{parseInt(mainOrder.material_fee)} tk</td>
        <td>
          {mainOrder.orderdiscount
            ? `${
                parseInt(mainOrder.total_price) +
                parseInt(mainOrder.collection_fee) +
                parseInt(mainOrder.material_fee) -
                parseInt(mainOrder.orderdiscount.discount_price)
              } tk`
            : parseInt(mainOrder.total_price) +
              parseInt(mainOrder.collection_fee) +
              parseInt(mainOrder.material_fee) +
              ' tk'}
        </td>
        <td className='method'>
          <div className='lists'>
            {paymentClientArray.map((method, i) => (
              <div key={i} className='single'>
                <span className='me-1'>{i + 1}.</span>
                <div>
                  <p className='text-capitalize mb-0'>
                    {_toSpace(method.payment_method)} (
                    {parseInt(method.payment_amount)} tk)
                  </p>
                  {/* <p className='mb-0'>{parseInt(method.payment_amount)} tk</p> */}
                  {method.payment_note && (
                    <p className='mb-0'>Note: {method.payment_note}</p>
                  )}
                </div>
                <div>
                  <PaymentMethodPopover
                    title='Payment Method By Client'
                    value={editPaymentMethod}
                    setValue={setEditPaymentMethod}
                    options={paymentMethodChoice}
                    handleIClick={() => handlePaymentMethod(method)}
                    textArea={editPaymentNote}
                    setTextArea={setEditPaymentNote}
                    input={editPaymentAmount}
                    setInput={setEditPaymentAmount}
                    handleSave={handleClientPaymentEdit}
                  />
                </div>
              </div>
            ))}
          </div>
          {paymentClientArray.length < 3 && (
            <div className='payment-method'>
              <PaymentMethodPopover
                title='Payment Method By Client'
                value={clientPaymentMethod}
                setValue={setclientPaymentMethod}
                options={paymentMethodChoice}
                handleIClick={() => handleIClick(mainOrder)}
                textArea={clientPaymentNote}
                setTextArea={setclientPaymentNote}
                input={clientPaymentAmount}
                setInput={setclientPaymentAmount}
                handleSave={handleClientPayment}
              />
            </div>
          )}
        </td>
        <td>{paymentClientPrice - paymentClientPriceDue} tk</td>
        <td>
          {mainOrder.orderdetail ? (
            <>{mainOrder.orderdetail.references || '—'}</>
          ) : (
            '—'
          )}
        </td>
        <td className='text-capitalize'>
          {mainOrder.orderdetail ? _toSpace(mainOrder.order_type) : '—'}
        </td>
        <td>
          {mainOrder.orderitem.length !== 0
            ? mainOrder.orderitem[0].purchasable_order_item
                .testitem_purchasable_oi.branch.lab.name
            : '-'}
        </td>
        <td>
          <div className='dtls-wit-icn'>
            {mainOrder.orderdetail ? (
              <>
                {mainOrder.orderdetail.lab_invoice
                  ? mainOrder.orderdetail.lab_invoice
                  : ''}
              </>
            ) : (
              ''
            )}
            <InputPopover
              title='Enter Lab Invoice No'
              value={labInvoice}
              setValue={setLabInvoice}
              handleSave={handleLabInvoice}
              handleIClick={() => handleIClick(mainOrder)}
              inputType='text'
              placeholder='enter invoice number'
            />
          </div>
        </td>
        <td>
          <div className='dtls-wit-icn'>
            {mainOrder.orderdetail ? (
              <>
                {mainOrder.orderdetail.payable_to_diagnostic
                  ? parseInt(mainOrder.orderdetail.payable_to_diagnostic) +
                    ' tk'
                  : ''}
              </>
            ) : (
              ''
            )}
            <InputPopover
              title='Payable to diagnostic'
              value={payableToDiag}
              setValue={setPayableToDiag}
              handleSave={handlePayableToDiag}
              handleIClick={() => handleIClick(mainOrder)}
              inputType='number'
              placeholder='enter amount'
            />
          </div>
        </td>
        <td className='method'>
          <div className='lists'>
            {paymentAmarlabArray.map((method, i) => (
              <div key={i} className='single'>
                <span className='me-1'>{i + 1}.</span>
                <div>
                  <p className='text-capitalize mb-0'>
                    {_toSpace(method.payment_method)} (
                    {parseInt(method.payment_amount)} tk)
                  </p>
                  {/* <p className='mb-0'>{parseInt(method.payment_amount)} tk</p> */}
                  {method.payment_note && (
                    <p className='mb-0'>Note: {method.payment_note}</p>
                  )}
                  <p className='mb-0'>
                    Lab: {idToLabName(method.lab) || 'n/a'}
                  </p>
                </div>
                <div>
                  <PaymentMethodPopover
                    title='Payment Method By Amarlab'
                    value={editPaymentMethod}
                    setValue={setEditPaymentMethod}
                    options={paymentMethodChoice}
                    handleIClick={() => handlePaymentMethod(method)}
                    textArea={editPaymentNote}
                    setTextArea={setEditPaymentNote}
                    input={editPaymentAmount}
                    setInput={setEditPaymentAmount}
                    handleSave={handleAmarlabPaymentEdit}
                    showLabs={true}
                    orderLabs={orderLabs}
                    lab={editPaymentLab}
                    setLab={setEditPaymentLab}
                  />
                </div>
              </div>
            ))}
          </div>
          {paymentAmarlabArray.length < 3 * orderLabs.length && (
            <div className='payment-method'>
              <PaymentMethodPopover
                title='Payment Method By Amarlab'
                value={clientPaymentMethod}
                setValue={setclientPaymentMethod}
                options={paymentMethodChoice}
                handleIClick={() => handleIClick(mainOrder)}
                textArea={clientPaymentNote}
                setTextArea={setclientPaymentNote}
                input={clientPaymentAmount}
                setInput={setclientPaymentAmount}
                handleSave={handleAmarlabPayment}
                showLabs={true}
                orderLabs={orderLabs}
                lab={clientPaymentlab}
                setLab={setclientPaymentLab}
              />
            </div>
          )}
        </td>
        <td>{paymentAmarlabPrice - paymentAmarlabPriceDue} tk</td>
        <td>
          {/* {mainOrder.orderdetail ? (
            <>
              {mainOrder.orderdetail.payable_to_diagnostic
                ? parseInt(paymentClientPrice) -
                  parseInt(mainOrder.orderdetail.payable_to_diagnostic) +
                  ' tk'
                : ''}
            </>
          ) : (
            '0 tk'
          )} */}
          {paymentClientPrice -
            paymentClientPriceDue -
            (paymentAmarlabPrice - paymentAmarlabPriceDue)}{' '}
          tk
        </td>
        <td>
          {mainOrder.orderdetail ? (
            <>
              {mainOrder.orderdetail.mt ? (
                <>
                  {mainOrder.orderdetail.mt.first_name
                    ? `${mainOrder.orderdetail.mt.first_name} ${mainOrder.orderdetail.mt.last_name}`
                    : mainOrder.orderdetail.mt.username}
                </>
              ) : (
                '—'
              )}
            </>
          ) : (
            '—'
          )}
        </td>
      </tr>
    </>
  );
};

export default AccountOrderTable;
