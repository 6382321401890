import moment from 'moment';
import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';

const MtAssignHistoryModal = (props) => {
  const { mt_assign_modal, setMtAssignModal, data } = props;
  return (
    <Modal
      show={mt_assign_modal}
      onHide={() => setMtAssignModal(false)}
      animation={false}
    >
      <ModalBody>
        <div>
          <div className='generete-ticket'>
            <div className='d-flex justify-content-between align-items-center mb-5'>
              <h5 className='m-0'>Mt Assign History</h5>
              <button
                className='btn btn-sm btn-danger'
                onClick={() => setMtAssignModal(false)}
              >
                Close
              </button>
            </div>
          </div>
          <div>
            {data &&
              data.map((status, i) => (
                <div key={i} className='mb-4'>
                  <h5 className='text-capitalize'>
                    {i + 1}. {status.name}
                  </h5>
                  <p className='mb-1 fw-bold'>
                    MT: {status.mt.first_name + ' ' + status.mt.last_name}
                  </p>
                  <p className='mb-1 fw-bold'>
                    Date:{' '}
                    {moment(status.created_at).format('DD MM YYYY; hh:mm A')}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MtAssignHistoryModal;
