import { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

const PaymentMethodPopover = ({
  title,
  value,
  setValue,
  options,
  handleSave,
  handleIClick,
  textArea,
  setTextArea,
  input,
  setInput,
  handleOrderDisSource,
  showLabs,
  orderLabs,
  lab,
  setLab,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (e) => {
    setShow(!show);
    setTarget(e.target);
    handleIClick();
  };

  return (
    <div ref={ref} className='select-pop'>
      <button
        className='icn-btn'
        //   onClick={(e) => handleClick(e, mainOrder, 'order_status')}
        onClick={(e) => handleClick(e)}
      >
        <i className='fas fa-pencil-alt pointer ms-1 edit-icn'></i>
      </button>

      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover id='popover-contained'>
          <Popover.Content>
            <p className='font-semibold mb-3'>{title}</p>
            <select
              value={value || ''}
              className='form-select form-select-sm'
              onChange={
                setValue
                  ? (e) => setValue(e.target.value)
                  : handleOrderDisSource
              }
            >
              {options &&
                options.map((option, i) => (
                  <option value={option.id} key={i}>
                    {option.name}
                  </option>
                ))}
            </select>
            {showLabs && (
              <select
                value={lab || ''}
                onChange={(e) => setLab(e.target.value)}
                className='form-select form-select-sm mt-3'
              >
                <option value=''>Select Lab</option>
                {orderLabs.map((lab) => (
                  <option value={lab.id} key={lab.id}>
                    {lab.lab}
                  </option>
                ))}
              </select>
            )}
            <input
              type='number'
              className='form-control mt-3 form-select-sm'
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder='Enter payment amount'
            />
            <textarea
              value={textArea}
              onChange={(e) => setTextArea(e.target.value)}
              className='form-control mt-3'
              placeholder='Enter payment note'
              //   placeholder='Enter payment note'
            ></textarea>
            <div className='footer'>
              <button onClick={() => setShow(false)}>Cancel</button>
              <button
                onClick={() => {
                  handleSave(setShow);
                }}
              >
                Save
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default PaymentMethodPopover;
