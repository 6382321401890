import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BranchLabMod from '../components/BranchLabMod/BranchLabMod';
import HeaderToolbar from '../components/HeaderToolbar';
import { AuthContext } from '../context/AuthContext';

class PackageDetails extends Component {
  state = {
    pack: null,
    branches: null,
    locations: null,
    labs: null,
    tLocation: '',
    tBranch: '',
    tPrice: '',
    tSellPrice: '',
    showEditModal: false,
    editId: null,
    editLocation: '',
    editBranch: '',
    editPrice: '',
    editSellPrice: '',
    pOIId: null,
    packageItemId: null,

    showBranchLabModal: false,

    removeDeleteAccess: true,
  };

  fetchPackageItem = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/${id}/`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ pack: resp.data });
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  fetchBranch = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/branch-get/?page=1&limit=200&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ branches: resp.data.results });
      });
  };

  fetchLocation = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/location/`)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ locations: resp.data.results });
        this.fetchLab();
      });
  };

  fetchLab = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/lab/?limit=200&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        let results = resp.data.results.map((item) => {
          const locDetails = this.state.locations.filter(
            (loc) => loc.id === item.location
          );
          return {
            ...item,
            locDetail: locDetails ? locDetails[0] : {},
          };
        });
        this.setState({ labs: results });
      });
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Tests & Packages', link: '/tests' },
      { name: 'Edit Package Item', link: '' },
    ]);
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (userRole.is_superuser || userRole.groups[0].name === 'Admin')
      this.setState({ removeDeleteAccess: false });

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchPackageItem(this.props.match.params.id);
      this.fetchBranch();
      this.fetchLocation();
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name !== 'Admin' &&
        userRole.groups[0].name !== 'Supervisor'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchPackageItem(this.props.match.params.id);
        this.fetchBranch();
        this.fetchLocation();
      }
    }
  }

  handleItemAdd = (e) => {
    e.preventDefault();

    if (this.state.tLocation === '') {
      toast.error(`Please select a location.`, {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.tBranch === '') {
      toast.error(`Please select a branch.`, {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.tPrice === '') {
      toast.error(`Please enter main price.`, {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.tSellPrice === '') {
      toast.error(`Please enter sell price.`, {
        autoClose: 3000,
      });
      return;
    }

    if (!this.state.editId) {
      const diagnosticAdd = {
        name: this.state.pack.name,
        description: this.state.pack.description,
        is_active: true,
        created_by: this.context.user_details.id,
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/`,
          diagnosticAdd
        )
        .then((resp) => {
          const diagnosticId = resp.data.id;

          const postPrice = {
            price: parseInt(this.state.tPrice),
            sell_price: parseInt(this.state.tSellPrice),
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/order/purchaseable-order-item/`,
              postPrice
            )
            .then((resp) => {
              // console.log(resp.data);
              const id = resp.data.id;

              const postData = {
                diagnostic_test: diagnosticId,
                branch: parseInt(this.state.tBranch),
                location: parseInt(this.state.tLocation),
                purchasable_order_item: id,
                updated_by: this.context.user_details.id,
              };

              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/diagnostic/testitem/`,
                  postData
                )
                .then((resp) => {
                  // console.log(resp.data);
                  // this.fetchTestItem(resp.data.diagnostic_test);
                  const postPI = {
                    package: this.state.pack.id,
                    test_item: resp.data.id,
                    updated_by: this.context.user_details.id,
                  };

                  axios
                    .post(
                      `${process.env.REACT_APP_BASE_URL}/diagnostic/packageitem/`,
                      postPI
                    )
                    .then((resp) => {
                      toast.success(`Package item added successfully.`, {
                        autoClose: 3000,
                      });
                      this.setState({ tLocation: '' });
                      this.setState({ tBranch: '' });
                      this.setState({ tPrice: '' });
                      this.setState({ tSellPrice: '' });
                      this.fetchPackageItem(this.props.match.params.id);
                    })
                    .catch((error) => {
                      // console.log(error.response);
                    });
                });
            })
            .catch((error) => {
              // console.log(error.response);
            });
        })
        .catch((error) => {
          // console.log(error.response);
        });
    } else {
      this.handleEdit();
    }
  };

  handleItemDelete = (item) => {
    // console.log(item);

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/packageitem/${item.id}/`
      )
      .then((resp) => {
        toast.success(`Package item deleted successfully.`, {
          autoClose: 3000,
        });
        this.fetchPackageItem(this.props.match.params.id);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  handleItemEdit = (item) => {
    // console.log(item);
    this.setState({ editId: item.test_item.id, packageItemId: item.id });
    this.setState({ pOIId: item.test_item.purchasable_order_item.id });
    this.setState({ tLocation: item.test_item.location.id });
    this.setState({ tBranch: item.test_item.branch.id });
    this.setState({
      tPrice: parseInt(item.test_item.purchasable_order_item.price),
    });
    this.setState({
      tSellPrice: parseInt(item.test_item.purchasable_order_item.sell_price),
    });
  };

  handleEdit = () => {
    const postPrice = {
      price: parseInt(this.state.tPrice),
      sell_price: parseInt(this.state.tSellPrice),
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/packageitem/${this.state.packageItemId}/`,
        {
          updated_by: this.context.user_details.id,
        }
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/purchaseable-order-item/${this.state.pOIId}/`,
        postPrice
      )
      .then((resp) => {
        // console.log(resp.data);
        // const id = resp.data.id;
        const putData = {
          branch: parseInt(this.state.tBranch),
          location: parseInt(this.state.tLocation),
          // purchasable_order_item: id,
          updated_by: this.context.user_details.id,
        };

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/testitem/${this.state.editId}/`,
            putData
          )
          .then((resp) => {
            // console.log(resp.data);
            this.fetchPackageItem(this.props.match.params.id);
            toast.success(`Package item updated successfully.`, {
              autoClose: 3000,
            });
            this.handleCancle();
          });
      });
  };

  handleCancle = () => {
    this.setState({ editId: null, packageItemId: null });
    this.setState({ pOIId: null });
    this.setState({ tLocation: '' });
    this.setState({ tBranch: '' });
    this.setState({ tPrice: '' });
    this.setState({ tSellPrice: '' });
  };

  render() {
    const {
      pack,
      branches,
      locations,
      tPrice,
      tSellPrice,
      editId,
      removeDeleteAccess,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Edit Diagnostic Package' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row page-test-details'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title'>
                      <span className='card-label font-weight-bolder text-dark'>
                        {pack && pack.name}
                      </span>
                    </h3>
                    <div className='card-toolbar'>
                      <Link to='/tests' className='btn btn-primary'>
                        Go Back
                      </Link>
                    </div>
                  </div>
                  <hr />
                  <div className='card-body pt-2 pb-0'>
                    <h5 className='mb-5'>{editId ? 'Edit' : 'Add New'} Item</h5>
                    <form onSubmit={this.handleItemAdd}>
                      <div className='form-group mb-3'>
                        <select
                          className='form-control'
                          onChange={(e) =>
                            this.setState({ tLocation: e.target.value })
                          }
                          value={this.state.tLocation}
                        >
                          <option value=''>Select Location *</option>
                          {locations &&
                            locations.map((location, i) => (
                              <option value={location.id} key={i}>
                                {location.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className='form-group mb-3 d-flex'>
                        <select
                          className='form-control me-3'
                          onChange={(e) =>
                            this.setState({ tBranch: e.target.value })
                          }
                          value={this.state.tBranch}
                        >
                          <option value=''>Select Branch *</option>
                          {branches &&
                            branches.map((branch, i) => (
                              <option value={branch.id} key={i}>
                                {branch.name} &mdash; {branch.lab.name}
                              </option>
                            ))}
                        </select>
                        <div className=''>
                          <button
                            className='btn btn-primary font-weight-bolder fs-2 px-6 py-0 h-100 justify-content-center'
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showBranchLabModal: true });
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className='form-group mb-3'>
                        <input
                          type='number'
                          className='form-control'
                          placeholder='Enter main price *'
                          value={tPrice}
                          onChange={(e) =>
                            this.setState({ tPrice: e.target.value })
                          }
                          min='00'
                        />
                      </div>
                      <div className='form-group mb-3'>
                        <input
                          type='number'
                          className='form-control'
                          placeholder='Enter sell price *'
                          value={tSellPrice}
                          onChange={(e) =>
                            this.setState({ tSellPrice: e.target.value })
                          }
                          min='00'
                        />
                      </div>
                      <div className='form-group mb-3 mb-2'>
                        <button className='btn btn-primary mr-3'>
                          {editId ? 'Edit' : 'Add'} Item
                        </button>
                        {editId && (
                          <button
                            type='button'
                            className='btn btn-danger'
                            onClick={this.handleCancle}
                          >
                            Clear
                          </button>
                        )}
                        {!editId && (
                          <button
                            className='btn btn-danger'
                            onClick={this.handleCancle}
                            type='button'
                          >
                            Clear
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                  <hr />
                  <div className='card-body pt-2 pb-0'>
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          <th>Idx</th>
                          <th>Branch</th>
                          <th>Lab</th>
                          <th>Price</th>
                          <th>Sell Price</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pack &&
                          pack.packageitem.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.test_item.branch.name}</td>
                              <td>{item.test_item.branch.lab.name}</td>
                              <td>
                                {parseInt(
                                  item.test_item.purchasable_order_item.price
                                )}
                              </td>
                              <td>
                                {parseInt(
                                  item.test_item.purchasable_order_item
                                    .sell_price
                                )}
                              </td>
                              <td>
                                <button
                                  className='btn btn-primary mr-3 btn-sm'
                                  onClick={() => this.handleItemEdit(item)}
                                >
                                  Edit
                                </button>
                                {!removeDeleteAccess && (
                                  <button
                                    className='btn btn-danger btn-sm'
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          'Are you sure to delete this package item?'
                                        )
                                      )
                                        this.handleItemDelete(item);
                                    }}
                                  >
                                    Delete
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BranchLabMod
          showBranchLabModal={this.state.showBranchLabModal}
          setStateP={(stateObj) => this.setState(stateObj)}
          branches={this.state.branches}
          fetchBranch={() => this.fetchBranch()}
          locations={this.state.locations}
          fetchLocation={() => this.fetchLocation()}
          labs={this.state.labs}
          fetchLab={() => this.fetchLab()}
        />
      </>
    );
  }
}

export default PackageDetails;
PackageDetails.contextType = AuthContext;
