import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BranchLabMod from '../components/BranchLabMod/BranchLabMod';
import HeaderToolbar from '../components/HeaderToolbar';
import { AuthContext } from '../context/AuthContext';
import BtnLoading from '../components/BtnLoading';

class TestDetails extends Component {
  state = {
    test: null,
    branches: null,
    locations: null,
    labs: null,
    tLocation: '',
    tBranch: '',
    tPrice: '',
    tSellPrice: '',
    tIsActive: true,
    editId: null,
    pOIId: null,

    showBranchLabModal: false,

    removeDeleteAccess: true,

    loading: false,
  };

  fetchTestItem = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test-item-dashboard/${id}/`
      )
      .then((resp) => {
        const checkEverything = resp.data.testitem.filter(
          (item) => item.branch !== null
        );
        // console.log(checkEverything);
        // console.log(resp.data);
        this.setState({ test: resp.data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  fetchBranch = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/branch-get/?page=1&limit=200&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        const data = resp.data.results.filter(
          (branch) => branch.name !== 'Chattogram'
        );
        this.setState({ branches: data });
      });
  };

  fetchLocation = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/location/?limit=200&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        const data = resp.data.results.filter(
          (dis) => dis.name !== 'Chattogram'
        );
        this.setState({ locations: data });
        this.fetchLab();
      });
  };

  fetchLab = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/lab/?limit=200&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        let results = resp.data.results.map((item) => {
          const locDetails = this.state.locations.filter(
            (loc) => loc.id === item.location
          );
          return {
            ...item,
            locDetail: locDetails ? locDetails[0] : {},
          };
        });
        this.setState({ labs: results });
      });
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Tests & Packages', link: '/tests' },
      { name: 'Edit Test Item', link: '' },
    ]);
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (userRole.is_superuser || userRole.groups[0].name === 'Admin')
      this.setState({ removeDeleteAccess: false });

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchTestItem(this.props.match.params.id);
      this.fetchBranch();
      this.fetchLocation();
      // this.fetchLab();
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name !== 'Admin' &&
        userRole.groups[0].name !== 'Supervisor'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchTestItem(this.props.match.params.id);
        this.fetchBranch();
        this.fetchLocation();
        // this.fetchLab();
      }
    }
  }

  handleItemAdd = (e) => {
    e.preventDefault();

    if (this.state.tLocation === '') {
      toast.error(`Please select a location.`, {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.tBranch === '') {
      toast.error(`Please select a branch.`, {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.tPrice === '') {
      toast.error(`Please enter main price.`, {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.tSellPrice === '') {
      toast.error(`Please enter sell price.`, {
        autoClose: 3000,
      });
      return;
    }


    //new validation for existing lab and branch
    if(!this.state.editId){
      for(let x = 0; x<this.state.test.testitem.length; x++){
        // alert(this.state.tBranch)
        if (this.state.tBranch == this.state.test.testitem[x].branch.id) {
          // alert(this.state.test.testitem[x].branch.id);
          // alert(this.state.tBranch)
          toast.error(`This Branch/Lab already exist, please choose another one.`, {
            autoClose: 3000,
          });
          return;
        }
      }
    }
    //finish new validation



    if (!this.state.editId) {
      const postPrice = {
        price: parseInt(this.state.tPrice),
        sell_price: parseInt(this.state.tSellPrice),
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/order/purchaseable-order-item/`,
          postPrice
        )
        .then((resp) => {
          // console.log(resp.data);
          const id = resp.data.id;

          const postData = {
            diagnostic_test: this.state.test.id,
            branch: parseInt(this.state.tBranch),
            location: parseInt(this.state.tLocation),
            purchasable_order_item: id,
            updated_by: this.context.user_details.id,
            is_active: this.state.tIsActive,
          };


          // console.log("Branch is = ", postData.branch);
          // return

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/diagnostic/testitem/`,
              postData
            )
            .then((resp) => {
              console.log("llllllllllllllllll", this.state.test);
              this.fetchTestItem(resp.data.diagnostic_test);
              toast.success(`Test item added successfully.`, {
                autoClose: 3000,
              });
              this.setState({ tLocation: '' });
              this.setState({ tBranch: '' });
              this.setState({ tPrice: '' });
              this.setState({ tSellPrice: '' });
            });
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      this.handleEdit();
    }
  };

  handleItemDelete = (item) => {
    // console.log(item.id);

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/testitem/${item.id}/`
      )
      .then((resp) => {
        toast.success(`Test item deleted successfully.`, {
          autoClose: 3000,
        });
        this.fetchTestItem(this.props.match.params.id);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleItemEdit = (item) => {
    // console.log(item);
    this.setState({ editId: item.id });
    this.setState({ pOIId: item.purchasable_order_item.id });
    this.setState({ pOTestId: item.diagnostic_test.id });
    this.setState({ tLocation: item.location.id });
    this.setState({ tBranch: item.branch.id });
    this.setState({ tPrice: parseInt(item.purchasable_order_item.price) });
    this.setState({ tIsActive: item.is_active ? true : false });
    this.setState({
      tSellPrice: parseInt(item.purchasable_order_item.sell_price),
    });
  };

  handleEdit = () => {
    const postPrice = {
      price: parseInt(this.state.tPrice),
      sell_price: parseInt(this.state.tSellPrice),
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/purchaseable-order-item/`,
        postPrice
      )
      .then((resp) => {
        // console.log(resp.data);



        const putOldOrderData = {
          diagnostic_test:parseInt(this.state.pOTestId),
          //test_item:parseInt(this.state.editId),
          purchasable_order_item:parseInt(this.state.pOIId),
          branch:parseInt(this.state.tBranch),
        };
        //alert(e);
       //return;
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/test-item-order/`,
            putOldOrderData
          )
          .then((resp) => {
            // console.log(resp.data);
            toast.success(`All test order fixation updated successfully.`, {
              autoClose: 3000,
            });
            //this.handleCancle();
          });






        const id = resp.data.id;
        const putData = {
          branch: parseInt(this.state.tBranch),
          location: parseInt(this.state.tLocation),
          purchasable_order_item: id,
          updated_by: this.context.user_details.id,
          is_active: this.state.tIsActive,
        };

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/testitem/${this.state.editId}/`,
            putData
          )
          .then((resp) => {
            // console.log(resp.data);
            this.fetchTestItem(this.props.match.params.id);
            toast.success(`Test item updated successfully.`, {
              autoClose: 3000,
            });
            this.handleCancle();
          });



      });
  };

  handleCancle = () => {
    this.setState({ editId: null });
    this.setState({ pOIId: null });
    this.setState({ tLocation: '' });
    this.setState({ tBranch: '' });
    this.setState({ tPrice: '' });
    this.setState({ tSellPrice: '' });
    this.setState({ tIsActive: true });
  };

  render() {
    const {
      test,
      branches,
      locations,
      tPrice,
      tSellPrice,
      tIsActive,
      editId,
      removeDeleteAccess,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Edit Diagnostic Test' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row page-test-details'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title'>
                      <span className='card-label font-weight-bolder text-dark'>
                        {test && test.name}
                      </span>
                    </h3>
                    <div className='card-toolbar'>
                      <Link to='/tests' className='btn btn-primary'>
                        Go Back
                      </Link>
                    </div>
                  </div>
                  <hr />
                  <div className='card-body pt-2 pb-0'>
                    <h5 className='mb-5'>{editId ? 'Edit' : 'Add New'} Item</h5>
                    <form onSubmit={this.handleItemAdd}>
                      <div className='form-group mb-3'>
                        <select disabled={this.state.editId}
                          className='form-control'
                          onChange={(e) =>
                            this.setState({ tLocation: e.target.value })
                          }
                          value={this.state.tLocation}
                        >
                          <option value=''>Select Location *</option>
                          {locations &&
                            locations.map((location, i) => (
                              <option value={location.id} key={i}>
                                {location.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className='form-group mb-3 d-flex'>
                        <select disabled={this.state.editId}
                          className='form-control me-3'
                          onChange={(e) =>
                            this.setState({ tBranch: e.target.value })
                          }
                          value={this.state.tBranch}
                        >
                          <option value=''>Select Branch *</option>
                          {branches &&
                            branches.map((branch, i) => (
                              <option value={branch.id} key={i}>
                                {branch.name} &mdash; {branch.lab.name}
                              </option>
                            ))}
                        </select>
                        <div className=''>
                          <button
                            className='btn btn-primary font-weight-bolder fs-2 px-6 py-0 h-100 justify-content-center'
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showBranchLabModal: true });
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className='form-group mb-3'>
                        <input
                          type='number'
                          className='form-control'
                          placeholder='Enter main price *'
                          value={tPrice}
                          onChange={(e) =>
                            this.setState({ tPrice: e.target.value })
                          }
                          min='00'
                        />
                      </div>
                      <div className='form-group mb-3'>
                        <input
                          type='number'
                          className='form-control'
                          placeholder='Enter sell price *'
                          value={tSellPrice}
                          onChange={(e) =>
                            this.setState({ tSellPrice: e.target.value })
                          }
                          min='00'
                        />
                      </div>
                      <div className='form-group mb-6 d-flex align-items-center'>
                        <p className='m-0 fs-5 fw-bold me-3'>Is Active</p>
                        <div className='form-check form-switch form-check-custom form-check-solid'>
                          <input
                            type='checkbox'
                            name='is_active'
                            defaultChecked={tIsActive}
                            onChange={() =>
                              this.setState({
                                tIsActive: !this.state.tIsActive,
                              })
                            }
                            checked={tIsActive ? 'checked' : ''}
                            className='form-check-input w-50px'
                          />
                        </div>
                      </div>
                      <div className='form-group mb-3 mb-2'>
                        {!this.state.loading ? (
                          <button className='btn btn-primary me-3'>
                            {editId ? 'Edit' : 'Add'} Item
                          </button>
                        ) : (
                          <BtnLoading
                            name={editId ? 'Editing' : 'Adding'}
                            btnClass='primary me-3'
                          />
                        )}
                        <button
                          className='btn btn-danger'
                          onClick={this.handleCancle}
                          type='button'
                        >
                          Clear
                        </button>
                      </div>
                    </form>
                  </div>
                  <hr />
                  <div className='card-body pt-2 pb-0'>
                    <table className='table table-row-dashed table-row-gray-300 gy-7'>
                      <thead>
                        <tr className='fw-bolder fs-6 text-gray-800'>
                          <th>Idx</th>
                          <th>Branch</th>
                          <th>Lab</th>
                          <th>Price</th>
                          <th>Sell Price</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {test &&
                          test.testitem.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.branch ? item.branch.name : '-'} </td>
                              <td>
                                {item.branch ? item.branch.lab.name : '-'}
                              </td>
                              <td>
                                {parseInt(item.purchasable_order_item.price)}
                              </td>
                              <td>
                                {parseInt(
                                  item.purchasable_order_item.sell_price
                                )}
                              </td>
                              <td>
                                {item.is_active ? <span className="active">Activated</span> : <span className="inactive">Deactivated</span>}
                              </td>
                              <td>
                                <button
                                  className='btn btn-primary mr-3 btn-sm'
                                  onClick={() => this.handleItemEdit(item)}
                                >
                                  Edit
                                </button>
                                {!removeDeleteAccess && (
                                  <>
                                    {!item.branch && (
                                      <button
                                        className='btn btn-danger btn-sm'
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              'Are you sure to delete this test item?'
                                            )
                                          )
                                            this.handleItemDelete(item);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BranchLabMod
          showBranchLabModal={this.state.showBranchLabModal}
          setStateP={(stateObj) => this.setState(stateObj)}
          branches={this.state.branches}
          fetchBranch={() => this.fetchBranch()}
          locations={this.state.locations}
          fetchLocation={() => this.fetchLocation()}
          labs={this.state.labs}
          fetchLab={() => this.fetchLab()}
        />
      </>
    );
  }
}

export default TestDetails;
TestDetails.contextType = AuthContext;
