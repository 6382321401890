import React from 'react';
import Select from 'react-select';

const SelectTests = ({ allTests, setOrderReportTests, orderTests }) => {
  const handleChange = (e) => {
    // console.log(e);
    setOrderReportTests(e);
  };

  return (
    <Select
      isMulti
      options={allTests ? allTests : []}
      placeholder={'Select Tests'}
      className='mb-5'
      onChange={handleChange}
      value={orderTests}
    />
  );
};

export default SelectTests;
