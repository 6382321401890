/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

export const NotificationCenter = ({
  orderStatus,
  showOrderStat,
  hideNotification,
}) => {
  const history = useHistory();
  const handleThisNotif = (e, location) => {
    e.preventDefault();
    hideNotification();
    history.push(location);
    window.location.reload();
  };

  return (
    <>
      {showOrderStat && (
        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown px-7 py-3 w-325px w-md-375px show'
          data-kt-menu='true'
          style={{
            zIndex: 105,
            position: 'fixed',
            inset: '0px 0px auto auto',
            margin: 0,
            transform: 'translate(-80px, 65px)',
          }}
          data-popper-placement='bottom-end'
        >
          <div data-kt-search-element='wrapper'>
            <div className='w-100 mb-1 min-h-25px d-flex'>
              <div className='btn-sm w-75 d-flex justify-content-start align-items-center pt-2 px-2 text-primary'>
                <span className='me-2'>
                  {' '}
                  {orderStatus.proccessing_order +
                    orderStatus.quick_order +
                    orderStatus.order_report_due +
                    orderStatus.order_payment_due}{' '}
                  pending orders{' '}
                </span>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-color-primary w-25'
                data-kt-search-element='toolbar'
              >
                {/* <div data-kt-search-element="preferences-show" className="btn btn-icon w-20px btn-sm btn-active-color-primary me-1" data-bs-toggle="tooltip" title="" data-bs-original-title="Show search preferences">
                  <span className="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="black"></path>
                      <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="black"></path>
                    </svg>
                  </span>
                </div> */}
                <Link onClick={hideNotification} to='/orders'>
                  <div className='w-auto text-primary'>
                    <em>View all</em>
                  </div>
                </Link>
              </div>
            </div>

            <div className='separator border-gray-200 mb-3'></div>

            <div className='mb-4' data-kt-search-element='main'>
              <div className='scroll-y mh-200px mh-lg-325px'>
                <a
                  href='?#'
                  onClick={(e) =>
                    handleThisNotif(e, '/quick-orders?is_responsed=false')
                  }
                  className='d-flex align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='fas fa-pen fs-3'></i>
                    </span>
                  </div>
                  <div className='d-flex flex-column' style={{ flexGrow: 2 }}>
                    <span className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      Quick Order ({orderStatus.quick_order})
                    </span>
                  </div>
                  <div className='d-flex flex-column px-2 align-items-end'>
                    <span className='fs-7 text-muted fw-bold badge badge-light-info'>
                      Website
                    </span>
                  </div>
                </a>
                <a
                  href='?#'
                  onClick={(e) => handleThisNotif(e, '/orders?status=pending')}
                  className='d-flex align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='far fa-hourglass fs-3'></i>
                    </span>
                  </div>
                  <div className='d-flex flex-column' style={{ flexGrow: 2 }}>
                    <span className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      Pending Order ({orderStatus.proccessing_order})
                    </span>
                  </div>
                  <div className='d-flex flex-column px-2 align-items-end'>
                    <span className='fs-7 text-muted fw-bold badge badge-light-info'>
                      Website
                    </span>
                  </div>
                </a>
                {/* <a
                  onClick={(e) => handleThisNotif(e, '/orders?payment-status=unpaid')}
                  href='?#'
                  className='d-flex align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='far fa-money-bill-alt fs-3'></i>
                    </span>
                  </div>
                  <div className='d-flex flex-column' style={{ flexGrow: 2 }}>
                    <span
                      href='#'
                      className='fs-6 text-gray-800 text-hover-primary fw-bold'
                    >
                      Payment Due ({orderStatus.order_payment_due})
                    </span>
                  </div>
                  <div className='d-flex flex-column px-2  align-items-end'>
                    <span className='fs-7 text-muted fw-bold badge badge-light-info'>
                      Website
                    </span>
                  </div>
                </a> */}
                <a
                  onClick={(e) =>
                    handleThisNotif(
                      e,
                      '/orders?report-delivery=soft_copy_due&report-delivery-hard=hard_copy_due'
                    )
                  }
                  href='?#'
                  className='d-flex align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='far fa-file-alt fs-3'></i>
                    </span>
                  </div>
                  <div className='d-flex flex-column' style={{ flexGrow: 2 }}>
                    <span
                      href='#'
                      className='fs-6 text-gray-800 text-hover-primary fw-bold'
                    >
                      Report Due ({orderStatus.order_report_due})
                    </span>
                  </div>
                  <div className='d-flex flex-column px-2  align-items-end'>
                    <span className='fs-7 text-muted fw-bold badge badge-light-info'>
                      Website
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <span className='svg-icon svg-icon-4x opacity-50'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z'
                      fill='black'
                    ></path>
                    <path
                      d='M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z'
                      fill='black'
                    ></path>
                    <rect
                      x='13.6993'
                      y='13.6656'
                      width='4.42828'
                      height='1.73089'
                      rx='0.865447'
                      transform='rotate(45 13.6993 13.6656)'
                      fill='black'
                    ></rect>
                    <path
                      d='M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z'
                      fill='black'
                    ></path>
                  </svg>
                </span>
              </div>
              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>
                  Please try again with a different query
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationCenter;
