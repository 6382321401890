import { useState, useRef, useEffect } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import AngleDown from '../../assets/img/angle-down.png';

const FilterPopover = (props) => {
  const {
    title,
    value,
    customIcon,
    setValue,
    options,
    handleSave,
    handleIClick,
    placement,
    onBlurHide,
    popOverId,
    editAccess,
    placeholder,
  } = props;

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  useEffect(() => {

  }, [])

  const handleClick = (e) => {
    if (show) ref.current.blur();
    else ref.current.focus()
    setShow(!show);
    if (e) setTarget(e.target);
    handleIClick && handleIClick();
  };

  return (
    <div ref={ref} className='select-pop' tabIndex={-1} onBlur={() => {
      if (onBlurHide) handleClick();
    }} >
      <div className='d-flex flex-row align-items-center pointer' onClick={(e) => handleClick(e)} >
        {customIcon
          ? customIcon()
          : <div className={`px-1 border border-0 d-flex align-items-center ms-1${!editAccess ? ' d-none' : ''}`} >
            <img src={AngleDown} alt='' style={{ width: '15px', height: '8px', opacity: 0.3, }} />
          </div>
        }
      </div>

      <Overlay
        show={show}
        target={target}
        placement={'bottom'}
        container={ref.current}
        containerPadding={20}
      >
        <Popover
          id={popOverId || 'popover-contained'}
          style={{ boxShadow: '0px 0px 50px 0px #27134e18', fontSize: '11px', }}
        >
          <Popover.Content style={{ maxHeight: '250px', overflowY: 'auto', }}>
            {options && options.map((option, i) => (
              <div className={`pointer border px-2 py-1${i === (options.length - 1) ? ` border-0` : ` border-left-0 border-right-0 border-top-0`}`} value={option.id} key={i}
                onClick={() => {
                  if (option.id) setValue(option.id);
                  setShow(false);
                  handleSave(option.id);
                }}
              >
                {option.name}
              </div>
            ))}
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default FilterPopover;
