import { Spinner } from 'react-bootstrap';

const BtnLoading = ({ name, btnClass }) => {
  return (
    <button className={`btn btn-${btnClass}`} disabled>
      <Spinner animation='border' size='sm' /> {name ? name : 'Loading'}
    </button>
  );
};

export default BtnLoading;
