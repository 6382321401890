import axios from 'axios';
import React, { Component } from 'react';
import { Modal, ModalBody, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

export class TestCategoryAddModal extends Component {
  state = {
    categoryEditId: null,
    categoryName: '',
    categoryDesc: '',
    categoryImage: null,
    categoryImageExisting: '',

    loading: false,
    deleting: false,
    deletingId: null,

    isSupervisor: false,
  };

  componentDidMount() {
    const user_details = localStorage.getItem('user_details');
    if (user_details) {
      const userRole = JSON.parse(user_details);
      if (userRole.groups.length !== 0) {
        if (userRole.groups[0].name === 'Supervisor')
          this.setState({ isSupervisor: true });
      }
    }
  }

  handleCloseCategoryModal = () => {
    const { setShowTestCategoryModal } = this.props;
    setShowTestCategoryModal(false);
  };

  addEditCategory = () => {
    const {
      categoryEditId,
      categoryName,
      categoryDesc,
      categoryImage,
      categoryImageExisting,
    } = this.state;
    const { fetchTestCategories } = this.props;

    if (!categoryName)
      toast.error('Please fill the category name.', { autoClose: 3000 });
    else if (!categoryImage && !categoryEditId)
      toast.error('Please upload an image.', { autoClose: 3000 });
    else if (categoryImage && categoryImage.size > 1024000)
      toast.error('Image can not be more than 1MB.', { autoClose: 3000 });
    else {
      let postData = {
        name: categoryName,
        description: categoryDesc,
      };
      this.setState({ loading: true });

      if (!categoryEditId) {
        // postData["description"] = labDesc;
        postData['image'] = categoryImage;

        let postFormData = new FormData();
        for (const key in postData) {
          // console.log(key, postData[key]);
          postFormData.append(key, postData[key]);
        }

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/category/`,
            postFormData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          )
          .then((resp) => {
            fetchTestCategories();
            this.handleClearCategory();
            toast.success('New category has been added successfully.', {
              autoClose: 3000,
            });
            this.setState({ loading: false });
            this.handleCloseCategoryModal();
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
            toast.error('Category add failed.', { autoClose: 3000 });
          });
      } else {
        // if (categoryDesc) postData["description"] = categoryDesc;
        if (categoryImage) postData['logo'] = categoryImage;

        let postFormData = new FormData();
        for (const key in postData) {
          postFormData.append(key, postData[key]);
        }

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/category/${categoryEditId}/`,
            postFormData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          )
          .then((resp) => {
            fetchTestCategories();
            this.handleClearCategory();
            this.setState({ loading: false });
            toast.success('Category has been updated successfully.', {
              autoClose: 3000,
            });
            this.handleCloseCategoryModal();
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
            toast.error('Category update failed.', { autoClose: 3000 });
          });
      }
    }
  };

  handleEditCategory = (cat) => {
    this.setState({
      categoryEditId: cat.id,
      categoryName: cat.name,
      categoryDesc: cat.description,
      categoryImage: null,
      categoryImageExisting: cat.image,
    });
  };

  handleDeleteCategory = (cat) => {
    const { fetchTestCategories } = this.props;
    this.setState({ deleting: true, deletingId: cat.id });

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/category/${cat.id}/`
      )
      .then((resp) => {
        fetchTestCategories();
        this.setState({ deleting: false, deletingId: null });
        toast.success(`Category deleted successfully.`, { autoClose: 3000 });
      })
      .catch((error) => {
        this.setState({ deleting: false, deletingId: null });
        toast.error('Category deletion failed.', { autoClose: 3000 });
        console.log(error.response);
      });
  };

  handleClearCategory = () => {
    this.setState({
      categoryEditId: null,
      categoryName: '',
      categoryDesc: '',
      categoryImage: null,
      categoryImageExisting: '',
    });
  };

  render() {
    const { showTestCategoryModal, testCategories } = this.props;
    const {
      categoryEditId,
      categoryName,
      categoryDesc,
      categoryImage,
      categoryImageExisting,
      loading,
      deleting,
      deletingId,
      isSupervisor,
    } = this.state;

    return (
      <>
        <Modal
          show={showTestCategoryModal}
          onHide={() => this.handleCloseCategoryModal()}
          animation={true}
          size='lg'
        >
          <ModalBody>
            <div className='row page-test-details'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='edit-order'>
                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                      <h5 className='mb-0'>
                        {categoryEditId ? 'Edit' : 'Add New'} Category
                      </h5>
                      <button
                        className='btn btn-sm btn-danger'
                        onClick={() => this.handleCloseCategoryModal()}
                      >
                        Close
                      </button>
                    </div>
                    {/* <hr /> */}
                    <div className='card-body p-0 pt-2'>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className='form-group mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter category name'
                            value={categoryName}
                            onChange={(e) =>
                              this.setState({ categoryName: e.target.value })
                            }
                          />
                        </div>
                        <div className='form-group mb-3'>
                          <textarea
                            className='form-control'
                            placeholder='Enter category description'
                            rows='4'
                            value={categoryDesc}
                            onChange={(e) =>
                              this.setState({ categoryDesc: e.target.value })
                            }
                          ></textarea>
                        </div>
                        <div className='form-group mb-3'>
                          <div className='custom-file'>
                            <input
                              type='file'
                              className='custom-file-input'
                              accept='image/png, image/jpeg'
                              onChange={(e) => {
                                this.setState({
                                  categoryImage: e.target.files[0],
                                });
                              }}
                            />
                            <label className='custom-file-label'>
                              {categoryImage
                                ? categoryImage.name
                                : categoryImageExisting
                                ? categoryImageExisting.length > 85
                                  ? categoryImageExisting.slice(0, 83) + '...'
                                  : categoryImageExisting
                                : `Select category image`}
                            </label>
                          </div>
                        </div>

                        <div className='form-group mb-3 mb-2'>
                          <button
                            className='btn btn-primary me-3'
                            disabled={loading}
                            onClick={this.addEditCategory}
                          >
                            {categoryEditId ? 'Edit' : 'Add'} category
                            {loading && (
                              <Spinner
                                size='sm'
                                animation='border'
                                className='ms-2'
                              />
                            )}
                          </button>
                          {categoryEditId && (
                            <button
                              className='btn btn-danger'
                              onClick={this.handleClearCategory}
                              type='button'
                            >
                              Clear
                            </button>
                          )}
                          {!categoryEditId && (
                            <button
                              className='btn btn-danger'
                              onClick={this.handleClearCategory}
                              type='button'
                            >
                              Clear
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                    <hr />
                    <div
                      className='card-body pt-0 px-2 table-responsive sticky-header'
                      style={{ maxHeight: '400px' }}
                    >
                      <table className='table table-bordered'>
                        <thead>
                          <tr>
                            <th>Idx</th>
                            <th style={{ minWidth: '200px' }}>Name</th>
                            <th style={{ minWidth: '100px' }}>Logo</th>
                            <th style={{ minWidth: '200px' }}>Description</th>
                            <th style={{ maxWidth: '100px' }}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {testCategories &&
                            testCategories.map((cat, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{cat.name}</td>
                                <td className=''>
                                  {cat.image ? (
                                    <img
                                      src={cat.image}
                                      alt=''
                                      width='30px'
                                      height='30px'
                                      className='shadow'
                                    />
                                  ) : (
                                    <div
                                      className='d-flex justify-content-center align-items-center border border-2 rounded'
                                      style={{ width: '30px', height: '30px' }}
                                    >
                                      <i
                                        className='fas fa-file-medical-alt'
                                        style={{
                                          fontSize: '12px',
                                          borderRadius: 5,
                                        }}
                                      />
                                    </div>
                                  )}
                                </td>
                                {cat.description ? (
                                  <td>{cat.description}</td>
                                ) : (
                                  <td className='px-4'>-</td>
                                )}
                                <td>
                                  <button
                                    className='btn btn-primary mr-3 btn-sm'
                                    onClick={() => this.handleEditCategory(cat)}
                                  >
                                    Edit
                                  </button>
                                  {!isSupervisor && (
                                    <button
                                      disabled={
                                        deleting &&
                                        deletingId &&
                                        deletingId === cat.id
                                      }
                                      className='btn btn-danger btn-sm'
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            'Are you sure to delete this category?'
                                          )
                                        )
                                          this.handleDeleteCategory(cat);
                                      }}
                                    >
                                      Delete
                                      {deleting &&
                                        deletingId &&
                                        deletingId === cat.id && (
                                          <Spinner
                                            size='sm'
                                            animation='border'
                                            className='ms-2'
                                          />
                                        )}
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default TestCategoryAddModal;
