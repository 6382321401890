import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PatientDob from './PatientDob';
import SearchTests from './SearchTests';
import SelectLab from './SelectLab';
import PatientAddModal from './PatientAddModal';

const OrderItemAddNew = ({
  itemEditModal,
  setItemEditModal,
  userPatients,
  setUserPatients,
  testItemModalClose,
  testCarts,
  setTestCarts,
  handleSomeColculation,
  userPatientName,
  handlePatientSearch,
  userDetails,
  userAllPatients,
}) => {
  const [testType, setTestType] = useState('diagnostic');
  const [allLabs, setAllLabs] = useState([]);
  const [labDetails, setLabDetails] = useState(null);
  const [orderPatients, setOrderPatients] = useState([]);
  const [Loading, setLoading] = useState(false);

  //patient add
  const [showPatAddForm, setShowPatAddForm] = useState(false);
  const [patientName, setPatientName] = useState('');
  const [patientGender, setPatientGender] = useState('');
  const [patientDob, setPatientDob] = useState(null);

  const [testName, setTestName] = useState(null);
  const [selectedLabName, setSelectedLabName] = useState([]);
  const [labAndTest, setLabAndTest] = useState([]);

  const [showPatientAddModal, setShowPatientAddModal] = useState(false);

  const handleFamilyMember = (e, patient) => {
    if (e.target.checked) {
      setOrderPatients([...orderPatients, patient]);
    } else {
      // setOrderPatients.filter(
      //   (person) => person.full_name !== patient.full_name
      // );
      setOrderPatients(
        orderPatients.filter((person) => person.full_name !== patient.full_name)
      );
    }
  };

  const handleAddNewPatient = (e) => {
    e.preventDefault();

    if (patientName.trim() === '') {
      toast.error(`Please enter patient name.`, {
        autoClose: 3000,
      });
    } else if (patientName.length < 4) {
      toast.error(`Patient name must be at least three character long.`, {
        autoClose: 3000,
      });
    } else if (patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else if (patientDob === '' || patientDob === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    } else {
      const postPatient = {
        full_name: patientName,
        dob: moment(patientDob).format('YYYY-MM-DD'),
        sex: patientGender,
        // user: orderUserId,
      };
      // console.log(postPatient);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
          postPatient
        )
        .then((resp) => {
          // console.log(resp.data);
          setUserPatients([...userPatients, resp.data]);
          setPatientName('');
          setPatientGender('');
          setPatientDob(null);
          setShowPatAddForm(false);
        });
    }
  };

  const handleSingleTestAdd = () => {
    if (labAndTest.length === 0) {
      toast.error('Please select test and lab.');
      return;
    }

    if (orderPatients.length === 0) {
      toast.error('Please select patients.', {
        autoClose: 3000,
      });
      return;
    }

    let carts = [];

    orderPatients.map((patient) => {
      return labAndTest.map((test) => {
        const single = {
          order_type: test.labDetails.order_type,
          patient: patient,
          test_item: test.labDetails,
        };
        // console.log(z);
        carts.push(single);
      });
    });

    if (testCarts.length !== 0) {
      if (testCarts[0].order_type !== carts[0].order_type) {
        toast.error(
          'Sorry, You cannot order packages and tests at the same time.'
        );
        return;
      }
      // console.log(this.state.orders[0].order_type);
      // console.log(carts[0].order_type);
    }

    setTestCarts(carts);
    setOrderPatients([]);
    setAllLabs([]);
    setLabDetails(null);
    setTestName(null);
    setLabAndTest([]);
    setItemEditModal(false);
    handleSomeColculation();

    toast.success('Test added successfully.', {
      autoClose: 3000,
    });

    return;
    // console.log(orderPatients);
    // console.log(labDetails);
    setLoading(true);

    const orderItem = orderPatients.map((patient) => {
      return {
        order: 40,
        patient: patient.id,
        order_type: labDetails.order_type,
        address: 2,
        // meterial_fee:
        //   mainOrder.orderitem.length !== 0
        //     ? mainOrder.orderitem[0].meterial_fee
        //     : fees.meterial_fee,
        purchasable_order_item:
          labDetails.order_type === 'diagnostic'
            ? labDetails.purchasable_order_item.id
            : labDetails.test_item.purchasable_order_item.id,
      };
    });

    orderItem.forEach((item, i) => {
      // console.log(orderItem.length, i);

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/order/order-item/`, item)
        .then((resp) => {
          // console.log(resp.data);
          if (orderItem.length === i + 1) {
            testItemModalClose();
            setTimeout(() => {
              setOrderPatients([]);
              setLabDetails(null);
              setLoading(false);
              setTestName(null);
            }, 1000);
          }
        })
        .catch((error) => console.log(error.response));
    });
  };

  const handleAddTestAndLab = () => {
    const labName =
      testType === 'diagnostic'
        ? labDetails.branch.lab.id
        : labDetails.test_item.branch.lab.id;

    setSelectedLabName([labName]);
    // return;

    const testNdLab = {
      testDetails: testName,
      labDetails: labDetails,
    };
    // console.log(testNdLab);
    setLabAndTest([testNdLab, ...labAndTest]);
    setTestName(null);
    setLabDetails(null);
    // setTimeout(() => {
    //   console.log(this.state.LabAndTest);
    // }, 500);
  };

  const handleTestAndLabDlt = (test) => {
    // console.log(test);
    const filterlabAndTest = labAndTest.filter((o) => o !== test);
    setLabAndTest(filterlabAndTest);

    setTimeout(() => {
      // console.log(this.state.LabAndTest);
      if (testCarts.length === 0) {
        if (filterlabAndTest.length === 0) {
          setSelectedLabName([]);
        }
      }
    }, 200);
  };

  const modalClose = () => {
    setOrderPatients([]);
    setAllLabs([]);
    setLabDetails(null);
    setTestName(null);
    setLabAndTest([]);
    setItemEditModal(false);
  };

  return (
    <>
      <Modal
        show={itemEditModal}
        animation={false}
        // onHide={modalClose}
        size='lg'
      >
        <ModalBody className='ord-edt-itm-mdl ord-edt-itm-mdl-add'>
          <p className='text-end m-0'>
            <button className='btn btn-light modal-close' onClick={modalClose}>
              Close
            </button>
          </p>
          <div className='patients'>
            <h5 className='title'>Select Patient *</h5>
            <input
              type='text'
              className='form-control mb-4'
              placeholder='Search by name'
              onChange={handlePatientSearch}
              value={userPatientName}
            />
            <div className='lists row'>
              {userPatients &&
                userPatients.map((patient, i) => (
                  <div className='col-md-6' key={i}>
                    <div className={`box ${patient.is_account ? 'user' : ''}`}>
                      <label className='single'>
                        <div className='details'>
                          <span className='name'>{patient.full_name}</span>
                          <span className='age'>
                            Age:{' '}
                            {moment().diff(`${patient.dob}`, 'years') +
                              ' Years'}
                          </span>
                          <span className='sex'>Sex: {patient.sex}</span>
                          {/* <div className='buttons'>
                          <i className='far fa-trash-alt me-3'></i>
                          <i className='far fa-edit edit'></i>
                        </div> */}
                        </div>
                        <input
                          type='checkbox'
                          name='pat'
                          onChange={(e) => handleFamilyMember(e, patient)}
                        />
                        <span className='checkmark'></span>
                      </label>
                    </div>
                  </div>
                ))}
            </div>
            <p className='text-end m-0 add-patient'>
              <i
                className='fas fa-plus'
                onClick={() => {
                  setShowPatientAddModal(true);
                  setItemEditModal(false);
                  setOrderPatients([]);
                }}
              ></i>
            </p>
          </div>
          <div className='tests row'>
            <div className='col-md-6'>
              <h5 className='title'>Test Type *</h5>
              <select
                className='form-select my-3'
                value={testType}
                onChange={(e) => setTestType(e.target.value)}
              >
                <option value='diagnostic'>Diagnostic</option>
                <option value='package'>Package</option>
              </select>
            </div>
            <div className='col-md-6'>
              <h5 className='title'>Select Test *</h5>
              <SearchTests
                testType={testType}
                setAllLabs={setAllLabs}
                testName={testName}
                setTestName={setTestName}
                selectedLabName={selectedLabName}
              />
            </div>
            {testType === 'package' && testName && testName.description && (
              <div className='col-md-12'>
                <p className='mb-3'>Package Details: {testName.description}</p>
              </div>
            )}
            <div className='col-12 mb-5'>
              <h5 className='title'>Select Lab *</h5>
              <div className='lab-and-button'>
                <SelectLab
                  allLabs={allLabs}
                  setLabDetails={setLabDetails}
                  labDetails={labDetails}
                />
                <button
                  type='button'
                  className='btn badge bg-secondary text-dark'
                  onClick={handleAddTestAndLab}
                  disabled={testName && labDetails ? '' : 'disabled'}
                >
                  +{/* <i className='fas fa-plus'></i> */}
                </button>
              </div>
            </div>
            <div className='col-12'>
              {labAndTest.length !== 0 &&
                labAndTest.map((test, i) => (
                  <div key={i} className='mb-5 lab-test-div'>
                    <p className='m-0 fw-bold'>
                      {i + 1}. {test.testDetails.name} &mdash;{' '}
                      {test.labDetails.order_type === 'diagnostic'
                        ? test.labDetails.branch.lab.name
                        : test.labDetails.test_item.branch.lab.name}{' '}
                      &mdash;{' '}
                      {test.labDetails.order_type === 'diagnostic'
                        ? parseInt(
                            test.labDetails.purchasable_order_item.sell_price
                          )
                        : parseInt(
                            test.labDetails.test_item.purchasable_order_item
                              .sell_price
                          )}{' '}
                    </p>
                    <i
                      onClick={() => {
                        if (window.confirm('Are you sure to delete this test?'))
                          handleTestAndLabDlt(test);
                      }}
                      className='fas fa-trash text-danger pointer'
                    ></i>
                  </div>
                ))}
            </div>
            <div className='col-12'>
              <p className='text-end'>
                <button
                  className='submit-add-btn btn btn-primary'
                  onClick={handleSingleTestAdd}
                >
                  Add Test
                </button>
              </p>
            </div>
          </div>

          {/* {!showPatAddForm && (
          <span
            onClick={() => setShowPatAddForm(true)}
            className='bg-success text-white rounded px-2 py-1 pointer'
          >
            Add New Patient
          </span>
        )} */}

          {showPatAddForm && (
            <form className='pat-add' onSubmit={handleAddNewPatient}>
              <div className='first d-flex'>
                <div className='left mr-2'>
                  <input
                    type='text'
                    placeholder='Patient name'
                    value={patientName}
                    onChange={(e) => setPatientName(e.target.value)}
                  />
                </div>
                <div className='right ml-2'>
                  <p className='mb-0 font-size-lg font-weight-bold'>Gender</p>
                  <div className='male-female d-flex'>
                    <label className='gender'>
                      Male
                      <input
                        type='radio'
                        name='gender'
                        value='male'
                        onChange={(e) => setPatientGender(e.target.value)}
                      />
                      <span className='checkmark'></span>
                    </label>
                    <label className='gender'>
                      Female
                      <input
                        type='radio'
                        name='gender'
                        value='female'
                        onChange={(e) => setPatientGender(e.target.value)}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='last'>
                <PatientDob
                  patientDob={patientDob}
                  setPatientDob={setPatientDob}
                />
              </div>
              <button className='btn btn-primary btn-sm mt-2'>
                Add Patient
              </button>
            </form>
          )}
        </ModalBody>
      </Modal>

      <PatientAddModal
        showModal={showPatientAddModal}
        setShowModal={setShowPatientAddModal}
        setItemModal={setItemEditModal}
        userDetails={userDetails}
        userAllPatients={userAllPatients}
        setUserPatients={setUserPatients}
      />
    </>
  );
};

export default OrderItemAddNew;
