import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderToolbar from '../components/HeaderToolbar';
import PatientDob from '../components/PatientDob';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';
import BtnLoading from '../components/BtnLoading';

class EditUser extends Component {
  state = {
    group: [],
    userId: null,
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    username: '',
    user_group: '',
    lab_permit: '',
    is_active: false,
    is_staff: false,
    is_superuser: false,
    patient_id: null,

    labs: [],
    selectedLabs: [],

    patientDob: null,
    dobInputType: 'text',
    patientGender: '',
    submitLoading: false,
  };

  setPatientDob = (val) => {
    this.setState({ patientDob: val });
  };

  fetchLab = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab/`)
      .then((resp) => {
        // console.log(resp.data)
        this.setState({ labs: resp.data.results });
      })
      .catch((e) => {});
  };

  fetchGroup = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user_management/group/`)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ group: resp.data.results });
      });
  };

  fetchEditUser = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user_management/user/${id}`)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ first_name: resp.data.first_name });
        this.setState({ last_name: resp.data.last_name });
        this.setState({ email: resp.data.email });
        this.setState({
          user_group: resp.data.groups.length !== 0 ? resp.data.groups[0] : '',
        });
        this.setState({ is_active: resp.data.is_active });
        this.setState({ is_staff: resp.data.is_staff });
        this.setState({ userId: resp.data.id });
        this.setState({ username: resp.data.username });
        this.setState({ password: resp.data.password });
        this.setState({ is_superuser: resp.data.is_superuser });

        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/user_management/patient/?user=${resp.data.id}&limit=300`
          )
          .then((resp) => {
            // console.log(resp.data.results);
            if (resp.data.results.length !== 0) {
              const userPatient = resp.data.results.filter((patient) => {
                return patient.is_account === true;
              });

              this.setState({
                patient_id: userPatient.length !== 0 ? userPatient[0].id : null,
              });
              this.setState({
                lab_permit:
                  userPatient.length !== 0 ? userPatient[0].lab_permit : '',
              });
              this.setState({
                selectedLabs:
                  userPatient.length !== 0 && userPatient[0].lab_permit
                    ? userPatient[0].lab_permit.split(', ')
                    : [],
              });

              this.setState({
                patientDob:
                  userPatient.length !== 0
                    ? new Date(userPatient[0].dob)
                    : null,
              });
              this.setState({
                patientGender:
                  userPatient.length !== 0 ? userPatient[0].sex : '',
              });
            }
          });
      })
      .catch((error) => {
        // console.log(error.response);
        if (error.response.status === 404) {
          this.props.history.push('/users');
        }
      });
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Users', link: '/users' },
      { name: 'Edit User', link: '' },
    ]);
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    if (!token) {
      this.props.history.push('/login');
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });

      const userRole = JSON.parse(user_details);

      if (userRole.is_superuser) {
        this.fetchGroup();
        this.fetchLab();
        const params = this.props.match.params.id;
        if (params) {
          this.fetchEditUser(params);
        }
      } else {
        if (userRole.groups.length === 0) {
          this.props.history.push('/orders');
        } else if (userRole.groups[0].name !== 'Admin') {
          this.props.history.push('/orders');
        } else {
          this.fetchGroup();
          this.fetchLab();
          const params = this.props.match.params.id;

          if (params) {
            this.fetchEditUser(params);
          }
        }
      }
    }
  }

  handleRole = (e) => {
    // console.log(e.target.value);
    this.setState({ user_group: e.target.value });
  };

  ajaxPut = (putUser, putPatient) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/${this.state.userId}/`,
        putUser
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`User profile updated successfully.`, {
          autoClose: 3000,
        });
        this.props.history.push('/users');

        if (this.state.patient_id !== null) {
          this.ajaxPutPatient(putPatient);
        }
      })
      .catch((error) => {
        // console.log(error.response);
        this.setState({ submitLoading: false });
        if (error.response.status === 400) {
          if (error.response.data.username) {
            toast.error(`${error.response.data.username[0]}`, {
              autoClose: 3000,
            });
          } else if (error.response.data.groups[0]) {
            toast.error(`Please select a role.`, {
              autoClose: 3000,
            });
          }
        } else {
          toast.error('Sorry something went wrong, please try again later.', {
            autoClose: 3000,
          });
        }
      });
  };

  ajaxPutPatient = (putPatient) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient/${this.state.patient_id}/`,
        putPatient
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ submitLoading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let numberPattern = /^01\d{9}$/;
    let emailPattern =
      /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
    if (!numberPattern.test(this.state.username)) {
      toast.error('Please enter a valid mobile number.', {
        autoClose: 3000,
      });
    } else if (this.state.first_name.trim() === '') {
      toast.error('Please enter first name.', {
        autoClose: 3000,
      });
    } else if (this.state.patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else if (this.state.user_group === '') {
      toast.error(`Please select a role.`, {
        autoClose: 3000,
      });
    } else {
      this.setState({ submitLoading: true });
      let strToInt;
      const typeCheck = typeof this.state.user_group;
      if (typeCheck === 'string') {
        strToInt = parseInt(this.state.user_group);
      } else {
        strToInt = this.state.user_group;
      }
      const putUser = {
        username: this.state.username,
        email: this.state.email,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        is_staff: this.state.is_staff,
        is_active: this.state.is_active,
        // is_superuser: strToInt === 1 ? true : false,
        password: this.state.password,
        groups: [strToInt],
      };

      const putPatient = {
        full_name: `${this.state.first_name} ${this.state.last_name}`,
        email: this.state.email,
        lab_permit:
          this.state.user_group == '5'
            ? this.state.selectedLabs.join(', ')
            : '',
        dob: moment(this.state.patientDob).format('YYYY-MM-DD'),
        sex: this.state.patientGender,
      };

      this.ajaxPut(putUser, putPatient);
    }
  };

  handleLab = (e) => {
    const value = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    this.setState({ selectedLabs: value });
  };

  render() {
    // console.log(this.props.match.params.id);
    const {
      group,
      email,
      first_name,
      last_name,
      is_active,
      user_group,
      username,
      is_superuser,
      // is_staff,
      // newPassword,
      labs,
      selectedLabs,
      submitLoading,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Edit User' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card py-8 px-8 py-lg-15 px-lg-10'>
                  <div className='row justify-content-center'>
                    <div className='col-xl-9'>
                      <h5 className='text-dark font-weight-bold mb-10'>
                        Edit User :
                      </h5>
                      <form onSubmit={this.handleSubmit}>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Phone Number *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={username}
                              onChange={(e) =>
                                this.setState({ username: e.target.value })
                              }
                              placeholder='01XXXXXX'
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            First Name *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={first_name}
                              onChange={(e) =>
                                this.setState({ first_name: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Last Name
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              value={last_name}
                              onChange={(e) =>
                                this.setState({ last_name: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Date Of Birth *
                          </label>
                          <div className='col-lg-9 col-xl-9 patient-dob'>
                            <PatientDob
                              patientDob={this.state.patientDob}
                              setPatientDob={this.setPatientDob}
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Gender *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <select
                              className='form-select form-control form-control-solid form-control-lg'
                              // value={user_group || ''}
                              value={this.state.patientGender}
                              onChange={(e) =>
                                this.setState({ patientGender: e.target.value })
                              }
                            >
                              <option value=''>Select user gender</option>
                              <option value='male'>Male</option>
                              <option value='female'>Female</option>
                            </select>
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Email
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              className='form-control form-control-solid form-control-lg'
                              type='email'
                              value={email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        {/* <div className='form-group row fv-plugins-icon-container mb-3'>
                    <label className='col-xl-3 col-lg-3 col-form-label'>
                      Password
                    </label>
                    <div className='col-lg-9 col-xl-9'>
                      <input
                        className='form-control form-control-solid form-control-lg'
                        type='text'
                        value={newPassword}
                        onChange={(e) =>
                          this.setState({ newPassword: e.target.value })
                        }
                      />
                      <div className='fv-plugins-message-container' />
                    </div>
                  </div> */}
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Role *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <select
                              className='form-control form-control-solid form-control-lg form-select'
                              value={user_group || ''}
                              onChange={this.handleRole}
                            >
                              <option value=''>User Role</option>
                              {group.length !== 0 &&
                                group.map((group, i) => (
                                  <option key={i} value={group.id}>
                                    {group.name === 'Lab Partner'
                                      ? 'Partner'
                                      : group.name}
                                  </option>
                                ))}
                            </select>
                            <div className='fv-plugins-message-container' />
                          </div>
                        </div>
                        <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label checkbox checkbox-success'>
                            Is Active
                          </label>
                          <div className='col-lg-9 col-xl-9 d-flex align-items-center'>
                            <input
                              type='checkbox'
                              name='is_active'
                              onChange={() =>
                                this.setState({
                                  is_active: !this.state.is_active,
                                })
                              }
                              checked={is_active}
                            />
                          </div>
                        </div>
                        {/* <div className='form-group row fv-plugins-icon-container mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label checkbox checkbox-success'>
                            Is Stuff
                          </label>
                          <div className='col-lg-9 col-xl-9 d-flex align-items-center'>
                            <input
                              type='checkbox'
                              name='is_staff'
                              onChange={() =>
                                this.setState({ is_staff: !this.state.is_staff })
                              }
                              checked={is_staff}
                            />
                          </div>
                        </div> */}
                        {user_group == '5' && (
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Select Labs
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <select
                                multiple
                                className='form-control form-control-solid form-control-lg custom_scrollbar'
                                onChange={this.handleLab}
                                value={selectedLabs}
                              >
                                {labs.length !== 0 &&
                                  labs.map((lab, i) => (
                                    <option key={i} value={lab.id}>
                                      {lab.name}
                                    </option>
                                  ))}
                              </select>
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                        )}
                        <div className='d-flex justify-content-end border-top pt-5 mt-2'>
                          <div>
                            <Link
                              to='/users'
                              type='submit'
                              className='btn btn-success font-weight-bolder px-9 py-4 mr-3'
                            >
                              Go Back
                            </Link>
                          </div>
                          <div>
                            {!submitLoading ? (
                              <button
                                type='submit'
                                className='btn btn-primary font-weight-bolder px-9 py-4'
                                disabled={is_superuser ? 'disabled' : ''}
                              >
                                Update
                              </button>
                            ) : (
                              <BtnLoading
                                name='Updating...'
                                btnClass='primary font-weight-bolder px-9 py-4'
                              />
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditUser;
EditUser.contextType = AuthContext;
