import moment from 'moment';
import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import BtnLoading from './BtnLoading';

const ChannelRefCategory = ({
  showModal,
  setShowModal,
  title,
  name,
  setName,
  isActive,
  setIsActive,
  handleSave,
  lists,
  handleDelete,
  handleEdit,
  editMode,
  clearForm,
  addLoading,
  deleteLoading,
  deleteId,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      animation={false}
      size='lg'
    >
      <ModalBody>
        <div className='channel-reference .row'>
          <div className='mb-5 d-flex justify-content-between align-items-center'>
            <h5 className='mb-0'></h5>
            <button
              className='btn btn-sm btn-danger'
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
          <hr />
          <h5 className='mb-5'>{title}</h5>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSave();
            }}
          >
            <div className='form-group mb-5'>
              <p className='fs-5 mb-3'>Category Name*</p>
              <input
                type='text'
                className='form-control'
                placeholder='Enter category name *'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='form-group mb-5 d-flex align-items-center'>
              <p className='fs-5 mb-0'>Is Active</p>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  type='checkbox'
                  name='new_user_only'
                  onChange={() => setIsActive()}
                  checked={isActive ? 'checked' : ''}
                  className='form-check-input w-50px h-25px ms-3'
                />
              </div>
            </div>
            {addLoading ? (
              <BtnLoading name='Loading...' btnClass='primary' />
            ) : (
              <button className='btn btn-primary'>{title}</button>
            )}
            {editMode && (
              <button
                type='button'
                className='btn btn-danger ms-3'
                onClick={clearForm}
              >
                Clear
              </button>
            )}
          </form>
          <hr />
          <table className='table align-start table-row-dashed fs-7 gy-5'>
            <thead>
              <tr className='text-start text-dark fw-bolder fs-7 text-uppercase gs-0'>
                <th className='min-w-150px sorting ps-3'>Name</th>
                <th className='min-w-150px sorting ps-3'>Status</th>
                <th className='min-w-150px sorting ps-3'>Cerated At</th>
                <th className='min-w-150px sorting ps-3'>Actions</th>
              </tr>
            </thead>
            <tbody className='fw-bold text-gray-700'>
              {lists.map((list, i) => (
                <tr key={i}>
                  <td className='ps-3'>{list.name}</td>
                  <td className='ps-3'>
                    {list.status ? 'Activated' : 'Deactivated'}
                  </td>
                  <td className='ps-3'>
                    {moment(list.created_at).format('DD MMM YYYY; hh:mm A')}
                  </td>
                  <td className='d-flex align-items-center'>
                    <button
                      className='btn btn-info btn-sm me-3'
                      onClick={() => handleEdit(list)}
                    >
                      Edit
                    </button>
                    {deleteLoading && deleteId === list.id ? (
                      <BtnLoading name='Deleting' btnClass='danger btn-sm' />
                    ) : (
                      <button
                        className='btn btn-danger btn-sm'
                        onClick={() => handleDelete(list)}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChannelRefCategory;
