import axios from 'axios';
import { useContext, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import BtnLoading from './BtnLoading';
import SearchTestsMulti from './SearchTestsMulti';

const PackageAddModal = ({
  showPackageModal,
  setShowPackageModal,
  fetchPackages,
}) => {
  const { user_details } = useContext(AuthContext);
  const [Name, setName] = useState('');
  const [Description, setDescription] = useState('');
  const [TestImg, setTestImg] = useState(null);
  const [Price, setPrice] = useState('');
  const [SellPrice, setSellPrice] = useState('');
  const [testCount, setTestCount] = useState('');
  const [idealFor, setIdealFor] = useState('');
  const [IsActive, setIsActive] = useState(true);
  const [packageSource, setPackageSource] = useState(' ');
  const [whyShould, setWhyShould] = useState('');

  const [pacTests, setPacTests] = useState([]);

  const [loading, setLoading] = useState(false);

  const putPackageImg = (id, data) => {
    console.log(data);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        setTestImg(null);
        fetchPackages();
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  const postPackageTests = (id) => {
    const testPackData = pacTests.map((test) => {
      return {
        diagnostic_test: test.id,
        package: id,
      };
    });

    testPackData.forEach((item) => {
      // console.log(item);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/package-test/`,
          item
        )
        .then((resp) => {
          // console.log(resp.data);
        })
        .catch((error) => {
          // console.log(error.response);
        });
    });

    // console.log(testPackData);
    setPacTests([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Name.trim() === '') {
      toast.error(`Please enter package name.`, {
        autoClose: 3000,
      });
      return;
    }

    if (packageSource.trim() === '') {
      toast.error(`Please select package source.`, {
        autoClose: 3000,
      });
      return;
    }
    // if (Price.trim() === '') {
    //   toast.error(`Please enter main price.`, {
    //     autoClose: 3000,
    //   });
    //   return;
    // }

    // if (SellPrice.trim() === '') {
    //   toast.error(`Please enter sell price.`, {
    //     autoClose: 3000,
    //   });
    //   return;
    // }

    if (pacTests.length === 0) {
      toast.error(`Please select include tests.`, {
        autoClose: 3000,
      });
      return;
    }

    if (idealFor.trim() === '') {
      toast.error(`Please enter ideal for.`, {
        autoClose: 3000,
      });
      return;
    }

    if (whyShould.trim() === '') {
      toast.error(`Please enter why should.`, {
        autoClose: 3000,
      });
      return;
    }

    const postPrice = {
      price: parseInt(Price),
      sell_price: parseInt(SellPrice),
    };

    const imgg = new FormData();
    imgg.append('image', TestImg);

    const postData = {
      name: Name,
      description: Description,
      is_active: IsActive,
      package_source: packageSource,
      created_by: user_details.id,
      ideal_for: idealFor,
      why_should: whyShould,
    };

    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/`,
        postData
      )
      .then((resp) => {
        // console.log(resp.data);
        postPackageTests(resp.data.id);
        toast.success(`Package added successfully.`, {
          autoClose: 3000,
        });
        if (TestImg) putPackageImg(resp.data.id, imgg);
        setName('');
        setDescription(null);
        setPrice('');
        setSellPrice('');
        setTestCount('');
        setIdealFor('');
        setShowPackageModal(false);
        fetchPackages();
        setLoading(false);
        setPackageSource('');
        
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });

    // axios
    //   .post(
    //     `${process.env.REACT_APP_BASE_URL}/order/purchaseable-order-item/`,
    //     postPrice
    //   )
    //   .then((resp) => {
    //     const id = resp.data.id;
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
  };

  return (
    <Modal
      show={showPackageModal}
      onHide={() => setShowPackageModal(false)}
      animation={false}
      size='lg'
    >
      <ModalBody>
        <div className=''>
          <div className='mb-3 d-flex justify-content-between align-items-center'>
            <h5 className='mb-0'>Add New Package</h5>
            <button
              className='btn btn-sm btn-danger'
              onClick={() => setShowPackageModal(false)}
            >
              Close
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='form-group mb-3'>
              <input
                type='text'
                className='form-control fw-normal'
                placeholder='Enter package name *'
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='form-group mb-3'>
              <textarea
                className='form-control fw-normal'
                placeholder='Enter test description'
                rows='4'
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className='form-group mb-3'>
              <div className='custom-file'>
                <input
                  type='file'
                  className='custom-file-input'
                  accept='image/png, image/gif, image/jpeg'
                  onChange={(e) => setTestImg(e.target.files[0])}
                />
                <label className='custom-file-label'>
                  Select Package Image
                </label>
              </div>
            </div>
            <div className='form-group mb-3 d-flex align-items-center'>
              <h5 className='mb-0 me-2'>Is Active</h5>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-50px'
                  type='checkbox'
                  name='is_active'
                  onChange={() => setIsActive(!IsActive)}
                  defaultChecked={IsActive}
                />
              </div>
            </div>


            <div className='form-group  mb-3'>
              <select className='form-select fw-normal'
              value={packageSource}
                onChange={(e) => setPackageSource(e.target.value)}
                 
              >
                <option value=''>Package Source *</option>
                <option value='ebl'>EBL</option>
                <option value='amarlab'>Amarlab</option>
              </select>
            </div>
            {/* <div className='form-group mb-3'>
              <input
                type='number'
                className='form-control fw-normal'
                placeholder='Enter main price *'
                value={Price}
                onChange={(e) => setPrice(e.target.value)}
                min='00'
              />
            </div> */}
            {/* <div className='form-group mb-3'>
              <input
                type='number'
                className='form-control fw-normal'
                placeholder='Enter sell price *'
                value={SellPrice}
                onChange={(e) => setSellPrice(e.target.value)}
                min='00'
              />
            </div> */}
            <div className='form-group'>
              <SearchTestsMulti
                testType={'diagnostic'}
                pacTests={pacTests}
                setPacTests={setPacTests}
                placeholderText='Search & Select Test *'
              />
            </div>
            <div className='form-group mb-3'>
              <textarea
                className='form-control fw-normal'
                placeholder='Enter ideal for *'
                rows='2'
                value={idealFor}
                onChange={(e) => setIdealFor(e.target.value)}
              ></textarea>
            </div>
            <div className='form-group mb-3'>
              <textarea
                className='form-control fw-normal'
                placeholder='Why Should *'
                rows='3'
                value={whyShould}
                onChange={(e) => setWhyShould(e.target.value)}
              ></textarea>
            </div>
            <div className='form-group mb-3'>
              {!loading ? (
                <button className='btn btn-primary'>Submit</button>
              ) : (
                <BtnLoading name='Submit...' btnClass='primary ' />
              )}
            </div>
          </form>
          {/* <button onClick={this.postTest}>Add Test</button> */}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PackageAddModal;
