import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Modal, ModalBody, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DateSelect from '../components/DateSelect';
import PressCategoryModal from '../components/PressCategoryModal';
import { AuthContext } from '../context/AuthContext';

import Pagination from '../components/Pagination/Pagination';
import DeleteAlert from '../components/DeleteAlert';
import HeaderToolbar from '../components/HeaderToolbar';

export class Press extends Component {
  static contextType = AuthContext;

  state = {
    articles: null,
    categories: null,
    // user_id: null,
    group: [],
    showDelModal: false,
    showEditModal: false,
    showCatModal: false,
    // deleteId: null,
    // totalUsers: null,
    // dateType: 'text',

    articleId: null,
    articleName: '',
    articleImage: null,
    articleExistingImage: '',
    articleSource: '',
    articleIs_active: true,
    articleIs_linkable: false,
    articleLink: '',
    articleDate: '',
    articleDescription: '',
    articleCategory: null,

    count: 0,
    next: null,
    // offset: 10,
    limit: 10,
    pagination: 1,
    searchText: '',
    loading: false,
    postAdding: false,
    postDeleteId: null,
    postDeleting: false,
    categoriesLoading: false,
  };

  fetchPosts = ({ limit, pagination }, seatchText) => {
    let fetchuserUrl = `${
      process.env.REACT_APP_BASE_URL
    }/press/post/?page=1&limit=${limit}&offset=${(pagination - 1) * limit}`;
    if (!!seatchText) {
      fetchuserUrl = `${
        process.env.REACT_APP_BASE_URL
      }/press/post/?page=1&search=${seatchText}&limit=${limit}&offset=${
        (pagination - 1) * limit
      }`;
    }

    this.setState({ loading: true });
    axios
      .get(fetchuserUrl)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ articles: resp.data.results });
        this.setState({ next: resp.data.next, count: resp.data.count });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  fetchCategories = ({ limit, pagination }) => {
    let fetchuserUrl = `${
      process.env.REACT_APP_BASE_URL
    }/press/category/?page=1&limit=${limit + 100}&offset=${
      (pagination - 1) * limit
    }`;

    this.setState({ loading: true });
    axios
      .get(fetchuserUrl)
      .then((resp) => {
        // console.log({ categories: resp.data.results })
        this.setState({ categories: resp.data.results });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  fetchGroup = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user_management/group/`)
      .then((resp) => {
        this.setState({ group: resp.data.results });
      });
  };

  componentDidMount() {
    this.context.setPageTitle('Posts');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Press', link: '/press' },
    ]);
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchCategories(this.state);
      this.fetchPosts(this.state);
      this.fetchGroup();
      // if (user_details) {
      //   const user = JSON.parse(user_details);
      //   this.setState({ user_id: user.id });
      // }
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (userRole.groups[0].name !== 'Admin') {
        this.props.history.push('/orders');
      } else {
        this.fetchCategories(this.state);
        this.fetchPosts(this.state);
        this.fetchGroup();
        // if (user_details) {
        //   const user = JSON.parse(user_details);
        //   this.setState({ user_id: user.id });
        // }
      }
    }
  }

  handlePostEdit = (article) => {
    this.setState({
      articleId: article.id,
      articleName: article.name,
      articleImage: null,
      articleExistingImage: article.image,
      articleSource: article.source,
      articleIs_active: article.is_active,
      articleIs_linkable: article.is_linkable,
      articleLink: article.link,
      articleDate: new Date(article.date),
      articleDescription: article.description,
      articleCategory: article.category,

      showEditModal: true,
    });
  };

  handleSearch = (searchText) => {
    this.setState({ searchText: searchText, pagination: 1 });
    if (!searchText)
      this.fetchPosts({ limit: this.state.limit, pagination: 1 });
    else
      this.fetchPosts({ limit: this.state.limit, pagination: 1 }, searchText);
  };

  resetPostData = () => {
    this.setState({
      articleId: null,
      articleName: '',
      articleImage: null,
      articleExistingImage: '',
      articleSource: '',
      articleIs_active: false,
      articleIs_linkable: false,
      articleLink: '',
      articleDate: '',
      articleDescription: '',
      articleCategory: null,
      postAdding: false,
    });
  };

  addEditPostItem = () => {
    const {
      articleId,
      articleName,
      articleImage,
      articleExistingImage,
      articleSource,
      articleIs_active,
      articleIs_linkable,
      articleLink,
      articleDate,
      articleDescription,
      articleCategory,
    } = this.state;

    if (!articleName)
      toast.error('Fill post name please.', { autoClose: 3000 });
    else if (!articleDate)
      toast.error('Fill post date please.', { autoClose: 3000 });
    else if (!articleCategory)
      toast.error('Select post category please.', { autoClose: 3000 });
    else if (!articleImage && !articleExistingImage)
      toast.error('Fill post image please.', { autoClose: 3000 });
    else if (articleImage && articleImage.size >= 1024000)
      toast.error('Image size must be less than 1MB.', { autoClose: 3000 });
    else if (articleIs_linkable && !articleSource)
      toast.error('Fill post source please.', { autoClose: 3000 });
    else if (articleIs_linkable && !articleLink)
      toast.error('Fill post link please.', { autoClose: 3000 });
    else if (!articleIs_linkable && !articleDescription)
      toast.error('Fill post description please.', { autoClose: 3000 });
    else {
      let postData = {
        name: articleName,
        source: articleSource,
        is_active: articleIs_active,
        is_linkable: articleIs_linkable,
        link: articleLink,
        date: moment(articleDate).format('YYYY-MM-DD'),
        description: articleDescription,
        category: articleCategory,
      };

      if (articleImage) postData.image = articleImage;
      // if (articleCategory) postData.category = articleCategory;

      // console.log(postData)

      let postFormData = new FormData();
      for (const key in postData) {
        postFormData.append(key, postData[key]);
      }

      if (!articleId) {
        this.setState({ postAdding: true });
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/press/post/`, postFormData)
          .then((response) => {
            console.log('post: ', response);
            this.fetchPosts(this.state, this.state.searchText);
            this.resetPostData();
            this.setState({ postAdding: false, showEditModal: false });
            toast.success('New post added successfully.', { autoClose: 3000 });
          })
          .catch((err) => {
            console.log('err: ', err);
            this.setState({ postAdding: false });
            toast.error('Unable to add post, try again.', { autoClose: 3000 });
          });
      } else {
        this.setState({ postAdding: true });
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/press/post/${articleId}/`,
            postFormData
          )
          .then((response) => {
            console.log('put: ', response);
            this.fetchPosts(this.state, this.state.searchText);
            this.resetPostData();
            this.setState({ postAdding: false, showEditModal: false });
            toast.success('Post updated successfully.', { autoClose: 3000 });
          })
          .catch((err) => {
            console.log('err: ', err);
            this.setState({ postAdding: false });
            toast.error('Unable to update post, try again.', {
              autoClose: 3000,
            });
          });
      }
    }
  };

  handleDeletePost = () => {
    const { postDeleteId } = this.state;
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/press/post/${postDeleteId}/`)
      .then((resp) => {
        this.setState({
          articles: this.state.articles.filter(
            (use) => use.id !== postDeleteId
          ),
        });
        this.fetchPosts(this.state, this.state.searchText);
        this.setState({ showDelModal: false, postDeleteId: null });
        toast.success(`Post deleted successfully.`, { autoClose: 3000 });
      })
      .catch((error) => {
        toast.error(`Post deletion failed.`, { autoClose: 3000 });
      });
  };

  handlePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    if (this.state.searchText)
      this.fetchPosts(
        { limit: this.state.limit, pagination: pageno },
        this.state.searchText
      );
    else this.fetchPosts({ limit: this.state.limit, pagination: pageno });
    // this.fetchUsers({ limit: this.state.limit, pagination: pageno });
  };

  handlePostModalClose = () => {
    this.resetPostData();
    this.setState({ showEditModal: false });
  };

  getCatById = (id) => {
    const { categories } = this.state;
    const cat = categories.filter((cat) => cat.id === id);
    return cat.length ? cat[0].name : '-';
  };

  render() {
    const {
      searchText,
      count,
      limit,
      pagination,
      loading,
      showDelModal,
      showEditModal,
      postAdding,
    } = this.state;
    const { showCatModal, categories, categoriesLoading } = this.state;
    const {
      articles,
      articleId,
      articleName,
      articleImage,
      articleSource,
      articleIs_active,
      articleIs_linkable,
      articleLink,
      articleDate,
      articleDescription,
      articleCategory,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Press And Media' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      {/* <span className='card-label font-weight-bolder text-dark'>
                  All Users
                </span> */}
                      <div className='d-flex align-items-center position-relative my-1'>
                        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <rect
                              opacity='0.5'
                              x='17.0365'
                              y='15.1223'
                              width='8.15546'
                              height='2'
                              rx='1'
                              transform='rotate(45 17.0365 15.1223)'
                              fill='black'
                            ></rect>
                            <path
                              d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                              fill='black'
                            ></path>
                          </svg>
                        </span>
                        <input
                          type='text'
                          data-kt-customer-table-filter='search'
                          className='form-control form-control-solid w-250px ps-15'
                          placeholder='Search post by name'
                          // ref={this.searchRef}
                          // disabled={loading}
                          value={searchText}
                          onChange={(e) => this.handleSearch(e.target.value)}
                        />
                      </div>
                      <span className='text-muted mt-3 font-weight-bold fs-7 ps-1'>
                        {`Total  ${count ? count + '+ ' : '00'} ${
                          count ? `articles` : `article`
                        } ${searchText && `found`}`}
                      </span>
                    </h3>
                    <div className='card-toolbar'>
                      <div
                        className='btn btn-primary'
                        onClick={() => {
                          this.setState({ showEditModal: true });
                        }}
                      >
                        Add Item
                      </div>
                    </div>
                  </div>
                  <div className='card-body pt-2 pb-0 mt-3'>
                    <div className='table-responsive custom_scrollbar'>
                      {articles && (
                        <table className='table table-row-dashed table-row-gray-300 align-middle fs-6 no-footer gy-5'>
                          <thead>
                            <tr className='text-start text-gray-400 fw-bolder fs-7 no-footer text-uppercase gs-0'>
                              <th className='w-50px'>Idx</th>
                              <th
                                className='min-w-100px text-truncate'
                                style={{ width: 420 }}
                              >
                                Name
                              </th>
                              <th className='min-w-140px'>Image</th>
                              <th className='min-w-100px'>Is Active</th>
                              <th className='min-w-100px'>Date</th>
                              <th className='min-w-100px'>Category</th>
                              <th className='min-w-100px'>Action</th>
                            </tr>
                          </thead>
                          <tbody className='fw-bold text-gray-600'>
                            {articles &&
                              articles.map((article, i) => (
                                <tr key={i}>
                                  <td className='text-gray-600 text-hover-primary mb-0'>
                                    #{i + 1}
                                  </td>

                                  <td className='text-gray-600 text-hover-primary mb-0 pe-4'>
                                    {article.name.length > 50
                                      ? article.name.slice(0, 49) + '...'
                                      : article.name}
                                  </td>

                                  <td className='text-gray-600 text-hover-primary mb-0'>
                                    {article.image ? (
                                      <img
                                        src={article.image}
                                        alt=''
                                        width='35px'
                                        height='30px'
                                        className='shadow'
                                      />
                                    ) : (
                                      <div
                                        className='d-flex justify-content-center align-items-center border border-2 rounded'
                                        style={{
                                          width: '35px',
                                          height: '30px',
                                        }}
                                      >
                                        <i
                                          className='fas fa-file-medical-alt'
                                          style={{
                                            fontSize: '12px',
                                            borderRadius: 5,
                                          }}
                                        />
                                      </div>
                                    )}
                                  </td>

                                  <td className='text-gray-600 mb-0'>
                                    {article.is_active ? (
                                      <span
                                        className='text-green'
                                        style={{ minWidth: 90 }}
                                      >
                                        Active
                                      </span>
                                    ) : (
                                      <span
                                        className='text-danger'
                                        style={{ minWidth: 90 }}
                                      >
                                        Inactive
                                      </span>
                                    )}
                                  </td>

                                  <td className='align-middle fs-6 fw-bold '>
                                    <span className='text-muted font-weight-bold d-block font-size-lg'>
                                      {article.date
                                        ? moment(article.date).format(
                                            'DD MMM YYYY'
                                          )
                                        : '-- --- ----'}
                                    </span>
                                  </td>

                                  <td className='align-middle fs-6 fw-bold'>
                                    <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                      {article.category
                                        ? categories
                                          ? this.getCatById(article.category)
                                          : article.category
                                        : '-'}
                                    </span>
                                  </td>

                                  <td className='text-gray-600 text-hover-primary mb-0'>
                                    <button
                                      className='btn btn-primary mr-2 btn-sm me-2'
                                      onClick={() =>
                                        this.handlePostEdit(article)
                                      }
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className='btn btn-danger btn-sm me-1'
                                      onClick={() => {
                                        this.setState({
                                          postDeleteId: article.id,
                                          showDelModal: true,
                                        });
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {/* <p className='text-center mb-15 mt-5'>
                <button className='btn btn-primary mb-0' disabled={next ? '' : 'disabled'}
                  onClick={this.loadMore} >
                  SHow More
                </button>
              </p> */}
                    <div className='row justify-content-start mt-3 mb-10'>
                      {loading && (
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start px-4'>
                          <Spinner animation='border' size='sm' />{' '}
                          <span
                            className='ms-2 text-gray-600'
                            style={{ fontFamily: 'Poppins' }}
                          >
                            loading, Please wait...
                          </span>
                        </div>
                      )}
                      <Pagination
                        onPageChange={(pageno) =>
                          this.handlePaginationPress(pageno)
                        }
                        totalCount={count}
                        pageSize={limit}
                        currentPage={pagination}
                        siblingCount={1}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <DeleteAlert
                showDelModal={showDelModal}
                setShowDelModal={(val) => this.setState({ showDelModal: val })}
                onAllow={this.handleDeletePost}
              />

              <>
                <Modal
                  show={showEditModal}
                  onHide={() => this.handlePostModalClose()}
                  animation={true}
                  size='xl'
                >
                  <ModalBody>
                    <div className='pt-5 p-5'>
                      <div className='mb-4 d-flex justify-content-between'>
                        <h5 className='mb-0'>
                          {articleId ? 'Edit' : 'Add'} Post
                        </h5>
                        <button
                          className='btn btn-sm btn-danger'
                          onClick={() => {
                            this.handlePostModalClose();
                          }}
                        >
                          Close
                        </button>
                      </div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.addEditPostItem();
                        }}
                      >
                        <div className=' form-group row mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Enter Name *{' '}
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <input
                              value={articleName}
                              placeholder='Enter Name'
                              className='form-control form-control-solid form-control-lg'
                              type='text'
                              onChange={(e) =>
                                this.setState({ articleName: e.target.value })
                              }
                            />
                          </div>
                        </div>

                        <div className=' form-group row mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Upload Image *
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <div className='custom-file'>
                              <input
                                type='file'
                                className='custom-file-input'
                                accept='image/png, image/gif, image/jpeg'
                                onChange={(e) =>
                                  this.setState({
                                    articleImage: e.target.files[0],
                                  })
                                }
                              />
                              <label className='custom-file-label'>
                                {articleImage
                                  ? articleImage.name.length > 50
                                    ? articleImage.name.slice(0, 49) + '...'
                                    : articleImage.name
                                  : 'Select Test Image'}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className=' form-group row mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Is Active{' '}
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                className='form-check-input w-50px'
                                type='checkbox'
                                name='is_active'
                                onChange={() =>
                                  this.setState({
                                    articleIs_active: !articleIs_active,
                                  })
                                }
                                defaultChecked={articleIs_active}
                              />
                            </div>
                          </div>
                        </div>

                        <div className=' form-group row mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Is Linkable{' '}
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                className='form-check-input w-50px'
                                type='checkbox'
                                name='is_active'
                                onChange={() =>
                                  this.setState({
                                    articleIs_linkable: !articleIs_linkable,
                                  })
                                }
                                defaultChecked={articleIs_linkable}
                              />
                            </div>
                          </div>
                        </div>

                        {articleIs_linkable && (
                          <div className=' form-group row mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Enter Source *
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <input
                                value={articleIs_linkable ? articleSource : ''}
                                placeholder='Enter Source'
                                className='form-control form-control-solid form-control-lg'
                                type='text'
                                disabled={!articleIs_linkable}
                                onChange={(e) =>
                                  this.setState({
                                    articleSource: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        )}

                        {articleIs_linkable && (
                          <div className=' form-group row mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Enter Link *
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <input
                                value={articleIs_linkable ? articleLink : ''}
                                placeholder='Enter Link'
                                className='form-control form-control-solid form-control-lg'
                                type='text'
                                // disabled={!articleIs_linkable}
                                onChange={(e) =>
                                  this.setState({ articleLink: e.target.value })
                                }
                              />
                            </div>
                          </div>
                        )}

                        <div className=' form-group row mb-3 custom-dp'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Enter Publication Date *{' '}
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            {/* <input
                              type={dateType}
                              className='mb-5 form-control'
                              placeholder='Enter User Date of Birth'
                              data-date-format="DD MMMM YYYY"
                              onFocus={() => this.setState({ dateType: 'date' })}
                              onBlur={() => this.setState({ dateType: 'text' })}
                              value={articleDate}
                              onChange={(e) => this.setState({ articleDate: e.target.value })}
                            /> */}
                            <DateSelect
                              sampleDate={articleDate}
                              setSampleDate={(value) =>
                                this.setState({ articleDate: value })
                              }
                              placeholderText='Enter Publication Date'
                              allowprevious={true}
                            />
                          </div>
                        </div>

                        {!articleIs_linkable && (
                          <div className=' form-group row mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Enter Description *
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <textarea
                                value={
                                  !articleIs_linkable ? articleDescription : ''
                                }
                                placeholder='Enter Description'
                                className='form-control form-control-solid form-control-lg '
                                rows='3'
                                disabled={articleIs_linkable}
                                onChange={(e) =>
                                  this.setState({
                                    articleDescription: e.target.value,
                                  })
                                }
                              ></textarea>
                            </div>
                          </div>
                        )}

                        <div className=' form-group row mb-3'>
                          <label className='col-xl-3 col-lg-3 col-form-label'>
                            Select Category *{' '}
                          </label>
                          <div className='col-lg-9 col-xl-9'>
                            <div className='d-flex'>
                              <select
                                className='form-select form-select-solid category-select me-3'
                                onChange={(e) => {
                                  this.setState({
                                    articleCategory: parseInt(e.target.value),
                                  });
                                }}
                                value={
                                  parseInt(articleCategory)
                                    ? `${articleCategory}`
                                    : '0'
                                }
                              >
                                <option
                                  className='text-placeholder-color'
                                  value='0'
                                  disabled={true}
                                >
                                  {categoriesLoading
                                    ? 'Loading category Data'
                                    : 'Select a category'}
                                </option>
                                {categories &&
                                  categories.length &&
                                  categories.map((category, index) => (
                                    <option
                                      key={index}
                                      value={`${category.id}`}
                                    >
                                      {category.name}
                                    </option>
                                  ))}
                              </select>
                              <div className=''>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showCatModal: true });
                                  }}
                                  className='btn btn-primary font-weight-bolder fs-2 px-6 py-0 h-100 justify-content-center'
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=' form-group row my-5'>
                          <label className='col-xl-3 col-lg-3 col-form-label' />
                          <div className='col-lg-9 col-xl-9'>
                            <button
                              type='submit'
                              className='btn btn-primary font-weight-bolder px-9 py-4'
                              disabled={postAdding}
                              style={{ display: 'inline-flex' }}
                            >
                              <div>{!articleId ? 'Add' : 'Update'} Post</div>
                              {postAdding && (
                                <div className='ms-3'>
                                  <Spinner animation='border' size='sm' />
                                </div>
                              )}
                            </button>
                            <button
                              type='button'
                              onClick={(e) => {
                                e.preventDefault();
                                this.resetPostData();
                              }}
                              className='btn btn-danger font-weight-bolder px-9 py-4 mx-5'
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <PressCategoryModal
                      showCatModal={showCatModal}
                      setShowCatModal={(val) =>
                        this.setState({ showCatModal: val })
                      }
                      categories={categories}
                      categoriesLoading={categoriesLoading}
                      fetchCategories={() => {
                        this.fetchCategories(this.state);
                      }}
                    />
                  </ModalBody>
                </Modal>
              </>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Press;
