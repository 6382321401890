import axios from 'axios';
import moment from 'moment';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BtnLoading from '../components/BtnLoading';
import HeaderToolbar from '../components/HeaderToolbar';
import LoadingContent from '../components/LoadingContent';
import { AuthContext } from '../context/AuthContext';

export class QuickOrder extends Component {
  state = {
    allAccess: false,
    daterange_start: moment(),
    daterange_end: moment(),
    showDateFilter: false,
    dateFilterName: null,

    loading: false,
    seeMoreLoading: false,

    quickOrderDetailsModalShow: false,
    quickOrderDetails: {},

    quickOrder: [],

    responseLoading: false,
    responseLoadingId: '',
    isResponsed: false,

    count: 0,
    next: null,

    qOrderFltr: {
      limit: 10,
      searchedId: '',
      phone_number: '',
      client_ip: '',
      is_responsed: '',
      order: '',
      created_at: '',
      created_at__gt: moment().subtract(5, 'year'),
      created_at__lt: moment(),
      created_at__gte: '',
      created_at__lte: '',
    },

    qOrderFltrParam: {},

    // searchedId: '',

    currentUser: null,
  };

  openQOrderDetailsModal(qOrder) {
    this.setState({
      quickOrderDetailsModalShow: true,
      quickOrderDetails: qOrder,
    });
    console.log(qOrder);
  }
  closeQOrderDetailsModal() {
    this.setState({
      quickOrderDetailsModalShow: false,
    });
  }

  componentDidMount() {
    this.context.setPageTitle('Quick Orders');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Quick Orders', link: '/quick-orders' },
    ]);
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');

    if (!token || !user_details) {
      this.props.history.push('/login');
    } else {
      const userRole = JSON.parse(user_details);
      this.setState({ currentUser: { ...userRole, token: JSON.parse(token) } });

      axios.interceptors.request.use((config) => {
        config.headers.Authorization = 'Token ' + JSON.parse(token);
        return config;
      });

      if (userRole.is_superuser) {
        this.setState({ allAccess: true });
        // this.fetchQuickOrders(this.state.qOrderFltr);
        this.handleInitialParams();
      } else {
        if (userRole.groups.length === 0) {
        } else if (
          userRole.groups[0].name === 'User' ||
          userRole.groups[0].name === 'Lab Partner' ||
          userRole.groups[0].name === 'Medical Technologist' ||
          userRole.groups[0].name === 'External'
        ) {
          this.props.history.push('/order');
        } else {
          this.setState({ allAccess: true });
          // this.fetchQuickOrders(this.state.qOrderFltr);
          this.handleInitialParams();
        }
      }
    }
  }

  handleInitialParams = () => {
    let param_collection = {};

    let params = new URLSearchParams(this.props.location.search);
    let filterObj = {};

    for (const param of params.entries()) {
      param_collection[param[0]] = param[1];
      if (param[0] === 'search') filterObj.searchedId = param[1];
      if (param[0] === 'is_responsed') filterObj.is_responsed = param[1];
    }

    this.setState({
      qOrderFltrParam: param_collection,
      qOrderFltr: { ...this.state.qOrderFltr, ...filterObj },
    });
    this.fetchQuickOrders({ ...this.state.qOrderFltr, ...filterObj });
  };

  customParam = (key, value, ispush = true) => {
    let newUrlParam = { ...this.state.qOrderFltrParam };
    newUrlParam[key] = value;
    this.setState({ qOrderFltrParam: newUrlParam });

    let paramstring = '';
    for (const key in newUrlParam) {
      if (newUrlParam[key]) paramstring += `&${key}=${newUrlParam[key]}`;
    }

    if (ispush)
      this.props.history.push(`/quick-orders?${paramstring.slice(1)}`);
  };

  fetchQuickOrders = ({
    limit = 10,
    searchedId = '',
    phone_number = '',
    client_ip = '',
    is_responsed = '',
    order = '',
    created_at = '',
    created_at__gt = moment().subtract(5, 'year'),
    created_at__lt = moment(),
    created_at__gte = '',
    created_at__lte = '',
  }) => {
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/order/quick-order-get/?page=1&limit=${limit}&ofset=0&search=${searchedId}&phone_number=${phone_number}&client_ip=${client_ip}&is_responsed=${is_responsed}&order=${order}&created_at=${created_at}&created_at__gt=&created_at__lt=&response_user=&created_at__gt=${created_at__gt.format(
      'YYYY-MM-DD'
    )}+00:00:00&created_at__lt=${created_at__lt.format(
      'YYYY-MM-DD'
    )}+23:59:59&created_at__gte=${created_at__gte}&created_at__lte=${created_at__lte}`;

    // console.log(url)
    this.setState({ loading: true });

    axios
      .get(url)
      .then((resp) => {
        // console.log(resp.data);
        const qorders = resp.data.results.map((item) => item);
        this.setState({ quickOrder: qorders });
        this.setState({ count: resp.data.count });
        this.setState({ next: resp.data.next });

        this.setState({ loading: false });
        this.setState({ seeMoreLoading: false });
        this.setState({ responseLoading: false, responseLoadingId: '' });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        this.setState({ seeMoreLoading: false });
        this.setState({ responseLoading: false, responseLoadingId: '' });
      });
  };

  handleResponse = (qid, isChecked) => {
    const { currentUser } = this.state;
    this.setState({ responseLoadingId: qid });

    const data = {
      is_responsed: isChecked,
      response_user: currentUser.id,
    };

    this.setState({ responseLoading: true });
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/quick-order/${qid}/`, data)
      .then((resp) => {
        this.fetchQuickOrders(this.state.qOrderFltr);
        this.context.fetchOrderStatus();
      })
      .catch((error) => {
        this.setState({ responseLoading: false, responseLoadingId: '' });
      });
  };

  handleSearch = (searchedId) => {
    this.setState({
      qOrderFltr: { ...this.state.qOrderFltr, searchedId: searchedId },
    });

    // if (searchedId === '') {
    //   this.fetchQuickOrders({ ...this.state.qOrderFltr, searchedId: searchedId });
    //   this.props.history.push(`/quick-orders`);
    // } else {
    //   this.props.history.push(`/quick-orders?search=${searchedId}`);
    //   this.fetchQuickOrders({ ...this.state.qOrderFltr, searchedId: searchedId });
    // }
    this.customParam('search', searchedId);
    this.fetchQuickOrders({ ...this.state.qOrderFltr, searchedId: searchedId });
  };

  handleLoadmore = () => {
    const { next } = this.state;
    this.setState({ seeMoreLoading: true });

    if (next) {
      axios
        .get(next)
        .then((resp) => {
          const qorders = resp.data.results.map((item) => item);
          this.setState({ quickOrder: [...this.state.quickOrder, ...qorders] });
          this.setState({ count: resp.data.count });
          this.setState({ next: resp.data.next });

          this.setState({ seeMoreLoading: false });
          this.setState({ responseLoading: false, responseLoadingId: '' });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ seeMoreLoading: false });
          this.setState({ responseLoading: false, responseLoadingId: '' });
        });
    }
  };

  handleDateRangeApply = (start, end) => {
    this.setState({
      daterange_start: start,
      daterange_end: end,
      showDateFilter: true,
    });

    this.fetchQuickOrders({
      ...this.state.qOrderFltr,
      created_at__gt: start,
      created_at__lt: end,
    });
  };

  setDateFilterName = (value) => this.setState({ dateFilterName: value });

  render() {
    const {
      allAccess,
      daterange_start,
      daterange_end,
      showDateFilter,
      dateFilterName,
    } = this.state;
    const {
      next,
      count,
      loading,
      seeMoreLoading,
      quickOrder,
      responseLoading,
      responseLoadingId,
      currentUser,
      qOrderFltr,
    } = this.state;

    return (
      <>
        <HeaderToolbar
          title='Quick Orders'
          dateFilter={true}
          startDate={daterange_start}
          endDate={daterange_end}
          handleDateRangeApply={this.handleDateRangeApply}
          showDateFilter={showDateFilter}
          dateFilterName={dateFilterName}
          setDateFilterName={this.setDateFilterName}
          createAccess={allAccess}
        />

        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-header border-0 pt-6'>
                    <div className='card-title'>
                      <span className='card-label font-weight-bolder text-dark'>
                        All Quick Orders
                      </span>
                      <div className='d-flex align-items-start flex-column'>
                        <div className='d-flex align-items-center position-relative my-1'>
                          <form
                            className='d-flex align-items-center position-relative my-1'
                            onSubmit={(e) => {
                              e.preventDefault();
                              this.handleSearch(qOrderFltr.searchedId);
                            }}
                          >
                            <span
                              className='svg-icon svg-icon-1 position-absolute ms-6 pointer'
                              onClick={() =>
                                this.handleSearch(qOrderFltr.searchedId)
                              }
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                              >
                                <rect
                                  opacity='0.5'
                                  x='17.0365'
                                  y='15.1223'
                                  width='8.15546'
                                  height={2}
                                  rx={1}
                                  transform='rotate(45 17.0365 15.1223)'
                                  fill='black'
                                />
                                <path
                                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                  fill='black'
                                />
                              </svg>
                            </span>

                            <div>
                              <input
                                type='text'
                                className='form-control form-control-solid w-250px ps-15'
                                placeholder='Search By Phone Number'
                                value={qOrderFltr.searchedId}
                                onSubmit={() =>
                                  this.handleSearch(qOrderFltr.searchedId)
                                }
                                onChange={
                                  (e) =>
                                    this.setState({
                                      qOrderFltr: {
                                        ...this.state.qOrderFltr,
                                        searchedId: e.target.value,
                                      },
                                    })
                                  // this.handleSearch(e.target.value)
                                }
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-body pt-0'>
                    <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                      <span className='text-muted mt-3 fw-bold fs-7 ps-1'>
                        Total {count || '00'}+ orders found
                      </span>

                      {loading ? (
                        <LoadingContent name='Loading Orders...' />
                      ) : (
                        <div>
                          <div className='table-responsive mt-5 ordr-tabb'>
                            {quickOrder && quickOrder.length !== 0 && (
                              <table
                                className={`table align-start table-row-dashed fs-7 gy-5 border`}
                              >
                                <thead>
                                  <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                                    <th className='min-w-50px ps-2'>IDX</th>
                                    <th className='min-w-125px'>
                                      Phone Number
                                    </th>
                                    <th className='min-w-125px'>Client Ip</th>
                                    <th className='min-w-125px'>
                                      Is Responsed
                                    </th>
                                    <th className='min-w-125px'>Order</th>
                                    <th className='min-w-125px'>Details</th>
                                    <th className='min-w-150px'>
                                      Response User
                                    </th>
                                    <th className='min-w-125px'>Created At</th>
                                  </tr>
                                </thead>
                                <tbody className='fw-bold text-gray-600'>
                                  {quickOrder &&
                                    quickOrder.map((qOrder, indx) => (
                                      <tr key={indx} className=''>
                                        <td className='ps-2'>{indx + 1}</td>
                                        <td className='' style={{ left: 0 }}>
                                          {qOrder.phone_number}
                                        </td>
                                        <td className=''>
                                          {qOrder.client_ip
                                            ? qOrder.client_ip
                                            : '--'}
                                        </td>
                                        <td className='align-item-center'>
                                          {qOrder.is_responsed ? (
                                            <div className='text-muted'>
                                              Responsed
                                            </div>
                                          ) : (
                                            <div className='form-check form-switch form-check-custom form-check-solid'>
                                              <input
                                                className='form-check-input w-50px'
                                                type='checkbox'
                                                name='is_responsed'
                                                onChange={(e) => {
                                                  if (currentUser)
                                                    this.handleResponse(
                                                      qOrder.id,
                                                      e.target.checked
                                                    );
                                                }}
                                                defaultChecked={
                                                  qOrder.is_responsed
                                                }
                                              />
                                              {responseLoading &&
                                                responseLoadingId ==
                                                  qOrder.id && (
                                                  <Spinner
                                                    animation='border'
                                                    size='sm'
                                                    className='ms-3 mt-0'
                                                  />
                                                )}
                                            </div>
                                          )}
                                        </td>
                                        <td className=''>
                                          {qOrder.order ? (
                                            <Link
                                              to={`/orders?search=${qOrder.order.order_id}`}
                                            >
                                              #{qOrder.order.order_id}
                                            </Link>
                                          ) : qOrder.is_responsed &&
                                            !responseLoading ? (
                                            <Link
                                              to={{
                                                pathname: '/order-add',
                                                params: {
                                                  qOrderId: qOrder.id,
                                                  isqOrder: true,
                                                  qOrderPhone:
                                                    qOrder.phone_number,
                                                },
                                              }}
                                            >
                                              <button className='bg-primary edit-order'>
                                                Make Order
                                              </button>
                                            </Link>
                                          ) : (
                                            <button
                                              className='bg-primary edit-order'
                                              style={{ cursor: 'not-allowed' }}
                                            >
                                              Make Order
                                            </button>
                                          )}
                                        </td>
                                        <td>
                                          {/* <button
                                            className="bg-primary edit-order"
                                            onClick={() =>
                                              this.openQOrderDetailsModal(
                                                qOrder
                                              )
                                            }
                                          >
                                            Details
                                          </button> */}
                                          {qOrder.date &&
                                            qOrder.location_district && (
                                              <button
                                                className='bg-primary edit-order'
                                                onClick={() =>
                                                  this.openQOrderDetailsModal(
                                                    qOrder
                                                  )
                                                }
                                              >
                                                Details
                                              </button>
                                            )}
                                        </td>
                                        <td className=''>
                                          {qOrder.response_user
                                            ? qOrder.response_user.first_name
                                              ? `${qOrder.response_user.first_name} ${qOrder.response_user.last_name}`
                                              : qOrder.response_user.username
                                            : '--'}
                                        </td>
                                        <td>
                                          {' '}
                                          {moment(qOrder.created_at).format(
                                            'DD MMM YYYY, h:mm A'
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            )}
                          </div>
                          <div className='row'>
                            <div className='col-12 mt-10'>
                              {next && (
                                <p className='m-0 text-center'>
                                  {!seeMoreLoading ? (
                                    <button
                                      className="btn btn-primary btn-lg"
                                      onClick={this.handleLoadmore}
                                      disabled={next ? '' : 'disabled'}
                                    >
                                      See More
                                    </button>
                                  ) : (
                                    <BtnLoading
                                      name='Loading...'
                                      btnClass='primary btn-lg'
                                    />
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.quickOrderDetailsModalShow}
          animation={true}
          scrollable={true}
          size='md'
          centered
          onHide={() => this.closeQOrderDetailsModal()}
        >
          <ModalBody>
            <div className='generete-ticket'>
              <div className='d-flex justify-content-between align-items-center mb-5'>
                <h5 className='m-0'>Details</h5>
                <button
                  onClick={() => this.closeQOrderDetailsModal()}
                  className='btn btn-sm btn-danger'
                >
                  Close
                </button>
              </div>
              <>
                <table id='copy-table-short' className='d-flex flex-column'>
                  <tbody className='d-flex flex-column' style={{ flexGrow: 1 }}>
                    <tr>
                      <td>
                        <b>Date: </b>
                        {moment(this.state.quickOrderDetails?.date).format(
                          'Do MMMM YYYY'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>District: </b>
                        {this.state.quickOrderDetails?.location_district}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Area: </b>
                        {this.state.quickOrderDetails?.location_area}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Details: </b>
                        {this.state.quickOrderDetails?.location_details}
                      </td>
                    </tr>
                    {this.state.quickOrderDetails?.prescription && (
                      <>
                        <br />
                        <tr>
                          <td>
                            <a
                              target='_blank'
                              href={this.state.quickOrderDetails?.prescription}
                              className='btn btn-primary'
                            >
                              Download Prescription <i className='fa fa-download' />
                            </a>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default QuickOrder;
QuickOrder.contextType = AuthContext;
