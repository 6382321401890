import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react'
import { Modal, ModalBody, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DeleteAlert from './DeleteAlert';

export const PressCategoryModal = ({
  showCatModal,
  setShowCatModal,
  categories,
  categoriesLoading,
  fetchCategories
}) => {
  
  const [myCategories, setMyCategories] = useState([])

  const [categoryName, setCategoryName] = useState('')
  const [categorySorting, setCategorySorting] = useState('')
  const [categoryDescription, setCategoryDescription] = useState('')
  const [categoryImage, setCategoryImage] = useState(null)
  const [categoryParent, setCategoryParent] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [categoryAddEdit, setCategoryAddEdit] = useState(false)

  const [showCatDelModal, setShowCatDelModal] = useState(false)
  const [categoryDeleteId, setCategoryDeleteId] = useState(null)

  const catImgInputRef = useRef();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // axios.interceptors.request.use((config) => {
      //   const tokehjjhhn = 'Token ' + JSON.parse(token);
      //   config.headers.Authorization = tokehjjhhn;
      //   return config;
      // });
    }
    
    setMyCategories(categories)
    
  }, [categories])

  const handleAddEditCategory = () => {
    if (!categoryName) toast.error('Fill category name please.', { autoClose: 3000 });
    if (!categorySorting) toast.error('Fill category sorting please.', { autoClose: 3000 });
    else if ((typeof categoryImage != 'string') && categoryImage && categoryImage.size >= 1024000) toast.error('Image size must be less than 1MB.', { autoClose: 3000 });
    else {
      let categoryData = { name: categoryName, sorting: parseInt(categorySorting), description: categoryDescription, }
      // if (categoryDescription) categoryData.description = categoryDescription;
      if (categoryParent && categoryParent !== '0' && parseInt(categoryParent)) categoryData.parent = parseInt(categoryParent);
      if ((typeof categoryImage != 'string') && categoryImage) categoryData.image = categoryImage;

      // console.log(categoryData)

      let postFormData = new FormData();
      for (const key in categoryData) {
        postFormData.append(key, categoryData[key]);
      }

      if (!categoryId) {
        setCategoryAddEdit(true);
        axios.post(`${process.env.REACT_APP_BASE_URL}/press/category/`,
          postFormData
        ).then(response => {
          // console.log('post: ', response)
          fetchCategories()
          setCategoryAddEdit(false);
          resetCategoryData()
          toast.success('New category added successfully.', { autoClose: 3000 });
        })
          .catch(err => {
            console.log('err: ', err)
            setCategoryAddEdit(false);
            toast.error('Unable to add category, try again.', { autoClose: 3000 });
          })

      } else {
        setCategoryAddEdit(true);
        axios.put(`${process.env.REACT_APP_BASE_URL}/press/category/${categoryId}/`,
          postFormData
        ).then(response => {
          // console.log('put: ', response)
          fetchCategories()
          setCategoryAddEdit(false);
          resetCategoryData()
          toast.success('Category updated successfully.', { autoClose: 3000 });
        })
          .catch(err => {
            console.log('err: ', err)
            setCategoryAddEdit(false);
            toast.error('Unable to update category, try again.', { autoClose: 3000 });
          })
      }

    }
  }

  const handleEditCategoryData = (cat) => {
    setCategoryName(cat.name)
    setCategorySorting(''+cat.sorting)
    setCategoryDescription(cat.description)
    setCategoryImage(cat.image)
    setCategoryParent(cat.parent)
    setCategoryId(cat.id)
  }

  const resetCategoryData = () => {
    setCategoryName('')
    setCategorySorting('')
    setCategoryDescription('')
    setCategoryImage(null)
    setCategoryParent(null)
    setCategoryId(null)
    setCategoryAddEdit(false)
  }

  const handleDeleteCat = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/press/category/${categoryDeleteId}/`)
      .then((resp) => {
        setMyCategories(myCategories.filter(cat => cat.id !== categoryDeleteId));
        fetchCategories()
        setShowCatDelModal(false)
        setCategoryDeleteId(null)
        toast.success(`Category deleted successfully.`, { autoClose: 3000 });
      })
      .catch((error) => {
        toast.error(`Category deletion failed.`, { autoClose: 3000 });
      });
  };


  return (
    <>
      <Modal
        show={showCatModal}
        onHide={() => { setShowCatModal(false) }}
        animation={true}
        size='xl'
      >
        <ModalBody style={{ minHeight: 700 }}>
          <div className="card pt-8 p-10">
            <div className="row mb-4">
              <div className='col-lg-12 col-xl-12 d-flex justify-content-between'>
                <h5 className='mb-0'>{categoryId ? 'Edit' : 'Add'} Category</h5>
                <button className='btn btn-sm btn-danger ' onClick={e => { e.preventDefault(); setShowCatModal(false) }} >
                  Close
                </button>
              </div>
            </div>

            <form onSubmit={e => { e.preventDefault(); handleAddEditCategory() }} >
              <div className=" form-group row mb-3">
                <label className='col-xl-3 col-lg-3 col-form-label'>Category Name </label>
                <div className='col-lg-9 col-xl-9'>
                  <input value={categoryName} placeholder='Category Name' className='form-control form-control-solid form-control-lg' type='text'
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </div>
              </div>
              
              <div className=" form-group row mb-3">
                <label className='col-xl-3 col-lg-3 col-form-label'>Category Sorting </label>
                <div className='col-lg-9 col-xl-9'>
                  <input value={categorySorting} placeholder='Category Sorting' className='form-control form-control-solid form-control-lg' type='number'
                    onChange={(e) => setCategorySorting(e.target.value)}
                  />
                </div>
              </div>

              <div className=" form-group row mb-3">
                <label className='col-xl-3 col-lg-3 col-form-label'>Category Description </label>
                <div className='col-lg-9 col-xl-9'>
                  <textarea value={categoryDescription} placeholder="Category Description" className="form-control form-control-solid form-control-lg " rows="3" id="medicineTextArea"
                    onChange={(e) => setCategoryDescription(e.target.value)} ></textarea>
                </div>
              </div>

              <div className=" form-group row mb-3">
                <label className='col-xl-3 col-lg-3 col-form-label'>Category Image </label>
                <div className='col-lg-9 col-xl-9'>
                  <div className='custom-file-med form-control form-control-solid form-control-lg' onClick={e => catImgInputRef.current.click()}>
                    <input
                      ref={catImgInputRef}
                      type='file'
                      className='custom-file-med-input d-none'
                      accept='image/x-png,image/gif,image/jpeg'
                      onChange={(e) => setCategoryImage(e.target.files[0])}
                    />
                    <label className='custom-file-med-label d-flex align-items-center text-placeholder-color'>
                      {categoryImage ? ((typeof categoryImage == 'string') ? (categoryImage.length < 50 ? categoryImage : categoryImage.substring(0, 50) + '...') : (categoryImage.name.length < 50 ? categoryImage.name : categoryImage.name.substring(0, 50) + '...')) : 'Upload Category Image'}
                      <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow position-absolute d-none" style={{ top: '-12px', left: '-12px', height: 'auto', width: 'auto', padding: '5px 7px', backgroundColor: '#FFF5' }} >
                        <i className="fas fa-times icon-xs text-muted"></i>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className=" form-group row mb-3">
                <label className='col-xl-3 col-lg-3 col-form-label'>Category Parent </label>
                <div className='col-lg-9 col-xl-9'>
                  <select className="form-select form-select-solid category-select"
                    onChange={e => {
                      setCategoryParent(e.target.value)
                    }}
                    value={categoryParent ? categoryParent : '0'} >
                    <option className="text-placeholder-color" value='0' disabled={true} >{categoriesLoading ? 'Loading category Data' : 'Select a parent category'}</option>
                    {(myCategories && myCategories.length) && myCategories.map((category, index) => (
                      <option key={index} value={`${category.id}`} >{category.name}</option>
                    ))
                    }
                  </select>
                </div>
              </div>

              <div className=" form-group row mt-5 mb-5">
                <label className='col-xl-3 col-lg-3 col-form-label' />
                <div className='col-lg-9 col-xl-9'>
                  <button type='submit' className='btn btn-primary font-weight-bolder px-9 py-4' disabled={categoryAddEdit} >
                    {categoryId ? 'Update Category' : 'Add Category'}
                    {categoryAddEdit && <span className="ms-5">
                      <Spinner animation='border' size='sm' />
                    </span>}
                  </button>
                  <button type='button' onClick={e => { e.preventDefault(); resetCategoryData() }} className='btn btn-danger font-weight-bolder px-9 py-4 mx-5' >
                    Clear
                  </button>
                </div>
              </div>

            </form>

            {/* list of category */}
            <div className="border border-2 px-5 py-2 overflow-auto">
              <div className="" style={{ maxHeight: '250px', minHeight: 220 }} >
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                  <thead>
                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 category-list">
                      <th className="min-w-25px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending" style={{ width: '25px' }}>Idx</th>
                      <th className="min-w-150px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending" style={{ width: '200px' }}>Name</th>
                      <th className="min-w-50px sorting" tabIndex="1" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Image: nothing to do, just watch image." style={{ width: '50px' }}>Image</th>
                      <th className="min-w-125px sorting" tabIndex="1" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Form: activate to sort column ascending" style={{ width: '200px' }}>Description</th>
                      <th className="min-w-50px sorting" tabIndex="2" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending" style={{ width: '100px' }}>Ordering</th>
                      <th className="min-w-125px sorting" tabIndex="5" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" aria-label="Category: activate to sort column ascending" style={{ width: '150px' }}>Action</th>
                      {/* <th className="text-end min-w-70px sorting_disabled" rowSpan="1" colSpan="1" aria-label="Actions" style={{ width: '118.422px' }}>Category</th> */}
                    </tr>
                  </thead>
                  <tbody className="fw-bold text-gray-600">
                    {(myCategories && myCategories.length) && myCategories.map((category, index) => (
                      <tr key={index} className="category-list">
                        <td>
                          <div className="text-gray-600 text-hover-info mb-0">{index + 1}</div>
                        </td>
                        <td>
                          <div className="text-gray-600 text-hover-primary mb-0">{category.name}</div>
                        </td>
                        <td>
                          <div className="text-gray-600 text-hover-primary mb-0">
                            <div className="w-50px rounded d-flex justify-content-center align-items-center" style={{ border: '1px solid #7C839950', padding: '5px 1px', height: '30px' }} >
                              {category.image ?
                                <img src={category.image} alt="No category" className="h-100 mw-75 rounded" />
                                : <i className='far fa-newspaper fs-2' />
                              }
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="text-gray-600 text-hover-primary mb-0">{category.description ? category.description : '-'}</div>
                        </td>
                        <td>
                          <div className="text-gray-600 text-hover-primary mb-0">{category.sorting ? category.sorting : '-'}</div>
                        </td>
                        <td >
                          <button
                            className='btn btn-primary mr-2 btn-sm me-2'
                            onClick={() => handleEditCategoryData(category)}
                          >
                            Edit
                          </button>
                          <button
                            className='btn btn-danger btn-sm me-1'
                            onClick={() => {
                              setShowCatDelModal(true);
                              setCategoryDeleteId(category.id)
                            }}
                          >
                            Delete
                          </button>

                        </td>

                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>

        </ModalBody>
      </Modal>
      <DeleteAlert
        showDelModal={showCatDelModal}
        setShowDelModal={val => setShowCatDelModal(val)}
        onAllow={handleDeleteCat}
      />
    </>
  )
}

export default PressCategoryModal
