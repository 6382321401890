export const sendMsgCustom = (
  expoToken = '',
  title = '',
  subtitle = '',
  message_body = '',
  extraData,
  priority = 'normal',
  channelid = 'userapp_default',
  categoryid = 'orderdetails'
) => {
  fetch('https://fcm.googleapis.com/fcm/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `key=AAAAYVU4O0I:APA91bFTMBIaSzmPADPXAy5fKFDVBnvCAc-rAU14djRq4rJP-QtN73AkwtMgkJSzDvj_EHK2uWZeJyJpX3gPeUArEQCw_8qtaxhAG1Aol5MgPzU_GPZv2ldKdHkSA8j00oSXoS6wbYcp`,
    },
    body: JSON.stringify({
      to: `${expoToken}`,
      priority: priority || 'normal',
      data: {
        experienceId: '@mipamarlab/Amarlab',
        scopeKey: '@mipamarlab/Amarlab',
        title: `${title}`,
        message: `${message_body}`,
        subtitle: `${subtitle}`,
        channelId: channelid || 'userapp_default',
        categoryId: categoryid || 'orderdetails',
        extra: extraData,
      },
    }),
  })
    .then((prom) => prom.text())
    .then((resp) => console.log('resp: ', resp))
    .catch((err) => console.log('err: ', err));
};

// export const getsend => (

// getUserToken(user_id, (tokens) => {
//   if (tokens) {
//     tokens.map((tokenObj) => {
//       for (const device in tokenObj) {
//         const token = tokenObj[device];

//         sendMsgCustom =
//           (token,
//           `Order ${order_status}`,
//           `Order ${order_id}`,
//           `our order ${new_orderstat.order_id} has been ${order_status}.`);
//       }
//     });
//   }
// });
