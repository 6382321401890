import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment';
import NewFont from './../../assets/SourceSerif4-Regular.otf';

Font.register({
  family: 'Serif',
  src: NewFont,
});

const styles = StyleSheet.create({
  infoText: {
    fontSize: 10,
    color: '#323232',
    textTransform: 'capitalize',
    fontFamily: 'Serif',
  },
  infoTextLeft: {
    // backgroundColor: 'red',
    minWidth: 88,
  },
  orderDetailsInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 5,
    marginBottom: 5,
  },
  orderDetailsInfoSingle: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoLeft: {
    marginRight: 10,
  },
  infoRight: {
    marginLeft: 10,
  },
});

function InvoiceInfo({ order, patient }) {
  return (
    <>
      <View style={styles.orderDetailsInfo}>
        <View style={[styles.orderDetailsInfoSingle, styles.infoLeft]}>
          <Text style={[styles.infoText, styles.infoTextLeft]}>Order Id</Text>
          <Text style={styles.infoText}>: #{order ? order.order_id : ''}</Text>
        </View>
        <View style={[styles.orderDetailsInfoSingle, styles.infoRight]}>
          <Text style={[styles.infoText, styles.infoTextLeft]}>
            Patient Name
          </Text>
          <Text style={styles.infoText}>: {patient.patient.full_name}</Text>
        </View>
      </View>
      <View style={styles.orderDetailsInfo}>
        <View style={[styles.orderDetailsInfoSingle, styles.infoLeft]}>
          <Text style={[styles.infoText, styles.infoTextLeft]}>Order Date</Text>
          <Text style={styles.infoText}>
            : {moment(order.created_at).format('DD MMM YYYY; hh:mm A')}
          </Text>
        </View>
        <View style={[styles.orderDetailsInfoSingle, styles.infoRight]}>
          <Text style={[styles.infoText, styles.infoTextLeft]}>Contact</Text>
          <Text style={styles.infoText}>: {order.user.username}</Text>
        </View>
      </View>
      <View style={styles.orderDetailsInfo}>
        <View style={[styles.orderDetailsInfoSingle, styles.infoLeft]}>
          <Text style={[styles.infoText, styles.infoTextLeft]}>
            Service Status
          </Text>
          <Text style={styles.infoText}>
            : {order.orderdetail.order_status}
          </Text>
        </View>
        <View style={[styles.orderDetailsInfoSingle, styles.infoRight]}>
          <Text style={[styles.infoText, styles.infoTextLeft]}>
            Payment Status
          </Text>
          <Text style={styles.infoText}>
            : {order.orderdetail.payment_status}
          </Text>
        </View>
      </View>
      <View style={styles.orderDetailsInfo}>
        <View
          style={{
            alignItems: 'flex-start',
            flexDirection: 'row',
            marginRight: 30,
          }}
        >
          <Text
            style={{
              fontSize: 10,
              fontFamily: 'Serif',
              color: '#323232',
              minWidth: 88,
            }}
          >
            Address
          </Text>
          <Text style={{ fontSize: 10, color: 'slategray' }}>: </Text>
          <Text style={[styles.infoText, { marginRight: 50 }]}>
            <View style={{ alignItems: 'flex-start' }}>
              <Text style={{ marginLeft: 10 }}>
                {order.orderdetail.address.address.replace(/\s+/g, ' ').trim()},
                {order.orderdetail.address.thana},
                {order.orderdetail.address.district}
              </Text>
            </View>
          </Text>
        </View>
      </View>
    </>
  );
}

export default InvoiceInfo;
