import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Modal, Spinner, Button, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import AllDateSelect from '../components/AllDateSelect';
import Pagination from '../components/Pagination/Pagination';
import HeaderToolbar from '../components/HeaderToolbar';
import warningLogo from '../assets/img/warning.svg';
import DueDateSelect from '../components/DueDateSelect';

class AppPromotion extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem('token');
    axios.interceptors.request.use((config) => {
      const tokenjson = 'Token ' + JSON.parse(token);
      config.headers.Authorization = tokenjson;
      return config;
    });
  }

  state = {
    appPromotions: [],

    editAppPromotionModalShow: false,

    showModal: false,

    appPromotionImg: null,

    totalAppPromotions: null,
    promotionAdding: false,
    promotionID: null,
    is_active: true,
    name: '',
    description: '',
    image: null,
    existingImage: '',
    startdate: null,
    enddate: null,

    loading: false,
    limit: 8,
    count: 0,
    pagination: 1,
    searchText: '',

    addNewUserModalShow: false,
  };

  closeAppPromotionEditModal() {
    this.setState({
      editAppPromotionModalShow: false,
    });
    this.resetPromotionData();
  }

  fetchAppPromotion = ({ limit, pagination }, seatchText) => {
    let fetchpromotionUrl = `${
      process.env.REACT_APP_BASE_URL
    }/diagnostic/app-promotion/?page=1&limit=${limit}&offset=${
      (pagination - 1) * limit
    }`;
    if (!!seatchText) {
      fetchpromotionUrl = `${
        process.env.REACT_APP_BASE_URL
      }/diagnostic/app-promotion/?page=1&search=${seatchText}&limit=${limit}&offset=${
        (pagination - 1) * limit
      }`;
    }

    this.setState({ loading: true });
    axios
      .get(fetchpromotionUrl)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ appPromotions: resp.data.results });
        this.setState({ next: resp.data.next, count: resp.data.count });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.context.setPageTitle('App Promotion');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'App Promotion', link: '/app-promotion' },
    ]);

    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (userRole.is_superuser) {
      this.fetchAppPromotion(this.state);
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Lab Partner' ||
        userRole.groups[0].name === 'External'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchAppPromotion(this.state);
      }
    }
  }

  handleAppPromotiontEdit = (appPromotion) => {
    this.setState({
      promotionID: appPromotion.id,
      is_active: appPromotion.is_active,
      name: appPromotion.name,
      description: appPromotion.description,
      image: null,
      existingImage: appPromotion.image,
      // startdate: new Date(appPromotion.startdate),
      // enddate: new Date (appPromotion.enddate),
      startdate: appPromotion.startdate
        ? new Date(appPromotion.startdate)
        : null,
      enddate: appPromotion.enddate ? new Date(appPromotion.enddate) : null,

      editAppPromotionModalShow: true,
    });
  };

  handleSearch = (searchText) => {
    this.setState({ searchText: searchText, pagination: 1 });
    if (!searchText)
      this.fetchAppPromotion({ limit: this.state.limit, pagination: 1 });
    else
      this.fetchAppPromotion(
        { limit: this.state.limit, pagination: 1 },
        searchText
      );
  };

  handlePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    if (this.state.searchText)
      this.fetchAppPromotion(
        { limit: this.state.limit, pagination: pageno },
        this.state.searchText
      );
    else
      this.fetchAppPromotion({ limit: this.state.limit, pagination: pageno });
    // this.fetchUsers({ limit: this.state.limit, pagination: pageno });
  };

  //Start Date

  setStartDate = (v) => {
    this.setState({ startdate: v });
  };

  setEndDate = (v) => {
    this.setState({ enddate: v });
  };

  resetPromotionData = () => {
    this.setState({
      promotionID: null,
      is_active: true,
      name: '',
      description: '',
      image: null,
      existingImage: '',
      startdate: null,
      enddate: null,
      promotionAdding: false,
    });
  };

  addEditPromotiontItem = () => {
    const {
      promotionID,
      is_active,
      name,
      description,
      existingImage,
      image,
      startdate,
      enddate,
    } = this.state;

    if (!name) toast.error('Fill promotion name please.', { autoClose: 3000 });
    else if (!startdate)
      toast.error('Fill start date please.', { autoClose: 3000 });
    else if (!enddate)
      toast.error('Fill end date please.', { autoClose: 3000 });
    else if (!image && !existingImage)
      toast.error('Fill promotion image please.', { autoClose: 3000 });
    else if (image && image.size >= 1024000)
      toast.error('Image size must be less than 1MB.', { autoClose: 3000 });
    else if (!description)
      toast.error('Fill Promotion Description please.', { autoClose: 3000 });
    else {
      let appPromotionData = {
        name: name,
        is_active: is_active,
        description: description,
        // startdate: moment(startdate).format(),
        // enddate: moment(enddate).format(),
        startdate: startdate ? moment(startdate).format() : null,
        enddate: enddate ? moment(enddate).format() : null,
      };

      if (image) appPromotionData.image = image;
      // if (articleCategory) postData.category = articleCategory;

      // console.log(postData)

      let postFormData = new FormData();
      for (const key in appPromotionData) {
        postFormData.append(key, appPromotionData[key]);
      }

      // console.log(postFormData);
      // console.log(appPromotionData);
      if (!promotionID) {
        this.setState({ promotionAdding: true });
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/app-promotion/`,
            postFormData
          )
          .then((response) => {
            // console.log('promotion: ', response);
            this.fetchAppPromotion(this.state, this.state.searchText);
            this.resetPromotionData();
            this.setState({
              promotionAdding: false,
              editAppPromotionModalShow: false,
            });
            toast.success('New promotion added successfully.', {
              autoClose: 3000,
            });
          })
          .catch((err) => {
            console.log('err: ', err);
            this.setState({ promotionAdding: false });
            toast.error('Unable to add AddPromotion, try again.', {
              autoClose: 3000,
            });
          });
      } else {
        this.setState({ promotionAdding: true });
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/app-promotion/${promotionID}/`,
            postFormData
          )
          .then((response) => {
            // console.log(response);
            console.log('put: ', response);
            this.fetchAppPromotion(this.state, this.state.searchText);
            this.resetPromotionData();
            this.setState({
              promotionAdding: false,
              editAppPromotionModalShow: false,
            });
            toast.success('Promotion updated successfully.', {
              autoClose: 3000,
            });
          })
          .catch((err) => {
            console.log('err: ', err);
            this.setState({ promotionAdding: false });
            toast.error('Unable to update Post, try again.', {
              autoClose: 3000,
            });
          });
      }
    }
  };

  // deleteAppPromotion = (appPromotion) => {
  //   // console.log(user.id);

  //   // this.setState({ showModal: !this.state.showModal });
  //   this.setState({ showModal: true });
  //   this.setState({
  //     deleteId: appPromotion.id,
  //     is_active: !appPromotion.is_active,
  //   });
  // };

  updateAppPromotionStatus = (appPromotions, id) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/app-promotion/${id}/`,
        appPromotions
      )
      .then((resp) => {
        console.log(resp.data);
        toast.success(`App promotion update successfully.`, {
          autoClose: 3000,
        });

        // console.log(this.state.appPromotions);
        this.setState({
          // showModal: false,
          appPromotions: this.state.appPromotions.map((appPromotion) =>
            appPromotion.id === resp.data.id ? resp.data : appPromotion
          ),
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.success(`Unable to change active status, try again..`, {
          autoClose: 3000,
        });
      });
  };

  handleModalDlt = (appPromotion) => {
    if (appPromotion.is_active) {
      this.updateAppPromotionStatus({ is_active: false }, appPromotion.id);
    } else {
      this.updateAppPromotionStatus({ is_active: true }, appPromotion.id);
    }
  };

  //AppPromotion Table end

  openAddNewUserModal() {
    this.setState({
      addNewUserModalShow: true,
    });
  }
  closeAddNewUserModal() {
    this.setState({
      addNewUserModalShow: false,
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.name.trim() === '') {
      toast.error(`Please enter app promotion name.`, {
        autoClose: 3000,
      });
      return;
    }
    // console.log(this.state);
    this.createPromotions();
    // console.log(this.createPromotions());
  };

  render() {
    const {
      appPromotions,

      showModal,
      promotionAdding,
      promotionID,
      is_active,
      name,
      description,
      image,
      startdate,
      enddate,

      loading,
      count,
      limit,
      pagination,
      searchText,

      appPromotionImg,
    } = this.state;
    return (
      <>
        <HeaderToolbar title='App Promotion' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      {/* <div className='d-flex align-items-center position-relative my-1'>
                        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <rect
                              opacity='0.5'
                              x='17.0365'
                              y='15.1223'
                              width='8.15546'
                              height='2'
                              rx='1'
                              transform='rotate(45 17.0365 15.1223)'
                              fill='black'
                            ></rect>
                            <path
                              d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                              fill='black'
                            ></path>
                          </svg>
                        </span>
                        <input
                          type='text'
                          data-kt-customer-table-filter='search'
                          className='form-control form-control-solid w-250px ps-15'
                          placeholder='Search promotion by name'
                          // ref={this.searchRef}
                          // disabled={loading}
                          value={searchText}
                          onChange={(e) => this.handleSearch(e.target.value)}
                        />
                      </div> */}
                      <span className='text-muted mt-3 font-weight-bold fs-7 ps-1'>
                        {`Total  ${count ? count + '+ ' : '00'} ${
                          count ? `promotions` : `promotion`
                        } ${searchText && `found`}`}
                      </span>
                    </h3>
                    <div className='card-toolbar'>
                      <div className='position-relative'></div>

                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          this.setState({ editAppPromotionModalShow: true });
                        }}
                      >
                        Add App Promotion
                      </button>
                    </div>
                  </div>
                  <div className='card-body pt-2 pb-0 mt-3'>
                    {/* <AppPromotionDataTable
                      onAppPromotionAdded={this.createPromotions}
                    /> */}

                    <div className='table-responsive custom_scrollbar'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle fs-6 no-footer gy-5'>
                        <thead className='text-gray-600'>
                          <tr className='text-start text-gray-600 fw-bolder fs-7 no-footer text-uppercase gs-0'>
                            <th className='min-w-50px'>Idx</th>
                            <th className='min-w-50px'>Name</th>
                            <th className='min-w-140px'>Description</th>
                            <th className='min-w-140px'>Image</th>
                            <th className='min-w-100px'>Is Active</th>
                            <th className='min-w-110px'>Start Date</th>
                            <th className='min-w-110px'>End Date</th>
                            <th className='min-w-150px'>Actions</th>
                          </tr>
                        </thead>
                        <tbody className='fw-normal text-gray-500'>
                          {appPromotions &&
                            appPromotions.map((appPromotion, i) => (
                              <tr key={i}>
                                <td className='align-start fs-6 py-1'>
                                  <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                    #{i + 1}
                                  </span>
                                </td>

                                <td className='align-start fs-6 py-1'>
                                  {appPromotion.name}
                                </td>

                                <td className='align-start fs-6'>
                                  {appPromotion.description}
                                </td>
                                <td className='text-gray-600 text-hover-primary mb-0'>
                                  {appPromotion.image ? (
                                    <img
                                      src={appPromotion.image}
                                      alt='App promotion iamge'
                                      width='35px'
                                      height='30px'
                                      className='shadow'
                                    />
                                  ) : (
                                    <div
                                      className='d-flex justify-content-center align-items-center border border-2 rounded'
                                      style={{
                                        width: '35px',
                                        height: '30px',
                                      }}
                                    >
                                      <i
                                        className='fas fa-file-medical-alt'
                                        style={{
                                          fontSize: '12px',
                                          borderRadius: 5,
                                        }}
                                      />
                                    </div>
                                  )}
                                </td>
                                <td className='text-gray-600 mb-0'>
                                  {appPromotion.is_active ? (
                                    <span
                                      className='text-green'
                                      style={{ minWidth: 90 }}
                                    >
                                      Active
                                    </span>
                                  ) : (
                                    <span
                                      className='text-danger'
                                      style={{ minWidth: 90 }}
                                    >
                                      Inactive
                                    </span>
                                  )}
                                </td>
                                <td className='align-start fs-6'>
                                  {moment(appPromotion.startdate).format(
                                    'DD MMM YYYY, hh:mm A'
                                  )}
                                </td>
                                <td className='align-start fs-6'>
                                  <span className='text-muted font-weight-bold d-block font-size-lg'>
                                    {moment(appPromotion.enddate).format(
                                      'DD MMM YYYY, hh:mm A'
                                    )}
                                    {/* {appPromotion.enddate} */}
                                  </span>
                                </td>
                                <td className='align-start fs-6 d-flex'>
                                  <button
                                    onClick={() =>
                                      this.handleAppPromotiontEdit(appPromotion)
                                    }
                                    className='btn label label-lg btn-light-primary label-inline btn-sm me-3'
                                  >
                                    Edit
                                  </button>

                                  <div className='form-check form-switch form-check-custom form-check-solid'>
                                    <input
                                      className='form-check-input w-50px'
                                      type='checkbox'
                                      name='is_active'
                                      onChange={() => {
                                        if (window.confirm('Are you sure?'))
                                          this.handleModalDlt(appPromotion);
                                      }}
                                      // onChange={() => {
                                      //   this.deleteAppPromotion(appPromotion);
                                      // }}
                                      checked={appPromotion.is_active}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div className='row justify-content-end mt-3 mb-10'>
                      {loading && (
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                          <Spinner animation='border' size='sm' />{' '}
                          <span
                            className='ms-2 text-gray-600'
                            style={{ fontFamily: 'Poppins' }}
                          >
                            loading, Please wait...
                          </span>
                        </div>
                      )}
                      <Pagination
                        onPageChange={(pageno) =>
                          this.handlePaginationPress(pageno)
                        }
                        totalCount={count}
                        pageSize={limit}
                        currentPage={pagination}
                        siblingCount={1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Edit New User Modal */}

        <Modal
          show={this.state.editAppPromotionModalShow}
          animation={true}
          size='md'
          onHide={() => this.closeAppPromotionEditModal()}
        >
          <ModalBody>
            <div className='generete-ticket'>
              <div className='d-flex justify-content-between align-items-center mb-5'>
                <h5 className='m-0'>
                  {!promotionID ? 'Add' : 'Edit'} App Promotion
                </h5>
                <button
                  onClick={() => this.closeAppPromotionEditModal()}
                  className='btn btn-sm btn-danger'
                >
                  Close
                </button>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.addEditPromotiontItem();
                }}
              >
                <div className='form-group mb-3'>
                  <input
                    type='text'
                    className='form-control fw-normal'
                    placeholder='Enter name *'
                    name='name'
                    value={name}
                    onChange={this.handleChange}
                  />
                </div>

                <div className='form-group mb-3'>
                  <textarea
                    className='form-control fw-normal'
                    placeholder='Enter description'
                    rows='3'
                    name='description'
                    value={description}
                    onChange={this.handleChange}
                  ></textarea>
                </div>

                <div className='form-group mb-4'>
                  {this.state.promotionID && (
                    <img
                      src={this.state.existingImage}
                      alt=''
                      height='100'
                      className='mb-3'
                    />
                  )}
                  <div className='custom-file'>
                    <input
                      type='file'
                      className='custom-file-input'
                      accept='image/png, image/gif, image/jpeg'
                      onChange={(e) =>
                        this.setState({
                          image: e.target.files[0],
                        })
                      }
                    />
                    <label className='custom-file-label'>
                      {image
                        ? image.name.length > 50
                          ? image.name.slice(0, 49) + '...'
                          : image.name
                        : 'Select Promotion Image'}
                    </label>
                  </div>
                  <div className="images-notes">
                    <p style={{fontSize: "10px", color: "#3F4254", marginTop: "3px", marginBottom: "8px"}}>Note: Promotional image should maintain the following ratio ( Height:Width: 7:5 )</p>

                  </div>
                </div>

                <div className=' form-group row mb-3'>
                  <label className='col-xl-2 col-lg-2 col-form-label'>
                    Is Active
                  </label>
                  <div className='col-lg-10 col-xl-10'>
                    <div className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input w-50px'
                        type='checkbox'
                        name='is_active'
                        onChange={() =>
                          this.setState({
                            is_active: !is_active,
                          })
                        }
                        checked={is_active}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-group mb-4 row'>
                  <div className='col-md-6'>
                    <DueDateSelect
                      placeholderText='Choose Start Date and Time'
                      sampleDate={startdate}
                      setSampleDate={this.setStartDate}
                    />
                  </div>
                  <div className='col-md-6'>
                    <DueDateSelect
                      placeholderText='Choose End Date and Time'
                      sampleDate={enddate}
                      setSampleDate={this.setEndDate}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <button
                    type='submit'
                    className='btn btn-primary font-weight-bolder px-9 py-4'
                    disabled={promotionAdding}
                    style={{ display: 'inline-flex' }}
                  >
                    <div>{!promotionID ? 'Save' : 'Update'} Promotion</div>
                    {promotionAdding && (
                      <div className='ms-3'>
                        <Spinner animation='border' size='sm' />
                      </div>
                    )}
                  </button>
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      this.resetPromotionData();
                    }}
                    className='btn btn-danger font-weight-bolder px-9 py-4 mx-5'
                  >
                    Clear
                  </button>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const trStyle = {
  borderTop: '1px solid #d4d4d4',
};

export default AppPromotion;
AppPromotion.contextType = AuthContext;
