import axios from 'axios';
import { createContext, Component, createRef } from 'react';
import NotificatonSecond from '../assets/audio/notification2.mp3';
import NotificationSound from '../assets/audio/notification.wav';
import { app } from '../firebase/Firebase';

export const AuthContext = createContext();

class AuthContextProvider extends Component {
  state = {
    firebase: app,
    isLoggedIn: false,
    apiKey: null,
    user_details: null,
    is_superuser: false,
    quick_order: null,
    proccessing_order: null,
    order_report_due: null,
    order_payment_due: 0,
    pageTitle: 'Orders',
    showStats: 'yearly',
    breadcrumArr: [],
    audio: new Audio(NotificationSound),

    pending_order_mt: null,
    ongoing_order_mt: null,
    go_to_lab_order_mt: null,
    completed_order_mt: null,
  };

  dummyRf = createRef();

  setShowState = (value) => {
    this.setState({ showStats: value });
  };

  setPageTitle = (value) => {
    this.setState({ pageTitle: value });
  };

  setBreadcrum = (breadcrumArr) => {
    this.setState({ breadcrumArr: breadcrumArr });
  };

  handleIsLoggedIn = (value, key) => {
    this.setState({ isLoggedIn: value });
    this.setState({ apiKey: key });
  };

  interceptor = (key) => {
    axios.interceptors.request.use((config) => {
      const token = 'Token ' + key;
      config.headers.Authorization = token;
      return config;
    });
  };

  setUserDetails = (data) => {
    this.setState({ user_details: data });
  };

  setIsSuperuser = (value) => {
    this.setState({ is_superuser: value });
  };

  playSound(counter = 0, successCallback) {
    const audio = new Audio(NotificatonSecond);
    audio.autoplay = true;
    audio.muted = false;
    audio
      .play()
      .then(() => {
        console.log('success');
        if (successCallback) successCallback();
      })
      .catch((err) => {
        console.log('failed');
        if (counter <= 115) {
          setTimeout(() => {
            this.playSound(counter + 1, successCallback);
          }, 1000);
        }
      });
  }

  fetchOrderStatus = (sound = false) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-status`)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ proccessing_order: resp.data.proccessing_order });
        this.setState({ quick_order: resp.data.quick_order });
        this.setState({ order_report_due: resp.data.order_report_due });
        // this.setState({ order_payment_due: resp.data.order_payment_due });

        let totalStat =
          resp.data.proccessing_order +
          resp.data.quick_order +
          resp.data.order_report_due;
        if (totalStat > 0) {
          document.title = `(${totalStat}) Amarlab | Admin`;
          // localStorage.setItem('notifications', JSON.stringify(totalStat));

          const pastNotifi = localStorage.getItem('notifications');
          if (pastNotifi) {
            if (JSON.parse(pastNotifi) < totalStat) {
              setTimeout(() => {
                this.playSound(0, () => {
                  localStorage.setItem(
                    'notifications',
                    JSON.stringify(totalStat)
                  );
                });
              }, 300);
            }
          } else {
            setTimeout(() => {
              this.playSound(0, () => {
                localStorage.setItem(
                  'notifications',
                  JSON.stringify(totalStat)
                );
              });
            }, 300);
          }
        } else {
          document.title = 'Amarlab | Admin';
        }
      });
  };

  fetchOrderStatusRecursive = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-status`)
      .then((resp) => {
        this.setState({ proccessing_order: resp.data.proccessing_order });
        this.setState({ quick_order: resp.data.quick_order });
        this.setState({ order_report_due: resp.data.order_report_due });
        // this.setState({ order_payment_due: resp.data.order_payment_due });

        let totalStat =
          resp.data.proccessing_order +
          resp.data.quick_order +
          resp.data.order_report_due;
        if (totalStat > 0) {
          document.title = `(${totalStat}) Amarlab | Admin`;

          const pastNotifi = localStorage.getItem('notifications');
          if (pastNotifi) {
            if (JSON.parse(pastNotifi) < totalStat) {
              setTimeout(() => {
                this.playSound(0, () => {
                  localStorage.setItem(
                    'notifications',
                    JSON.stringify(totalStat)
                  );
                });
              }, 300);
            }
          } else {
            setTimeout(() => {
              this.playSound(0, () => {
                localStorage.setItem(
                  'notifications',
                  JSON.stringify(totalStat)
                );
              });
            }, 300);
          }
        } else {
          document.title = 'Amarlab | Admin';
        }

        setTimeout(() => {
          this.fetchOrderStatusRecursive();
        }, 120000);
      })
      .catch((error) => console.log(error));
  };

  fetchOrderStatusMt = (sound = false) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-mt-status`)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ pending_order_mt: resp.data.pending_order });
        this.setState({ ongoing_order_mt: resp.data.ongoing_order });
        this.setState({ go_to_lab_order_mt: resp.data.go_to_lab_order });
        this.setState({ completed_order_mt: resp.data.completed_order });

        let totalStat =
          resp.data.pending_order +
          resp.data.ongoing_order +
          resp.data.go_to_lab_order;
        if (totalStat > 0) {
          // document.title = `(${totalStat}) Amarlab | Admin`;
          // localStorage.setItem('notifications', JSON.stringify(totalStat));

          const pastNotifi = localStorage.getItem('notifications');
          if (pastNotifi) {
            if (JSON.parse(pastNotifi) < totalStat) {
              setTimeout(() => {
                this.playSound(0, () => {
                  localStorage.setItem(
                    'notifications',
                    JSON.stringify(totalStat)
                  );
                });
              }, 300);
            }
          } else {
            setTimeout(() => {
              this.playSound(0, () => {
                localStorage.setItem(
                  'notifications',
                  JSON.stringify(totalStat)
                );
              });
            }, 300);
          }
        } else {
          // document.title = 'Amarlab | Admin';
        }
      });
  };

  fetchOrderStatusRecursiveMt = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-mt-status`)
      .then((resp) => {
        this.setState({ pending_order_mt: resp.data.pending_order });
        this.setState({ ongoing_order_mt: resp.data.ongoing_order });
        this.setState({ go_to_lab_order_mt: resp.data.go_to_lab_order });
        this.setState({ completed_order_mt: resp.data.completed_order });

        let totalStat =
          resp.data.pending_order +
          resp.data.ongoing_order +
          resp.data.go_to_lab_order;
        if (totalStat > 0) {
          // document.title = `(${totalStat}) Amarlab | Admin`;

          const pastNotifi = localStorage.getItem('notifications');
          if (pastNotifi) {
            if (JSON.parse(pastNotifi) < totalStat) {
              setTimeout(() => {
                this.playSound(0, () => {
                  localStorage.setItem(
                    'notifications',
                    JSON.stringify(totalStat)
                  );
                });
              }, 300);
            }
          } else {
            setTimeout(() => {
              this.playSound(0, () => {
                localStorage.setItem(
                  'notifications',
                  JSON.stringify(totalStat)
                );
              });
            }, 300);
          }
        } else {
          // document.title = 'Amarlab | Admin';
        }

        setTimeout(() => {
          this.fetchOrderStatusRecursiveMt();
        }, 120000);
      })
      .catch((error) => console.log(error));
  };

  forLogout = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/auth/user/`)
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
        if (error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          // this.props.history.push('/login');
          window.location.href = '/login';
          // window.location.href = '/';
        }
      });
  };

  componentDidMount() {
    const token = localStorage.getItem('token');

    if (token) {
      this.setState({ apiKey: JSON.parse(token) });
      this.setState({ isLoggedIn: true });
      this.interceptor(JSON.parse(token));
      this.forLogout();
    }

    const user_details = localStorage.getItem('user_details');
    if (user_details) {
      this.setState({ user_details: JSON.parse(user_details) });
    }

    const is_superuser = localStorage.getItem('is_superuser');
    if (is_superuser) {
      this.setIsSuperuser(JSON.parse(is_superuser));
    }
    this.fetchOrderStatus(true);
    this.fetchOrderStatusMt(true);

    setTimeout(() => {
      try {
        // console.log(this.dummyRf);
        // this.dummyRf.current.click();
        var x = document.getElementById('consumeable');
        x.click();
      } catch (error) {}
    }, 1000);
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          handleIsLoggedIn: this.handleIsLoggedIn,
          interceptor: this.interceptor,
          setUserDetails: this.setUserDetails,
          setIsSuperuser: this.setIsSuperuser,
          fetchOrderStatus: this.fetchOrderStatus,
          fetchOrderStatusRecursive: this.fetchOrderStatusRecursive,
          fetchOrderStatusMt: this.fetchOrderStatusMt,
          fetchOrderStatusRecursiveMt: this.fetchOrderStatusRecursiveMt,
          setPageTitle: this.setPageTitle,
          setShowState: this.setShowState,
          setBreadcrum: this.setBreadcrum,
          playSound: this.playSound,
        }}
      >
        <div
          ref={this.dummyRf}
          id='consumeable'
          onClick={() => console.log('object')}
        ></div>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextProvider;
