import axios from 'axios';
import AsyncSelect from 'react-select/async';

const SearchTestsMulti = ({
  testType,
  pacTests,
  setPacTests,
  placeholderText,
}) => {
  const handleChange = (e) => {
    // console.log(e);
    setPacTests(e);
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url;
      if (testType === 'diagnostic') {
        url = 'diagnostic-test-ops';
      } else if (testType === 'package') {
        url = 'diagnostic-package';
      }
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/${url}/?search=${inputValue}&limit=200`
        )
        .then((resp) => {
          // console.log(resp.data.results);
          const tests = resp.data.results.map((test) => {
            return {
              ...test,
              value: test.name,
              label: test.name,
              order_type: testType === 'diagnostic' ? 'diagnostic' : 'package',
            };
          });
          resolve(tests);
          // console.log(tests);
        });
    });

  return (
    <AsyncSelect
      onChange={handleChange}
      loadOptions={promiseOptions}
      placeholder={placeholderText}
      className='mb-3'
      value={pacTests}
      isMulti
    />
  );
};

export default SearchTestsMulti;
