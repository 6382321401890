import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import BtnLoading from '../components/BtnLoading';
import DateSelect from '../components/DateSelect';
import HeaderToolbar from '../components/HeaderToolbar';
import SearchTests from '../components/SearchTests';
import SearchTestsBulk from '../components/SearchTestsBulk';
import SelectLab from '../components/SelectLab';
import SelectLabBulk from '../components/SelectLabBulk';
import { AuthContext } from '../context/AuthContext';

import { dhakaArea, ctgArea } from '../helpers/Areas';

export class BulkUploader extends Component {
  state = {
    district: '',
    thana: '',
    address: `Flat no/Level: \nHouse: \nRoad: \nBlock: \nDetails: `,
    locationLoopItem: dhakaArea,
    date: null,

    testType: 'diagnostic',
    testDeatails: null,
    allLabs: [],
    labDetails: null,
    selectedLabName: [],
    LabAndTest: [],
    references: '',
    discount: '',
    discountNote: '',

    csvFile: null,
    uploadingFile: false,
  };

  componentDidMount() {
    this.context.setPageTitle('Posts');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Bulk Uploader', link: '/bulk-uploader' },
    ]);

    const token = localStorage.getItem('token');
    axios.interceptors.request.use((config) => {
      const token_new = 'Token ' + JSON.parse(token);
      config.headers.Authorization = token_new;
      return config;
    });

    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (userRole.is_superuser) {
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Lab Partner' ||
        userRole.groups[0].name === 'External'
      ) {
        this.props.history.push('/orders');
      } else {
      }
    }
  }

  handleDistrict = (e) => {
    this.setState({ district: e.target.value });

    if (e.target.value === 'Dhaka') {
      this.setState({ locationLoopItem: dhakaArea });
    } else if (e.target.value === 'Chattogram') {
      this.setState({ locationLoopItem: ctgArea });
    } else {
      this.setState({ locationLoopItem: dhakaArea });
    }
  };

  setSelectedLabName = (value) => {
    this.setState({ selectedLabName: [value] });
  };

  setAllLabs = (value) => {
    this.setState({ allLabs: value });
  };

  setLabDetails = (value) => {
    this.setState({ labDetails: value });

    setTimeout(() => {
      this.handleAddTestAndLab(); // for single
    }, 200);
  };

  setTest = (value) => {
    this.setState({ testDeatails: value });

    this.setState({ labDetails: null }); // for single
  };

  handleAddTestAndLab = () => {
    const labName =
      this.state.labDetails.order_type === 'diagnostic'
        ? this.state.labDetails.branch.lab.id
        : this.state.labDetails.test_item.branch.lab.id;

    // this.setSelectedLabName(labName);       // for single. remove comment for multiple

    const testNdLab = {
      testDetails: this.state.testDeatails,
      labDetails: this.state.labDetails,
    };

    // this.setState({ LabAndTest: [testNdLab, ...this.state.LabAndTest] });

    this.setState({ LabAndTest: [testNdLab] }); // for single
    // this.setState({ testDeatails: null, labDetails: null });       // for single. remove comment for multiple
  };

  handleTestAndLabDlt = (test) => {
    this.setState({
      LabAndTest: this.state.LabAndTest.filter((o) => o !== test),
    });

    setTimeout(() => {
      if (this.state.LabAndTest.length === 0) {
        this.setState({ selectedLabName: [] });
      }
    }, 200);
  };

  checkIsFileExtentionValid = (fname) => {
    const extn = fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2);
    if (extn === 'csv' || extn === 'xlsx') return true;
    else return false;
  };

  setUploadingFile = (value) => {
    this.setState({ uploadingFile: value });
  };

  placeAnOrder = (orderdata) => {
    // console.log(orderdata);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/importer/import_order_dashboard/`,
        orderdata
      )
      .then((response) => {
        this.setUploadingFile(false);
        // console.log('place order: ', response.data);
        toast.success('Uploaded successfully!!!');
      })
      .catch((err) => {
        this.setUploadingFile(false);
        console.log('err: ', err.response);
        toast.error('Unable to place orders, try again later.', {
          autoClose: 3000,
        });
      });
  };

  handleSubmit = () => {
    const {
      district,
      thana,
      address,
      testDeatails,
      date,
      labDetails,
      csvFile,
      references,
      discount,
      discountNote,
    } = this.state;

    if (!district) {
      toast.error('Please select a city.', { autoClose: 3000 });
    } else if (!testDeatails) {
      toast.error('Please select a test.', { autoClose: 3000 });
    } else if (!labDetails) {
      toast.error('Please select a lab.', { autoClose: 3000 });
    } else if (!date) {
      toast.error('Please select a valid date', { autoClose: 3000 });
    } else if (!discount) {
      toast.error('Please enter discount', { autoClose: 3000 });
    } else if (!csvFile) {
      toast.error('Please select a csv file.', { autoClose: 3000 });
    } else if (!this.checkIsFileExtentionValid(csvFile.name)) {
      toast.error('Please select a valid csv or xlsx file and file name', {
        autoClose: 3000,
      });
    } else {
      const fileData = {
        report: csvFile,
        upload_by: this.context.user_details.id,
      };

      let fileFormData = new FormData();
      for (const key in fileData) {
        fileFormData.append(key, fileData[key]);
      }

      this.setUploadingFile(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/importer/importer-files/`,
          fileFormData
        )
        .then((response) => {
          // console.log('upload file: ', response);
          const uploaded_file_url = response.data.report;

          const orderData = {
            address: address,
            district: district,
            thana: thana,
            date:
              moment(date).format('YYYY-MM-DD') ||
              moment().format('YYYY-MM-DD'),
            test: testDeatails.id,
            lab: labDetails.branch.lab.id,
            url: uploaded_file_url,
            reference: references,
            discount: discount,
            discount_note: discountNote,
          };
          this.placeAnOrder(orderData);
        })
        .catch((err) => {
          this.setUploadingFile(false);
          console.log('err: ', err.response);
          toast.error('Unable to upload file, try again later.', {
            autoClose: 3000,
          });
        });
    }
  };

  render() {
    return (
      <>
        <HeaderToolbar title='Bulk Uploader' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row create-edit-order'>
              <div className='col-md-12'>
                <div className='card py-8 px-8 py-lg-15 px-lg-10'>
                  <div className='row justify-content-center'>
                    <div className='col-xl-9 bulk-upload'>
                      <h5 className='text-dark font-weight-bold mb-5'>
                        Upload Details
                      </h5>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.handleSubmit();
                        }}
                      >
                        <div className='col-md-12 mb-5'>
                          <select
                            className='form-select mb-5 text-placeholder'
                            onChange={this.handleDistrict}
                            value={this.state.district}
                          >
                            <option disabled value=''>
                              Select City
                            </option>
                            <option value='Dhaka'>Dhaka</option>
                            <option value='Chattogram'>Chattogram</option>
                          </select>
                          <select
                            className='form-select mb-5 text-placeholder'
                            value={this.state.thana}
                            onChange={(e) =>
                              this.setState({ thana: e.target.value })
                            }
                          >
                            <option disabled value=''>
                              Select Thana
                            </option>
                            {this.state.locationLoopItem.map((area, i) => (
                              <option value={area} key={i}>
                                {area}
                              </option>
                            ))}
                          </select>
                          <textarea
                            rows='5'
                            className='form-control'
                            placeholder='Location Details'
                            value={this.state.address}
                            onChange={(e) =>
                              this.setState({ address: e.target.value })
                            }
                          ></textarea>
                        </div>

                        <div className='col-md-12 left mb-5'>
                          {/* <select
                            className='form-select mb-5'
                            value={this.state.testType}
                            onChange={(e) => {
                              this.setState({ testType: e.target.value });
                            }}
                          >
                            <option value=''>Select Test Type</option>
                            <option value='diagnostic'>Diagnostic</option>
                            <option value='package'>Package</option>
                          </select> */}

                          <SearchTestsBulk
                            testType={this.state.testType}
                            setAllLabs={this.setAllLabs}
                            testDeatails={this.state.testDeatails}
                            setTest={this.setTest}
                          />
                          <div className='lab-and-button mb-5'>
                            <SelectLabBulk
                              allLabs={this.state.allLabs}
                              setLabDetails={this.setLabDetails}
                              labDetails={this.state.labDetails}
                            />
                            {/* <button
                              type='button'
                              className='btn badge bg-secondary text-dark'
                              onClick={this.handleAddTestAndLab}
                              disabled={
                                this.state.testDeatails && this.state.labDetails
                                  ? ''
                                  : 'disabled'
                              }
                            >
                              <i className='fas fa-plus'></i>
                            </button> */}
                          </div>
                        </div>

                        <div className='col-md-12 left mb-5'>
                          <input
                            type='text'
                            className='form-control text-placeholder refcls'
                            placeholder='Enter Reference'
                            value={this.state.references}
                            onChange={(e) =>
                              this.setState({ references: e.target.value })
                            }
                          />
                        </div>

                        <div>
                          <DateSelect
                            sampleDate={this.state.date}
                            setSampleDate={(value) => {
                              this.setState({ date: value });
                            }}
                            placeholderText='Select a date'
                            allowprevious={true}
                          />
                        </div>

                        <div className='col-md-12 left mb-5'>
                          <input
                            type='number'
                            className='form-control text-placeholder refcls'
                            placeholder='Enter Discount in percentage (0 - 100 %)'
                            value={this.state.discount}
                            onChange={(e) =>
                              this.setState({ discount: e.target.value })
                            }
                            min={1}
                            max={100}
                          />
                        </div>

                        <div className='col-md-12 left mb-5'>
                          <textarea
                            className='form-control text-placeholder'
                            placeholder='Enter Discount Note'
                            value={this.state.discountNote}
                            onChange={(e) =>
                              this.setState({ discountNote: e.target.value })
                            }
                            rows='3'
                          ></textarea>
                        </div>

                        <div className='col-md-12 left mb-5'>
                          <div
                            className='custom-file'
                            style={{ transform: 'scaleY(1.0)' }}
                          >
                            <input
                              type='file'
                              className='custom-file-input'
                              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls'
                              onChange={(e) => {
                                this.setState({ csvFile: e.target.files[0] });
                              }}
                            />
                            <label
                              className={`custom-file-label ${
                                this.state.csvFile ? '' : 'text-placeholder'
                              } d-flex align-items-center`}
                            >
                              {this.state.csvFile
                                ? this.state.csvFile.name.length > 85
                                  ? this.state.csvFile.name.slice(0, 83) + '...'
                                  : this.state.csvFile.name
                                : `Select a csv file`}
                            </label>
                          </div>
                        </div>

                        <p className='text-end m-0'>
                          {this.state.uploadingFile ? (
                            <BtnLoading
                              name='Uploading'
                              btnClass='primary px-8'
                            />
                          ) : (
                            <button
                              type='submit'
                              className='btn btn-primary px-8'
                            >
                              Upload
                            </button>
                          )}
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BulkUploader;
BulkUploader.contextType = AuthContext;
