/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios';
import { lte, parseInt } from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
// import DateSelect from './DateSelect';
// import OrderItemEdit from './OrderItemEdit';
import PaymentSourceModal from './PaymentSourceModal';
// import TimeSelect from './TimeSelect';
import SvgTicket from '../assets/img/Ticket2.png';
// import CampainSvg from '../assets/img/Campain.svg';
import SelectPopover from './Dropdown/SelectPopover';
import CsMtPopover from './Dropdown/CsMtPopover';
// import TextareaPopover from './Dropdown/TextareaPopover';
import SelAreaInpPop from './Dropdown/SelAreaInpPop';
// import ContactPopover from './Dropdown/ContactPopover';
import DateTimePopover from './Dropdown/DateTimePopover';
// import DiscountPopover from './Dropdown/DiscountPopover';
// import ImageUploadPopOver from './Dropdown/ImageUploadPopOver';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import InvoicePdf from './invoice/InvoicePdf';
import AddressPopover from './Dropdown/AddressPopover';
import TextInputPopover from './Dropdown/TextInputPopover';
// import Select from 'react-select';
// import SelectPopoverNew from './Dropdown/SelectPopoverNew';
import { dhakaArea, ctgArea } from '../helpers/Areas';
// import AngleDown from '../assets/img/angle-down.png';
import RemarksPopover from './Dropdown/RemarksPopover';
import SeeAllPopover from './Dropdown/SeeAllPopover';
import AngleDown from '../assets/img/angle-down.png';
import CampainModal from './CampainModal';
import { getUserTokens } from '../firebase/Firebase';
import { sendMsgCustom } from '../firebase/SendMsgCustom';

const OrderTable = ({
  order,
  orderManager,
  medicalTechnologist,
  showAchieve,
  refreshPage,
}) => {
  const { user_details, fetchOrderStatus } = useContext(AuthContext);
  const history = useHistory();

  const [showPaymentSourceModal, setShowPaymentSourceModal] = useState(false);
  const [showCampainModal, setShowCampainModal] = useState(false);
  const [paymentSourceDetails, setPaymentSourceDetails] = useState(null);

  const [allAccess, setAllAccess] = useState(false);
  const [approveAccess, setApproveAccess] = useState(false);
  const [approveStatus, setApproveStatus] = useState('');
  const [isLabPartner, setIsLabPartner] = useState(false);

  const [mainOrder, setMainOrder] = useState(order);
  const [showEditModal, setShowEditModal] = useState(false);

  const [generateTicketModal, setGenerateTicketModal] = useState(false);

  const [shownItemName, setShownItemName] = useState('');

  //check null
  // const [orderDelivery, setOrderDelivery] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderDiscount, setOrderDiscount] = useState(null);

  const [orderId, setOrderId] = useState(null);
  const [orderDetailsId, setOrderDetailsId] = useState(null);
  const [orderDiscountId, setOrderDiscountId] = useState(null);

  // order Report
  const [orderAllPatients, setOrderAllPatients] = useState([]);
  const [orderReportFile, setOrderReportFile] = useState(null);
  const [reportStatOlay, setReportStatOlay] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [orderReportName, setorderReportName] = useState('');
  const [reportPatientName, setreportPatientName] = useState('');

  // main order notes or remarks
  const [orderNotes, setOrderNotes] = useState(mainOrder.ordernote);

  // order details
  const [orderStatus, setOrderStatus] = useState(
    mainOrder.orderdetail && mainOrder.orderdetail.order_status
  );
  const [cancelNote, setCancelNote] = useState(
    mainOrder.orderdetail && mainOrder.orderdetail.status_note
  );
  const [csAgent, setCsAgent] = useState(
    mainOrder.orderdetail &&
    mainOrder.orderdetail.cs_agent &&
    mainOrder.orderdetail.cs_agent.id
  );
  const [mt, setMt] = useState(
    mainOrder.orderdetail &&
    mainOrder.orderdetail.mt &&
    mainOrder.orderdetail.mt.id
  );
  const [orderType, setOrderType] = useState(mainOrder && mainOrder.order_type);
  const [orderReference, setOrderReference] = useState(
    mainOrder.orderdetail && mainOrder.orderdetail.references
  );
  const [orderPersona, setOrderPersona] = useState(
    mainOrder.orderdetail && mainOrder.orderdetail.persona
  );
  const [orderPaymentStatus, setOrderPaymentStatus] = useState(
    mainOrder.orderdetail && mainOrder.orderdetail.payment_status
  );
  const [orderDeliveryCopy, setOrderDeliveryCopy] = useState(
    mainOrder.orderdetail && mainOrder.orderdetail.delivery_copy_status
  );
  const [orderNote, setOrderNote] = useState(
    mainOrder.orderdetail && mainOrder.orderdetail.order_note
  );
  const [paymentNote, setPaymentNote] = useState(
    mainOrder.orderdetail && mainOrder.orderdetail.payment_note
  );
  const [edDate, setEDDate] = useState(
    mainOrder.orderdetail &&
    mainOrder.orderdetail.estimate_date &&
    new Date(mainOrder.orderdetail.estimate_date)
  );

  // order details address
  const [orderAddressAddress, setOrderAddressAddress] = useState(
    mainOrder.orderdetail &&
    mainOrder.orderdetail.address &&
    (mainOrder.orderdetail.address.address || '')
  );
  const [orderAddressDistrict, setOrderAddressDistrict] = useState(
    mainOrder.orderdetail &&
    mainOrder.orderdetail.address &&
    (mainOrder.orderdetail.address.district || '')
  );
  const [orderAddressThana, setOrderAddressThana] = useState(
    mainOrder.orderdetail &&
    mainOrder.orderdetail.address &&
    (mainOrder.orderdetail.address.thana || '')
  );
  const [orderAddressEmail, setOrderAddressEmail] = useState(
    mainOrder.orderdetail &&
    mainOrder.orderdetail.address &&
    (mainOrder.orderdetail.address.email || '')
  );
  const [orderAddressMobile, setOrderAddressMobile] = useState(
    mainOrder.orderdetail &&
    mainOrder.orderdetail.address &&
    (mainOrder.orderdetail.address.mobile || '')
  );

  // order discount
  const [orderDiscountNote, setOrderDiscountNote] = useState(
    mainOrder.orderdiscount && mainOrder.orderdiscount.discount_note
  );

  const [orderDiscountColl, setOrderDiscountColl] = useState(
    mainOrder.orderdiscount && mainOrder.orderdiscount.collection_discount_price
  );

  const [orderDiscountMate, setOrderDiscountMate] = useState(
    mainOrder.orderdiscount && mainOrder.orderdiscount.material_discount_price
  );

  const [orderDiscountPrice, setOrderDiscountPrice] = useState(
    mainOrder.orderdiscount && mainOrder.orderdiscount.discount_price
  );

  const [orderDiscountSource, setOrderDiscountSource] = useState(
    mainOrder.orderdiscount && mainOrder.orderdiscount.discount_source
  );
  // const [orderDiscountBy, setOrderDiscountBy] = useState(
  //   mainOrder.orderdiscount &&
  //     mainOrder.orderdiscount.discount_by &&
  //     mainOrder.orderdiscount.discount_by.id
  // );

  //Main Order
  const [IsAchieved, setIsAchieved] = useState(
    mainOrder && mainOrder.is_archived
  );
  const [scDate, setSCDate] = useState(new Date(mainOrder.date));
  const [scTimeNew, setSCTimeNew] = useState(
    mainOrder.time ? new Date(moment(mainOrder.time, 'HH:mm:ss')) : null
  );

  const [csDate, setCsDate] = useState(
    mainOrder.orderdelivery.length > 0
      ? new Date(mainOrder.orderdelivery.slice(-1)[0].created_at)
      : null
  );
  const [csTimeNew, setCsTimeNew] = useState(
    mainOrder.orderdelivery.length > 0
      ? new Date(mainOrder.orderdelivery.slice(-1)[0].created_at)
      : null
  );

  // order delivery
  const [reportDelivery, setReportDelivery] = useState(
    mainOrder.orderdelivery.length > 0
      ? mainOrder.orderdelivery.slice(-1)[0].name
      : ``
  );

  // order delivery
  const [reportDeliveryAfterSoft, setReportDeliveryAfterSoft] = useState('');
  const [reportDeliveryAfterHard, setReportDeliveryAfterHard] = useState(``);
  const [reportDeliverySoftDate, setReportDeliverySoftDate] = useState(null);
  const [reportDeliveryHardDate, setReportDeliveryHardDate] = useState(null);

  const [reportDeliveryAfterDescSoft, setReportDeliveryAfterDescSoft] =
    useState('');
  const [reportDeliveryAfterDescHard, setReportDeliveryAfterDescHard] =
    useState('');

  // Order Item
  const [itemEditModal, setItemEditModal] = useState(false);
  const [userPatients, setUserPatients] = useState(null);
  const [orderEditId, setOrderEditId] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [orderUserId, setOrderUserId] = useState(null);

  const [orderItemsId, setOrderItemsId] = useState([]);

  //Fees
  const [fees, setFees] = useState(null);

  // access
  const [mtAccess, setMtAccess] = useState(false);
  const [lpAccess, setLpAccess] = useState(false);
  const [onlyAdminAccess, setOnlyAdminAccess] = useState(false);
  const [sampleCollAccess, setSampleCollAccess] = useState(false);
  const [addressAccess, setAddressAccess] = useState(false);

  // Address edit
  const [address, setAddress] = useState(null);

  const [discountPrice, setDiscountPrice] = useState(null);

  const [patientOrders, setPatientOrders] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(null);

  useEffect(() => {
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    if (userRole.is_superuser) {
      setMtAccess(true);
    } else {
      if (userRole.groups.length === 0) {
        setMtAccess(false);
      } else if (userRole.groups[0].name === 'User') {
        setMtAccess(false);
      } else if (userRole.groups[0].name === 'External') {
        setMtAccess(false);
      } else if (
        userRole.groups[0].name === 'Lab Partner' &&
        order.orderapproval !== null &&
        order.orderapproval.is_approved
      ) {
        setMtAccess(false);
      } else {
        // setMtAccess(true);
      }
    }

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/fees/`)
      .then((resp) => setFees(resp.data.results[0]));
  }, []);

  useEffect(() => {
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    if (userRole.is_superuser) {
      setLpAccess(true);
    } else {
      if (userRole.groups.length === 0) {
        setLpAccess(false);
      } else if (userRole.groups[0].name === 'User') {
        setLpAccess(false);
      } else if (userRole.groups[0].name === 'External') {
        setLpAccess(false);
      } else if (
        userRole.groups[0].name === 'Lab Partner' &&
        order.orderapproval !== null &&
        order.orderapproval.is_approved
      ) {
        setLpAccess(false);
      } else if (userRole.groups[0].name === 'Medical Technologist') {
        setLpAccess(false);
        setMtAccess(true);
        setAddressAccess(true);
      } else if (userRole.groups[0].name === 'Admin') {
        setOnlyAdminAccess(true);
        setAddressAccess(true);
        setLpAccess(true);
        setSampleCollAccess(true);
      } else {
        setLpAccess(true);
        setAddressAccess(true);
        setSampleCollAccess(true);
      }
    }
  }, []);

  useEffect(() => {
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (userRole.is_superuser) {
      setAllAccess(true);
      setApproveAccess(true);
      setOnlyAdminAccess(true);
      setSampleCollAccess(true);
      setAddressAccess(true);
    } else {
      if (userRole.groups.length !== 0) {
        if (
          userRole.groups[0].name === 'Admin' ||
          userRole.groups[0].name === 'Order Manager' ||
          userRole.groups[0].name === 'Supervisor'
        ) {
          setAllAccess(true);
          setApproveAccess(true);
          setMtAccess(true);
        } else if (
          userRole.groups[0].name === 'Order Manager' ||
          userRole.groups[0].name === 'Supervisor'
        ) {
          setSampleCollAccess(false);
        }
      }
    }

    if (userRole.groups.length !== 0) {
      if (userRole.groups[0].name === 'Lab Partner') {
        setIsLabPartner(true);
        mainOrder.orderapproval !== null && mainOrder.orderapproval.is_approved
          ? setApproveStatus('Approved')
          : setApproveStatus('Pending');
      }
    }
  }, []);

  useEffect(() => {
    setMainOrder(order);
  }, [order]);

  useEffect(() => {
    tablePatients(mainOrder);

    setTimeout(() => {
      if (mainOrder.is_archived) {
        setMtAccess(false);
        setLpAccess(false);
        setAllAccess(false);
        setApproveAccess(false);
        setOnlyAdminAccess(false);
        setSampleCollAccess(false);
        setAddressAccess(false);
      } else {
        // setMtAccess(true);
        // setLpAccess(true);
        // setAllAccess(true);
        // setApproveAccess(true);
        // setOnlyAdminAccess(true);
        // setSampleCollAccess(true);
        // setAddressAccess(true);
      }
    }, 300);

    if (mainOrder.orderdiscount && mainOrder.orderdiscount.discount_price) {
      setDiscountPrice(parseInt(mainOrder.orderdiscount.discount_price));
    }

    handlePatientOrders(mainOrder);
    calculateDiscountInvoice(mainOrder);
  }, [mainOrder]);

  const adjustCapitalize = (strng) => {
    return strng
      .split(' ')
      .map(
        (text) => text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase()
      )
      .join(' ');
  };

  const calculateDiscountInvoice = (order) => {
    if (order.orderdiscount) {
      const discountPercentage =
        (parseInt(order.orderdiscount.discount_price) /
          parseInt(order.total_price)) *
        100;
      // console.log(discountPercentage);
      setDiscountPercentage(discountPercentage);
      // const singleDiscount =
      //   parseInt(order.orderdiscount.discount_price) / order.orderitem.length;
      // setSingleDiscountTk(singleDiscount);
      // console.log(singleDiscount);
    }
  };

  const handlePatientOrders = (order) => {
    const itemArray = order.orderitem;
    const patientDetails = itemArray.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    // console.log(itemArray);
    // console.log(filteredPatient);
    let patientOrders = [];

    filteredPatient.forEach((patient) => {
      const result = itemArray.filter((o1) => {
        return o1.patient.id === patient.id;
      });
      const newArray = {
        patient: patient,
        testItem: result,
      };
      patientOrders.push(newArray);
      // console.log(newArray);
    });
    patientOrders.sort((p) => (p.patient.is_account ? -1 : 1));
    setPatientOrders(patientOrders);
    // this.setState({ patientOrders: patientOrders });
    // console.log(patientOrders);
  };

  const getTestItemsPrice = (array) => {
    const price = array.reduce(
      (total, item) => total + parseInt(item.purchasable_order_item.sell_price),
      0
    );

    return parseInt(price);
  };

  const handleMeterialPerson = (array) => {
    const patients = array
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    // console.log(patients.length);
    return patients.length;
  };

  const paymentSourceName = (string) => {
    if (string === 'bkash') {
      return 'bKash';
    } else if (string === 'sslc') {
      return 'SSL Commerz';
    } else {
      return 'Cash On Delivery';
    }
  };

  const _toSpace = (string) => {
    let newStr = string.replace(/_/g, ' ');
    return newStr;
  };

  const getSingleOrderTree = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-tree/${id}`)
      .then((resp) => {
        console.log(resp.data);
        setMainOrder(resp.data);
        //
        // post edit
        setOrderStatus(
          resp.data.orderdetail && resp.data.orderdetail.order_status
        );
        setReportDelivery(
          resp.data.orderdelivery.length > 0
            ? resp.data.orderdelivery.slice(-1)[0].name
            : ``
        );

        //
        setReportLoading(false); // when single report image one by one upload
        setReportStatOlay(false); // when single report image one by one upload
      });
  };

  const putAddress = (data, id, setShow) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/address/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        getSingleOrderTree(orderId);
        // mainOrder.orderdetail.address = resp.data;
        toast.success(`Order contact details saved sucessfully.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        setShowPopover(false);
        if (setShow) setShow(false);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        if (setShow) setShow(false);
      });
  };

  const handleContact = (setShow) => {
    const numberPattern = /^01\d{9}$/;

    if (!numberPattern.test(orderAddressMobile)) {
      toast.error('Please enter a valid mobile number.', {
        autoClose: 3000,
      });
    } else {
      const pputAddress = {
        mobile: orderAddressMobile,
        email: orderAddressEmail,
      };
      // console.log(putAddress, address);
      putAddress(pputAddress, address, setShow);
    }
  };

  const handleArea = (setShow) => {
    if (orderAddressDistrict === '') {
      toast.error('Please select a district.', {
        autoClose: 3000,
      });
    } else if (orderAddressThana === '') {
      toast.error('Area can not be empty.', {
        autoClose: 3000,
      });
    } else if (orderAddressAddress === '') {
      toast.error('Address can not be empty.', {
        autoClose: 3000,
      });
    } else {
      const pputAddress = {
        district: orderAddressDistrict,
        thana: orderAddressThana,
        address: orderAddressAddress,
      };
      // console.log(pputAddress, address);
      putAddress(pputAddress, address, setShow);
    }
  };

  const handleEditModal = (order, name) => {
    setShowEditModal(true);
    console.log(order);
    setOrderId(order.id);
    setOrderDetailsId(order.orderdetail ? order.orderdetail.id : null);
    setOrderDiscountId(order.orderdiscount ? order.orderdiscount.id : null);
    // console.log(name);
    setShownItemName(name);
    // setOrderDelivery(order.orderdelivery);
    setOrderDetails(order.orderdetail);
    setOrderDiscount(order.orderdiscount);
  };

  const ajaxOrderDetailsPost = (postDate, setShow, sendSms = false) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-detail/`, postDate)
      .then((resp) => {
        console.log(resp.data);
        if (sendSms) {
          // if (resp.data.order_status === 'confirmed') {
          //   smsApiCall(resp.data.order, 'order_confirmation');
          // }
        }
        getSingleOrderTree(resp.data.order);
        toast.success(`Order status saved sucessfully.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        fetchOrderStatus();
        setShowPopover(false);
        if (setShow) setShow(false);

        // for order status change notification

        try {
          // for order status change notification
          if (postDate && !!postDate.order_status) {
            const newOrder = order;
            const newUserId = newOrder.user.id;
            const newOrderStatus =
              resp.data.order_status || postDate.order_status;

            // console.log(newUserId);
            getUserTokens(newUserId, (tokens) => {
              const extradata = {
                orderId: `${newOrder.order_id}`,
                orderid: newOrder.id,
              };
              // console.log(newUserId);
              // console.log(tokens);
              // console.log(extradata);

              tokens.map((token) => {
                sendMsgCustom(
                  token,
                  `Order ${newOrderStatus}`,
                  `Order ${newOrder.order_id}`,
                  `Your order #${newOrder.order_id} has been ${newOrderStatus}.`,
                  extradata
                );
              });
            });
          }
        } catch (error) {
          console.log(error);
        }

      })

      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        if (setShow) setShow(false);
      });
  };

  const ajaxOrderDetailsPut = (postDate, id, setShow, sendSms=false) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/order-detail/${id}/`,
        postDate
      )
      .then((resp) => {
        // console.log(resp.data);
        if (sendSms) {
          // if (resp.data.order_status === 'confirmed') {
          //   smsApiCall(resp.data.order, 'order_confirmation');
          // } else if (resp.data.order_status === 'completed') {
          //   // smsApiCall(resp.data.order, 'order_confirmation');
          //   if (mainOrder && mainOrder.orderitem.length !== 0) {
          //     const sou = mainOrder.orderitem[0].order.url
          //     if (sou === 'http://localhost:3000' || sou === 'http://ops.amarlab.com:8080' || sou === 'http://18.188.76.126:8080' || sou === 'https://ops.amarlab.com') {
          //       // console.log('came from dashboard');
          //       smsApiCall(resp.data.order, 'order_completed_ops');
          //     } else if (sou === 'http://amarlab.com:8080' || sou === 'http://3.142.245.74:8080' || sou === 'https://amarlab.com') {
          //       // console.log('came from frontend');
          //       smsApiCall(resp.data.order, 'order_completed_web');
          //     } else {
          //       // console.log('came from app');
          //       smsApiCall(resp.data.order, 'order_completed_app');
          //     }
          //   }
          // }
        }
        getSingleOrderTree(resp.data.order);
        // mainOrder.orderdetail = resp.data;
        toast.success(`Order status saved sucessfully.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        fetchOrderStatus();
        setShowPopover(false);
        if (setShow) setShow(false);

        try {
          // for order status change notification
          if (postDate && !!postDate.order_status) {
            const newOrder = order;
            const newUserId = newOrder.user.id;
            const newOrderStatus = resp.data.order_status || postDate.order_status;

            // console.log(newUserId);
            getUserTokens(newUserId, (tokens) => {
              const extradata = {
                orderId: `${newOrder.order_id}`,
                orderid: newOrder.id,
              };
              // console.log(newUserId);
              // console.log(tokens);
              // console.log(extradata);

              tokens.map((token) => {
                sendMsgCustom(
                  token,
                  `Order ${newOrderStatus}`,
                  `Order ${newOrder.order_id}`,
                  `Your order #${newOrder.order_id} has been ${newOrderStatus}.`,
                  extradata
                );
              });
            });
          }
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        if (setShow) setShow(false);
      });
  };

  const handleOrderStatus = (setShow, ostat) => {
    const ostatus = ostat || orderStatus;

    // console.log(ostatus);
    if (ostatus === '' || ostatus === null) {
      toast.error(`Select Order Status.`, {
        autoClose: 3000,
      });
    } else if (ostatus === 'cancel' && (cancelNote === null || cancelNote === '')) {
      toast.error(`Please enter cancel note.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDetails = {
        order_status: ostatus,
        order: orderId,
        cs_agent: user_details.id,
      };
      const putOrderDetails = {
        order_status: ostatus,
        cs_agent: user_details.id,
      };

      if (ostatus === 'cancel') {
        postOrderDetails.status_note = cancelNote;
        putOrderDetails.status_note = cancelNote;
      }

      if (orderDetails === null) {
        // console.log('Post request');
        // console.log(postOrderDetails);
        ajaxOrderDetailsPost(postOrderDetails, setShow, true);
      } else {
        // console.log('Put request');
        // console.log(putOrderDetails);
        ajaxOrderDetailsPut(putOrderDetails, orderDetailsId, setShow, true);

      }
    }
  };

  const handleOrderCs = (setShow) => {
    if (csAgent === '' || csAgent === null) {
      toast.error(`Select cs agent.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDetails = {
        cs_agent: parseInt(csAgent),
        order: orderId,
      };
      const putOrderDetails = {
        cs_agent: parseInt(csAgent),
      };
      if (orderDetails === null) {
        ajaxOrderDetailsPost(postOrderDetails, setShow);
      } else {
        ajaxOrderDetailsPut(putOrderDetails, orderDetailsId, setShow);
      }
    }
  };

  const mtAssigned = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/mt/mt-status/`, data)
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  const handleOrderMt = (setShow) => {
    if (mt === '' || mt === null) {
      toast.error(`Select medical technologist.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDetails = {
        mt: parseInt(mt),
        order: orderId,
      };
      const putOrderDetails = {
        mt: parseInt(mt),
      };
      if (orderDetails === null) {
        ajaxOrderDetailsPost(postOrderDetails, setShow);
      } else {
        ajaxOrderDetailsPut(putOrderDetails, orderDetailsId, setShow);
      }
      const mtData = {
        order: orderId,
        mt: parseInt(mt),
        assigned_by: user_details.id,
        name: 'pending',
      };

      // console.log(mtData);
      // mtAssigned(mtData);
    }
  };

  const handleOrderType = (setShow) => {
    if (orderType === '' || orderType === null) {
      toast.error(`Select Order type.`, {
        autoClose: 3000,
      });
    } else {
      const putOrder = {
        order_type: orderType,
      };

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/order/order-only/${orderId}/`,
          putOrder
        )
        .then((resp) => {
          // console.log(resp.data);
          getSingleOrderTree(orderId);
          toast.success(`Order type saved sucessfully.`, {
            autoClose: 3000,
          });
          if (setShow) setShow(false);
        })
        .catch((error) => {
          console.log(error.response);
        });

      // ajaxOrderDetailsPut(putOrderDetails, orderDetailsId, setShow);
    }
  };

  const handleOrderReference = (setShow) => {
    if (orderReference === '' || orderReference === null) {
      toast.error(`Please enter Order reference.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDetails = {
        references: orderReference,
        order: orderId,
      };
      const putOrderDetails = {
        references: orderReference,
      };
      if (orderDetails === null) {
        ajaxOrderDetailsPost(postOrderDetails, setShow);
        // console.log(postOrderDetails);
      } else {
        ajaxOrderDetailsPut(putOrderDetails, orderDetailsId, setShow);
        // console.log(putOrderDetails);
      }
    }
  };

  const handleOrderPersona = () => {
    if (orderPersona === '' || orderPersona === null) {
      toast.error(`Please select Order reference.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDetails = {
        persona: orderPersona,
        order: orderId,
      };
      const putOrderDetails = {
        persona: orderPersona,
      };
      if (orderDetails === null) {
        ajaxOrderDetailsPost(postOrderDetails);
        // console.log(postOrderDetails);
      } else {
        ajaxOrderDetailsPut(putOrderDetails, orderDetailsId);
        // console.log(putOrderDetails);
      }
    }
  };

  const putOrderPaymentStatus = (data, id, setShow) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-detail/${id}/`, data)
      .then((resp) => {
        // console.log(resp.data);
        // if (resp.data.payment_status === 'paid') {
        //   ajaxOrderDetailsPut({ order_status: 'completed' }, id, setShow);
        // }
        getSingleOrderTree(resp.data.order);
        // mainOrder.orderdetail = resp.data;
        // toast.success(`Order status saved sucessfully.`);
        setShowEditModal(false);
        fetchOrderStatus();
        setShowPopover(false);
        if (setShow) setShow(false);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        if (setShow) setShow(false);
      });
  };

  const handleOrderPayStatus = (setShow, pStat) => {
    const pStatus = pStat || orderPaymentStatus;
    if (pStatus === '' || pStatus === null) {
      toast.error(`Please select payment status.`, {
        autoClose: 3000,
      });
    } else if (pStatus === 'paid' && (paymentNote === '' || paymentNote === null)) {
      toast.error(`Please write payment note.`, {
        autoClose: 3000,
      });
    } else {

      const postOrderDetails = {
        payment_status: pStatus,
        order: orderId,
        payment_note: paymentNote,
      };
      const putOrderDetails = {
        payment_status: pStatus,
        payment_note: paymentNote,
      };
      if (orderDetails === null) {
        ajaxOrderDetailsPost(postOrderDetails, setShow);
        // console.log(postOrderDetails);
      } else {
        putOrderPaymentStatus(putOrderDetails, orderDetailsId, setShow);
        // console.log(putOrderDetails);
      }
    }
  };

  const handlePaymentNote = (setShow) => {
    if (typeof paymentNote !== 'string') {
      toast.error(`Please enter valid payment note.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDetails = {
        order: orderId,
        payment_note: paymentNote,
      };
      const putOrderDetails = {
        payment_note: paymentNote,
      };
      if (orderDetails === null) {
        ajaxOrderDetailsPost(postOrderDetails, setShow);
        // console.log(postOrderDetails);
      } else {
        putOrderPaymentStatus(putOrderDetails, orderDetailsId, setShow);
        // console.log(putOrderDetails);
      }
    }
  };

  const handleOrderDelCopy = (setShow, ordrDelCopy) => {
    const orderDelCopy = ordrDelCopy || orderDeliveryCopy;
    if (orderDelCopy === '' || orderDelCopy === null) {
      toast.error(`Please select Order Delivery Copy.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDetails = {
        delivery_copy_status: orderDelCopy,
        order: orderId,
      };
      const putOrderDetails = {
        delivery_copy_status: orderDelCopy,
      };
      if (orderDetails === null) {
        ajaxOrderDetailsPost(postOrderDetails, setShow);
        // console.log(postOrderDetails);
      } else {
        ajaxOrderDetailsPut(putOrderDetails, orderDetailsId, setShow);
        // console.log(putOrderDetails);
      }
    }
  };

  const handleOrderNote = (setShow) => {
    if (orderNote === '' || orderNote === null) {
      toast.error(`Please select Order status.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDetails = {
        order_note: orderNote,
        order: orderId,
      };
      const putOrderDetails = {
        order_note: orderNote,
      };
      if (orderDetails === null) {
        ajaxOrderDetailsPost(postOrderDetails, setShow);
        // console.log(postOrderDetails);
      } else {
        ajaxOrderDetailsPut(putOrderDetails, orderDetailsId, setShow);
        // console.log(putOrderDetails);
      }
    }
  };

  const handleEstimateDelivery = (setShow) => {
    if (edDate === '' || edDate === null) {
      toast.error(`Please select estimate delivery date.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDetails = {
        estimate_date: moment(edDate).format('YYYY-MM-DD'),
        order: orderId,
      };
      const putOrderDetails = {
        estimate_date: moment(edDate).format('YYYY-MM-DD'),
      };
      if (orderDetails === null) {
        ajaxOrderDetailsPost(postOrderDetails, setShow);
        // console.log(postOrderDetails);
      } else {
        ajaxOrderDetailsPut(putOrderDetails, orderDetailsId, setShow);
        // console.log(putOrderDetails);
      }
    }
  };

  const ajaxOrderDiscountPost = (postDate, setShow) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-discount/`, postDate)
      .then((resp) => {
        const putOrder = {
          collection_fee: orderCollection(orderDiscountSource),
          material_fee: orderMaterial(orderDiscountSource),
        };

        const order_id = resp.data.order;

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/order/order-only/${order_id}/`,
            putOrder
          )
          .then((resp) => {
            getSingleOrderTree(order_id);
            // mainOrder.orderdiscount = resp.data;
            toast.success(`Order discount saved sucessfully.`, {
              autoClose: 3000,
            });
            setShowEditModal(false);
            setShowPopover(false);
            if (setShow) setShow(false);
          })
          .catch((error) => {
            console.log(error.response);
          });
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        if (setShow) setShow(false);
      });
  };

  const ajaxOrderDiscountPut = (postDate, id, setShow) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/order-discount/${id}/`,
        postDate
      )
      .then((resp) => {
        // console.log(resp.data);
        const putOrder = {
          collection_fee: orderCollection(orderDiscountSource),
          material_fee: orderMaterial(orderDiscountSource),
        };

        const order_id = resp.data.order;

        // console.log(putOrder);

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/order/order-only/${order_id}/`,
            putOrder
          )
          .then((resp) => {
            // console.log(resp.data);
            getSingleOrderTree(order_id);
            // mainOrder.orderdiscount.push(resp.data);
            toast.success(`Order discount saved sucessfully.`, {
              autoClose: 3000,
            });
            setShowEditModal(false);
            setShowPopover(false);
            if (setShow) setShow(false);
          })
          .catch((error) => {
            console.log(error.response);
          });

        // console.log(orderId);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        if (setShow) setShow(false);
      });
  };

  const orderCollection = (source) => {
    if (
      source === 'fee' ||
      source === 'fee_discount' ||
      source === 'collection_fee'
    ) {
      const mainColl = fees.collection_fee - parseInt(orderDiscountColl);
      return mainColl;
    } else {
      return fees.collection_fee;
    }
  };

  const orderMaterial = (source) => {
    const patts = mainOrder.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);

    if (
      source === 'fee' ||
      source === 'fee_discount' ||
      source === 'material_fee'
    ) {
      return fees.meterial_fee * patts.length - orderDiscountMate;
    } else {
      return fees.meterial_fee * patts.length;
    }
  };

  const orderItemMaterial = (source) => {
    const patts = mainOrder.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);

    if (
      source === 'fee' ||
      source === 'fee_discount' ||
      source === 'material_fee'
    ) {
      const main = fees.meterial_fee * patts.length - orderDiscountMate;
      return Math.round(main / patts.length);
    } else {
      return fees.meterial_fee;
    }
  };

  const handleOrderDiscount = (setShow) => {
    if (orderDiscountSource === '' || orderDiscountSource === null) {
      toast.error(`Please enter discount source.`, {
        autoClose: 3000,
      });
    } else if (orderDiscountPrice === '' || orderDiscountPrice === null) {
      toast.error(`Please enter discount price.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDiscount = {
        order: orderId,
        discount_note: orderDiscountNote,
        discount_price: parseInt(orderDiscountPrice),
        discount_by: user_details.id,
        discount_source: orderDiscountSource,
        material_discount_price: orderDiscountMate,
        collection_discount_price: orderDiscountColl,
      };
      const putOrderDiscount = {
        discount_note: orderDiscountNote,
        discount_price: parseInt(orderDiscountPrice),
        discount_by: user_details.id,
        discount_source: orderDiscountSource,
        material_discount_price: orderDiscountMate,
        collection_discount_price: orderDiscountColl,
      };

      orderItemsId.forEach((single) => {
        updateOrderItem(single);
      });

      if (orderDiscount === null) {
        ajaxOrderDiscountPost(postOrderDiscount, setShow);
        // console.log(postOrderDiscount);
      } else {
        ajaxOrderDiscountPut(putOrderDiscount, orderDiscountId, setShow);
        // console.log(putOrderDiscount);
      }
    }
  };

  const updateOrderItem = (id) => {
    const putData = {
      meterial_fee: orderItemMaterial(orderDiscountSource),
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/order-item-get/${id}/`,
        putData
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  const handleReportDelivery = (setShow, rDeliv) => {
    const rDelivery = rDeliv || reportDelivery;
    console.log(rDelivery);
    if (rDelivery === '' || rDelivery === null) {
      toast.error(`Please select service status.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDelivery = {
        order: orderId,
        name: rDelivery,
        created_by: user_details.id,
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/order/order-delivery-status/`,
          postOrderDelivery
        )
        .then((resp) => {
          // console.log(resp.data);
          // mainOrder.orderdelivery.push(resp.data);
          getSingleOrderTree(orderId);
          toast.success(`Service status saved sucessfully.`, {
            autoClose: 3000,
          });
          setShowEditModal(false);
          setReportDelivery(rDelivery);
          setShowPopover(false);
          setShow(false);
        })
        .catch((error) => {
          console.log(error.response);
          toast.error(`Something went wrong, plase try again later.`, {
            autoClose: 3000,
          });
          setShowEditModal(false);
          setShow(false);
        });

      // const putOrderDelivery = {
      //   name: rDelivery,
      // };
      // if (orderDelivery.length === 0) {
      //   // ajaxOrderDiscountPost(postOrderDiscount);
      //   console.log(postOrderDelivery);
      // } else {
      //   // ajaxOrderDiscountPut(putOrderDiscount, orderDiscountId);
      //   console.log(putOrderDelivery);
      // }
    }
  };

  const postSoftCopy = (data, setShow) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-delivery-status/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        mainOrder.orderreportdelivery.push(resp.data);
        toast.success(`Report delivery status saved sucessfully. lllllllllllllllllllllllll`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        setReportDeliveryAfterDescSoft('');
        setReportDeliveryAfterSoft('');
        setReportDeliverySoftDate(null);
        setShowPopover(false);
        setShow(false);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        setShow(false);
      });
  };






  const handleReportDeliveryAfterSoft = (setShow) => {
    if (reportDeliveryAfterSoft === '' || reportDeliveryAfterSoft === null) {
      toast.error(`Please select after delivery status.`, {
        autoClose: 3000,
      });
    } else {
      if (reportDeliveryAfterSoft === 'soft_copy_due') {
        if (reportDeliverySoftDate === '' || reportDeliverySoftDate === null) {
          toast.error(`Please select due date.`);
          return;
        } else {
          const date = reportDeliverySoftDate
            ? moment(reportDeliverySoftDate).format('YYYY-MM-DD')
            : null;

          const time = reportDeliverySoftDate
            ? moment(reportDeliverySoftDate).format('HH:mm')
            : null;

          const postOrderDeliveryAfter = {
            order: orderId,
            name: reportDeliveryAfterSoft,
            created_by: user_details.id,
            note: reportDeliveryAfterDescSoft,
            due_date: reportDeliverySoftDate ? date + 'T' + time : null,
          };

          postSoftCopy(postOrderDeliveryAfter, setShow);
        }
      } else {
        const postOrderDeliveryAfter = {
          order: orderId,
          name: reportDeliveryAfterSoft,
          created_by: user_details.id,
          note: reportDeliveryAfterDescSoft,
        };

        postSoftCopy(postOrderDeliveryAfter, setShow);
      }

      // console.log(postOrderDeliveryAfter);
    }
  };

  const postHardCopy = (data, setShow) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-hardcopy-delivery-status/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        mainOrder.orderreporthardcopydelivery.push(resp.data);
        toast.success(`Report delivery status saved sucessfully.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        setReportDeliveryAfterHard('');
        setReportDeliveryAfterDescHard('');
        setReportDeliveryHardDate(null);
        setShowPopover(false);
        setShow(false);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
        setShowEditModal(false);
        setShow(false);
      });
  };

  const handleReportDeliveryAfterHard = (setShow) => {
    if (reportDeliveryAfterHard === '' || reportDeliveryAfterHard === null) {
      toast.error(`Please select after delivery status.`, {
        autoClose: 3000,
      });
    } else {
      if (reportDeliveryAfterHard === 'hard_copy_due') {
        if (reportDeliveryHardDate === '' || reportDeliveryHardDate === null) {
          toast.error(`Please select due date.`);
          return;
        } else {
          const date = reportDeliveryHardDate
            ? moment(reportDeliveryHardDate).format('YYYY-MM-DD')
            : null;

          const time = reportDeliveryHardDate
            ? moment(reportDeliveryHardDate).format('HH:mm')
            : null;

          const postOrderDeliveryAfter = {
            order: orderId,
            name: reportDeliveryAfterHard,
            created_by: user_details.id,
            note: reportDeliveryAfterDescHard,
            due_date: reportDeliveryHardDate ? date + 'T' + time : null,
          };

          postHardCopy(postOrderDeliveryAfter, setShow);
        }
      } else {
        const postOrderDeliveryAfter = {
          order: orderId,
          name: reportDeliveryAfterHard,
          created_by: user_details.id,
          note: reportDeliveryAfterDescHard,
        };

        postHardCopy(postOrderDeliveryAfter, setShow);
      }
    }
  };

  const handleOrItDlt = (item) => {
    const orderId = item.order.id;
    // console.log(orderId);
    // orderItemGet(orderId);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/order/order-item/${item.id}/`)
      .then((resp) => {
        // console.log(resp.data);
        // getSingleOrderTree(orderId);
        orderItemGet(orderId);
        toast.success(`Test item removed successfully.`, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleTestItemModal = (order) => {
    // console.log(order);
    setAddressId(
      // order.orderitem.length !== 0 && order.orderitem[0].address !== null
      //   ? order.orderitem[0].address.id
      //   : null
      order.orderdetail.address.id || ''
    );
    setOrderEditId(order.id);
    setOrderUserId(order.user.id);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient-with-info/?user=${order.user.id}&limit=300`
      )
      .then((resp) => {
        const results = resp.data.results.filter(
          (patient) => patient.is_active
        );
        // console.log(results);
        setUserPatients(results);
        setItemEditModal(true);
      });
  };

  const orderItemGet = (orderEditId) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-item-get/?order=${orderEditId}&limit=100`
      )
      .then((resp) => {
        const items = resp.data.results;
        // console.log(items);
        const totalPrice = items.reduce((total, item) => {
          return (
            total + parseInt(item.purchasable_order_item.sell_price)
            // fees.meterial_fee
          );
        }, 0);
        // console.log(totalPrice);

        const patients = items
          .map((patient) => {
            return patient.patient.id;
          })
          .filter((x, i, a) => a.indexOf(x) === i);
        // console.log(patients.length);

        let subTotal = items[0].meterial_fee * patients.length;
        // subTotal = subTotal + fees.collection_fee;
        // console.log(subTotal);

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/order/order-only/${orderEditId}/`,
            {
              total_price: totalPrice,
              material_fee: subTotal,
            }
          )
          .then((resp) => {
            // console.log(resp.data);
            getSingleOrderTree(orderEditId);
            setItemEditModal(false);
          })
          .catch((error) => {
            console.log(error.response);
          });
      });
  };

  const testItemModalClose = () => {
    // getSingleOrderTree(orderEditId);
    // setItemEditModal(false);
    setTimeout(() => {
      orderItemGet(orderEditId);
      toast.success(`Test item added successfully.`, {
        autoClose: 3000,
      });
    }, 1000);
  };

  const putOrderApproval = (data, id) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/order-approval/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        getSingleOrderTree(resp.data.order);
        toast.success(`Order approved sucessfully.`, {
          autoClose: 3000,
        });
        fetchOrderStatus();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const approveOrder = (order) => {
    // console.log(order.orderapproval.id);
    const postOrdrAproval = {
      is_approved: true,
      approved_by: user_details.id,
    };
    putOrderApproval(postOrdrAproval, order.orderapproval.id);
  };

  const unApproveOrder = (order) => {
    // console.log(order.orderapproval.id);
    const postOrdrAproval = {
      is_approved: false,
      approved_by: user_details.id,
    };
    putOrderApproval(postOrdrAproval, order.orderapproval.id);
    // axios
    //   .delete(
    //     `${process.env.REACT_APP_BASE_URL}/order/order-approval/${order.orderapproval.id}`
    //   )
    //   .then((resp) => {
    //     // console.log(resp.data);
    //     getSingleOrderTree(order.id);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
    // console.log(order.id);
  };

  const generateTicket = (mainOrder) => {
    // console.log(mainOrder);
    setGenerateTicketModal(true);
    handleCpyToClipParient();
  };

  const copyToClipboard = (el) => {
    // console.log(el);
    let body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand('copy');
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      document.execCommand('copy');
    }
  };

  const downloadAsTxt = (mainOrder) => {
    const tableRow = document.querySelectorAll('#copy-table tbody tr td');
    let retContent = [];
    let retString = '';
    let elemText = [];

    tableRow.forEach((elem) => {
      elemText.push(elem.innerText);
    });

    retContent.push(elemText.join('\r\n'));
    retString = retContent.join(' ');

    const element = document.createElement('a');
    const file = new Blob([retString], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `order_${mainOrder.order_id}.txt`;
    document.body.appendChild(element);
    element.click();
  };

  const downloadAsTxtShort = (mainOrder) => {
    const tableRow = document.querySelectorAll('#copy-table-short tbody tr td');
    let retContent = [];
    let retString = '';
    let elemText = [];

    tableRow.forEach((elem) => {
      elemText.push(elem.innerText);
    });

    retContent.push(elemText.join('\r\n'));
    retString = retContent.join(' ');

    const element = document.createElement('a');
    const file = new Blob([retString], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `order_${mainOrder.order_id}_short.txt`;
    document.body.appendChild(element);
    element.click();
  };

  const printTicket = (mainOrder) => {
    // console.log(mainOrder);

    printDiv('#copy-table');
  };

  function printDiv(divName) {
    var printContents = document.querySelector(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

    // var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    // mywindow.document.write(
    //   '<html><head><title>' + document.title + '</title>'
    // );
    // mywindow.document.write('</head><body >');
    // mywindow.document.write('<h1>' + document.title + '</h1>');
    // mywindow.document.write(document.querySelector(divName).innerHTML);
    // mywindow.document.write('</body></html>');

    // mywindow.document.close(); // necessary for IE >= 10
    // mywindow.focus(); // necessary for IE >= 10*/

    // mywindow.print();
    // mywindow.close();

    // return true;
  }

  const handleOrderReport = async (data, id) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-report/${id}/`, data)
      .then((resp) => {
        // console.log(resp.data.order, id);
        getSingleOrderTree(resp.data.order);
        toast.success(`Order report uploaded sucessfully.`, {
          autoClose: 3000,
        });
        setOrderReportFile(null);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOrderReportFile = async (mainOrder) => {
    if (orderReportName === null || orderReportName === '') {
      toast.error(`Please enter order report name.`);
    } else if (reportPatientName === null || reportPatientName === '') {
      toast.error(`Please select a patient.`);
    } else if (!orderReportFile || !orderReportFile.length) {
      toast.error(`Please select a file.`, {
        autoClose: 3000,
      });
    } else if (!orderReportName)
      toast.error(`Please select a file.`, { autoClose: 3000 });
    else {
      const postOrderReport = {
        order: mainOrder.id,
        upload_by: user_details.id,
        name: orderReportName,
        patient: reportPatientName,
      };

      setorderReportName('');
      setreportPatientName('');

      setReportLoading(true);

      for (let i = 0; i < orderReportFile.length; i++) {
        const data = new FormData();
        data.append('report', orderReportFile[i]);

        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/order/order-report/`,
            postOrderReport
          )
          .then(async (resp) => {
            // console.log(resp.data);
            // handleOrderReport(data, resp.data.id);
            await axios
              .put(
                `${process.env.REACT_APP_BASE_URL}/order/order-report/${resp.data.id}/`,
                data
              )
              .then((resp) => {
                getSingleOrderTree(resp.data.order);
                setorderReportName('');
                setreportPatientName('');
              })
              .catch((error) => {
                console.log(error.response);
              });
          })
          .catch((error) => {
            console.log(error.response);
          });
      }

      toast.success(`Order report uploaded sucessfully.`, {
        autoClose: 3000,
      });
      setOrderReportFile(null);
      setReportLoading(false);
      // setReportStatOlay(false);  // when multiple report altogether

      // if (!mainOrder.orderreport) {
      //   // console.log('post data');
      //   axios
      //     .post(
      //       `${process.env.REACT_APP_BASE_URL}/order/order-report/`,
      //       postOrderReport
      //     )
      //     .then((resp) => {
      //       // console.log(resp.data);
      //       handleOrderReport(data, resp.data.id);
      //     })
      //     .catch((error) => {
      //       console.log(error.response);
      //     });
      // } else {
      //   // console.log('put data');
      //   handleOrderReport(data, mainOrder.orderreport.id);
      //   // console.log(mainOrder.orderreport.id);
      // }
    }
  };

  const deleteOrderReport = (report) => {
    // console.log(report);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/order/order-report/${report.id}/`
      )
      .then((resp) => {
        // console.log('deleted', resp.data);
        getSingleOrderTree(report.order.id);
        toast.success(`Order report deleted sucessfully.`, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const putSampleCollection = (id, data, setShow) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-only/${id}/`, data)
      .then((resp) => {
        // console.log(resp.data);
        toast.success('Sample Collection updated successfully.', {
          autoClose: 3000,
        });
        getSingleOrderTree(id);
        // console.log(orderId);
        setShowEditModal(false);
        setSCTimeNew(null);
        setShowPopover(false);
        if (setShow) setShow(false);
      })
      .catch((error) => {
        // console.log(error.response);
        if (setShow) setShow(false);
      });
  };

  const handleSampleCollection = (setShow) => {
    if (scDate === '' || scDate === null) {
      toast.error('Please select a date.', {
        autoClose: 3000,
      });
    } else {
      if (scTimeNew !== '' && scTimeNew !== null) {
        const putOrderTime = {
          date: moment(scDate).format('YYYY-MM-DD'),
          time: moment(scTimeNew).format('HH:mm:ss'),
        };

        putSampleCollection(orderId, putOrderTime, setShow);
      } else {
        const putOrderTime = {
          date: moment(scDate).format('YYYY-MM-DD'),
        };
        putSampleCollection(orderId, putOrderTime, setShow);
      }

      // console.log(putOrderTime, orderId);
    }
  };

  const handlePaymentSourceModal = (order) => {
    // console.log(order);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/payment-status/?order=${order.id}`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        setPaymentSourceDetails(resp.data.results);
        setShowPaymentSourceModal(true);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOrderDisSource = (e) => {
    setOrderDiscountSource(e.target.value);

    let meteFee = 0;
    let collFee =
      mainOrder && mainOrder.collection_fee ? mainOrder.collection_fee : '0';
    let total = 0;

    const patts = mainOrder.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    meteFee =
      mainOrder && mainOrder.orderitem.length !== 0
        ? mainOrder.orderitem[0].meterial_fee * patts.length
        : patts.length * 50;

    if (meteFee === '0' || collFee === '0') {
      total = '0';
    } else {
      total = parseInt(collFee) + parseInt(meteFee);
    }

    // console.log(meteFee, collFee);
    // console.log(total);

    if (e.target.value === 'admin') {
      setOrderDiscountPrice(
        mainOrder.orderdiscount && mainOrder.orderdiscount.discount_price
          ? mainOrder.orderdiscount.discount_price
          : ''
      );
      setOrderDiscountMate(0);
      setOrderDiscountColl(0);
    } else if (e.target.value === 'collection_fee') {
      setOrderDiscountColl(collFee);
      setOrderDiscountPrice(0);
      setOrderDiscountMate(0);
    } else if (e.target.value === 'material_fee') {
      setOrderDiscountMate(meteFee <= 0 ? '0' : meteFee);
      setOrderDiscountPrice(0);
      setOrderDiscountColl(0);
    } else if (e.target.value === 'fee') {
      // setOrderDiscountPrice(total);
      setOrderDiscountColl(collFee);
      setOrderDiscountMate(meteFee <= 0 ? '0' : meteFee);
      setOrderDiscountPrice(0);
    } else if (e.target.value === 'fee_discount') {
      setOrderDiscountPrice(
        // mainOrder.orderdiscount ? mainOrder.orderdiscount.discount_price : 0
        mainOrder.orderdiscount && mainOrder.orderdiscount.discount_price
          ? mainOrder.orderdiscount.discount_price
          : ''
      );
      setOrderDiscountColl(collFee);
      setOrderDiscountMate(meteFee <= 0 ? '0' : meteFee);
    } else {
      setOrderDiscountPrice(
        mainOrder.orderdiscount && mainOrder.orderdiscount.discount_price
          ? mainOrder.orderdiscount.discount_price
          : ''
      );
    }

    // console.log(mainOrder);
  };

  // const [singleDiscountTk, setSingleDiscountTk] = useState(null);
  const [discountPercentClipboard, setDiscountPercentClipboard] =
    useState(null);

  const calculateDiscountForClipboard = (order) => {
    if (order.orderdiscount) {
      // console.log(parseInt(order.total_price));
      // console.log(parseInt(order.orderdiscount.discount_price));
      const discountPercentage =
        (parseInt(order.orderdiscount.discount_price) /
          parseInt(order.total_price)) *
        100;
      // console.log(discountPercentage);
      setDiscountPercentClipboard(discountPercentage);
      // const singleDiscount =
      //   parseInt(order.orderdiscount.discount_price) / order.orderitem.length;
      // setSingleDiscountTk(singleDiscount);
      // console.log(singleDiscount);
    }
  };

  const [CtCPatient, setCtCPatient] = useState([]);
  const handleCpyToClipParient = () => {
    const itemArray = mainOrder.orderitem;
    const patientDetails = itemArray.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    // console.log(itemArray);
    // console.log(filteredPatient);
    let patientOrders = [];

    filteredPatient.forEach((patient) => {
      const result = itemArray.filter((o1) => {
        return o1.patient.id === patient.id;
      });
      const newArray = {
        patient: patient,
        testItem: result,
      };
      patientOrders.push(newArray);
      // console.log(newArray);
    });
    patientOrders.sort((p) => (p.patient.is_account ? -1 : 1));
    setCtCPatient(patientOrders);
    // console.log(patientOrders);

    calculateDiscountForClipboard(mainOrder);
  };

  const [showPopover, setShowPopover] = useState(false);

  const [orderStatusOptions] = useState([
    {
      name: 'Select order status',
      label: 'Select order status',
      value: '',
      id: '',
    },
    {
      name: 'Pending',
      label: 'Pending',
      value: 'pending',
      id: 'pending',
    },
    {
      name: 'Waiting for Confirmation',
      label: 'Waiting for Confirmation',
      value: 'waiting_for_confirmation',
      id: 'waiting_for_confirmation',
    },
    {
      name: 'Service on Hold',
      label: 'Service on Hold',
      value: 'service_on_hold',
      id: 'service_on_hold',
    },
    {
      name: 'Confirmed',
      label: 'Confirmed',
      value: 'confirmed',
      id: 'confirmed',
    },
    {
      name: 'Cancel',
      label: 'Cancel',
      value: 'cancel',
      id: 'cancel',
    },
    {
      name: 'Completed',
      label: 'Completed',
      value: 'completed',
      id: 'completed',
    },
  ]);

  const [orderTypeOptions] = useState([
    {
      name: 'Select order type',
      id: '',
    },
    {
      name: 'Covid',
      id: 'covid',
    },
    {
      name: 'Non Covid',
      id: 'non_covid',
    },
  ]);

  const [orderDeilveryCopy] = useState([
    {
      name: 'Select order delivery copy',
      id: '',
    },
    {
      name: 'Soft Copy',
      id: 'soft',
    },
    {
      name: 'Hard Copy',
      id: 'hard',
    },
    {
      name: 'Both',
      id: 'both',
    },
  ]);

  const [orderServiceStatus] = useState([
    {
      name: 'Select service status',
      id: '',
    },
    {
      name: 'Pending',
      id: 'pending',
    },
    {
      name: 'MT Order Received',
      id: 'order_received',
    },
    {
      name: 'Sample Collected',
      id: 'sample_collected',
    },
    {
      name: 'Sample Delivered to Lab',
      id: 'sample_delivered_to_lab',
    },
  ]);

  const [reportDeliveryOptionsSoft] = useState([
    {
      name: 'Select report delivery status',
      id: '',
    },
    {
      name: 'Soft copy due',
      id: 'soft_copy_due',
    },
    {
      name: 'Soft copy sent',
      id: 'soft_copy_sent',
    },
    {
      name: 'No soft copy',
      id: 'no_soft_copy',
    },
  ]);

  const [reportDeliveryOptionsHard] = useState([
    {
      name: 'Select report delivery status',
      id: '',
    },
    {
      name: 'Hard copy due',
      id: 'hard_copy_due',
    },
    {
      name: 'Hard copy sent',
      id: 'hard_copy_sent',
    },
    {
      name: 'No hard copy',
      id: 'no_hard_copy',
    },
  ]);

  const [paymentStatusOptions] = useState([
    {
      name: 'Select Payment Status',
      id: '',
    },
    {
      name: 'Paid',
      id: 'paid',
    },
    {
      name: 'Unpaid',
      id: 'unpaid',
    },
  ]);

  const [districtOptions] = useState([
    {
      name: 'Select district',
      id: '',
    },
    {
      name: 'Dhaka',
      id: 'Dhaka',
    },
    // {
    //   name: 'Chattogram',
    //   id: 'Chattogram',
    // },
  ]);

  const [dicountSouOpt] = useState([
    {
      name: 'Select discount source',
      id: '',
    },
    {
      name: 'Custom',
      id: 'admin',
    },
    {
      name: 'Collection Fee',
      id: 'collection_fee',
    },
    {
      name: 'Material Fee',
      id: 'material_fee',
    },
    {
      name: 'Fee (Collection + Material)',
      id: 'fee',
    },
    {
      name: 'Fee (Collection + Material + Custom)',
      id: 'fee_discount',
    },
  ]);

  const handleIClick = (order) => {
    // console.log(order);
    setShowPopover(!showPopover);
    setAddress(
      order.orderdetail && order.orderdetail.address
        ? (order.orderdetail.address.id || null)
        : null
    );

    // console.log(order);

    const item_ids = order.orderitem.map((item) => item.id);
    setOrderItemsId(item_ids);

    setOrderId(order.id);
    setOrderDetailsId(order.orderdetail ? order.orderdetail.id : null);
    setOrderDiscountId(order.orderdiscount ? order.orderdiscount.id : null);
    // console.log(name);
    // setShownItemName(name);
    // setOrderDelivery(order.orderdelivery);
    setOrderDetails(order.orderdetail);
    setOrderDiscount(order.orderdiscount);
  };

  const getAge = (dob) => {
    const year = moment().diff(`${dob}`, 'years');

    if (0 < year) {
      return year + ' Years';
    } else {
      const month = moment().diff(`${dob}`, 'months');
      return month + ' Months';
    }
  };

  const fetchOrderPatients = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-patient/${id}`)
      .then((resp) => {
        const l = [];
        const ptns = resp.data.patient.filter((ptn) => {
          if (!l.includes(ptn.id)) {
            l.push(ptn.id);
            return true;
          }
          return false;
        });


        setOrderAllPatients(ptns);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const [specTabPatients, setSpecTabPatients] = useState([]);
  const tablePatients = () => {
    const itemArray = mainOrder.orderitem;
    const patientDetails = itemArray.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    // console.log(filteredPatient);
    // return filteredPatient;
    setSpecTabPatients(filteredPatient);
  };

  const handleOrderAchieve = (e) => {
    setIsAchieved(!IsAchieved);
    // console.log(e.target.value);
    // console.log(mainOrder.id);

    setTimeout(() => {
      const putDate = {
        is_archived: !IsAchieved,
      };

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/order/order-only/${mainOrder.id}/`,
          putDate
        )
        .then((resp) => {
          // console.log(resp.data);
          if (resp.data.is_archived) {
            toast.warning(`Achieved sucessfully.`);
            refreshPage();
          } else {
            toast.success(`Unachieved sucessfully.`);
          }
          getSingleOrderTree(mainOrder.id);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }, 100);
  };


  // now soft
  const handleSoftTitle = (title) => {
    if (title === 'soft_copy_due') return 'Soft Due';
    else if (title === 'soft_copy_sent') return 'Soft Sent';
    else if (title === 'no_soft_copy') return 'No Copy';
    else return null;
  };


  const handleSoftLatestTitle = (arr) => {

  // Sort the array by id in descending order
    const sortedArray = arr.slice().sort((a, b) => b.id - a.id);
    const title = sortedArray.length > 0 ? sortedArray[0].name : null;

    if (title === 'soft_copy_due') return 'Soft Due';
    else if (title === 'soft_copy_sent') return 'Soft Sent';
    else if (title === 'no_soft_copy') return 'No Copy';
    else return null;
  };




  const handleSoftLatestDueDate = (arr) => {

  // Sort the array by id in descending order
    const sortedArray = arr.slice().sort((a, b) => b.id - a.id);
    const title = sortedArray.length > 0 ? sortedArray[0].due_date : null;

    if (title) return moment(title).format('DD MMM; h:mm a');
    else return "Date note available";
  };



  const handleSoftLatestNote = (arr) => {

  // Sort the array by id in descending order
    const sortedArray = arr.slice().sort((a, b) => b.id - a.id);
    const title = sortedArray.length > 0 ? sortedArray[0].note : null;

    if (title ) return title;
    else return null;
  };


  // now hard

  const handleHardTitle = (title) => {
    if (title === 'hard_copy_due') return 'Hard Due';
    else if (title === 'hard_copy_sent') return 'Hard Sent';
    else if (title === 'no_hard_copy') return 'No Copy';
    else return null;
  };

  const handleHardLatestTitle = (arr) => {

  // Sort the array by id in descending order
    const sortedArray = arr.slice().sort((a, b) => b.id - a.id);
    const title = sortedArray.length > 0 ? sortedArray[0].name : null;

    if (title === 'hard_copy_due') return 'Hard Due';
    else if (title === 'hard_copy_sent') return 'Hard Sent';
    else if (title === 'no_hard_copy') return 'No Copy';
    else return null;
  };

  const handleHardLatestDueDate = (arr) => {

  // Sort the array by id in descending order
    const sortedArray = arr.slice().sort((a, b) => b.id - a.id);
    const title = sortedArray.length > 0 ? sortedArray[0].due_date : null;

    if (title) return moment(title).format('DD MMM; h:mm a');
    else return "Date note available";
  };

  const handleHardLatestNote = (arr) => {
  // Sort the array by id in descending order
    const sortedArray = arr.slice().sort((a, b) => b.id - a.id);
    const title = sortedArray.length > 0 ? sortedArray[0].note : null;

    if (title ) return title;
    else return null;
  };









  const asciiCode = (user) => {
    try {
      const userPhone = user.username.slice(2, 4); // phone number 2 digit
      const phnLst = user.username.slice(-1); // last character of phone number
      const firstNameLast = user.first_name.slice(-1); // last character of firstname
      const firstNameFirst = user.first_name.charAt(0); // first character of firstname
      return (
        userPhone +
        '0zV1' +
        phnLst.charCodeAt(0).toString(16) +
        '2Qj27' +
        firstNameLast.charCodeAt(0).toString(16) +
        '90pK4' +
        firstNameFirst.charCodeAt(0)
      );
    } catch (error) {
      console.log(error);
      console.log(`There is no user in #${mainOrder.order_id}`);
    }
  };

  const noteLastItem = (order) => {
    if (order.ordernote.length !== 0) {
      return order.ordernote.at(-1).marked ? 'row-highlight' : '';
    }
  };

  const externalUserName = (order) => {
    if (order.external_source_user) {
      if (order.external_source_user.first_name) {
        return `Partner: ${order.external_source_user.first_name} ${order.external_source_user.last_name}`;
      } else {
        return `Partner: ${order.external_source_user.username}`;
      }
    }
  };

  const orderLabName = (order) => {
    if (order.orderitem.length !== 0) {
      if (order.orderitem[0].purchasable_order_item) {
        if (order.orderitem[0].purchasable_order_item.testitem_purchasable_oi) {
          if (
            order.orderitem[0].purchasable_order_item.testitem_purchasable_oi
              .branch
          ) {
            if (
              order.orderitem[0].purchasable_order_item.testitem_purchasable_oi
                .branch.lab
            ) {
              if (
                order.orderitem[0].purchasable_order_item
                  .testitem_purchasable_oi.branch.lab.name
              ) {
                return order.orderitem[0].purchasable_order_item
                  .testitem_purchasable_oi.branch.lab.name;
              }
            }
          }
        }
      }
    } else {
      return '-';
    }
  };

  const [shownTicket, setShownTicket] = useState('mt-copy');

  const smsApiCall = (orderId, status) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/sms-order-status/${orderId}/${status}`)
      .then((resp) => {
        // console.log('come success', resp.data);
      })
      .catch((error)=> {
        // console.log('come err', error.response);
      })
  }

  return (
    <>
      <tr
        className={`h_px ${mainOrder.ordercampaign !== null ? 'locked' : ''
          } ${noteLastItem(mainOrder)}`}
      >
        {/* col: 1 */}
        <td className='ps-3 h_px_td'>
          <div className='d-flex flex-column'>
            <div className='fw-bold text-dark d-flex flex-row align-items-center'>
              #{mainOrder.order_id}
              {mtAccess && (
                <div
                  className='px-1 border border-0 rounded d-flex align-items-center ms-1 pointer'
                  onClick={() => generateTicket(mainOrder)}
                >
                  <img
                    src={SvgTicket}
                    alt=''
                    style={{ width: '23px', height: '15px' }}
                  />
                  {/* <i className='fas fa-ticket-alt pointer' style={{margin: '1px 2px', color:'#FFFD'}}></i> */}
                </div>
              )}
            </div>
            <div className='text-dim-dark'>
              {moment(mainOrder.created_at).format('DD MMM YYYY')}
            </div>
            <div className='text-dim-dark'>
              {moment(mainOrder.created_at).format('hh:mm A')}
            </div>
            <div className='text-primary'>{externalUserName(mainOrder)}</div>
            {/* <div className='d-flex'>
              <span className='fw-bold text-dark'>Ref: </span> BL007
              <TextInputPopover
                placeholder='Enrer Reference'
                title='Enter Reference'
                value={orderReference}
                setValue={setOrderReference}
                handleSave={handleOrderReference}
                handleIClick={() => handleIClick(mainOrder)}
                placement="bottom"
                iconStyle={{ opacity: 0.4 }}
              />

            </div> */}
            <div className='d-flex'>
              {/* <div style={{ textTransform: 'capitalize', padding: '4px 12px' }}
                className={`fs-8 my-2 border border-0 rounded ${mainOrder.orderdetail.order_status === 'pending' ||
                  mainOrder.orderdetail.order_status === 'waiting_for_confirmation' ||
                  mainOrder.orderdetail.order_status === 'service_on_hold' ?
                  'text-warning bg-dim-warning' : (
                    mainOrder.orderdetail.order_status === 'cancel'
                      ? 'text-danger bg-dim-danger'
                      : 'text-purple bg-dim-purple'
                  )
                  }`}
              >
                {adjustCapitalize(mainOrder.orderdetail.order_status.split('_').join(' '))}
              </div> */}
              {allAccess && mainOrder.ordercampaign === null && (
                <SelectPopover
                  title='Select Order Status'
                  value={orderStatus}
                  setValue={setOrderStatus}
                  handleSave={handleOrderStatus}
                  options={orderStatusOptions}
                  handleIClick={() => handleIClick(mainOrder)}
                  isButton={true}
                  selectedName={
                    orderStatus &&
                    adjustCapitalize(orderStatus.split('_').join(' '))
                  }
                  textArea={orderStatus === 'cancel' ? true : false}
                  textAreaPlaceholder={'enter cancel note'}
                  textAreaValue={cancelNote}
                  setTextAreaValue={setCancelNote}
                  selectedClassName={
                    !orderStatus ||
                      orderStatus === 'pending' ||
                      orderStatus === 'waiting_for_confirmation' ||
                      orderStatus === 'service_on_hold'
                      ? 'text-warning bg-dim-warning'
                      : orderStatus === 'cancel'
                        ? 'text-danger bg-dim-danger'
                        : orderStatus === 'confirmed'
                          ? 'text-purple bg-dim-purple'
                          : 'text-green bg-dim-green'
                  }
                />
              )}
              {/* <SelectPopoverNew
                title='Select Order Status'
                placeholder='Order Status'
                value={orderStatus}
                setValue={setOrderStatus}
                handleSave={handleOrderStatus}
                options={orderStatusOptions}
                handleIClick={() => handleIClick(mainOrder)}
                mainOrder={mainOrder}
                adjustCapitalize={adjustCapitalize}
                placement='bottom-start'
                selectedName={orderStatus && adjustCapitalize(orderStatus.split('_').join(' '))}
                selectedClassName={!orderStatus ||
                  orderStatus === 'pending' ||
                  orderStatus === 'waiting_for_confirmation' ||
                  orderStatus === 'service_on_hold' ?
                  'text-warning bg-dim-warning' : (
                    orderStatus === 'cancel'
                      ? 'text-danger bg-dim-danger'
                      : (orderStatus === 'confirmed'
                        ? 'text-purple bg-dim-purple'
                        : 'text-green bg-dim-green'
                      )
                  )
                }
                popOverId='order-stat-drop'
                editAccess={allAccess && mainOrder.ordercampaign === null}
              /> */}
            </div>
            {mainOrder && mainOrder.orderdetail && mainOrder.orderdetail.order_status ==='cancel' && (
                <p className='mb-0 text-danger'>Note: {mainOrder.orderdetail.status_note}</p>
              )}
            {mainOrder.is_archived && (
              <>
                <div className='d-flex'>
                  <div className='text-capitalize text-center fs-8 mt-2 border border-0 rounded text-primary bg-dim-primary status-btn'>
                    {mainOrder.orderdetail.order_status}
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='text-capitalize text-center fs-8 mt-2 border border-0 rounded text-danger bg-dim-purple status-btn'>
                    Achieved
                  </div>
                </div>
              </>
            )}
            {mainOrder.ordercampaign !== null && (
              <div
                style={{ width: 'fit-content' }}
                onClick={() => setShowCampainModal(true)}
                className='text-capitalize text-center fs-8 mt-2 border border-0 rounded text-info bg-dim-purple status-btn pointer'
              >
                Campain
              </div>
            )}
          </div>
        </td>

        {/* col: 2 */}
        <td className='ps-3 h_px_td'>
          <div className='d-flex flex-column'>
            {/* <div className='fw-bold text-dark'>{mainOrder.user.username}</div> */}
            {mainOrder.orderitem.length !== 0
              ? specTabPatients.map((item, i) => (
                <div key={i}>
                  {item.mobile && (
                    <div className='fw-bold text-dark'>{item.mobile}</div>
                  )}
                  <div
                    style={{ whiteSpace: 'break-spaces' }}
                    className='text-dim-dark'
                  >
                    {item.full_name} ({item.sex.substring(0, 1).toUpperCase()}
                    {item.dob === null
                      ? ''
                      : `, ${moment().diff(`${item.dob}`, 'years') + 'y'}`}
                    )
                  </div>
                </div>
              ))
              : ''}
            <div
              className='text-dark pointer'
              onClick={() => {
                history.push(`/order-edit/${mainOrder.id}#order-summery-nav`);
              }}
            >
              <u>
                {orderLabName(mainOrder)}
                {/* {mainOrder.orderitem.length !== 0 &&
                  mainOrder.orderitem[0].purchasable_order_item
                    .testitem_purchasable_oi.branch &&
                  mainOrder.orderitem[0].purchasable_order_item
                    .testitem_purchasable_oi.branch.lab.name} */}
              </u>
            </div>
          </div>
        </td>

        {/* col: 3 */}
        <td className='ps-3 h_px_td'>
          <div className='d-flex flex-column'>
            <div className='fw-bold text-dark d-flex justify-content-between'>
              {moment(mainOrder.date).format('DD MMM YYYY')}
              {mainOrder.time
                ? `; ${moment(mainOrder.time, ['HH:mm:ss']).format(
                  'hh:mm'
                )} - ${moment(mainOrder.time, 'HH:mm:ss')
                  .add(120, 'minutes')
                  .format('hh:mm a')}`
                : ''}
              {sampleCollAccess && mainOrder.ordercampaign === null && (
                <DateTimePopover
                  title='Sample Collection Date & Time'
                  date={scDate}
                  setDate={setSCDate}
                  time={scTimeNew}
                  setTime={setSCTimeNew}
                  showTime={true}
                  handleSave={handleSampleCollection}
                  handleIClick={() => handleIClick(mainOrder)}
                  staticIcon={false}
                  iconStyle={{ opacity: 0.4 }}
                />
              )}
            </div>
            {/* <div className='fw-bold text-dark'>{mainOrder.time ?
              `${moment(mainOrder.time, ['HH:mm:ss']).format('hh:mm A')} - ${moment(mainOrder.time, 'HH:mm:ss').add(60, 'minutes').format('hh:mm A')}` : ''}
            </div> */}
            {mainOrder.orderdetail && (
              <div className=' d-flex mt-1 justify-content-between'>
                <AddressPopover
                  title='Sample Collection Address'
                  address={
                    mainOrder.orderdetail.address
                      ? (mainOrder.orderdetail.address.address || '')
                      : ''
                  }
                  area={
                      mainOrder && mainOrder.orderdetail && mainOrder.orderdetail.address
                      ? adjustCapitalize(mainOrder.orderdetail.address.thana || '')
                      : mainOrder && mainOrder.orderitem && mainOrder.orderitem[0] &&  mainOrder.orderitem[0].address && mainOrder.orderitem[0].address.thana ?     adjustCapitalize(mainOrder.orderitem[0].address.thana || '') : ''
                  }
                  city={
                    mainOrder.orderdetail.address
                      ? (mainOrder.orderdetail.address.district || '')
                      : mainOrder && mainOrder.orderitem && mainOrder.orderitem[0] && mainOrder.orderitem[0].address &&  mainOrder.orderitem[0].address.district ? (mainOrder.orderitem[0].address.district || '') : ''
                  }
                  handleIClick={() => { }}
                />
                {addressAccess && mainOrder.ordercampaign === null && (
                  <SelAreaInpPop
                    title='Order Location Details'
                    value={orderAddressDistrict}
                    setValue={(value) => {
                      setOrderAddressDistrict(value);
                      setOrderAddressThana('');
                    }}
                    options={districtOptions}
                    thanaOptions={
                      orderAddressDistrict === 'Dhaka' ? dhakaArea : ctgArea
                    }
                    handleIClick={() => handleIClick(mainOrder)}
                    textArea={orderAddressAddress}
                    setTextArea={setOrderAddressAddress}
                    input={orderAddressThana}
                    setInput={setOrderAddressThana}
                    thana={orderAddressThana}
                    setThana={setOrderAddressThana}
                    handleSave={handleArea}
                    staticIcon={false}
                    iconStyle={{ opacity: 0.4 }}
                    handleOuterCancel={() => {
                      setOrderAddressDistrict(
                        mainOrder.orderdetail.address
                          ? (mainOrder.orderdetail.address.district || '')
                          : (mainOrder.orderitem[0].address.district || '')
                      );
                      setOrderAddressThana(
                        mainOrder.orderdetail.address
                          ? (mainOrder.orderdetail.address.thana || '')
                          : (mainOrder.orderitem[0].address.thana || '')
                      );
                      setOrderAddressAddress(
                        mainOrder.orderdetail && mainOrder.orderdetail.address
                          ? (mainOrder.orderdetail.address.address || '')
                          : ''
                      );
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </td>

        {/* col: 4 */}
        <td className='ps-3 h_px_td'>
          {mainOrder.orderdelivery.length > 0 ? (
            <div className='d-flex flex-column'>
              <div className='d-flex flex-row justify-content-between'>
                {moment(mainOrder.orderdelivery.slice(-1)[0].created_at).format(
                  'DD MMM YYYY; h:mm A'
                )}
                <DateTimePopover
                  title='Change Last Service Status'
                  date={csDate}
                  setDate={setCsDate}
                  time={csTimeNew}
                  setTime={setCsTimeNew}
                  showTime={true}
                  handleSave={() => console.log(`doing nothing`)}
                  handleIClick={() => handleIClick(mainOrder)}
                  staticIcon={false}
                  iconStyle={{ opacity: 0.4 }}
                />
              </div>
              <div className='d-flex flex-row'>
                <div className=''>
                  <span className='fw-bold text-dark'>MT: </span>
                  {mainOrder.orderdelivery.slice(-1)[0].created_by.groups
                    .length > 0 &&
                    mainOrder.orderdelivery.slice(-1)[0].created_by.groups[0]
                      .name === 'Medical Technologist'
                    ? mainOrder.orderdelivery.slice(-1)[0].created_by.first_name
                      ? `${mainOrder.orderdelivery.slice(-1)[0].created_by
                        .first_name
                      } ${mainOrder.orderdelivery.slice(-1)[0].created_by
                        .last_name
                      }`
                      : mainOrder.orderdelivery.slice(-1)[0].created_by.username
                    : mainOrder.orderdetail && mainOrder.orderdetail.mt
                      ? mainOrder.orderdetail.mt.first_name
                        ? `${mainOrder.orderdetail.mt.first_name} ${mainOrder.orderdetail.mt.last_name}`
                        : mainOrder.orderdetail.mt.username
                      : `No one assigned yet`}
                </div>
                {allAccess &&
                  mainOrder.ordercampaign === null &&
                  !(
                    mainOrder.orderdelivery.slice(-1)[0].created_by.groups
                      .length > 0 &&
                    mainOrder.orderdelivery.slice(-1)[0].created_by.groups[0]
                      .name === 'Medical Technologist'
                  ) && (
                    <CsMtPopover
                      title='Select MT'
                      value={mt}
                      setValue={setMt}
                      options={medicalTechnologist}
                      handleSave={handleOrderMt}
                      handleIClick={() => handleIClick(mainOrder)}
                      staticIcon={false}
                      iconStyle={{ opacity: 0.4 }}
                    />
                  )}
              </div>
              <div className='d-flex flex-row'>
                {/* <div style={{ textTransform: 'capitalize', padding: '5px 18px' }}
                  className={`fs-8 my-2 border border-0 rounded ${mainOrder.orderdelivery.slice(-1)[0].name === 'pending' ?
                    'text-warning bg-dim-warning' : (
                      mainOrder.orderdelivery.slice(-1)[0].name === 'order_received' ||
                        mainOrder.orderdelivery.slice(-1)[0].name === 'sample_collected'
                        ? 'text-purple bg-dim-purple'
                        : 'text-green bg-dim-green'
                    )
                    }`}
                >
                  {adjustCapitalize(mainOrder.orderdelivery.slice(-1)[0].name.split('_').join(' '))}
                </div> */}
                {mtAccess && mainOrder.ordercampaign === null && (
                  <SelectPopover
                    title='Select Service Status'
                    value={reportDelivery}
                    setValue={setReportDelivery}
                    handleSave={handleReportDelivery}
                    options={orderServiceStatus}
                    handleIClick={() => handleIClick(mainOrder)}
                    isButton={true}
                    selectedName={
                      reportDelivery &&
                      adjustCapitalize(reportDelivery.split('_').join(' '))
                    }
                    selectedClassName={
                      !reportDelivery || reportDelivery === 'pending'
                        ? 'text-warning bg-dim-warning'
                        : reportDelivery === 'order_received' ||
                          reportDelivery === 'sample_collected'
                          ? 'text-purple bg-dim-purple'
                          : 'text-green bg-dim-green'
                    }
                    placeholder={'Service Status'}
                  />
                )}
                {/* <SelectPopoverNew
                  title='Select Service Status'
                  placeholder="Service status"
                  value={reportDelivery}
                  setValue={setReportDelivery}
                  handleSave={handleReportDelivery}
                  options={orderServiceStatus}
                  handleIClick={() => handleIClick(mainOrder)}
                  mainOrder={mainOrder}
                  adjustCapitalize={adjustCapitalize}
                  placement='bottom'
                  selectedName={reportDelivery && adjustCapitalize(reportDelivery.split('_').join(' '))}
                  selectedClassName={!reportDelivery ||
                    reportDelivery === 'pending' ?
                    'text-warning bg-dim-warning' : (
                      reportDelivery === 'order_received' ||
                        reportDelivery === 'sample_collected'
                        ? 'text-purple bg-dim-purple'
                        : 'text-green bg-dim-green'
                    )
                  }
                  popOverId='order-stat-drop'
                  editAccess={mtAccess && mainOrder.ordercampaign === null}
                  selectedClassStyle={{ maxWidth: '130px', }}
                  onBlurHide={false}
                /> */}
              </div>
            </div>
          ) : (
            <div className='d-flex flex-column'>
              <div className='d-flex flex-row justify-content-between'>
                <div className='d-flex flex-column'>
                  {mainOrder.orderdetail && mainOrder.orderdetail.mt && (
                    <div className=''>
                      <span className='fw-bold text-dark'>MT: </span>
                      {mainOrder.orderdetail.mt.first_name
                        ? `${mainOrder.orderdetail.mt.first_name} ${mainOrder.orderdetail.mt.last_name}`
                        : mainOrder.orderdetail.mt.username}
                    </div>
                  )}
                </div>
                {allAccess && mainOrder.ordercampaign === null && (
                  <CsMtPopover
                    title='Select MT'
                    value={mt}
                    setValue={setMt}
                    options={medicalTechnologist}
                    handleSave={handleOrderMt}
                    handleIClick={() => handleIClick(mainOrder)}
                    staticIcon={false}
                    iconStyle={{ opacity: 0.4 }}
                  />
                )}
              </div>
              {mainOrder.orderdetail && mainOrder.orderdetail.mt && (
                <div className='d-flex flex-row'>
                  {mtAccess && mainOrder.ordercampaign === null && (
                    <SelectPopover
                      title='Select Service Status'
                      value={reportDelivery}
                      setValue={setReportDelivery}
                      handleSave={handleReportDelivery}
                      options={orderServiceStatus}
                      handleIClick={() => handleIClick(mainOrder)}
                      isButton={true}
                      selectedName={
                        reportDelivery &&
                        adjustCapitalize(reportDelivery.split('_').join(' '))
                      }
                      selectedClassName={
                        !reportDelivery || reportDelivery === 'pending'
                          ? 'text-warning bg-dim-warning'
                          : reportDelivery === 'order_received' ||
                            reportDelivery === 'sample_collected'
                            ? 'text-purple bg-dim-purple'
                            : 'text-green bg-dim-green'
                      }
                      placeholder={'Service Status'}
                    />
                  )}

                  {/* <SelectPopoverNew
                    title='Select Service Status'
                    placeholder="Service status"
                    value={reportDelivery}
                    setValue={setReportDelivery}
                    handleSave={handleReportDelivery}
                    options={orderServiceStatus}
                    handleIClick={() => handleIClick(mainOrder)}
                    mainOrder={mainOrder}
                    adjustCapitalize={adjustCapitalize}
                    placement='bottom'
                    selectedName={reportDelivery && adjustCapitalize(reportDelivery.split('_').join(' '))}
                    selectedClassName={!reportDelivery ||
                      reportDelivery === 'pending' ?
                      'text-warning bg-dim-warning' : (
                        reportDelivery === 'order_received' ||
                          reportDelivery === 'sample_collected'
                          ? 'text-purple bg-dim-purple'
                          : 'text-green bg-dim-green'
                      )
                    }
                    popOverId='order-stat-drop'
                    editAccess={mtAccess && mainOrder.ordercampaign === null}
                    selectedClassStyle={{ maxWidth: '130px', }}
                    onBlurHide={true}
                  /> */}
                </div>
              )}
            </div>
          )}
        </td>

        {/* col: 5 */}
        <td className='ps-3 h_px_td'>
          {mainOrder.orderdetail &&
            (mainOrder.orderdetail.delivery_copy_status === 'soft' ||
              mainOrder.orderdetail.delivery_copy_status === 'both') && (
              <div className='d-flex flex-column'>
                {mainOrder &&
                  mainOrder.orderreportdelivery &&
                  mainOrder.orderreportdelivery.length !== 0 ? (
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-row justify-content-between'>
                      {!!handleSoftLatestTitle(
                        mainOrder.orderreportdelivery
                      ) && (
                          <div className='text-dark fw-normal'>
                            <span className='fw-bold text-dark'>
                              {handleSoftLatestTitle(
                                mainOrder.orderreportdelivery
                              )}
                              :
                            </span>{' '}
                            <br />
                            {handleSoftLatestDueDate(mainOrder.orderreportdelivery)}
                          </div>
                        )}
                    </div>

                    {!!handleSoftLatestNote(mainOrder.orderreportdelivery) &&
                      handleSoftLatestNote(mainOrder.orderreportdelivery) && (
                        <div className='fo-11 text-dim-dark'>
                          <span className='fw-bold'>Note: </span>
                           {handleSoftLatestNote(mainOrder.orderreportdelivery)}
                        </div>
                      )}

                    <SeeAllPopover
                      title='Report Soft Copy Status Lists'
                      listToShow={mainOrder.orderreportdelivery}
                      disableRightAction={true}
                      handleIClick={() => handleIClick(mainOrder)}
                      renderListItem={(item, index) => (
                        <div className='d-flex flex-column mb-2' key={index}>
                          <div className='d-flex flex-row justify-content-between'>
                            {!!handleSoftTitle(item.name) && (
                              <div className='text-dark fw-normal'>
                                <span className='fw-bold text-dark'>
                                  {handleSoftTitle(item.name)}:
                                </span>{' '}
                                {item.due_date
                                  ? moment(item.due_date).format('DD MMM; h:mm a')
                                  : `Date not available`}
                              </div>
                            )}
                          </div>

                          {item.note && (
                            <div
                              style={{ marginTop: '-2px' }}
                              className='fs-8 text-dim-dark'
                            >
                              <span className='fw-bold'>Note: </span>
                              {item.note}
                            </div>
                          )}
                        </div>
                      )}
                    />

                    {/* <SelectPopoverNew
                  title='Select Report Status'
                  placeholder='Report Status'
                  value={orderDeliveryCopy}
                  setValue={setOrderDeliveryCopy}
                  handleSave={handleOrderDelCopy}
                  options={orderDeilveryCopy}
                  handleIClick={() => handleIClick(mainOrder)}
                  mainOrder={mainOrder}
                  adjustCapitalize={adjustCapitalize}
                  placement='bottom'
                  selectedName={orderDeliveryCopy && adjustCapitalize(orderDeliveryCopy.split('_').join(' '))}
                  selectedClassName={!orderDeliveryCopy ||
                    orderDeliveryCopy === 'pending' ?
                    'text-warning bg-dim-warning' : (
                      orderDeliveryCopy === 'cancel'
                        ? 'text-danger bg-dim-danger'
                        : 'text-danger bg-dim-danger'
                    )
                  }
                  popOverId='order-stat-drop'
                  editAccess={allAccess && mainOrder.ordercampaign === null && mainOrder.orderdetail}
                /> */}
                  </div>
                ) : (
                  ``
                )}

                <div className='mt-1 d-flex'>
                  {mainOrder.ordercampaign === null && (
                    <SelectPopover
                      title='Select Report Delivery Status'
                      value={reportDeliveryAfterSoft}
                      setValue={setReportDeliveryAfterSoft}
                      date={reportDeliverySoftDate}
                      setDate={setReportDeliverySoftDate}
                      handleSave={handleReportDeliveryAfterSoft}
                      options={reportDeliveryOptionsSoft}
                      handleIClick={() => handleIClick(mainOrder)}
                      textArea={true}
                      textAreaValue={reportDeliveryAfterDescSoft}
                      textAreaPlaceholder='Enter report description'
                      setTextAreaValue={setReportDeliveryAfterDescSoft}
                      staticIcon={false}
                      iconStyle={{ opacity: 0.4 }}
                      isButton={true}
                      selectedName={
                        mainOrder.orderreportdelivery.length > 0 &&
                              handleSoftLatestTitle(
                                mainOrder.orderreportdelivery
                              )
                      }
                      selectedClassName={
                        !(
                          mainOrder.orderreportdelivery.length > 0 &&
                              handleSoftLatestTitle(
                                mainOrder.orderreportdelivery
                              )
                        ) ||
                          (mainOrder.orderreportdelivery.length > 0 &&
                              handleSoftLatestTitle(
                                mainOrder.orderreportdelivery
                              )) !== 'Soft Sent'
                          ? 'text-danger bg-dim-danger'
                          : 'text-purple bg-dim-purple'
                      }
                      placeholder='Report Status'
                    />
                  )}
                </div>
              </div>
            )}
        </td>

        {/* col: 6 */}
        <td className='ps-3 h_px_td'>
          {mainOrder.orderdetail &&
            (mainOrder.orderdetail.delivery_copy_status === 'hard' ||
              mainOrder.orderdetail.delivery_copy_status === 'both') && (
              <div className='d-flex flex-column'>
                {mainOrder &&
                  mainOrder.orderreporthardcopydelivery &&
                  mainOrder.orderreporthardcopydelivery.length !== 0 ? (
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-row justify-content-between'>
                      {!!handleHardLatestTitle(
                        mainOrder.orderreporthardcopydelivery
                      ) && (
                          <div className='text-dark fw-normal'>
                            <span className='fw-bold text-dark'>
                              {handleHardLatestTitle(
                                mainOrder.orderreporthardcopydelivery
                              )}
                              :
                            </span>{' '}
                            <br />
                            {handleHardLatestDueDate(mainOrder.orderreporthardcopydelivery)}
                          </div>
                        )}
                    </div>


                    {!!handleHardLatestNote(mainOrder.orderreporthardcopydelivery) &&
                      handleHardLatestNote(mainOrder.orderreporthardcopydelivery) && (
                        <div className='fo-11 text-dim-dark'>
                          <span className='fw-bold'>Note: </span>
                           {handleHardLatestNote(mainOrder.orderreporthardcopydelivery)}
                        </div>
                      )}

                    <SeeAllPopover
                      title='Report Hard Copy Status Lists'
                      listToShow={mainOrder.orderreporthardcopydelivery}
                      disableRightAction={true}
                      handleIClick={() => handleIClick(mainOrder)}
                      renderListItem={(item, index) => (
                        <div className='d-flex flex-column mb-2' key={index}>
                          <div className='d-flex flex-row justify-content-between'>
                            {!!handleHardTitle(item.name) && (
                              <div className='text-dark fw-normal'>
                                <span className='fw-bold text-dark'>
                                  {handleHardTitle(item.name)}:
                                </span>{' '}
                                {item.due_date
                                  ? moment(item.due_date).format('DD MMM; h:mm a')
                                  : `Date not available`}
                              </div>
                            )}
                          </div>

                          {item.note && (
                            <div
                              style={{ marginTop: '-2px' }}
                              className='fs-8 text-dim-dark'
                            >
                              <span className='fw-bold'>Note: </span>
                              {item.note}
                            </div>
                          )}
                        </div>
                      )}
                    />

                    {/* <SelectPopoverNew
                  title='Select Report Status'
                  placeholder='Select Report Status'
                  value={orderDeliveryCopy}
                  setValue={setOrderDeliveryCopy}
                  handleSave={handleOrderDelCopy}
                  options={orderDeilveryCopy}
                  handleIClick={() => handleIClick(mainOrder)}
                  mainOrder={mainOrder}
                  adjustCapitalize={adjustCapitalize}
                  placement='bottom'
                  selectedName={orderDeliveryCopy && adjustCapitalize(orderDeliveryCopy.split('_').join(' '))}
                  selectedClassName={!orderDeliveryCopy ||
                    orderDeliveryCopy === 'pending' ?
                    'text-warning bg-dim-warning' : (
                      orderDeliveryCopy === 'cancel'
                        ? 'text-danger bg-dim-danger'
                        : 'text-danger bg-dim-danger'
                    )
                  }
                  popOverId='order-stat-drop'
                  editAccess={allAccess && mainOrder.ordercampaign === null && mainOrder.orderdetail}
                /> */}
                  </div>
                ) : (
                  ``
                )}

                <div className='mt-1 d-flex'>
                  {mainOrder.ordercampaign === null && (
                    <SelectPopover
                      title='Select Report Delivery Status'
                      value={reportDeliveryAfterHard}
                      setValue={setReportDeliveryAfterHard}
                      date={reportDeliveryHardDate}
                      setDate={setReportDeliveryHardDate}
                      handleSave={handleReportDeliveryAfterHard}
                      options={reportDeliveryOptionsHard}
                      handleIClick={() => handleIClick(mainOrder)}
                      textArea={true}
                      textAreaValue={reportDeliveryAfterDescHard}
                      textAreaPlaceholder='Enter report description'
                      setTextAreaValue={setReportDeliveryAfterDescHard}
                      staticIcon={false}
                      iconStyle={{ opacity: 0.4 }}
                      isButton={true}
                      selectedName={
                        mainOrder.orderreporthardcopydelivery.length > 0 &&
                        handleHardLatestTitle(
                          mainOrder.orderreporthardcopydelivery
                        )
                      }
                      selectedClassName={
                        !(
                          mainOrder.orderreporthardcopydelivery.length > 0 &&
                          handleHardLatestTitle(
                            mainOrder.orderreporthardcopydelivery
                          )
                        ) ||
                          (mainOrder.orderreporthardcopydelivery.length > 0 &&
                            handleHardLatestTitle(
                              mainOrder.orderreporthardcopydelivery
                            )) !== 'Hard Sent'
                          ? 'text-danger bg-dim-danger'
                          : 'text-purple bg-dim-purple'
                      }
                      placeholder='Report Status'
                    />
                  )}
                </div>
              </div>
            )}
        </td>

        {/* col: 7 */}
        <td className='ps-3 h_px_td'>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row justify-content-between'>
              <div className='text-dark'>
                {/* <span className='fw-bold text-dark'>Payable Amount: </span> */}
                <span className='fw-bold text-dark'>Payable: </span>
                {mainOrder.orderdiscount
                  ? `${Number(mainOrder.total_price) +
                  Number(mainOrder.collection_fee) +
                  Number(mainOrder.material_fee) -
                  Number(mainOrder.orderdiscount.discount_price)
                  }`
                  : Number(mainOrder.total_price) +
                  Number(mainOrder.collection_fee) +
                  Number(mainOrder.material_fee)}
              </div>
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <div className='text-dim-dark fo-11'>
                {/* <span className='fw-bold'>Total Amount: </span> */}
                <span className='fw-bold'>Total: </span>
                {Number(mainOrder.total_price)}
              </div>
            </div>

            {mainOrder &&
              mainOrder.orderdetail &&
              mainOrder.orderdetail.payment_note && (
                <div className='d-flex flex-row justify-content-between'>
                  <div className='fo-11 text-dim-dark fw-normal'>
                    <span className='fw-bold'>Note:</span>{' '}
                    {mainOrder.orderdetail.payment_note
                      ? `${mainOrder.orderdetail.payment_note}`
                      : 'Not available'}
                  </div>
                </div>
              )}

            <div className='d-flex'>
              {mtAccess && mainOrder.ordercampaign === null && (
                <SelectPopover
                  title='Select Payment Status'
                  value={orderPaymentStatus}
                  setValue={setOrderPaymentStatus}
                  handleSave={handleOrderPayStatus}
                  options={paymentStatusOptions}
                  handleIClick={() => handleIClick(mainOrder)}
                  textArea={true}
                  textAreaValue={paymentNote}
                  textAreaPlaceholder='Enter payment note'
                  setTextAreaValue={setPaymentNote}
                  isButton={true}
                  selectedName={
                    orderPaymentStatus &&
                    adjustCapitalize(
                      mainOrder.orderdetail
                        ? mainOrder.orderdetail.payment_status
                          .split('_')
                          .join(' ')
                        : orderPaymentStatus.split('_').join(' ')
                    )
                  }
                  selectedClassName={
                    mainOrder.orderdetail
                      ? mainOrder.orderdetail.payment_status === 'unpaid'
                        ? 'text-danger bg-dim-danger'
                        : 'text-purple bg-dim-purple'
                      : orderPaymentStatus === 'unpaid' || !orderPaymentStatus
                        ? 'text-danger bg-dim-danger'
                        : 'text-purple bg-dim-purple'
                  }
                  placeholder={'Payment Status'}
                />
              )}
            </div>
            {mainOrder.is_archived && (
              <>
                <div className='d-flex'>
                  <div className='text-capitalize text-center fs-8 mt-2 border border-0 rounded text-primary bg-dim-primary status-btn'>
                    {mainOrder.orderdetail.payment_status}
                  </div>
                </div>
              </>
            )}
            {mainOrder &&
              mainOrder.orderdetail &&
              mainOrder.orderdetail.payment_source === 'sslc' && (
                <div className='d-flex'>
                  <div
                    onClick={() => handlePaymentSourceModal(mainOrder)}
                    className='text-capitalize text-center fs-8 mt-2 border border-0 rounded text-primary bg-dim-primary status-btn pointer'
                  >
                    Details
                  </div>
                </div>
              )}
            {/* <SelectPopoverNew
              title='Select Payment Status'
              placeholder='Payment Status'
              value={orderPaymentStatus}
              setValue={setOrderPaymentStatus}
              handleSave={handleOrderPayStatus}
              options={paymentStatusOptions}
              handleIClick={() => handleIClick(mainOrder)}
              mainOrder={mainOrder}
              adjustCapitalize={adjustCapitalize}
              placement='bottom'
              selectedName={orderPaymentStatus && adjustCapitalize(
                mainOrder.orderdetail
                  ? mainOrder.orderdetail.payment_status.split('_').join(' ')
                  : orderPaymentStatus.split('_').join(' ')
              )}
              selectedClassName={
                mainOrder.orderdetail
                  ? (mainOrder.orderdetail.payment_status === 'unpaid'
                    ? 'text-danger bg-dim-danger'
                    : 'text-purple bg-dim-purple')
                  : ((orderPaymentStatus === 'unpaid' || !orderPaymentStatus)
                    ? 'text-danger bg-dim-danger'
                    : 'text-purple bg-dim-purple')
              }
              popOverId='order-stat-drop'
              editAccess={mtAccess && mainOrder.ordercampaign === null}
            /> */}
          </div>
        </td>

        {/* col: 8 */}
        <td className='px-3 h_px_td remarks'>
          <div className='d-flex flex-row justify-content-between'>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-row'>
                <div className='text-dim-dark'>
                  {orderNotes.length > 0 ? (
                    <div
                      className='mb-2 text-dim-dark fo-11'
                      style={{ maxHeight: 70, overflow: 'hidden' }}
                    >
                      <div style={{ lineHeight: 1.1 }}>
                        {orderNotes.slice(-1)[0].note}
                      </div>
                      <div style={{ fontSize: '8px', marginTop: '2px' }}>
                        {moment(orderNotes.slice(-1)[0].created_at).format(
                          'DD MMM YYYY; hh:mm A'
                        )}
                      </div>
                      <div style={{ fontSize: '8px', marginTop: '2px' }}>
                        {orderNotes.slice(-1)[0].marked
                          ? 'Colored: True'
                          : 'Colored: False'}
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        history.push(
                          `/order-edit/${mainOrder.id}#order-note-nav`
                        )
                      }
                      className='text-dark text-center pointer d-flex align-items-center'
                    >
                      Add Note{' '}
                      <img
                        className='pointer'
                        src={AngleDown}
                        alt=''
                        style={{
                          marginLeft: 3,
                          height: '8px',
                          opacity: 0.8,
                          transform: 'rotate(-90deg)',
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <RemarksPopover
                title='Order Notes'
                orderNotes={orderNotes}
                handleAddNew={() => {
                  history.push(`/order-edit/${mainOrder.id}#order-note-nav`);
                }}
                handleIClick={() => handleIClick(mainOrder)}
              />
            </div>
            <div className='h-100 ms-3 justify-content-center align-items-center d-flex flex-column'>
              <div className='edit-btn'>
                <button
                  className='btn btn-light btn-sm px-5'
                  onClick={() => {
                    history.push(`/order-edit/${mainOrder.id}`);
                  }}
                >
                  <i
                    style={{}}
                    className={`fas fa-pencil-alt pointer me-0 text-dim-dark opacity-25`}
                  ></i>
                  Edit
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>

      {/* ticket modal start */}
      <Modal
        show={generateTicketModal}
        onHide={() => setGenerateTicketModal(false)}
        animation={false}
        size='lg'
      // scrollable={true}
      >
        <ModalBody>
          <div className='generete-ticket'>
            <div className='d-flex justify-content-between align-items-center mb-5'>
              <h5 className='m-0'></h5>
              <button
                className='btn btn-sm btn-danger'
                onClick={() => setGenerateTicketModal(false)}
              >
                Close
              </button>
            </div>

            <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
              <li className='nav-item'>
                <a
                  className={`nav-link pointer ${shownTicket === 'mt-copy' ? 'active' : ''
                    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setShownTicket('mt-copy');
                  }}
                >
                  Mt Copy
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link pointer ${shownTicket === 'client-copy' ? 'active' : ''
                    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setShownTicket('client-copy');
                  }}
                >
                  Client Copy
                </a>
              </li>
            </ul>

            {shownTicket === 'mt-copy' && (
              <>
                <table id='copy-table' className='d-flex flex-column'>
                  {mainOrder.orderitem.length !== 0 &&
                    CtCPatient.map((item, i) => (
                      <tbody
                        key={i}
                        className='d-flex flex-column'
                        style={{ flexGrow: 1 }}
                      >
                        <tr className='d-flex justify-content-center'>
                          <td>AmarLab Limited</td>
                        </tr>
                        <tr className='d-flex justify-content-center'>
                          <td>www.amarlab.com</td>
                        </tr>
                        <tr className='d-flex justify-content-center'>
                          <td>www.facebook.com/amarlab.bd</td>
                        </tr>
                        <tr className='d-flex justify-content-center'>
                          <td>Hotline: 09643445566</td>
                        </tr>
                        {/* <tr className='d-flex justify-content-center'>
                      <td>
                        Making Healthcare Easily Accessible to Everyone,
                        Everywhere
                      </td>
                    </tr> */}
                        <tr className='d-flex justify-content-center text-muted'>
                          <td>&nbsp;</td>
                        </tr>
                        {CtCPatient.length > 1 && (
                          <tr>
                            <td>{i + 1}.</td>
                          </tr>
                        )}
                        <tr>
                          <td>#{mainOrder.order_id}</td>
                        </tr>
                        <tr>
                          <td>
                            Ticket Generated On:{' '}
                            {moment().format('Do MMMM YYYY; hh:mm A')}
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>
                            Date:{' '}
                            {moment(mainOrder.date).format('Do MMMM YYYY')}
                          </td>
                        </tr>
                        {mainOrder.time && (
                          <tr>
                            <td>
                              Time:{' '}
                              {moment(mainOrder.time, ['HH:mm:ss']).format(
                                'hh:mm A'
                              )}{' '}
                              -{' '}
                              {moment(mainOrder.time, 'HH:mm:ss')
                                .add(120, 'minutes')
                                .format('hh:mm A')}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            Address:{' '}
                            {mainOrder.orderdetail ? (
                              <>
                                {mainOrder.orderdetail &&
                                  mainOrder.orderdetail.address
                                  ? `${mainOrder.orderdetail.address.address || ''}, ${mainOrder.orderdetail.address.district || ''}.` //${mainOrder.orderdetail.address.thana},
                                  : `${mainOrder.orderitem[0].address.address || ''}, ${mainOrder.orderitem[0].address.district || ''}.`}
                                {/* ${mainOrder.orderitem[0].address.thana}, */}
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Contact no:{' '}
                            {mainOrder.orderdetail ? (
                              <>
                                {mainOrder.orderdetail &&
                                  mainOrder.orderdetail.address
                                  ? (mainOrder.orderdetail.address.mobile || '')
                                  : (mainOrder.orderitem[0].address.mobile || '')}
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Email:{' '}
                            {mainOrder.orderdetail ? (
                              <>
                                {mainOrder.orderdetail &&
                                  mainOrder.orderdetail.address
                                  ? (mainOrder.orderdetail.address.email || '')
                                  : (mainOrder.orderitem[0].address.email || '')}
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                        {mainOrder.orderdetail &&
                          mainOrder.orderdetail.references && (
                            <tr>
                              <td>
                                References: {mainOrder.orderdetail.references}
                              </td>
                            </tr>
                          )}
                        {mainOrder.orderdetail &&
                          mainOrder.orderdetail.user_type && (
                            <tr className='text-capitalize'>
                              <td>
                                User Type: {mainOrder.orderdetail.user_type}
                              </td>
                            </tr>
                          )}
                        <tr>
                          <td>
                            Lab:{' '}
                            {mainOrder.orderitem.length !== 0
                              ? mainOrder.orderitem[0] &&
                              mainOrder.orderitem[0].purchasable_order_item &&
                              mainOrder.orderitem[0].purchasable_order_item
                                .testitem_purchasable_oi &&
                              mainOrder.orderitem[0].purchasable_order_item
                                .testitem_purchasable_oi.branch &&
                              mainOrder.orderitem[0].purchasable_order_item
                                .testitem_purchasable_oi.branch.lab &&
                              mainOrder.orderitem[0].purchasable_order_item
                                .testitem_purchasable_oi.branch.lab.name
                              : ''}


                            {mainOrder.orderitem.length !== 0
                              ? mainOrder.orderitem[0] &&
                              mainOrder.orderitem[0].purchasable_order_item &&
                              mainOrder.orderitem[0].purchasable_order_item
                                .testitem_purchasable_order[0] &&
                              mainOrder.orderitem[0].purchasable_order_item
                                .testitem_purchasable_order[0].branch &&
                              mainOrder.orderitem[0].purchasable_order_item
                                .testitem_purchasable_order[0].branch.lab &&
                              mainOrder.orderitem[0].purchasable_order_item
                                .testitem_purchasable_order[0].branch.lab.name
                              : ''}



                            {/* {mainOrder &&
                      mainOrder.purchasable_order_item &&
                      mainOrder.purchasable_order_item
                        .testitem_purchasable_oi &&
                      mainOrder.purchasable_order_item.testitem_purchasable_oi
                        .branch &&
                      mainOrder.purchasable_order_item.testitem_purchasable_oi
                        .branch.lab.name} */}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-capitalize'>
                            Payment Status:{' '}
                            {mainOrder.orderdetail &&
                              mainOrder.orderdetail.payment_status}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-capitalize'>
                            Payment Note:{' '}
                            {mainOrder.orderdetail &&
                              mainOrder.orderdetail.payment_note}
                          </td>
                        </tr>
                        {mainOrder.orderdetail && (
                          <tr>
                            <td className='text-capitalize'>
                              Order Category: {_toSpace(mainOrder.order_type)}
                            </td>
                          </tr>
                        )}
                        {mainOrder.orderdetail && mainOrder.orderdetail.mt && (
                          <tr>
                            <td>
                              MT:{' '}
                              {mainOrder.orderdetail &&
                                mainOrder.orderdetail.mt.first_name
                                ? mainOrder.orderdetail.mt.first_name +
                                ' ' +
                                mainOrder.orderdetail.mt.last_name
                                : mainOrder.orderdetail.mt.username}
                            </td>
                          </tr>
                        )}
                        {mainOrder.orderdetail &&
                          mainOrder.orderdetail.cs_agent && (
                            <tr>
                              <td>
                                CA:{' '}
                                {mainOrder.orderdetail &&
                                  mainOrder.orderdetail.cs_agent.first_name
                                  ? mainOrder.orderdetail.cs_agent.first_name +
                                  ' ' +
                                  mainOrder.orderdetail.cs_agent.last_name
                                  : mainOrder.orderdetail.cs_agent.username}
                              </td>
                            </tr>
                          )}
                        <tr>
                          <td>
                            Report Required:{' '}
                            <span className='text-capitalize'>
                              {mainOrder &&
                                mainOrder.orderdetail &&
                                mainOrder.orderdetail.delivery_copy_status}{' '}
                            </span>
                            Copy
                          </td>
                        </tr>
                        {orderNotes.length > 0 && (
                          <tr>
                            <td>Order Note: {orderNotes.slice(-1)[0].note}</td>
                          </tr>
                        )}
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td> ——————————————————————————————————————</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Name: {item.patient.full_name}</td>
                        </tr>
                        {item.patient.dob && (
                          <tr>
                            <td>
                              Age: {getAge(item.patient.dob)}
                              {/* {moment().diff(`${item.patient.dob}`, 'years') +
                              ' Years'} */}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className='text-capitalize'>
                            Sex: {item.patient.sex}
                          </td>
                        </tr>



                                  <tr>
                                    {item.patient.patient_info && item.patient.patient_info.weight ? (
                                      <td className='m-0 text-capitalize'>Weight: {item.patient.patient_info.weight} Kg</td>
                                    ) : (
                                      <>&nbsp;</>
                                    )}  
                                    </tr>

                                  <tr>
                                    {item.patient.patient_info && item.patient.patient_info.height ? (
                                      <td className='m-0 text-capitalize'>Weight: {item.patient.patient_info.height} ft.</td>
                                    ) : (
                                      <>&nbsp;</>
                                    )}  
                                    </tr>


                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        {item.testItem.map((item, i) => (
                          <>
                            <tr>
                              <td>
                                {i + 1}.{' '}


                                {item &&
                                  item.purchasable_order_item &&
                                  item.purchasable_order_item
                                    .testitem_purchasable_oi &&
                                  item.purchasable_order_item
                                    .testitem_purchasable_oi.diagnostic_test
                                  ? item.purchasable_order_item
                                    .testitem_purchasable_oi.diagnostic_test
                                    .name
                                  : ''}
                                :{' '}


                                {item &&
                                  item.purchasable_order_item &&
                                  item.purchasable_order_item
                                    .testitem_purchasable_order[0] &&
                                  item.purchasable_order_item
                                    .testitem_purchasable_order[0].diagnostic_test
                                  ? item.purchasable_order_item
                                    .testitem_purchasable_order[0].diagnostic_test
                                    .name
                                  : ''}
                                :{' '}




                                {
                                  item &&
                                  item.purchasable_order_item &&
                                  parseInt(
                                    item.purchasable_order_item.sell_price
                                  )
                                  // - parseInt(item.discount_price)
                                }{' '}
                                tk
                              </td>
                            </tr>
                          </>
                        ))}
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td> ——————————————————————————————————————</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>
                            Total Test Price ={' '}
                            {mainOrder.orderitem.length !== 0 &&
                              getTestItemsPrice(item.testItem)}{' '}
                            tk
                            {/* {mainOrder.orderdiscount ? '=' : ''}{' '} */}
                            {/* {mainOrder.orderitem.length !== 0 && (
                          <>
                            {mainOrder && mainOrder.orderdiscount && (
                              <>
                                {parseInt(mainOrder.total_price) -
                                  parseInt(
                                    mainOrder.orderdiscount.discount_price
                                  ) +
                                  'TK'}
                              </>
                            )}
                          </>
                        )} */}
                          </td>
                        </tr>
                        {i + 1 === 1 ? (
                          <tr>
                            <td>
                              Service Charge:{' '}
                              {parseInt(mainOrder.collection_fee)} tk
                            </td>
                          </tr>
                        ) : (
                          ''
                        )}
                        <tr>
                          <td>
                            Material Charge:{' '}
                            {mainOrder.orderitem.length !== 0 &&
                              mainOrder.orderitem[0].meterial_fee}{' '}
                            tk
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {i + 1 === 1 ? (
                              <>
                                Total:{' '}
                                {getTestItemsPrice(item.testItem) +
                                  mainOrder.orderitem[0].meterial_fee +
                                  parseInt(mainOrder.collection_fee)}{' '}
                                tk
                              </>
                            ) : (
                              <>
                                Total:{' '}
                                {getTestItemsPrice(item.testItem) +
                                  mainOrder.orderitem[0].meterial_fee}{' '}
                                tk
                              </>
                            )}
                          </td>
                        </tr>
                        {mainOrder.orderdiscount ? (
                          <>
                            <tr>
                              <td>
                                Discount:{' '}
                                {discountPercentClipboard
                                  ? (
                                    (discountPercentClipboard / 100) *
                                    getTestItemsPrice(item.testItem)
                                  ).toFixed(2)
                                  : '00'}{' '}
                                tk{' '}
                                {mainOrder.orderdiscount.discount_note
                                  ? `(${mainOrder.orderdiscount.discount_note})`
                                  : ''}
                              </td>
                            </tr>
                            {/* <tr>
                          <td>
                            Discount Note:{' '}
                            {mainOrder.orderdiscount.discount_note}
                          </td>
                        </tr> */}
                          </>
                        ) : (
                          ''
                        )}
                        <tr>
                          <td>
                            Total Payable:{' '}
                            {i + 1 === 1 ? (
                              <>
                                {mainOrder.orderdiscount
                                  ? (
                                    getTestItemsPrice(item.testItem) +
                                    parseInt(
                                      mainOrder.orderitem[0].meterial_fee
                                    ) +
                                    parseInt(mainOrder.collection_fee) -
                                    (discountPercentClipboard / 100) *
                                    getTestItemsPrice(item.testItem)
                                  ).toFixed(2)
                                  : (
                                    getTestItemsPrice(item.testItem) +
                                    parseInt(
                                      mainOrder.orderitem[0].meterial_fee
                                    ) +
                                    parseInt(mainOrder.collection_fee)
                                  ).toFixed(2)}
                              </>
                            ) : (
                              <>
                                {mainOrder.orderdiscount
                                  ? (
                                    getTestItemsPrice(item.testItem) +
                                    mainOrder.orderitem[0].meterial_fee -
                                    (discountPercentClipboard / 100) *
                                    getTestItemsPrice(item.testItem)
                                  ).toFixed(2)
                                  : (
                                    getTestItemsPrice(item.testItem) +
                                    mainOrder.orderitem[0].meterial_fee
                                  ).toFixed(2)}
                              </>
                            )}{' '}
                            tk
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        {/* <tr>
                      <td>&nbsp;</td>
                    </tr> */}
                      </tbody>
                    ))}
                </table>
                <div className='d-flex justify-content-end align-items-center'>
                  <button
                    className='btn btn-primary me-3'
                    onClick={() => downloadAsTxt(mainOrder)}
                  >
                    <i className='fas fa-download px-0'></i> Download
                  </button>
                  <button
                    className='btn btn-success'
                    onClick={() =>
                      copyToClipboard(document.getElementById('copy-table'))
                    }
                  >
                    <i className='fas fa-copy px-0'></i> Copy To Clipboard
                  </button>
                  {/* <button
                className='btn btn-info ms-3'
                onClick={() => printTicket(mainOrder)}
              >
                <i className='fas fa-print'></i> Print
              </button> */}
                </div>
              </>
            )}

            {shownTicket === 'client-copy' && (
              <>
                <table id='copy-table-short' className='d-flex flex-column'>
                  <tbody className='d-flex flex-column' style={{ flexGrow: 1 }}>
                    <tr>
                      <td>#{mainOrder.order_id}</td>
                    </tr>
                    <tr>
                      <td>
                        Service Date & Time:{' '}
                        {moment(mainOrder.date).format('Do MMMM YYYY')}
                        {mainOrder.time
                          ? `(${moment(mainOrder.time, ['HH:mm:ss']).format(
                            'hh:mm A'
                          )} - ${moment(mainOrder.time, ['HH:mm:ss'])
                            .add(120, 'minutes')
                            .format('hh:mm A')})`
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Address:{' '}
                        {mainOrder.orderdetail ? (
                          <>
                            {mainOrder.orderdetail &&
                              mainOrder.orderdetail.address
                              ? `${mainOrder.orderdetail.address.address || ''}, ${mainOrder.orderdetail.address.district || ''}.` //${mainOrder.orderdetail.address.thana},
                              : `${mainOrder.orderitem[0].address.address || ''}, ${mainOrder.orderitem[0].address.district || ''}.`}
                            {/* ${mainOrder.orderitem[0].address.thana}, */}
                          </>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                    {mainOrder.orderitem.length !== 0 &&
                      CtCPatient.map((item, i) => (
                        <tr key={i}>
                          <td>
                            {i + 1}. {item.patient.full_name},{' '}
                            {getAge(item.patient.dob)}, {item.patient.sex}
                          </td>
                        </tr>
                      ))}
                    {mainOrder.orderdetail.order_note && (
                      <tr>
                        <td>Order note: {mainOrder.orderdetail.order_note}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        Total:{' '}
                        {mainOrder.orderdiscount
                          ? `${parseInt(mainOrder.total_price) +
                          parseInt(mainOrder.collection_fee) +
                          parseInt(mainOrder.material_fee) -
                          parseInt(mainOrder.orderdiscount.discount_price)
                          }`
                          : parseInt(mainOrder.total_price) +
                          parseInt(mainOrder.collection_fee) +
                          parseInt(mainOrder.material_fee)}{' '}
                        tk
                      </td>
                    </tr>
                    <tr>
                      {mainOrder.user && (
                        <td>**Click here to see full order invoice</td>
                      )}
                    </tr>
                    <tr>
                      {mainOrder.user && (
                        <td>
                          https://amarlab.com/order-details/{mainOrder.id}
                          /ticket/{asciiCode(mainOrder.user)}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <div className='d-flex justify-content-end align-items-center mt-5'>
                  <button
                    className='btn btn-primary me-3'
                    onClick={() => downloadAsTxtShort(mainOrder)}
                  >
                    <i className='fas fa-download px-0'></i> Download
                  </button>
                  <button
                    className='btn btn-success'
                    onClick={() =>
                      copyToClipboard(
                        document.getElementById('copy-table-short')
                      )
                    }
                  >
                    <i className='fas fa-copy px-0'></i> Copy To Clipboard
                  </button>
                  {/* <button
                className='btn btn-info ms-3'
                onClick={() => printTicket(mainOrder)}
              >
                <i className='fas fa-print'></i> Print
              </button> */}
                </div>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>

      {/* ticket modal end */}

      <PaymentSourceModal
        showPaymentSourceModal={showPaymentSourceModal}
        setShowPaymentSourceModal={setShowPaymentSourceModal}
        paymentSourceDetails={paymentSourceDetails}
      />

      <CampainModal
        showCampainModal={showCampainModal}
        setShowCampainModal={setShowCampainModal}
        mainOrder={mainOrder}
      />
    </>
  );
};

export default OrderTable;
