import React, { Component } from 'react';
import HeaderToolbar from '../components/HeaderToolbar';
import AngleDown from '../assets/img/angle-down.png';
import FilterPopover from '../components/Dropdown/FilterPopover';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import Pagination from '../components/Pagination/Pagination';

class Reference extends Component {
  state = {
    sortedBy: '',
    sortedAsc: true,

    filterJoining: '',
    filterManager: '',

    references: null,
    limit: 10,
    pagination: 1,
    next: null,
    count: 0,
    loadingReference: false,

    searchText: '',
  };

  fetchReference = ({ limit, pagination }, seatchText, otherfilters = '') => {
    let fetchuserUrl = `${
      process.env.REACT_APP_BASE_URL
    }/order/reference-get/?page=1&limit=${limit}&offset=${
      (pagination - 1) * limit
    }${otherfilters}`;
    if (!!seatchText) {
      fetchuserUrl = `${
        process.env.REACT_APP_BASE_URL
      }/order/reference-get/?page=1&search=${seatchText}&limit=${limit}&offset=${
        (pagination - 1) * limit
      }${otherfilters}`;
    }

    this.setState({ loadingReference: true });
    axios
      .get(fetchuserUrl)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ references: resp.data.results });
        this.setState({ next: resp.data.next, count: resp.data.count });
        this.setState({ loadingReference: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ loadingReference: false });
      });
  };

  componentDidMount() {
    this.context.setPageTitle('Reference');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Reference', link: '/reference' },
    ]);
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchReference(this.state);
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Lab Partner' ||
        userRole.groups[0].name === 'External'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchReference(this.state);
      }
    }
  }

  putReference = (data, id) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/reference-single/${id}/`,
        data
      )
      .then((resp) => {
        console.log(resp.data);
        toast.success('Reference updated successfully.');
        const newReferences = this.state.references.map((obj) => {
          if (obj.id === resp.data.id) {
            return { ...obj, status: resp.data.status };
          }
          return obj;
        });

        this.setState({ references: newReferences });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleReferenceStatus = (ref) => {
    if (ref.status) {
      this.putReference({ status: false }, ref.id);
    } else {
      this.putReference({ status: true }, ref.id);
    }
  };

  handlePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    if (this.state.searchText)
      this.fetchReference(
        { limit: this.state.limit, pagination: pageno },
        this.state.searchText
      );
    else this.fetchReference({ limit: this.state.limit, pagination: pageno });
  };

  handleSearch = () => {
    this.setState({ pagination: 1 });
    if (!this.state.searchText) {
      this.fetchReference({ limit: this.state.limit, pagination: 1 });
    } else {
      this.fetchReference(
        { limit: this.state.limit, pagination: 1 },
        this.state.searchText
      );
    }
  };

  render() {
    const { sortedBy, sortedAsc, filterJoining, filterManager } = this.state;
    const { references, loadingReference } = this.state;

    return (
      <>
        <HeaderToolbar title='Reference' />
        <div className='content d-flex flex-column flex-column-fluid order-page ref-page'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-header border-0 pt-6'>
                    <div className='card-title head-item'>
                      <div className='d-flex align-items-start flex-column'>
                        <div className='d-flex flex-column align-items-start position-relative my-1'>
                          <div>All Reference</div>
                        </div>
                      </div>
                    </div>
                    <div className='right d-flex align-items-center'>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.handleSearch();
                        }}
                        className='search-box'
                      >
                        <div className='input-group'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Search By Name'
                            value={this.state.searchText}
                            onChange={(e) =>
                              this.setState({ searchText: e.target.value })
                            }
                          />
                          <button className='input-group-text'>
                            <i className='fas fa-search'></i>
                          </button>
                        </div>
                      </form>
                      <Link
                        to='/reference-add'
                        className='btn btn-primary ms-3'
                      >
                        Create Ref.
                      </Link>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    <div className='mt-2 table-responsive'>
                      <table className='table align-start table-row-dashed fs-7 gy-5'>
                        <thead>
                          <tr className='text-start text-dark fw-bolder fs-7 text-uppercase gs-0'>
                            <th className='min-w-80px sorting ps-3'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>
                                  REFERENCE NAME
                                </div>
                              </div>
                            </th>
                            <th className='min-w-125px sorting mt'>
                              <div className='d-flex flex-row'>
                                <div className='text-truncate'>CHANNEL</div>
                                {/* <div className='d-flex flex-row ms-2'>
                                  <div
                                    className={`px-0 border border-0 d-flex flex-column align-items-center ms-1`}
                                    onClick={() => this.handleSorting('date')}
                                    style={{
                                      marginTop: '2px',
                                      transform: 'scaleX(0.8) scaleY(0.9)',
                                    }}
                                  >
                                    <img
                                      className='pointer'
                                      src={AngleDown}
                                      alt=''
                                      style={{
                                        width: '15px',
                                        height: '8px',
                                        opacity:
                                          sortedBy === 'date' && !sortedAsc
                                            ? 1
                                            : 0.3,
                                        transform: 'rotate(180deg)',
                                      }}
                                    />
                                    <img
                                      className='pointer'
                                      src={AngleDown}
                                      alt=''
                                      style={{
                                        width: '15px',
                                        height: '8px',
                                        opacity:
                                          sortedBy === 'date' && sortedAsc
                                            ? 1
                                            : 0.3,
                                      }}
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </th>
                            <th className='min-w-125px sorting mt'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>
                                  DATE OF JOINING
                                </div>
                                {/* <div className='d-flex flex-row ms-2'>
                                  <div className='d-flex align-items-center fs-8'>
                                    <FilterPopover
                                      customIcon={() => (
                                        <i
                                          className='fas fa-sort-amount-down pointer text-dark'
                                          style={{
                                            opacity:
                                              filterJoining === '' ? 0.2 : 0.6,
                                          }}
                                        ></i>
                                      )}
                                      value={filterJoining}
                                      setValue={(stat) =>
                                        this.setState({
                                          filterJoining: stat,
                                        })
                                      }
                                      handleSave={this.handleByServiceStatus}
                                      options={this.orderServiceStatus}
                                      placement='bottom-end'
                                      popOverId='filter-drop'
                                      editAccess={true}
                                      onBlurHide={true}
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </th>
                            <th className='min-w-80px sorting ps-3'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>
                                  CONTACT DETAILS
                                </div>
                              </div>
                            </th>
                            <th className='min-w-125px sorting mt'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>
                                  ACCOUNT MANAGER
                                </div>
                                {/* <div className='d-flex flex-row ms-2'>
                                  <div className='d-flex align-items-center fs-8'>
                                    <FilterPopover
                                      customIcon={() => (
                                        <i
                                          className='fas fa-sort-amount-down pointer text-dark'
                                          style={{
                                            opacity:
                                              filterManager === '' ? 0.2 : 0.6,
                                          }}
                                        ></i>
                                      )}
                                      value={filterManager}
                                      setValue={(stat) =>
                                        this.setState({
                                          filterManager: stat,
                                        })
                                      }
                                      handleSave={this.handleByServiceStatus}
                                      options={this.orderServiceStatus}
                                      placement='bottom-end'
                                      popOverId='filter-drop'
                                      editAccess={true}
                                      onBlurHide={true}
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </th>
                            <th className='min-w-80px sorting ps-3'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'></div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='fw-bold text-gray-600'>
                          {references &&
                            references.map((reference, i) => (
                              <TableRowItem
                                props={this.props}
                                key={i}
                                reference={reference}
                                handleStatus={this.handleReferenceStatus}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div className='row justify-content-end mt-3 mb-10'>
                      {loadingReference && (
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                          <Spinner animation='border' size='sm' />{' '}
                          <span className='ms-2 text-gray-600'>
                            loading, Please wait...
                          </span>
                        </div>
                      )}
                      <Pagination
                        onPageChange={(pageno) =>
                          this.handlePaginationPress(pageno)
                        }
                        totalCount={this.state.count}
                        pageSize={this.state.limit}
                        currentPage={this.state.pagination}
                        siblingCount={1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const TableRowItem = ({ props, reference, handleStatus }) => (
  <tr>
    <td className='ps-3'>
      {/* <div className='fw-normal text-dark d-flex flex-row align-items-center'>
        #10056
      </div> */}
      <div className='fw-bolder text-dark d-flex flex-row align-items-center'>
        {reference.name}
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center'>
        {reference.channel.name}
      </div>
      <div className='text-dim-dark d-flex flex-row align-items-center'>
        Category: {reference.channel.channel_category.name}
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center'>
        {moment(reference.created_at).format('DD MMM YYYY; hh:mm A')}
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center'>
        {reference.number || '-'}
      </div>
      <div className='fw-light text-dark d-flex flex-row align-items-center'>
        {reference.email || '-'}
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center'>
        {reference.account_manager_name || '-'}
      </div>
      <div className='fw-light text-dark d-flex flex-row align-items-center'>
        {reference.account_manager_email || '-'}
      </div>
    </td>
    <td className='d-flex border-white'>
      <div className='d-flex align-items-center'>
        <div className='form-check form-switch form-check-custom form-check-solid'>
          <input
            className='form-check-input w-50px'
            type='checkbox'
            name='is_active'
            onChange={() => {
              if (window.confirm('Are you sure?')) handleStatus(reference);
            }}
            checked={reference.status}
          />
        </div>
      </div>
      <button
        className='btn btn-light btn-sm px-5 mx-3'
        onClick={() => {
          props.history.push(`/reference-edit/${reference.id}`);
        }}
      >
        <i
          className={`fas fa-pencil-alt pointer me-0 text-dim-dark opacity-25`}
        ></i>
        Edit
      </button>
      <button
        className='btn btn-light btn-sm px-5'
        onClick={() => {
          props.history.push(`/reference-analytic`);
        }}
        disabled
      >
        Analytics
      </button>
    </td>
  </tr>
);

export default Reference;
Reference.contextType = AuthContext;
