import axios from 'axios';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import HeaderToolbar from '../components/HeaderToolbar';
import LoadingContent from '../components/LoadingContent';
import PatientDob from '../components/PatientDob';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';

class MyProfile extends Component {
  state = {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    username: '',
    patientDob: null,
    patientGender: '',
    newPassword: '',
    newPasswordConfirm: '',
    user_id: null,
    patient_id: null,
    loading: false,
  };

  setPatientDob = (val) => {
    this.setState({ patientDob: val });
  };

  fetchUsers = (id) => {
    this.setState({ loading: true });
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user-all/${id}/?user=${id}`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ first_name: resp.data.first_name });
        this.setState({ last_name: resp.data.last_name });
        this.setState({ email: resp.data.email });
        this.setState({ username: resp.data.username });
        this.setState({ password: resp.data.password });
        this.setState({ user_id: resp.data.id });
        this.setState({ loading: false });

        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/user_management/patient/?user=${resp.data.id}&limit=300`
          )
          .then((resp) => {
            // console.log(resp.data.results);
            if (resp.data.results.length !== 0) {
              const userPatient = resp.data.results.filter((patient) => {
                return patient.is_account === true;
              });
              // console.log(userPatient.length !== 0 ? userPatient[0].id : null);
              this.setState({
                patient_id: userPatient.length !== 0 ? userPatient[0].id : null,
              });
              this.setState({
                patientDob: new Date(userPatient[0].dob),
                patientGender: userPatient[0].sex,
              });
            }
          });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ loading: false });
      });
  };

  ajaxPut = (data) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/user-all/${this.state.user_id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`Profile updated successfully.`, {
          autoClose: 3000,
        });
        this.props.history.push('/users');
      })
      .catch((error) => console.log(error.response));
  };

  ajaxPutPatient = (data) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient/${this.state.patient_id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        // toast.success(`User profile updated successfully.`, {
        //   autoClose: 3000,
        // });
        // this.props.history.push('/users');
      })
      .catch((error) => {
        console.log(error.response);
        // if (error.response.status === 400) {
        //   if (error.response.data.groups[0]) {
        //     toast.error(`Please select a role.`, {
        //       autoClose: 3000,
        //     });
        //   }
        // } else {
        //   toast.error(
        //     'Sorry something went wrong, please try again later.',
        //     {
        //       autoClose: 3000,
        //     }
        //   );
        // }
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.newPassword !== '' || this.state.newPasswordConfirm !== '') {
      if (this.state.first_name.trim() === '') {
        toast.error(`Firstname can not be empty.`, {
          autoClose: 3000,
        });
      } else if (this.state.last_name.trim() === '') {
        toast.error(`Lastname can not be empty.`, {
          autoClose: 3000,
        });
      } else if (this.state.newPassword === '') {
        toast.error(`Password can not be empty.`, {
          autoClose: 3000,
        });
      } else if (this.state.newPasswordConfirm === '') {
        toast.error(`Confirm password can not be empty.`, {
          autoClose: 3000,
        });
      } else if (this.state.newPassword.length < 8) {
        toast.error(`Password must be at least 8 characters.`, {
          autoClose: 3000,
        });
      } else if (this.state.newPasswordConfirm.length < 8) {
        toast.error(`Confirm password must be at least 8 characters.`, {
          autoClose: 3000,
        });
      } else if (this.state.newPassword !== this.state.newPasswordConfirm) {
        toast.error(`Password and confirm password does not match.`, {
          autoClose: 3000,
        });
      } else {
        const postData = {
          password: this.state.newPassword,
          user_id: this.state.user_id,
        };

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user_management/reset-password`,
            postData
          )
          .then((resp) => {
            // console.log(resp.data);

            axios
              .get(
                `${process.env.REACT_APP_BASE_URL}/user_management/user/${this.state.user_id}`
              )
              .then((resp) => {
                // console.log(resp.data);

                const putData = {
                  first_name: this.state.first_name,
                  last_name: this.state.last_name,
                  username: this.state.username,
                  password: resp.data.password,
                };

                const putPatient = {
                  full_name: `${this.state.first_name} ${this.state.last_name}`,
                  email: this.state.email,
                  dob: moment(this.state.patientDob).format('YYYY-MM-DD'),
                  sex: this.state.patientGender,
                };

                this.ajaxPut(putData);

                if (this.state.patient_id !== null) {
                  this.ajaxPutPatient(putPatient);
                }
              });

            // console.log(putData);
            // this.ajaxPut(putData);
            // this.ajaxPutPatient(putPatient);
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    } else {
      if (this.state.first_name.trim() === '') {
        toast.error(`Firstname can not be empty.`, {
          autoClose: 3000,
        });
      } else if (this.state.last_name.trim() === '') {
        toast.error(`Lastname can not be empty.`, {
          autoClose: 3000,
        });
      } else {
        const putData = {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          username: this.state.username,
          password: this.state.password,
        };

        const putPatient = {
          full_name: `${this.state.first_name} ${this.state.last_name}`,
          email: this.state.email,
          dob: moment(this.state.patientDob).format('YYYY-MM-DD'),
          sex: this.state.patientGender,
        };
        // console.log(putData);
        this.ajaxPut(putData);
        if (this.state.patient_id !== null) {
          this.ajaxPutPatient(putPatient);
        }
      }
    }
  };

  componentDidMount() {
    this.context.setPageTitle('My Profile');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'My Profile', link: '/my-profile' },
    ]);
    const user_details = localStorage.getItem('user_details');
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (user_details) {
      const user = JSON.parse(user_details);
      this.fetchUsers(user.id);
    } else {
      console.log('Logout');
    }
  }

  render() {
    const {
      first_name,
      last_name,
      email,
      newPassword,
      username,
      newPasswordConfirm,
      loading,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='My Profile' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card py-8 px-8 py-lg-15 px-lg-10'>
                  <div className='row justify-content-center'>
                    <div className='col-xl-9'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h5 className='text-dark font-weight-bold mb-10'>
                          My Profile
                        </h5>
                      </div>
                      {!loading ? (
                        <form onSubmit={this.handleSubmit}>
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Username *
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <input
                                className='form-control form-control-solid form-control-lg'
                                type='text'
                                value={username}
                                disabled
                              />
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              First Name *
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <input
                                className='form-control form-control-solid form-control-lg'
                                type='text'
                                value={first_name}
                                onChange={(e) =>
                                  this.setState({ first_name: e.target.value })
                                }
                              />
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Last Name *
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <input
                                className='form-control form-control-solid form-control-lg'
                                type='text'
                                value={last_name}
                                onChange={(e) =>
                                  this.setState({ last_name: e.target.value })
                                }
                              />
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Date Of Birth *
                            </label>
                            <div className='col-lg-9 col-xl-9 patient-dob'>
                              <PatientDob
                                patientDob={this.state.patientDob}
                                setPatientDob={this.setPatientDob}
                              />
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Gender *
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <select
                                className='form-select form-control form-control-solid form-control-lg'
                                value={this.state.patientGender}
                                onChange={(e) =>
                                  this.setState({
                                    patientGender: e.target.value,
                                  })
                                }
                              >
                                <option value=''>Select user gender</option>
                                <option value='male'>Male</option>
                                <option value='female'>Female</option>
                              </select>
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Email
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <input
                                className='form-control form-control-solid form-control-lg'
                                type='email'
                                value={email}
                                disabled
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Password
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <input
                                className='form-control form-control-solid form-control-lg'
                                type='text'
                                value={newPassword}
                                onChange={(e) =>
                                  this.setState({ newPassword: e.target.value })
                                }
                              />
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                          <div className='form-group row fv-plugins-icon-container mb-3'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                              Confirm Password
                            </label>
                            <div className='col-lg-9 col-xl-9'>
                              <input
                                className='form-control form-control-solid form-control-lg'
                                type='text'
                                value={newPasswordConfirm}
                                onChange={(e) =>
                                  this.setState({
                                    newPasswordConfirm: e.target.value,
                                  })
                                }
                              />
                              <div className='fv-plugins-message-container' />
                            </div>
                          </div>
                          <div className='d-flex justify-content-between border-top pt-5 mt-5'>
                            <div className='mr-2'></div>
                            <div>
                              <button
                                type='submit'
                                className='btn btn-primary font-weight-bolder px-9 py-4'
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      ) : (
                        <LoadingContent />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MyProfile;
MyProfile.contextType = AuthContext;
