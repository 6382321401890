import { Modal, ModalBody } from 'react-bootstrap';
import React, { Component } from 'react';
import moment from 'moment';

class PaymentSourceModal extends Component {
  state = {};

  statusClass = (data) => {
    if (data.status) {
      if (data.status === 'FAILED' || data.status === 'CANCELLED') {
        return 'text-danger';
      } else if (data.status === 'VALID') {
        return 'text-success';
      } else if (data.status === 'EXPIRED') {
        return 'text-warning';
      }
    }
  };

  render() {
    const {
      showPaymentSourceModal,
      setShowPaymentSourceModal,
      paymentSourceDetails,
    } = this.props;

    return (
      <Modal
        show={showPaymentSourceModal}
        animation={false}
        onHide={() => setShowPaymentSourceModal(false)}
        scrollable
      >
        <ModalBody>
          <div className='payment-source'>
            <div className='d-flex justify-content-between align-items-center mb-5'>
              <h5 className='m-0'>Payment Source</h5>
              <button
                className='btn btn-sm btn-danger'
                onClick={() => setShowPaymentSourceModal(false)}
              >
                Close
              </button>
            </div>
            {paymentSourceDetails &&
              paymentSourceDetails.map((details, i) => (
                <div className='details' key={i}>
                  <h5 className='text-center'>----- {i + 1} -----</h5>
                  <p>Amount: {parseInt(details.amount)} TK</p>
                  <p>Transaction Id: {details.tran_id}</p>
                  <p>Card Type: {details.card_type}</p>
                  <p>Card No: {details.card_no}</p>
                  <p>Store Amount: {details.store_amount}</p>
                  <p>Bank Transaction Id: {details.bank_tran_id}</p>
                  <p className={`${this.statusClass(details)} fw-bold`}>
                    Status: {details.status}
                  </p>
                  <p>
                    Transaction Date:{' '}
                    {moment(
                      details.tran_date.split('+').slice(0, -1).join(' ')
                    ).format('DD MMM YYYY')}
                    {}
                  </p>
                </div>
              ))}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default PaymentSourceModal;
