import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BtnLoading from './BtnLoading';
import PatientDob from './PatientDob';

const PatientAddModal = ({
  showModal,
  setShowModal,
  setItemModal,
  userDetails,
  userAllPatients,
  setUserPatients,
}) => {
  const [patientName, setPatientName] = useState('');
  const [patientGenter, setPatientGenter] = useState('');
  const [patientDobb, setPatientDobb] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePatientFormClr = () => {
    setPatientName('');
    setPatientGenter('');
    setPatientDobb('');
    setLoading(false);
  };

  const postPatient = (data) => {
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user_management/patient/`, data)
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`Patient added successfully.`, {
          autoClose: 3000,
        });
        handlePatientFormClr();
        const newPat = [resp.data].map((p) => {
          return {
            ...p,
            value: p.full_name,
            label: p.full_name,
          };
        });
        setUserPatients([...newPat, ...userAllPatients]);
        // this.setUserPatients([...newPat, ...userAllPatients]);
      })
      .catch((error) => {
        // setPtnLoading(false);
        console.log(error.response);
        handlePatientFormClr();
      });
  };

  const handlePatientAdd = (e) => {
    e.preventDefault();

    if (patientName.trim() === '') {
      toast.error(`Please enter patient name.`, {
        autoClose: 3000,
      });
    } else if (patientName.length < 4) {
      toast.error(`Patient name must be at least 4 character long.`, {
        autoClose: 3000,
      });
    } else if (patientDobb === '' || patientDobb === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    } else if (patientGenter === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else {
      const patientData = {
        full_name: patientName,
        dob: moment(patientDobb).format('YYYY-MM-DD'),
        sex: patientGenter,
        user: userDetails.id,
      };

      const check = userAllPatients.find(
        (item) => item.full_name.toLowerCase() === patientName.toLowerCase()
      );

      if (check) {
        toast.error(
          `A patient already exists with this name in your family, please try another name.`
        );
      } else {
        postPatient(patientData);
      }
    }
  };

  return (
    <Modal
      show={showModal}
      animation={false}
      onHide={() => setShowModal(false)}
      size='lg'
    >
      <ModalBody className='ord-edt-itm-mdl ord-edt-itm-mdl-add pat-add-modal'>
        <div className='d-flex justify-content-between mb-5'>
          <button
            className='btn btn-light modal-close'
            onClick={() => {
              setItemModal(true);
              setShowModal(false);
            }}
          >
            Back
          </button>
          <button
            className='btn btn-light modal-close'
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
        </div>
        <form className='row' onSubmit={handlePatientAdd}>
          <div className='col-md-6'>
            <div className='mb-7'>
              <p className='item-title fw-bold fs-6'>Patient Name *</p>
              <input
                type='text'
                className='form-control form-control-lg'
                placeholder='Patient Name'
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='mb-7'>
              <p className='item-title fw-bold fs-6'>Patient Date Of Birth gg *</p>
              <div className='dob-inp'>
                <PatientDob
                  placeholder='DD/MM/YY'
                  patientDob={patientDobb}
                  setPatientDob={setPatientDobb}
                />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='mb-7'>
              <p className='item-title fw-bold fs-6'>Patient Gender *</p>
              <select
                className='form-select form-select-sloid form-select-lg'
                value={patientGenter}
                onChange={(e) => setPatientGenter(e.target.value)}
              >
                <option value=''>Select Gender</option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
              </select>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='d-flex justify-content-end'>
              {!loading ? (
                <button type='submit' className='btn btn-primary sub'>
                  Add Patient
                </button>
              ) : (
                <BtnLoading name='Adding...' btnClass='primary sub' />
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default PatientAddModal;
