import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
// import RevenueSvg from '../assets/img/revenue.svg';
// import UserSvg from '../assets/img/user.svg';
// import OrderSvg from '../assets/img/ordsvg.svg';
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { AuthContext } from '../context/AuthContext';
import HeaderToolbar from '../components/HeaderToolbar';
import AccountHomepage from '../components/AccountHomepage';

class Homepage extends Component {
  state = {
    today: moment().add(1, 'days').format('YYYY-MM-DD'),
    yesterday: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    week: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    subTotal: null,
    todayTotal: null,
    weekTotal: null,
    adminAccess: false,

    topOrders: [],
    recentOrders: [],

    daterange_start: moment().subtract(29, 'days'),
    daterange_end: moment(),
    showDateFilter: false,
    dateFilterName: 'yearly',
    ordersInRange: null,

    options2: {
      chart: {
        id: 'todaycompletionchart',
      },
      plotOptions: {
        radialBar: {
          inverseOrder: false,
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
            margin: 15,
            size: '70%',
            background: 'transparent',
            image: undefined,
            imageWidth: 150,
            imageHeight: 150,
            imageOffsetX: 0,
            imageOffsetY: 0,
            imageClipped: true,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 1,
              left: 2,
              blur: 3,
              opacity: 0.5,
            },
          },
          track: {
            show: true,
            startAngle: undefined,
            endAngle: undefined,
            background: '#f2f2f2',
            strokeWidth: '98%',
            opacity: 1,
            margin: 5,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.1,
            },
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Poppins',
              fontWeight: 400,
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: '14px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val + '%';
              },
            },
            total: {
              show: false,
              label: 'Total',
              color: '#373d3f',
              fontSize: '16px',
              fontFamily: undefined,
              fontWeight: 600,
              formatter: function (w) {
                return (
                  w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0) /
                    w.globals.series.length +
                  '%'
                );
              },
            },
          },
        },
      },

      stroke: {
        lineCap: 'round',
      },
      labels: ['Rate'],
    },
    series2: [0],

    options1: {
      chart: {
        height: '100%',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
    series1: {
      today: [{ name: 'Price', data: [1] }],
      week: [{ name: 'Price', data: [] }],
      total: [{ name: 'Price', data: [] }],
      range: [{ name: 'Price', data: [] }],
    },
    currentRole: 'User',
  };

  setDateFilterName = (value) => {
    this.setState({ dateFilterName: value });
  };

  order_status = {
    pending: 'Pending',
    waiting_for_confirmation: 'Waiting for Confirmation',
    service_on_hold: 'Service on Hold',
    confirmed: 'Confirmed',
    cancel: 'Cancel',
    completed: 'Completed',
  };

  fetchTotal = (id) => {
    // console.log(`total: ${process.env.REACT_APP_BASE_URL}/order/order-summery`);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-summery/${id}`)
      .then((resp) => {
        // console.log('total', resp.data);
        this.setState({ subTotal: resp.data });
      });
  };

  fetchToday = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-summery/${id}/${this.state.yesterday}/${this.state.today}`
      )
      .then((resp) => {
        // console.log('today', resp.data);
        this.setState({ todayTotal: resp.data });
        this.calculateComplettionRate(
          resp.data.today_order_confirm,
          resp.data.today_order_completed
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  fetchWeek = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-summery/${id}/${this.state.week}/${this.state.today}`
      )
      .then((resp) => {
        // console.log('week', resp.data);
        this.setState({ weekTotal: resp.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  fetcRecentOrders = (fetchFor) => {
    if (fetchFor.for === 'Lab Partner') {
      // console.log('Load Lab Partner orders');
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/order/order-tree/?page=1&limit=5&ofset=0&is_archived=false&external_source_user=${fetchFor.id}`
        )
        .then((resp) => {
          // console.log('recent order: ', resp.data.results);
          this.setState({ recentOrders: resp.data.results });
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/order/order-tree/?page=1&limit=5&ofset=0&is_archived=false`
        )
        .then((resp) => {
          // console.log('recent order: ', resp.data.results);
          this.setState({ recentOrders: resp.data.results });
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  fetcTopOrders = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/top-product`)
      .then((resp) => {
        // console.log('top: ', resp.data.top_diagnostic.length);
        this.setState({ topOrders: resp.data.top_diagnostic });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  calculateComplettionRate = (confirmOrder, completeOrder) => {
    if (completeOrder + confirmOrder !== 0) {
      const rate = Math.ceil(
        (completeOrder / (completeOrder + confirmOrder)) * 100
      );
      // console.log('rate: ', completeOrder, confirmOrder);
      this.setState({ series2: [rate] });
    } else {
      this.setState({ series2: [100] });
    }
  };

  ellipsedString = (string, charNo) => {
    if (string.trim().length < charNo) return string;
    else return string.trim().slice(0, charNo) + '...';
  };

  sentenceCase = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  findUniqueKeyword = (givenStr, sep) => {
    let givenArr = givenStr.split(sep);
    let returnArr = [];

    givenArr.map((item) => {
      if (!returnArr.includes(item.trim())) returnArr.push(item.trim());
    });

    return returnArr.join(', ');
  };

  componentDidMount() {
    this.context.setPageTitle('Dashboard');
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    let fetchFor = { for: 'all', id: null };
    if (!token) {
      this.props.history.push('/login');
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
      if (user_details) {
        const userRole = JSON.parse(user_details);
        if (userRole.is_superuser) {
          this.fetchTotal(userRole.id);
          this.fetchToday(userRole.id);
          this.fetchWeek(userRole.id);
          this.setState({ adminAccess: true });
        } else {
          if (userRole.groups.length === 0) {
            this.props.history.push('/orders');
          } else if (
            userRole.groups[0].name === 'Medical Technologist' ||
            userRole.groups[0].name === 'User'
            // || userRole.groups[0].name === 'Lab Partner' ||
            // userRole.groups[0].name === 'External'
          ) {
            // console.log(userRole.groups[0].name);
            this.props.history.push('/orders');
          } else {
            this.fetchTotal(userRole.id);
            this.fetchToday(userRole.id);
            this.fetchWeek(userRole.id);
            this.setState({ adminAccess: true });
          }

          if (userRole.groups[0].name === 'Lab Partner') {
            fetchFor = { for: 'Lab Partner', id: userRole.id };
            this.setState({ currentRole: 'Partner' });
          }

          if (userRole.groups[0].name === 'Accounts') {
            this.setState({ currentRole: 'Accounts' });
          }

          if (
            userRole.groups[0].name === 'Order Manager' ||
            userRole.groups[0].name === 'Supervisor'
          ) {
            this.setState({ adminAccess: false });
          }
        }
      }
      this.fetcTopOrders();
      this.fetcRecentOrders(fetchFor);
    }
  }

  addZero = (string) => {
    if (0 <= string && string < 10) {
      return `0${string}`;
    } else {
      return string;
    }
  };

  fetchOrdersInRange = (start, end) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-summery/${this.context.user_details.id}/${start}/${end}`
      )
      .then((resp) => {
        // console.log('range ', resp.data);
        this.setState({ ordersInRange: resp.data });
      })
      .catch((error) => console.log(error.response));
  };

  handleDateRangeApply = (start, end) => {
    this.setState({
      daterange_start: start,
      daterange_end: end,
      showDateFilter: true,
    });

    this.fetchOrdersInRange(
      start.format('YYYY-MM-DD'),
      end.format('YYYY-MM-DD')
    );
  };

  render() {
    const {
      subTotal,
      todayTotal,
      weekTotal,
      topOrders,
      recentOrders,
      adminAccess,
      dateFilterName,
      ordersInRange,
      currentRole,
    } = this.state;

    return (
      <>
        <HeaderToolbar
          title='Dashboard'
          dateFilter={true}
          startDate={this.state.daterange_start}
          endDate={this.state.daterange_end}
          handleDateRangeApply={this.handleDateRangeApply}
          showDateFilter={this.state.showDateFilter}
          dateFilterName={this.state.dateFilterName}
          setDateFilterName={this.setDateFilterName}
          createAccess={false}
        />
        <div className='content d-flex flex-column flex-column-fluid'>
          {currentRole !== 'Accounts' ? (
            <div id='kt_content_container' className='container-xxl'>
              <div className='row g-5 g-xl-8'>
                <div className='col-xl-4'>
                  <Link
                    to='#'
                    className='card bg-danger hoverable card-xl-stretch mb-xl-8'
                  >
                    <div className='card-body'>
                      <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            d='M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z'
                            fill='black'
                          />
                          <path
                            opacity='0.3'
                            d='M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z'
                            fill='black'
                          />
                          <path
                            opacity='0.3'
                            d='M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z'
                            fill='black'
                          />
                        </svg>
                      </span>

                      <div className='text-white fw-bolder fs-2 mb-2 mt-5'>
                        Total Orders
                        {dateFilterName === 'daily' && (
                          <span className='fw-bolder fs-3'>
                            {todayTotal
                              ? `  (${this.addZero(todayTotal.order)})`
                              : `  (00)`}
                          </span>
                        )}
                        {dateFilterName === 'weekly' && (
                          <span className='fw-bolder fs-3'>
                            {weekTotal
                              ? `  (${this.addZero(weekTotal.order)})`
                              : `  (00)`}
                          </span>
                        )}
                        {dateFilterName === 'yearly' && (
                          <span className='fw-bolder fs-3'>
                            {subTotal
                              ? `  (${this.addZero(subTotal.order)})`
                              : `  (00)`}
                          </span>
                        )}
                        {dateFilterName === 'custom' && (
                          <span className='fw-bolder fs-3'>
                            {ordersInRange
                              ? `  (${this.addZero(ordersInRange.order)})`
                              : `  (00)`}
                          </span>
                        )}
                      </div>
                      <div className='fw-bold text-white'>
                        Total orders that have been confirmed from amarlab
                        operations
                      </div>
                    </div>
                  </Link>
                </div>
                <div className='col-xl-4'>
                  <Link
                    to='#'
                    className='card bg-primary hoverable card-xl-stretch mb-xl-8'
                  >
                    <div className='card-body'>
                      <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            opacity='0.3'
                            d='M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z'
                            fill='black'
                          />
                          <path
                            d='M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z'
                            fill='black'
                          />
                          <path
                            d='M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z'
                            fill='black'
                          />
                        </svg>
                      </span>

                      <div className='text-white fw-bolder fs-2 mb-2 mt-5'>
                        Total Users
                        {dateFilterName === 'daily' && (
                          <span className='fw-bolder fs-3'>
                            {todayTotal
                              ? `  (${this.addZero(todayTotal.ordered_user)})`
                              : `  (00)`}
                          </span>
                        )}
                        {dateFilterName === 'weekly' && (
                          <span className='fw-bolder fs-3'>
                            {weekTotal
                              ? `  (${this.addZero(weekTotal.ordered_user)})`
                              : `  (00)`}
                          </span>
                        )}
                        {dateFilterName === 'yearly' && (
                          <span className='fw-bolder fs-3'>
                            {subTotal
                              ? `  (${this.addZero(subTotal.ordered_user)})`
                              : `  (00)`}
                          </span>
                        )}
                        {dateFilterName === 'custom' && (
                          <span className='fw-bolder fs-3'>
                            {ordersInRange
                              ? `  (${this.addZero(
                                  ordersInRange.ordered_user
                                )})`
                              : `  (00)`}
                          </span>
                        )}
                      </div>
                      <div className='fw-bold text-white'>
                        Total ordered users
                      </div>
                    </div>
                  </Link>
                </div>
                <div className='col-xl-4'>
                  <Link
                    to='#'
                    className='card bg-success hoverable card-xl-stretch mb-5 mb-xl-8'
                  >
                    <div className='card-body'>
                      <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            opacity='0.3'
                            d='M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z'
                            fill='black'
                          />
                          <path
                            d='M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z'
                            fill='black'
                          />
                        </svg>
                      </span>

                      <div className='text-white fw-bolder fs-2 mb-2 mt-5'>
                        Total Patients
                        {dateFilterName === 'daily' && (
                          <span className='fw-bolder fs-3'>
                            {todayTotal
                              ? `  (${this.addZero(
                                  todayTotal.ordered_patient
                                )})`
                              : `  (00)`}
                          </span>
                        )}
                        {dateFilterName === 'weekly' && (
                          <span className='fw-bolder fs-3'>
                            {weekTotal
                              ? `  (${this.addZero(weekTotal.ordered_patient)})`
                              : `  (00)`}
                          </span>
                        )}
                        {dateFilterName === 'yearly' && (
                          <span className='fw-bolder fs-3'>
                            {subTotal
                              ? `  (${this.addZero(subTotal.ordered_patient)})`
                              : `  (00)`}
                          </span>
                        )}
                        {dateFilterName === 'custom' && (
                          <span className='fw-bolder fs-3'>
                            {ordersInRange
                              ? `  (${this.addZero(
                                  ordersInRange.ordered_patient
                                )})`
                              : `  (00)`}
                          </span>
                        )}
                      </div>
                      <div className='fw-bold text-white'>
                        Total ordered patients
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className='row gy-5 g-xl-8'>
                <div className='col-xl-4'>
                  <div className='card card-xl-stretch mb-xl-8'></div>
                  {/* {adminAccess ? (
                    <div className='card card-xl-stretch mb-xl-8'>
                      <div className='card-header border-0 py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bolder fs-3 mb-1'>
                            Sales Overview
                          </span>
                          <span className='text-muted fw-bold fs-7'>
                            Salse overview by filter
                          </span>
                        </h3>
                      </div>

                      <div className='card-body p-0 d-flex flex-column'>
                        <div className='card-p pt-5 bg-body flex-grow-1'>
                          <div className='row g-0'>
                            <div className='col mr-8'>
                              <div className='fs-7 text-muted fw-bold'>GMV</div>

                              <div className='d-flex align-items-center'>
                                {dateFilterName === 'daily' && (
                                  <div className='fs-4 fw-bolder'>
                                    {todayTotal
                                      ? `BDT ${this.addZero(
                                          Number(todayTotal.price)
                                        )}`
                                      : `00`}
                                  </div>
                                )}
                                {dateFilterName === 'weekly' && (
                                  <div className='fs-4 fw-bolder'>
                                    {weekTotal
                                      ? `BDT ${this.addZero(
                                          Number(weekTotal.price)
                                        )}`
                                      : `00`}
                                  </div>
                                )}
                                {dateFilterName === 'yearly' && (
                                  <div className='fs-4 fw-bolder'>
                                    {subTotal
                                      ? `BDT ${this.addZero(
                                          Number(subTotal.price)
                                        )}`
                                      : `00`}
                                  </div>
                                )}
                                {dateFilterName === 'custom' && (
                                  <div className='fs-4 fw-bolder'>
                                    {ordersInRange
                                      ? `BDT ${this.addZero(
                                          Number(ordersInRange.price)
                                        )}`
                                      : `00`}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className='col'>
                              <div className='fs-7 text-muted fw-bold'>
                                Total Sale
                              </div>

                              {dateFilterName === 'daily' && (
                                <div className='fs-4 fw-bolder'>
                                  {todayTotal
                                    ? `BDT ${this.addZero(
                                        Number(
                                          todayTotal.price - todayTotal.discount
                                        )
                                      )}`
                                    : `00`}
                                </div>
                              )}
                              {dateFilterName === 'weekly' && (
                                <div className='fs-4 fw-bolder'>
                                  {weekTotal
                                    ? `BDT ${this.addZero(
                                        Number(
                                          weekTotal.price - weekTotal.discount
                                        )
                                      )}`
                                    : `00`}
                                </div>
                              )}
                              {dateFilterName === 'yearly' && (
                                <div className='fs-4 fw-bolder'>
                                  {subTotal
                                    ? `BDT ${this.addZero(
                                        Number(
                                          subTotal.price - subTotal.discount
                                        )
                                      )}`
                                    : `00`}
                                </div>
                              )}
                              {dateFilterName === 'custom' && (
                                <div className='fs-4 fw-bolder'>
                                  {ordersInRange
                                    ? `BDT ${this.addZero(
                                        Number(
                                          ordersInRange.price -
                                            ordersInRange.discount
                                        )
                                      )}`
                                    : `00`}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='row g-0 mt-8'>
                            <div className='col mr-8'>
                              <div className='fs-7 text-muted fw-bold'>Due</div>

                              {dateFilterName === 'daily' && (
                                <div className='fs-4 fw-bolder'>
                                  {todayTotal
                                    ? `BDT ${this.addZero(
                                        Number(todayTotal.due)
                                      )}`
                                    : `00`}
                                </div>
                              )}
                              {dateFilterName === 'weekly' && (
                                <div className='fs-4 fw-bolder'>
                                  {weekTotal
                                    ? `BDT ${this.addZero(
                                        Number(weekTotal.due)
                                      )}`
                                    : `00`}
                                </div>
                              )}
                              {dateFilterName === 'yearly' && (
                                <div className='fs-4 fw-bolder'>
                                  {subTotal
                                    ? `BDT ${this.addZero(
                                        Number(subTotal.due)
                                      )}`
                                    : `00`}
                                </div>
                              )}
                              {dateFilterName === 'custom' && (
                                <div className='fs-4 fw-bolder'>
                                  {ordersInRange
                                    ? `BDT ${this.addZero(
                                        Number(ordersInRange.due)
                                      )}`
                                    : `00`}
                                </div>
                              )}
                            </div>

                            <div className='col'>
                              <div className='fs-7 text-muted fw-bold'>
                                Discount
                              </div>

                              <div className='d-flex align-items-center'>
                                {dateFilterName === 'daily' && (
                                  <div className='fs-4 fw-bolder'>
                                    {todayTotal
                                      ? `BDT ${this.addZero(
                                          Number(todayTotal.discount)
                                        )}`
                                      : `00`}
                                  </div>
                                )}
                                {dateFilterName === 'weekly' && (
                                  <div className='fs-4 fw-bolder'>
                                    {weekTotal
                                      ? `BDT ${this.addZero(
                                          Number(weekTotal.discount)
                                        )}`
                                      : `00`}
                                  </div>
                                )}
                                {dateFilterName === 'yearly' && (
                                  <div className='fs-4 fw-bolder'>
                                    {subTotal
                                      ? `BDT ${this.addZero(
                                          Number(subTotal.discount)
                                        )}`
                                      : `00`}
                                  </div>
                                )}
                                {dateFilterName === 'custom' && (
                                  <div className='fs-4 fw-bolder'>
                                    {ordersInRange
                                      ? `BDT ${this.addZero(
                                          Number(ordersInRange.discount)
                                        )}`
                                      : `00`}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className='mixed-widget-3-chart card-rounded-bottom mb-6 mr-2'
                          data-kt-chart-color='primary'
                          style={{ height: 250 }}
                        >
                          {subTotal && (
                            <Chart
                              options={{
                                ...this.state.options1,
                                xaxis: {
                                  categories: subTotal.price_cart.map((item) =>
                                    moment(item.month).format('MMM YY')
                                  ),
                                },
                              }}
                              series={[
                                {
                                  name: 'price',
                                  data: subTotal.price_cart.map(
                                    (item) => item.price
                                  ),
                                },
                              ]}
                              type='area'
                              width='100%'
                              height='100%'
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='card card-xl-stretch mb-xl-8'></div>
                  )} */}
                </div>

                <div className='col-xl-4'>
                  <div className='card card-xl-stretch'>
                    <div className='card-header border-0 py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>
                          Todays Completion Rate
                        </span>
                        <span className='text-muted fw-bold fs-7'>
                          Today’s confirmed order from Amarlab operations
                        </span>
                      </h3>
                    </div>

                    <div className='card-body d-flex flex-column'>
                      <div className='flex-grow-1 d-flex justify-content-center align-items-center'>
                        <div
                          className='mixed-widget-4-chart circle-ch'
                          data-kt-chart-color='primary'
                          id='hide-translate'
                        >
                          <Chart
                            options={this.state.options2}
                            series={this.state.series2}
                            type='radialBar'
                            width='100%'
                            height='100%'
                          />
                        </div>
                      </div>
                      <div className='pt-5'>
                        <p className='text-center fs-7 pb-5'>
                          <span className='badge badge-light-danger fs-8'>
                            Notes:
                          </span>
                          &#160; Completed order rate that came today and has
                          been completed today
                        </p>
                        <Link
                          to='/orders'
                          className='btn btn-primary w-100 py-3'
                        >
                          Orders
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-xl-4'>
                  <div
                    className='card card-xl-stretch mb-5 mb-xl-8 pb-3'
                    style={{ maxHeight: '520px' }}
                  >
                    {currentRole !== 'Partner' && (
                      <>
                        <div className='card-header align-items-center border-0 mt-3'>
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>
                              Most Ordered Tests
                            </span>
                            <span className='text-muted fw-bold fs-7'>
                              Most ordered test and order times
                            </span>
                          </h3>
                        </div>

                        <div className='card-body pt-5 overflow-auto '>
                          {topOrders.map((order, index) => (
                            <div key={index} className='d-flex mb-7'>
                              <div className='symbol symbol-60px symbol-2by3 flex-shrink-0 me-4 '>
                                {order.image ? (
                                  <img
                                    src={order.image}
                                    className='mw-100 border border-1'
                                    alt=''
                                  />
                                ) : (
                                  <i
                                    className='fas fa-file-medical-alt mw-100 d-flex justify-content-center align-items-center border border-2'
                                    style={{
                                      fontSize: '15px',
                                      borderRadius: 5,
                                    }}
                                  />
                                )}
                              </div>

                              <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                                <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                                  <Link
                                    to='#'
                                    className='fs-5 text-gray-800 text-hover-primary fw-bolder'
                                  >
                                    {order.name}
                                  </Link>
                                  <span className='text-gray-400 fw-bold fs-7 my-1'>
                                    {order.description
                                      ? this.ellipsedString(
                                          order.description,
                                          45
                                        )
                                      : 'No description found'}
                                  </span>
                                  {order.category_name && (
                                    <span className='text-gray-400 fw-bold fs-7'>
                                      Category:
                                      <Link
                                        to='#'
                                        className='text-primary fw-bold'
                                      >
                                        {this.findUniqueKeyword(
                                          order.category_name,
                                          ','
                                        )}
                                      </Link>
                                    </span>
                                  )}
                                </div>
                                {/* <div className="text-end py-lg-0 py-2">
                        <span className="text-gray-800 fw-boldest fs-3">24,900</span>
                        <span className="text-gray-400 fs-7 fw-bold d-block">Sales</span>
                      </div> */}
                              </div>

                              <div className='d-flex align-items-center flex-wrap min-w-75px max-w-75px justify-content-end'>
                                <div className='text-end py-lg-0 py-2'>
                                  <span className='text-gray-800 fw-boldest fs-3'>
                                    {order.num_order}
                                  </span>
                                  <span className='text-gray-400 fs-7 fw-bold d-block'>
                                    Order
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className='g-5 gx-xxl-8'>
                <div className='card'>
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>
                        Recent Orders
                      </span>
                      <span className='text-muted mt-1 fw-bold fs-7'>
                        Recent {recentOrders.length} orders
                      </span>
                    </h3>
                    <div className='card-toolbar'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <span className='svg-icon svg-icon-2'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24px'
                            height='24px'
                            viewBox='0 0 24 24'
                          >
                            <g
                              stroke='none'
                              strokeWidth={1}
                              fill='none'
                              fillRule='evenodd'
                            >
                              <rect
                                x='5'
                                y='5'
                                width='5'
                                height='5'
                                rx='1'
                                fill='#000000'
                              />
                              <rect
                                x='14'
                                y='5'
                                width='5'
                                height='5'
                                rx='1'
                                fill='#000000'
                                opacity='0.3'
                              />
                              <rect
                                x='5'
                                y='14'
                                width='5'
                                height='5'
                                rx='1'
                                fill='#000000'
                                opacity='0.3'
                              />
                              <rect
                                x='14'
                                y='14'
                                width='5'
                                height='5'
                                rx='1'
                                fill='#000000'
                                opacity='0.3'
                              />
                            </g>
                          </svg>
                        </span>
                      </button>

                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
                        data-kt-menu='true'
                      >
                        <div className='menu-item px-3'>
                          <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                            Quick Actions
                          </div>
                        </div>

                        <div className='separator mb-3 opacity-75'></div>

                        <div className='menu-item px-3'>
                          <Link to='#' className='menu-link px-3'>
                            New Ticket
                          </Link>
                        </div>

                        <div className='menu-item px-3'>
                          <Link to='#' className='menu-link px-3'>
                            New Customer
                          </Link>
                        </div>

                        <div
                          className='menu-item px-3'
                          data-kt-menu-trigger='hover'
                          data-kt-menu-placement='right-start'
                        >
                          <Link to='#' className='menu-link px-3'>
                            <span className='menu-title'>New Group</span>
                            <span className='menu-arrow'></span>
                          </Link>

                          <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                            <div className='menu-item px-3'>
                              <Link to='#' className='menu-link px-3'>
                                Admin Group
                              </Link>
                            </div>

                            <div className='menu-item px-3'>
                              <Link to='#' className='menu-link px-3'>
                                Staff Group
                              </Link>
                            </div>

                            <div className='menu-item px-3'>
                              <Link to='#' className='menu-link px-3'>
                                Member Group
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className='menu-item px-3'>
                          <Link to='#' className='menu-link px-3'>
                            New Contact
                          </Link>
                        </div>

                        <div className='separator mt-3 opacity-75'></div>

                        <div className='menu-item px-3'>
                          <div className='menu-content px-3 py-3'>
                            <Link
                              to='#'
                              className='btn btn-primary btn-sm px-4'
                            >
                              Generate Reports
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-body pt-3'>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='border-0'>
                            <th className='p-0'></th>
                            <th className='p-0 min-w-150px'></th>
                            <th className='p-0 min-w-200px'></th>
                            <th className='p-0 min-w-150px'></th>
                            <th className='p-0 min-w-100px text-end'></th>
                          </tr>
                        </thead>

                        <tbody>
                          {recentOrders.map((order, index) => (
                            <tr key={index}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-45px me-5'>
                                    {/* <img alt="Pic" src={OrderImg} /> */}
                                    {order.orderitem.length &&
                                    order.orderitem[0].purchasable_order_item &&
                                    order.orderitem[0].purchasable_order_item
                                      .testitem_purchasable_oi &&
                                    order.orderitem[0].purchasable_order_item
                                      .testitem_purchasable_oi.branch.lab
                                      .logo ? (
                                      <img
                                        alt='Pic'
                                        src={
                                          order.orderitem[0]
                                            .purchasable_order_item
                                            .testitem_purchasable_oi.branch.lab
                                            .logo
                                        }
                                        className='border border-2 p-2'
                                      />
                                    ) : (
                                      <span className='symbol-label bg-light-danger text-danger fw-bolder'></span>
                                    )}
                                  </div>

                                  <div className='d-flex justify-content-start flex-column'>
                                    {/* {(order.orderitem.length && order.orderitem[0].purchasable_order_item &&
                                order.orderitem[0].purchasable_order_item.testitem_purchasable_oi &&
                                order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.diagnostic_test) &&
                                <Link to="" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                  {order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.diagnostic_test.name}
                                </Link>
                              } */}
                                    {order.order_id && (
                                      <Link
                                        to=''
                                        className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                                      >
                                        {`# ${order.order_id}`}
                                      </Link>
                                    )}
                                    {order.user && order.user.first_name ? (
                                      <Link
                                        to=''
                                        className='text-muted text-hover-primary fw-bold text-muted d-block fs-7'
                                      >
                                        {/* <span className="text-dark">Name:</span>  */}
                                        {order.user.first_name +
                                          ' ' +
                                          order.user.last_name}{' '}
                                        ({order.user.username})
                                      </Link>
                                    ) : (
                                      <Link
                                        to=''
                                        className='text-muted text-hover-primary fw-bold text-muted d-block fs-7'
                                      >
                                        {/* <span className="text-dark">Mobile:</span>  */}
                                        {order.user.username}
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className='text-end'>
                                <Link
                                  to='#'
                                  className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                                >
                                  {'BDT ' + this.addZero(order.total_price)}
                                </Link>
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  {order.orderdetail &&
                                  order.orderdetail.payment_status
                                    ? this.sentenceCase(
                                        order.orderdetail.payment_status
                                      )
                                    : 'Unpaid'}
                                </span>
                              </td>
                              <td className='text-muted fw-bold text-end'>
                                {order.orderitem.length &&
                                  this.findUniqueKeyword(
                                    order.orderitem
                                      .map((item) =>
                                        !item.purchasable_order_item
                                          ? 'no name found'
                                          : item.purchasable_order_item
                                              .testitem_purchasable_oi
                                          ? item.purchasable_order_item
                                              .testitem_purchasable_oi
                                              .diagnostic_test.name
                                          : 'no name found'
                                      )
                                      .join(', '),
                                    ','
                                  )}
                              </td>
                              <td className='text-end'>
                                {order.orderdetail &&
                                  order.orderdetail.order_status &&
                                  order.orderdetail.order_status ===
                                    'confirmed' && (
                                    <span className='badge badge-light-success'>
                                      {this.order_status.confirmed}
                                    </span>
                                  )}
                                {order.orderdetail &&
                                  order.orderdetail.order_status &&
                                  order.orderdetail.order_status ===
                                    'completed' && (
                                    <span className='badge badge-light-primary'>
                                      {this.order_status.completed}
                                    </span>
                                  )}
                                {order.orderdetail &&
                                  order.orderdetail.order_status &&
                                  order.orderdetail.order_status ===
                                    'cancel' && (
                                    <span className='badge badge-light-danger'>
                                      {this.order_status.cancel}
                                    </span>
                                  )}
                                {order.orderdetail &&
                                  order.orderdetail.order_status &&
                                  order.orderdetail.order_status ===
                                    'service_on_hold' && (
                                    <span className='badge badge-light-warning'>
                                      {this.order_status.service_on_hold}
                                    </span>
                                  )}
                                {order.orderdetail &&
                                  order.orderdetail.order_status &&
                                  order.orderdetail.order_status ===
                                    'waiting_for_confirmation' && (
                                    <span className='badge badge-light-info'>
                                      {
                                        this.order_status
                                          .waiting_for_confirmation
                                      }
                                    </span>
                                  )}
                                {order.orderdetail &&
                                  order.orderdetail.order_status &&
                                  order.orderdetail.order_status ===
                                    'pending' && (
                                    <span className='badge badge-light-warning'>
                                      {this.order_status.pending}
                                    </span>
                                  )}
                                {(!order.orderdetail ||
                                  !order.orderdetail.order_status) && (
                                  <span className='badge badge-light-warning'>
                                    {this.order_status.pending}
                                  </span>
                                )}
                              </td>
                              <td className='text-end'>
                                <Link
                                  to='/orders'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                  <span className='svg-icon svg-icon-3'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                    >
                                      <path
                                        opacity='0.3'
                                        d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                                        fill='black'
                                      />
                                      <path
                                        d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                                        fill='black'
                                      />
                                    </svg>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                          {/* <tr>
                      <td>
                        <div className="d-flex align-items-center">

                          <div className="symbol symbol-45px me-5">
                            <span className="symbol-label bg-light-danger text-danger fw-bolder">M</span>
                          </div>


                          <div className="d-flex justify-content-start flex-column">
                            <Link to="#" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Melody Macy</Link>
                            <Link to="#" className="text-muted text-hover-primary fw-bold text-muted d-block fs-7">
                              <span className="text-dark">Email</span>: melody@altbox.com</Link>
                          </div>

                        </div>
                      </td>
                      <td className="text-end">
                        <Link to="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">$2,000,000</Link>
                        <span className="text-muted fw-bold text-muted d-block fs-7">Paid</span>
                      </td>
                      <td className="text-muted fw-bold text-end">ReactJs, HTML</td>
                      <td className="text-end">
                        <span className="badge badge-light-warning">In Progress</span>
                      </td>
                      <td className="text-end">
                        <Link to="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">

                          <span className="svg-icon svg-icon-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none">
                              <path
                                d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                fill="black" />
                              <path opacity="0.3"
                                d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                fill="black" />
                            </svg>
                          </span>

                        </Link>
                        <Link to="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">

                          <span className="svg-icon svg-icon-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none">
                              <path opacity="0.3"
                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                fill="black" />
                              <path
                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                fill="black" />
                            </svg>
                          </span>

                        </Link>
                        <Link to="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">

                          <span className="svg-icon svg-icon-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none">
                              <path
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="black" />
                              <path opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="black" />
                              <path opacity="0.5"
                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="black" />
                            </svg>
                          </span>

                        </Link>
                      </td>
                    </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <AccountHomepage
              dateFilterName={dateFilterName}
              todayTotal={todayTotal}
              weekTotal={weekTotal}
              subTotal={subTotal}
              ordersInRange={ordersInRange}
              topOrders={topOrders}
              series2={this.state.series2}
            />
          )}
        </div>
      </>
    );
  }
}

export default Homepage;
Homepage.contextType = AuthContext;
