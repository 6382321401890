import React from 'react'
import { Modal } from 'react-bootstrap'
import warningLogo from '../assets/img/warning.svg';

export const DeleteAlert = ({
  showDelModal,
  setShowDelModal,
  onAllow,
  onCancel
}) => {
  return (
    <>
      <Modal
        show={showDelModal}
        onHide={() => setShowDelModal(!showDelModal )}
        animation={false}
        centered
        className='delete_confirmation'
      >
        <div className='modal-body mx-auto'>
          <p className='text-center icon mb-0'>
            <img src={warningLogo} alt='' />
          </p>
          <div className='details'>
            <h5>Are you sure?</h5>
            <p>Once delete, it will be permanently deleted.</p>
          </div>
          <p className='buttons mb-0 text-center'>
            <button
              className='btn btn-primary me-2'
              onClick={() => {
                setShowDelModal(!showDelModal);
                if (onCancel) onCancel();
              }}
            >
              No
            </button>
            <button
              className='btn btn-danger ml-1'
              onClick={e => {
                e.preventDefault();
                onAllow();
              }}
            >
              Yes
            </button>
          </p>
        </div>
      </Modal>
    </>
  )
}

export default DeleteAlert
