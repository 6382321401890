import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AllDateSelect from '../components/AllDateSelect';
import BtnLoading from '../components/BtnLoading';
import HeaderToolbar from '../components/HeaderToolbar';
import { AuthContext } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';

class ReferenceAdd extends Component {
  state = {
    showCouponAddForm: false,
    channelArray: [],
    couponArray: [],

    channel: '',
    referenceName: '',
    referenceEmail: '',
    referencePhone: '',
    referenceDescription: '',
    onboardDate: null,
    dob: null,
    marriageDate: null,
    accManagerName: '',
    accManagerEmail: '',
    couponCode: false,
    createLoading: false,

    couponRangeS: '',
    couponRangeE: '',
    couponRangeCoupon: '',
    couponEditMode: false,
    couponEditid: '',

    referenceCouponArr: [],

    pageEditMode: false,
    pagePutId: null,
    edirExistingRefCup: [],
    couponAddUpLoading: false,
  };

  fetchChannel = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/channel/?limit=1000`)
      .then((resp) => {
        // console.log(resp.data.results);
        const activeChannels = resp.data.results.filter((item) => item.status);
        this.setState({ channelArray: activeChannels });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  fetchCoupon = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/cupon/?limit=1000`)
      .then((resp) => {
        // console.log(resp.data.results);
        const activeCupons = resp.data.results.filter((item) => item.is_active);
        this.setState({ couponArray: activeCupons });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  setOnboardDate = (v) => {
    this.setState({ onboardDate: v });
  };

  setDob = (v) => {
    this.setState({ dob: v });
  };

  setMarriageDate = (v) => {
    this.setState({ marriageDate: v });
  };

  fetchEditReference = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/reference-get/${id}/`)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          channel: resp.data.channel.id,
          referenceName: resp.data.name,
          referenceEmail: resp.data.email,
          referencePhone: resp.data.number,
          referenceDescription: resp.data.description,
          onboardDate: resp.data.onboarding_date
            ? new Date(resp.data.onboarding_date)
            : null,
          dob: resp.data.dob ? new Date(resp.data.dob) : null,
          marriageDate: resp.data.marriage_date
            ? new Date(resp.data.marriage_date)
            : null,
          accManagerName: resp.data.account_manager_name,
          accManagerEmail: resp.data.account_manager_email,
          couponCode: resp.data.status,

          pageEditMode: true,
          pagePutId: resp.data.id,
          edirExistingRefCup: resp.data.ref_cupon_ref,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  componentDidMount() {
    this.context.setPageTitle('Add Reference');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Reference', link: '/reference' },
      { name: 'Add Reference', link: '/reference-add' },
    ]);
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchChannel();
      this.fetchCoupon();

      if (this.props.match.params.id) {
        this.fetchEditReference(this.props.match.params.id);
      }
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Lab Partner' ||
        userRole.groups[0].name === 'External'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchChannel();
        this.fetchCoupon();

        if (this.props.match.params.id) {
          this.fetchEditReference(this.props.match.params.id);
        }
      }
    }
  }

  postReference = (data) => {
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/reference/`, data)
      .then((resp) => {
        // console.log(resp.data);
        // this.setState({ channelAddModal: false });
        toast.success('Channel added successfully.');
        this.setState({ createLoading: false });
        this.props.history.push('/reference');
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ createLoading: false });
        if (error.response.status === 400) {
          toast.error(`${error.response.data.description}`);
        } else {
          toast.error('Sorry something went wrong, please try again later.');
        }
      });
  };

  putReference = (data, id) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/reference-single/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success('Reference updated successfully.');
        this.setState({ createLoading: false });
        this.props.history.push('/reference');
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ createLoading: false });
        if (error.response.status === 400) {
          toast.error(`${error.response.data.description}`);
        } else {
          toast.error('Sorry something went wrong, please try again later.');
        }
      });
  };

  handleReferenceCreate = () => {
    let numberPattern = /^01\d{9}$/;
    if (this.state.referenceName.trim() === '') {
      toast.error('Please enter reference name.');
    } else if (this.state.channel === '') {
      toast.error('Please select a channel.');
    } else if (
      this.state.referencePhone &&
      !numberPattern.test(this.state.referencePhone)
    ) {
      toast.error('Please enter a valid mobile number.');
    } else {
      const { onboardDate, dob, marriageDate } = this.state;
      this.setState({ createLoading: true });
      if (!this.state.pageEditMode) {
        const refCupon = this.state.referenceCouponArr.map((item) => {
          return {
            cupon: item.cupon.id,
            from_range: item.from_range,
            to_range: item.to_range,
          };
        });
        // console.log(refCupon);

        const data = {
          name: this.state.referenceName,
          channel: this.state.channel,
          email: this.state.referenceEmail,
          number: this.state.referencePhone,
          description: this.state.referenceDescription,
          onboarding_date: onboardDate
            ? moment(this.state.onboardDate).format('YYYY-MM-DD')
            : null,
          dob: dob ? moment(this.state.dob).format('YYYY-MM-DD') : null,
          marriage_date: marriageDate
            ? moment(this.state.marriageDate).format('YYYY-MM-DD')
            : null,
          account_manager_name: this.state.accManagerName,
          account_manager_email: this.state.accManagerEmail,
          status: this.state.couponCode,
          ref_cupon_ref: refCupon,
          created_by: this.context.user_details.id,
        };
        // console.log(data);

        this.postReference(data);
      } else {
        const data = {
          name: this.state.referenceName,
          channel: this.state.channel,
          email: this.state.referenceEmail,
          number: this.state.referencePhone,
          description: this.state.referenceDescription,
          onboarding_date: onboardDate
            ? moment(this.state.onboardDate).format('YYYY-MM-DD')
            : null,
          dob: dob ? moment(this.state.dob).format('YYYY-MM-DD') : null,
          marriage_date: marriageDate
            ? moment(this.state.marriageDate).format('YYYY-MM-DD')
            : null,
          account_manager_name: this.state.accManagerName,
          account_manager_email: this.state.accManagerEmail,
          status: this.state.couponCode,
        };

        this.putReference(data, this.state.pagePutId);
      }
    }
  };

  postRefeCoupon = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/reference-cupon/`, data)
      .then((resp) => {
        // console.log(resp.data);
        toast.success('Coupon added successfully.');
        if (this.props.match.params.id) {
          this.fetchEditReference(this.props.match.params.id);
        }
        // this.setState({ createLoading: false });
        // this.props.history.push('/reference');
        this.setState({ showCouponAddForm: false, couponAddUpLoading: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ showCouponAddForm: false, couponAddUpLoading: false });
        // this.setState({ createLoading: false });
        // if (error.response.status === 400) {
        //   toast.error(`${error.response.data.description}`);
        // } else {
        //   toast.error('Sorry something went wrong, please try again later.');
        // }
      });
  };

  putRefeCoupon = (data, id) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/reference-cupon/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success('Coupon updated successfully.');
        if (this.props.match.params.id) {
          this.fetchEditReference(this.props.match.params.id);
        }
        // this.setState({ createLoading: false });
        // this.props.history.push('/reference');
        this.setState({ showCouponAddForm: false, couponAddUpLoading: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ showCouponAddForm: false, couponAddUpLoading: false });
        // this.setState({ createLoading: false });
        // if (error.response.status === 400) {
        //   toast.error(`${error.response.data.description}`);
        // } else {
        //   toast.error('Sorry something went wrong, please try again later.');
        // }
      });
  };

  handleCouponPostPut = () => {
    if (this.state.couponRangeS === '') {
      toast.error('Please enter range start.');
    } else if (this.state.couponRangeE === '') {
      toast.error('Please enter range end.');
    } else if (this.state.couponRangeCoupon === '') {
      toast.error('Please select a coupon.');
    } else {
      if (!this.state.pageEditMode) {
        const couponDetails = this.state.couponArray.filter(
          (coupon) => coupon.id === parseInt(this.state.couponRangeCoupon)
        );

        const postdata = {
          cupon: couponDetails[0],
          from_range: this.state.couponRangeS,
          to_range: this.state.couponRangeE,
          id: uuidv4(),
        };

        const putdata = {
          cupon: couponDetails[0],
          from_range: this.state.couponRangeS,
          to_range: this.state.couponRangeE,
        };

        if (!this.state.couponEditMode) {
          this.setState({
            referenceCouponArr: [postdata, ...this.state.referenceCouponArr],
          });

          this.setState({
            couponRangeS: '',
            couponRangeE: '',
            couponRangeCoupon: '',
            showCouponAddForm: false,
            couponEditMode: false,
            couponEditid: '',
          });
        } else {
          // console.log('Update coupon');
          // console.log(this.state.referenceCouponArr);
          // console.log(putdata);
          // console.log(this.state.couponEditid);

          const newReferenceCouponArr = this.state.referenceCouponArr.map(
            (obj) => {
              if (obj.id === this.state.couponEditid) {
                return {
                  ...obj,
                  ...putdata,
                };
              }
              return obj;
            }
          );
          this.setState({ referenceCouponArr: newReferenceCouponArr });
          this.setState({
            couponRangeS: '',
            couponRangeE: '',
            couponRangeCoupon: '',
            showCouponAddForm: false,
            couponEditMode: false,
            couponEditid: '',
          });
        }
      } else {
        if (!this.state.couponEditMode) {
          const postData = {
            cupon: this.state.couponRangeCoupon,
            from_range: this.state.couponRangeS,
            to_range: this.state.couponRangeE,
            reference: this.state.pagePutId,
          };
          // console.log(postData);
          this.setState({ couponAddUpLoading: true });
          this.postRefeCoupon(postData);
          this.setState({
            couponRangeS: '',
            couponRangeE: '',
            couponRangeCoupon: '',
            couponEditMode: false,
            couponEditid: '',
          });
        } else {
          const putData = {
            cupon: this.state.couponRangeCoupon,
            from_range: this.state.couponRangeS,
            to_range: this.state.couponRangeE,
          };

          this.setState({ couponAddUpLoading: true });
          this.putRefeCoupon(putData, this.state.couponEditid);

          // console.log(putData);
          // console.log(this.state.couponEditid);
        }
      }
    }
  };

  handleDeleteCoupon = (c) => {
    // console.log(c);
    const newReferenceCouponArr = this.state.referenceCouponArr.filter(
      (item) => item !== c
    );

    this.setState({ referenceCouponArr: newReferenceCouponArr });
  };

  handleEditCoupon = (c) => {
    // console.log(c);

    this.setState({
      couponRangeS: c.from_range,
      couponRangeE: c.to_range,
      couponRangeCoupon: c.cupon.id,
      couponEditid: c.id,
      couponEditMode: true,
      showCouponAddForm: true,
    });
  };

  render() {
    const { onboardDate, dob, marriageDate, showCouponAddForm } = this.state;
    return (
      <>
        <HeaderToolbar title='Add Reference' />
        <div className='content d-flex flex-column flex-column-fluid ref-add-page'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-header border-0 pt-6 align-items-center'>
                    <h5 className='fs-6 mb-0'>Create Reference</h5>
                    <div style={{ position: 'relative' }}>
                      <Link to='/reference' className='btn btn-light me-4'>
                        Go Back
                      </Link>
                      {!this.state.createLoading ? (
                        <button
                          className='btn btn-primary'
                          onClick={this.handleReferenceCreate}
                        >
                          {this.state.pageEditMode ? 'Update' : 'Create'}
                        </button>
                      ) : (
                        <BtnLoading
                          name={this.state.pageEditMode ? 'Updating' : 'Adding'}
                          btnClass='primary'
                        />
                      )}
                      {showCouponAddForm && (
                        <div className='create-form'>
                          <div className='head d-flex justify-content-end'>
                            <div
                              className='button btn btn-light mb-10'
                              onClick={() =>
                                this.setState({
                                  couponRangeS: '',
                                  couponRangeE: '',
                                  couponRangeCoupon: '',
                                  couponEditid: '',
                                  showCouponAddForm: false,
                                  couponEditMode: false,
                                })
                              }
                            >
                              Close
                            </div>
                          </div>
                          <div className='body row mb-10'>
                            <div className='col-md-6 col-f'>
                              <div className='mb-7'>
                                <p className='item-title'>Coupon Code Range*</p>
                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  placeholder='From'
                                  value={this.state.couponRangeS}
                                  onChange={(e) =>
                                    this.setState({
                                      couponRangeS: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6 col-l'>
                              <div className='mb-7'>
                                <p className='item-title text-white'>-</p>
                                <div className='category-add'>
                                  <input
                                    type='text'
                                    className='form-control form-control-solid'
                                    placeholder='To'
                                    value={this.state.couponRangeE}
                                    onChange={(e) =>
                                      this.setState({
                                        couponRangeE: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className=''>
                                <p className='item-title'>Coupon*</p>
                                <div className='category-add'>
                                  <select
                                    className='form-select form-select-sloid'
                                    onChange={(e) =>
                                      this.setState({
                                        couponRangeCoupon: e.target.value,
                                      })
                                    }
                                    value={this.state.couponRangeCoupon}
                                  >
                                    <option value=''>Select Coupon</option>
                                    {this.state.couponArray.map((coupon, i) => (
                                      <option key={i} value={coupon.id}>
                                        {coupon.name}
                                      </option>
                                    ))}
                                  </select>
                                  {/* <button className='btn btn light'>+</button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='head d-flex justify-content-end'>
                            {!this.state.couponAddUpLoading ? (
                              <div
                                className='button btn btn-primary'
                                onClick={this.handleCouponPostPut}
                              >
                                {this.state.couponEditMode ? 'UPDATE' : 'SAVE'}{' '}
                                COUPON
                              </div>
                            ) : (
                              <BtnLoading
                                name={
                                  this.state.couponEditMode
                                    ? 'Updating'
                                    : 'Adding'
                                }
                                btnClass='primary button'
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-7 col-f-item'>
                        <div className='row'>
                          <div className='col-md-6 f-f-item'>
                            <div className='mb-5'>
                              <p className='item-title'>Reference Name*</p>
                              <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder='Reference Name'
                                value={this.state.referenceName}
                                onChange={(e) =>
                                  this.setState({
                                    referenceName: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className='mb-5'>
                              <p className='item-title'>Email</p>
                              <input
                                type='email'
                                className='form-control form-control-solid'
                                placeholder='Enter Email'
                                value={this.state.referenceEmail}
                                onChange={(e) =>
                                  this.setState({
                                    referenceEmail: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className='mb-5'>
                              <p className='item-title'>Phone Number</p>
                              <input
                                type='number'
                                className='form-control form-control-solid'
                                placeholder='Enter Mobile Number'
                                value={this.state.referencePhone}
                                onChange={(e) =>
                                  this.setState({
                                    referencePhone: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className=''>
                              <p className='item-title'>Description</p>
                              <textarea
                                className='form-control form-control-solid'
                                rows='9'
                                placeholder='This customer will pay via bKash'
                                value={this.state.referenceDescription}
                                onChange={(e) =>
                                  this.setState({
                                    referenceDescription: e.target.value,
                                  })
                                }
                                maxLength={990}
                              ></textarea>
                            </div>
                          </div>
                          <div className='col-md-6 f-l-item'>
                            <div className='mb-5'>
                              <p className='item-title'>Channel*</p>
                              <div className='category-add'>
                                <select
                                  className='form-select form-select-sloid'
                                  onChange={(e) =>
                                    this.setState({
                                      channel: e.target.value,
                                    })
                                  }
                                  value={this.state.channel}
                                >
                                  <option value=''>Select Channel</option>
                                  {this.state.channelArray.map((channel, i) => (
                                    <option key={i} value={channel.id}>
                                      {channel.name}
                                    </option>
                                  ))}
                                </select>
                                {/* <button className='btn btn light'>+</button> */}
                              </div>
                            </div>
                            <div className='mb-5'>
                              <p className='item-title'>Date Of Onboarding</p>
                              <AllDateSelect
                                placeholderText='DD/MM/YYYY'
                                sampleDate={onboardDate}
                                setSampleDate={this.setOnboardDate}
                              />
                            </div>
                            <div className='mb-5'>
                              <p className='item-title'>Date Of Birth</p>
                              <AllDateSelect
                                placeholderText='DD/MM/YYYY'
                                sampleDate={dob}
                                setSampleDate={this.setDob}
                              />
                            </div>
                            <div className='mb-5'>
                              <p className='item-title'>Marriage Day</p>
                              <AllDateSelect
                                placeholderText='DD/MM/YYYY'
                                sampleDate={marriageDate}
                                setSampleDate={this.setMarriageDate}
                              />
                            </div>
                            <div className='mb-5'>
                              <p className='item-title'>Account Manager Name</p>
                              <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder='Enter Name'
                                value={this.state.accManagerName}
                                onChange={(e) =>
                                  this.setState({
                                    accManagerName: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className=''>
                              <p className='item-title'>
                                Account Manager Email
                              </p>
                              <input
                                type='email'
                                className='form-control form-control-solid'
                                placeholder='Enter Email'
                                value={this.state.accManagerEmail}
                                onChange={(e) =>
                                  this.setState({
                                    accManagerEmail: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-5 col-l-item'>
                        <div className='switc mb-10 d-flex align-items-center'>
                          <p className='item-title mb-0'>Reference Status</p>
                          <label className='ms-3 form-check form-switch form-check-custom form-check-solid'>
                            <input
                              className='form-check-input h-25px'
                              type='checkbox'
                              checked={this.state.couponCode}
                              onChange={() =>
                                this.setState({
                                  couponCode: !this.state.couponCode,
                                })
                              }
                            />
                          </label>
                          {/* <p className='item-title'>Coupon Code</p>
                          <div className='d-flex'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm me-8'>
                              <input
                                className='form-check-input'
                                type='radio'
                                id='radioYes'
                                name='yes_no'
                                value='Yes'
                                onChange={(e) => console.log(e.target.value)}
                              />
                              <label
                                className='form-check-label'
                                for='radioYes'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid form-check-sm'>
                              <input
                                className='form-check-input'
                                type='radio'
                                id='radioNo'
                                name='yes_no'
                                value='No'
                                onChange={(e) => console.log(e.target.value)}
                              />
                              <label className='form-check-label' for='radioNo'>
                                No
                              </label>
                            </div>
                          </div> */}
                        </div>
                        <div className='mb-10'>
                          <button
                            className='cup-add-btn'
                            onClick={() =>
                              this.setState({ showCouponAddForm: true })
                            }
                          >
                            Add Coupon
                          </button>
                        </div>
                        <div className='mb-10'>
                          <p className='cupon-title'>Created Coupons</p>
                          {/* <span className='text-gray-600 ms-6'>
                            No Coupon Created Yet.
                          </span> */}
                          <div className='cupon-lists row'>
                            {this.state.referenceCouponArr.map((item, i) => (
                              <SingleCoupon
                                key={i}
                                item={item}
                                deleteCoupon={this.handleDeleteCoupon}
                                editCoupon={this.handleEditCoupon}
                                pageEditMode={this.state.pageEditMode}
                              />
                            ))}
                            {this.state.edirExistingRefCup.map((item, i) => (
                              <SingleCoupon
                                key={i}
                                item={item}
                                deleteCoupon={this.handleDeleteCoupon}
                                editCoupon={this.handleEditCoupon}
                                pageEditMode={this.state.pageEditMode}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const SingleCoupon = ({ item, deleteCoupon, editCoupon, pageEditMode }) => (
  <div className='col-md-6'>
    <div className='single'>
      <div className='title'>
        {item.from_range} - {item.to_range}
      </div>
      <div className='date'>Coupon: {item.cupon.code}</div>
      <div className='icons'>
        <i
          className='far fa-edit me-2 pointer'
          onClick={() => editCoupon(item)}
        ></i>
        {!pageEditMode && (
          <i
            className='far fa-trash-alt pointer'
            onClick={() => deleteCoupon(item)}
          ></i>
        )}
      </div>
    </div>
  </div>
);

export default ReferenceAdd;
ReferenceAdd.contextType = AuthContext;
