import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const HeaderToolbar = ({
  title,
  dateFilter,
  startDate,
  endDate,
  handleDateRangeApply,
  showDateFilter,
  dateFilterName,
  setDateFilterName,
  createAccess,
  startDatePlaceholder
}) => {
  return (
    <div className='toolbar py-2' id='kt_toolbar'>
      <div className='container-fluid d-flex align-items-center'>
        <div className='flex-grow-1 flex-shrink-0 me-5'>
          <div className='page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0'>
            <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
              {title}
            </h1>
          </div>
        </div>
        {dateFilter && (
          <>
            {createAccess && (
              <div className='d-flex align-items-center flex-wrap'>
                <div className='flex-shrink-0 me-2'>
                  <ul className='nav'>
                    <li className='nav-item'>
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-bold fs-7 px-4 me-1 ${
                          dateFilterName === 'daily' ? 'active' : ''
                        }`}
                        href='?#'
                        onClick={(e) => {
                          setDateFilterName('daily');
                          e.preventDefault();
                          handleDateRangeApply(moment(), moment());
                        }}
                      >
                        Today
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-bold fs-7 px-4 me-1 ${
                          dateFilterName === 'weekly' ? 'active' : ''
                        }`}
                        href='?#'
                        onClick={(e) => {
                          setDateFilterName('weekly');
                          e.preventDefault();
                          handleDateRangeApply(
                            moment().subtract(6, 'days'),
                            moment()
                          );
                        }}
                      >
                        Week
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-bold fs-7 px-4 ${
                          dateFilterName === 'yearly' ? 'active' : ''
                        }`}
                        href='?#'
                        onClick={(e) => {
                          e.preventDefault();
                          setDateFilterName('yearly');
                          handleDateRangeApply(
                            moment().startOf('year'),
                            moment().endOf('year')
                          );
                        }}
                      >
                        Year
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='d-flex align-items-center'>
                  <DateRangePicker
                    initialSettings={{
                      startDate: startDate.toDate(),
                      endDate: endDate.toDate(),
                      ranges: {
                        All: [
                          moment().subtract(5, 'year').toDate(),
                          moment().toDate(),
                        ],
                        Today: [moment().toDate(), moment().toDate()],
                        Yesterday: [
                          moment().subtract(1, 'days').toDate(),
                          moment().subtract(1, 'days').toDate(),
                        ],
                        'Last 3 Days': [
                          moment().subtract(2, 'days').toDate(),
                          moment().toDate(),
                        ],
                        'Last 30 Days': [
                          moment().subtract(29, 'days').toDate(),
                          moment().toDate(),
                        ],
                        'This Month': [
                          moment().startOf('month').toDate(),
                          moment().endOf('month').toDate(),
                        ],
                        'Last Month': [
                          moment()
                            .subtract(1, 'month')
                            .startOf('month')
                            .toDate(),
                          moment().subtract(1, 'month').endOf('month').toDate(),
                        ],
                        'This Year': [
                          moment().startOf('year').toDate(),
                          moment().endOf('year').toDate(),
                        ],
                        'Last Year': [
                          moment().subtract(1, 'year').startOf('year').toDate(),
                          moment().subtract(1, 'year').endOf('year').toDate(),
                        ],
                      },
                    }}
                    onApply={(event, picker) => {
                      handleDateRangeApply(picker.startDate, picker.endDate);
                    }}
                  >
                    <a
                      href='?#'
                      className={`btn btn-sm btn-bg-light btn-color-gray-500 btn-active-color-primary me-2 
                            ${dateFilterName === 'custom' ? 'active' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setDateFilterName('custom');
                      }}
                    >
                      {!showDateFilter ? (
                        <span>Range: All</span>
                      ) : (
                        <>
                          <span className='me-1'>Range:</span>
                          <span className='me-1'>
                            {startDatePlaceholder.format('MMM D') +
                              ' - ' +
                              endDate.format('MMM D')}
                          </span>
                        </>
                      )}
                    </a>
                  </DateRangePicker>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderToolbar;
