import { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import DateSelect from '../DateSelect';
import TimeSelect from '../TimeSelect';

const DateTimePopover = ({
  title,
  date,
  setDate,
  time,
  setTime,
  showTime,
  handleSave,
  handleIClick,
  staticIcon,
  iconStyle,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (e) => {
    setShow(!show);
    setTarget(e.target);
    handleIClick();
  };

  return (
    <div ref={ref} className='select-pop'>
      <button
        className='icn-btn'
        //   onClick={(e) => handleClick(e, mainOrder, 'order_status')}
        onClick={(e) => handleClick(e)}
      >
        <i className={`fas fa-pencil-alt pointer ms-1${staticIcon ? '' : ` edit-icn`}`} style={iconStyle ? iconStyle : {}}></i>
      </button>

      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover id='popover-contained'>
          <Popover.Content>
            <p className='font-semibold mb-3'>{title}</p>
            <DateSelect sampleDate={date} setSampleDate={setDate} />
            {showTime && (
              <TimeSelect sampleTime={time} setSampleTime={setTime} />
            )}
            <div className='new-footer d-flex justify-content-between mt-5'>
              <button className='btn btn-sm btn-cancel' onClick={() => setShow(false)}>Cancel</button>
              <button className='btn btn-sm btn-primary btn-save'
                onClick={() => {
                  handleSave(setShow);
                }}
              >
                Save
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default DateTimePopover;
