import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tbaleHeader: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: '#323232',
    paddingHorizontal: 3,
    paddingVertical: 3,
    fontFamily: 'Serif',
  },
  tbaleBody: {
    fontSize: 8,
    color: '#323232',
    paddingHorizontal: 3,
    paddingVertical: 3,
  },
  tbaleBodyRow: {
    fontSize: 8,
    color: '#323232',
    paddingHorizontal: 5,
    paddingVertical: 3,
    fontFamily: 'Serif',
  },
  tbaleHeadLeft: {
    flex: 2,
    backgroundColor: '#ededed',
  },
  tbaleHeadRight: {
    flex: 1,
    backgroundColor: '#c9c9c9',
  },
  tbaleBodyLeft: {
    flex: 2,
    backgroundColor: '#ededed',
  },
  tbaleBodyRight: {
    flex: 1,
    backgroundColor: '#c9c9c9',
  },
});

function TestsTable({ order, patient }) {
  return (
    <>
      <View style={styles.main}>
        <View
          style={{
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <View style={styles.tbaleHeadLeft}>
            <Text style={styles.tbaleHeader}>Test Details</Text>
          </View>
          <View style={styles.tbaleHeadRight}>
            <Text style={styles.tbaleHeader}>Qty</Text>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <View style={styles.tbaleHeadLeft}>
            <Text style={styles.tbaleHeader}>Lab</Text>
          </View>
          <View style={styles.tbaleHeadRight}>
            <Text style={styles.tbaleHeader}>Price</Text>
          </View>
        </View>
      </View>
      {patient.testItem.length !== 0 &&
        patient.testItem.map((item, i) => (
          <View style={styles.main} key={i}>
            <View
              style={{
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <View style={styles.tbaleHeadLeft}>
                <Text style={styles.tbaleBodyRow}>
                  {item.purchasable_order_item.testitem_purchasable_oi ? (
                    <>


                      {item.purchasable_order_item.testitem_purchasable_oi
                        .diagnostic_test
                        ? item.purchasable_order_item.testitem_purchasable_oi
                            .diagnostic_test.name
                        : 'no name'}





                    </>
                  ) : (
                    <>

                  {item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test
                    ? item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test.name
                    : ''}

                    </>
                  )}



                </Text>
              </View>
              <View style={styles.tbaleHeadRight}>
                <Text style={styles.tbaleBodyRow}>1</Text>
              </View>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <View style={styles.tbaleHeadLeft}>
                <Text style={styles.tbaleBodyRow}>
                  {item.purchasable_order_item.testitem_purchasable_oi ? (
                    <>
                      {item.purchasable_order_item.testitem_purchasable_oi
                        .branch ? (
                        <>
                          {item.purchasable_order_item.testitem_purchasable_oi
                            .branch.lab
                            ? item.purchasable_order_item
                                .testitem_purchasable_oi.branch.lab.name
                            : ''}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                  <>
                  {item.purchasable_order_item.testitem_purchasable_order[0].branch.lab
                    ? item.purchasable_order_item.testitem_purchasable_order[0].branch.lab.name
                    : ''}
                    </>

                  )}
                </Text>
              </View>
              <View style={styles.tbaleHeadRight}>
                <Text style={styles.tbaleBodyRow}>
                  {parseInt(item.purchasable_order_item.sell_price)} TK
                </Text>
              </View>
            </View>
          </View>
        ))}
    </>
  );
}

export default TestsTable;
