import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

const TimeSelect = ({ sampleTime, setSampleTime, placeholderText, disabled = false }) => {
  return (
    <DatePicker
      disabled={disabled}
      selected={sampleTime}
      onChange={(date) => {
        setSampleTime(date);
        // console.log(moment(date).format('HH:mm:ss'));
        // console.log(date);
      }}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={30}
      minTime={setHours(setMinutes(new Date(), 0), 8)}
      maxTime={setHours(setMinutes(new Date(), 30), 21)}
      timeCaption='Time'
      dateFormat='h:mm aa'
      className='last form-control'
      placeholderText={
        placeholderText ? placeholderText : `Sample Collection Time`
      }
    />
  );
};

export default TimeSelect;
