import axios from 'axios';
import React, { Component } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import BtnLoading from './BtnLoading';
import SearchTestsMulti from './SearchTestsMulti';

class ShowPackages extends Component {
  state = {
    packages: null,
    next: null,
    limit: 8,
    offset: 8,
    showEditModal: false,
    name: '',
    IsActive: true,
    description: null,
    packageSource: '',
    price: '',
    sellPrice: '',
    testCount: '',
    idealFor: '',
    whyShould: '',
    packageId: null,
    pOIId: null,

    image: null,
    newImage: null,

    isSupervisor: false,

    pacTestsOld: [],
    pacTestsNew: [],

    loading: false,
  };

  setPacTests = (v) => {
    this.setState({ pacTestsNew: v });
  };

  componentDidMount() {
    const user_details = localStorage.getItem('user_details');
    if (user_details) {
      const userRole = JSON.parse(user_details);
      if (userRole.groups.length !== 0) {
        if (userRole.groups[0].name === 'Supervisor')
          this.setState({ isSupervisor: true });
      }
    }
  }

  handleLoadMore = () => {
    this.setState({ offset: this.state.offset + 10 });

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/?limit=${this.state.limit}&offset=${this.state.offset}&ofset=0&page=1`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          packages: [...this.state.packages, ...resp.data.results],
        });
        this.setState({ next: resp.data.next });
      });
  };

  putPackageImg = (id, data) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        this.props.updateStatePackage(resp.data.id, resp.data);
        this.setState({ newImage: null });
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  handleItemEdit = (item) => {
    // console.log(item);
    this.setState({ image: item.image });
    this.setState({ packageId: item.id });
    this.setState({ name: item.name });
    this.setState({ description: item.description, IsActive: item.is_active });
    this.setState({packageSource: item.package_source});
    this.setState({
      idealFor: item.ideal_for,
      whyShould: item.why_should,
    });

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/package-test-get/?package=${item.id}`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ pacTestsOld: resp.data.results });
      })
      .catch((error) => {
        console.log(error.response);
      });

    this.setState({ showEditModal: true });
    // axios
    //   .get(
    //     `${process.env.REACT_APP_BASE_URL}/order/purchaseable-order-item/${item.purchasable_order_item.id}`
    //   )
    //   .then((resp) => {
    //     // console.log(resp.data);
    //     this.setState({ price: parseInt(resp.data.price) });
    //     this.setState({ sellPrice: parseInt(resp.data.sell_price) });
    //     this.setState({ pOIId: resp.data.id });
    //   });
  };

  postPackageTests = (id) => {
    const testPackData = this.state.pacTestsNew.map((test) => {
      return {
        diagnostic_test: test.id,
        package: id,
      };
    });

    testPackData.forEach((item) => {
      // console.log(item);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/package-test/`,
          item
        )
        .then((resp) => {
          // console.log(resp.data);
        })
        .catch((error) => {
          // console.log(error.response);
        });
    });

    // console.log(testPackData);
    this.setState({ pacTestsNew: [] });
  };

  handleEdit = (e) => {
    e.preventDefault();

    if (this.state.name.trim() === '') {
      toast.error(`Please enter package name.`, {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.packageSource.trim() === '') {
      toast.error(`Please select package source.`, {
        autoClose: 3000,
      });
      return;
    }

    // if (this.state.price === '') {
    //   toast.error(`Please enter main price.`, {
    //     autoClose: 3000,
    //   });
    //   return;
    // }

    // if (this.state.sellPrice === '') {
    //   toast.error(`Please enter sell price.`, {
    //     autoClose: 3000,
    //   });
    //   return;
    // }

    if (this.state.pacTestsOld.length + this.state.pacTestsNew.length === 0) {
      toast.error(`Please select include tests.`, {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.idealFor.trim() === '') {
      toast.error(`Please enter ideal for.`, {
        autoClose: 3000,
      });
      return;
    }

    if (this.state.whyShould.trim() === '') {
      toast.error(`Please enter why should.`, {
        autoClose: 3000,
      });
      return;
    }

    const putPrice = {
      price: parseInt(this.state.price),
      sell_price: parseInt(this.state.sellPrice),
    };

    const postData = {
      name: this.state.name,
      description: this.state.description,
      package_source: this.state.packageSource,
      is_active: this.state.IsActive,
      ideal_for: this.state.idealFor,
      updated_by: this.context.user_details.id,
      why_should: this.state.whyShould,
    };

    this.setState({ loading: true });

    const imgg = new FormData();
    imgg.append('image', this.state.newImage);

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/${this.state.packageId}/`,
        postData
      )
      .then((resp) => {
        // console.log(resp.data);
        this.postPackageTests(resp.data.id);
        this.props.updateStatePackage(resp.data.id, resp.data);
        if (this.state.newImage) {
          this.putPackageImg(resp.data.id, imgg);
        }
        toast.success(`Package updated successfully.`, {
          autoClose: 3000,
        });
        this.setState({ showEditModal: false, loading: false });
      })
      .catch((error) => {
        this.setState({ showEditModal: false, loading: false });
      });

    // axios
    //   .put(
    //     `${process.env.REACT_APP_BASE_URL}/order/purchaseable-order-item/${this.state.pOIId}/`,
    //     putPrice
    //   )
    //   .then((resp) => {
    //     // console.log(resp.data);
    //   });
  };

  handleDelete = (test) => {
    // console.log(test.id);

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/${test.id}/`
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`Package deleted successfully.`, {
          autoClose: 3000,
        });
        this.props.deleteStatePackage(test.id);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  handleTestDelete = (test) => {
    // console.log(test);

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/package-test/${test.id}/`
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`Test removed successfully.`, {
          autoClose: 3000,
        });

        const newPacTestsOld = this.state.pacTestsOld.filter(
          (t) => t.id !== test.id
        );
        this.setState({ pacTestsOld: newPacTestsOld });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  render() {
    const {
      next,
      showEditModal,
      name,
      description,
      IsActive,
      packageSource,
      price,
      sellPrice,
      testCount,
      idealFor,
      image,
      isSupervisor,
      whyShould,
      pacTestsOld,
    } = this.state;

    const { packages } = this.props;

    return (
      <>
        <div className='package-edit-comp'>
          <table className='table table-row-dashed table-row-gray-300 align-middle fs-6 no-footer gy-5'>
            <thead>
              <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                <th className='min-w-40px sorting'>Index</th>
                <th className='min-w-100px sorting'>Image</th>
                <th className='min-w-250px sorting'>Name</th>
                <th className='min-w-100px sorting'>Is Active</th>
                <th className='min-w-250px sorting'>Actions</th>
              </tr>
            </thead>
            <tbody className='fw-bold text-gray-600'>
              {packages &&
                packages.map((test, i) => (
                  <tr key={i}>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      #{i + 1}
                    </td>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      {test.image ? (
                        <img
                          src={test.image}
                          alt=''
                          width='50px'
                          height='30px'
                          className='shadow'
                        />
                      ) : (
                        <div
                          className='d-flex justify-content-center align-items-center border border-2 rounded'
                          style={{ width: '50px', height: '30px' }}
                        >
                          <i
                            className='fas fa-file-medical-alt'
                            style={{ fontSize: '12px', borderRadius: 5 }}
                          />
                        </div>
                      )}
                    </td>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      {test.name}
                    </td>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      {test.is_active ? (
                        <span className='text-green'>Activated</span>
                      ) : (
                        <span className='text-danger'>Deactivated</span>
                      )}
                    </td>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      <button
                        className='btn btn-primary mr-2 btn-sm me-2'
                        onClick={() => this.handleItemEdit(test)}
                      >
                        Edit package
                      </button>
                      <Link
                        to={`/tests/diagnostic-package/${test.id}`}
                        className='btn btn-info mr-2 btn-sm me-2'
                      >
                        Edit package item
                      </Link>
                      {/* {!isSupervisor && (
                        <button
                          className='btn btn-danger btn-sm me-2'
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure to delete this package?'
                              )
                            )
                              this.handleDelete(test);
                          }}
                          disabled
                        >
                          Delete
                        </button>
                      )} */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* <button
            className='btn btn-primary mb-10 d-block mx-auto'
            disabled={!next ? 'disabled' : ''}
            onClick={this.handleLoadMore}
          >
            See More
          </button> */}
        </div>

        <Modal
          show={showEditModal}
          onHide={() => this.setState({ showEditModal: false })}
          animation={false}
          size='lg'
        >
          <ModalBody>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
              <h5 className='mb-0'>Edit Package</h5>
              <button
                className='btn btn-sm btn-danger'
                onClick={() => this.setState({ showEditModal: false })}
              >
                Close
              </button>
            </div>
            <form onSubmit={this.handleEdit}>
              <div className='form-group mb-3'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter package name'
                  value={name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control'
                  placeholder='Enter test description'
                  rows='4'
                  value={description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                ></textarea>
              </div>
              {image && (
                <div className='form-group mb-3'>
                  <img src={image} alt='' width='100' />
                </div>
              )}
              <div className='form-group mb-3'>
                <div className='custom-file'>
                  <input
                    type='file'
                    className='custom-file-input'
                    accept='image/png, image/gif, image/jpeg'
                    onChange={(e) =>
                      this.setState({ newImage: e.target.files[0] })
                    }
                  />
                  <label className='custom-file-label'>
                    Change Package Image
                  </label>
                </div>
              </div>
              <div className='form-group mb-3 d-flex align-items-center'>
                <h5 className='mb-0 me-2'>Is Active</h5>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-50px'
                    type='checkbox'
                    name='is_active'
                    onChange={() => this.setState({ IsActive: !IsActive })}
                    defaultChecked={IsActive}
                  />
                </div>
              </div>

               <div className='form-group  mb-3'>
              <select className='form-select fw-normal'
              value={packageSource}
                onChange={(e) =>
                    this.setState({ packageSource: e.target.value })
                  }
              >
                <option value=''>Package Source</option>
                <option value='ebl'>EBL</option>
                <option value='amarlab'>Amarlab</option>
              </select>
            </div>
              {/* <div className='form-group mb-3'>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Enter main price'
                  value={price}
                  onChange={(e) => this.setState({ price: e.target.value })}
                  min='00'
                />
              </div>
              <div className='form-group mb-3'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter sell price'
                  value={sellPrice}
                  onChange={(e) => this.setState({ sellPrice: e.target.value })}
                />
              </div> */}
              <div className='form-group mb-3'>
                {pacTestsOld && pacTestsOld.length !== 0 && (
                  <>
                    <p className='mb-1 fw-bold'>Existing Tests:</p>
                    <div className='d-flex align-items-center mb-3 flex-wrap'>
                      {pacTestsOld.map((test, i) => (
                        <div className='all-testsss me-2 mb-2' key={i}>
                          <span>{test.diagnostic_test.name}</span>
                          <i
                            onClick={() => {
                              if (
                                window.confirm(
                                  'Are you sure to delete this item?'
                                )
                              )
                                this.handleTestDelete(test);
                            }}
                            className='fas fa-times text-danger pointer ms-1'
                          ></i>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <SearchTestsMulti
                  testType={'diagnostic'}
                  pacTests={this.state.pacTestsNew}
                  setPacTests={this.setPacTests}
                  placeholderText='Search & Select Test *'
                />
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='Enter ideal for'
                  rows='2'
                  value={idealFor}
                  onChange={(e) => this.setState({ idealFor: e.target.value })}
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='Why Should *'
                  rows='3'
                  value={whyShould}
                  onChange={(e) => this.setState({ whyShould: e.target.value })}
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                {!this.state.loading ? (
                  <button className='btn btn-primary'>Update</button>
                ) : (
                  <BtnLoading name='Updating...' btnClass='primary ' />
                )}
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ShowPackages;
ShowPackages.contextType = AuthContext;
