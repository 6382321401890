import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BranchLabMod from '../components/BranchLabMod/BranchLabMod';
import BtnLoading from '../components/BtnLoading';
import HeaderToolbar from '../components/HeaderToolbar';
import SearchTests from '../components/SearchTests';
import SelectLab from '../components/SelectLab';
import { AuthContext } from '../context/AuthContext';

class PackageDetailsV2 extends Component {
  state = {
    pack: null,
    branches: null,
    locations: null,
    labs: null,
    tLocation: '',
    tBranch: '',
    tPrice: '',
    tSellPrice: '',
    showEditModal: false,
    editId: null,
    editLocation: '',
    editBranch: '',
    editPrice: '',
    editSellPrice: '',
    pOIId: null,
    packageItemId: null,

    showBranchLabModal: false,

    removeDeleteAccess: true,

    allLabs: [],
    testName: null,
    selectedLabName: [],
    labDetails: null,
    labAndTest: [],
    loading: false,
  };

  setLabDetails = (v) => {
    this.setState({ labDetails: v });
  };

  setAllLabs = (v) => {
    this.setState({ allLabs: v });
  };

  setTestName = (v) => {
    this.setState({ testName: v });
  };

  fetchPackageItem = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/${id}/`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ pack: resp.data });
        if (resp.data.packageitem.length !== 0) {
          const labName = resp.data.packageitem[0].test_item.branch.lab.id;
          this.setState({ selectedLabName: [labName] });
        } else {
          this.setState({ selectedLabName: [] });
        }
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  fetchBranch = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/branch-get/?page=1&limit=200&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ branches: resp.data.results });
      });
  };

  fetchLocation = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/location/`)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ locations: resp.data.results });
        this.fetchLab();
      });
  };

  fetchLab = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/lab/?limit=200&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        let results = resp.data.results.map((item) => {
          const locDetails = this.state.locations.filter(
            (loc) => loc.id === item.location
          );
          return {
            ...item,
            locDetail: locDetails ? locDetails[0] : {},
          };
        });
        this.setState({ labs: results });
      });
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Tests & Packages', link: '/tests' },
      { name: 'Edit Package Item', link: '' },
    ]);
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (userRole.is_superuser || userRole.groups[0].name === 'Admin')
      this.setState({ removeDeleteAccess: false });

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchPackageItem(this.props.match.params.id);
      this.fetchBranch();
      this.fetchLocation();
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name !== 'Admin' &&
        userRole.groups[0].name !== 'Supervisor'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchPackageItem(this.props.match.params.id);
        this.fetchBranch();
        this.fetchLocation();
      }
    }
  }

  handleItemAdd = (e) => {
    e.preventDefault();

    if (this.state.labAndTest.length === 0) {
      toast.error(`Please select a test and lab.`, {
        autoClose: 3000,
      });
      return;
    }

    this.setState({ loading: true });

    this.state.labAndTest.forEach((item, i) => {
      const postPackageItem = {
        package: this.state.pack.id,
        test_item: item.labDetails.id,
        updated_by: this.context.user_details.id,
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/packageitem/`,
          postPackageItem
        )
        .then((resp) => {
          //   console.log(resp.data);
          if (this.state.labAndTest.length === i + 1) {
            setTimeout(() => {
              toast.success(`Package item added successfully.`, {
                autoClose: 3000,
              });
              this.fetchPackageItem(this.props.match.params.id);
              this.handleCancle();
              this.setState({ loading: false });
            }, 1000);
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          // console.log(error.response);
        });
    });
  };

  handleItemDelete = (item) => {
    // console.log(item);

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/packageitem/${item.id}/`
      )
      .then((resp) => {
        toast.success(`Package item deleted successfully.`, {
          autoClose: 3000,
        });
        this.fetchPackageItem(this.props.match.params.id);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  handleItemEdit = (item) => {
    // console.log(item);
    this.setState({ editId: item.test_item.id, packageItemId: item.id });
    this.setState({ pOIId: item.test_item.purchasable_order_item.id });
    this.setState({ tLocation: item.test_item.location.id });
    this.setState({ tBranch: item.test_item.branch.id });
    this.setState({
      tPrice: parseInt(item.test_item.purchasable_order_item.price),
    });
    this.setState({
      tSellPrice: parseInt(item.test_item.purchasable_order_item.sell_price),
    });
  };

  handleEdit = () => {
    const postPrice = {
      price: parseInt(this.state.tPrice),
      sell_price: parseInt(this.state.tSellPrice),
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/packageitem/${this.state.packageItemId}/`,
        {
          updated_by: this.context.user_details.id,
        }
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/purchaseable-order-item/${this.state.pOIId}/`,
        postPrice
      )
      .then((resp) => {
        // console.log(resp.data);
        // const id = resp.data.id;
        const putData = {
          branch: parseInt(this.state.tBranch),
          location: parseInt(this.state.tLocation),
          // purchasable_order_item: id,
          updated_by: this.context.user_details.id,
        };

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/diagnostic/testitem/${this.state.editId}/`,
            putData
          )
          .then((resp) => {
            // console.log(resp.data);
            this.fetchPackageItem(this.props.match.params.id);
            toast.success(`Package item updated successfully.`, {
              autoClose: 3000,
            });
            this.handleCancle();
          });
      });
  };

  handleCancle = () => {
    this.setState({ editId: null, packageItemId: null });
    this.setState({ pOIId: null });
    this.setState({ tLocation: '' });
    this.setState({ tBranch: '' });
    this.setState({ tPrice: '' });
    this.setState({ tSellPrice: '' });

    this.setState({ testName: null, labDetails: null, labAndTest: [] });
  };

  handleAddTestAndLab = () => {
    const labName = this.state.labDetails.branch.lab.id;
    this.setState({ selectedLabName: [labName] });

    const testNdLab = {
      testDetails: this.state.testName,
      labDetails: this.state.labDetails,
    };
    // console.log(testNdLab);
    this.setState({ labAndTest: [testNdLab, ...this.state.labAndTest] });
    this.setState({ testName: null, labDetails: null });
  };

  handleTestAndLabDlt = (test) => {
    // console.log(test);
    this.setState({
      labAndTest: this.state.labAndTest.filter((o) => o !== test),
    });

    setTimeout(() => {
      // console.log(this.state.labAndTest);
      if (this.state.pack.packageitem.length === 0) {
        if (this.state.labAndTest.length === 0) {
          this.setState({ selectedLabName: [] });
        }
      }
    }, 500);
  };

  render() {
    const {
      pack,
      branches,
      locations,
      tPrice,
      tSellPrice,
      editId,
      removeDeleteAccess,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Edit Diagnostic Package' />
        <div className='content d-flex flex-column flex-column-fluid package-item-edit'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row page-test-details'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title'>
                      <span className='card-label font-weight-bolder text-dark'>
                        {pack && pack.name}
                      </span>
                    </h3>
                    <div className='card-toolbar'>
                      <Link to='/tests' className='btn btn-primary'>
                        Go Back
                      </Link>
                    </div>
                  </div>
                  <hr />
                  <div className='card-body pt-2 pb-0'>
                    <h5 className='mb-5'>{editId ? 'Edit' : 'Add New'} Test</h5>
                    <form onSubmit={this.handleItemAdd}>
                      <div className='form-group mb-3'>
                        <SearchTests
                          testType={'diagnostic'}
                          setAllLabs={this.setAllLabs}
                          testName={this.state.testName}
                          setTestName={this.setTestName}
                          selectedLabName={this.state.selectedLabName}
                        />
                      </div>
                      <div className='form-group mb-3 lab-and-button'>
                        <SelectLab
                          allLabs={this.state.allLabs}
                          setLabDetails={this.setLabDetails}
                          labDetails={this.state.labDetails}
                        />
                        <button
                          type='button'
                          className='btn badge bg-secondary text-dark'
                          onClick={this.handleAddTestAndLab}
                          disabled={
                            this.state.testName && this.state.labDetails
                              ? ''
                              : 'disabled'
                          }
                        >
                          +
                        </button>
                      </div>
                      <div className='form-group mb-7'>
                        {this.state.labAndTest.length !== 0 &&
                          this.state.labAndTest.map((test, i) => (
                            <div key={i} className='mb-5 lab-test-div'>
                              <p className='m-0 fw-bold'>
                                {i + 1}. {test.testDetails.name} &mdash;{' '}
                                {test.labDetails.order_type === 'diagnostic'
                                  ? test.labDetails.branch.lab.name
                                  : test.labDetails.test_item.branch.lab
                                      .name}{' '}
                                &mdash;{' '}
                                {test.labDetails.order_type === 'diagnostic'
                                  ? parseInt(
                                      test.labDetails.purchasable_order_item
                                        .sell_price
                                    )
                                  : parseInt(
                                      test.labDetails.test_item
                                        .purchasable_order_item.sell_price
                                    )}{' '}
                              </p>
                              <i
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure to delete this test?'
                                    )
                                  )
                                    this.handleTestAndLabDlt(test);
                                }}
                                className='fas fa-trash text-danger pointer'
                              ></i>
                            </div>
                          ))}
                      </div>
                      <div className='form-group mb-3 mb-2'>
                        {!this.state.loading ? (
                          <button className='btn btn-primary mr-3'>
                            {editId ? 'Edit' : 'Add'} Test
                          </button>
                        ) : (
                          <BtnLoading
                            name={editId ? 'Editing' : 'Adding'}
                            btnClass='primary mr-3'
                          />
                        )}

                        <button
                          className='btn btn-danger'
                          onClick={this.handleCancle}
                          type='button'
                        >
                          Clear
                        </button>
                      </div>
                    </form>
                  </div>
                  <hr />
                  <div className='card-body pt-2 pb-0'>
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          <th>Idx</th>
                          <th>Test</th>
                          <th>Branch</th>
                          <th>Lab</th>
                          <th>Price</th>
                          <th>Sell Price</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pack &&
                          pack.packageitem.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.test_item.diagnostic_test.name}</td>
                              <td>{item.test_item.branch.name}</td>
                              <td>{item.test_item.branch.lab.name}</td>
                              <td>
                                {parseInt(
                                  item.test_item.purchasable_order_item.price
                                )}
                              </td>
                              <td>
                                {parseInt(
                                  item.test_item.purchasable_order_item
                                    .sell_price
                                )}
                              </td>
                              <td>
                                {/* <button
                                  className='btn btn-primary mr-3 btn-sm'
                                  onClick={() => this.handleItemEdit(item)}
                                >
                                  Edit
                                </button> */}
                                {!removeDeleteAccess && (
                                  <button
                                    className='btn btn-danger btn-sm'
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          'Are you sure to delete this package item?'
                                        )
                                      )
                                        this.handleItemDelete(item);
                                    }}
                                  >
                                    Delete
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BranchLabMod
          showBranchLabModal={this.state.showBranchLabModal}
          setStateP={(stateObj) => this.setState(stateObj)}
          branches={this.state.branches}
          fetchBranch={() => this.fetchBranch()}
          locations={this.state.locations}
          fetchLocation={() => this.fetchLocation()}
          labs={this.state.labs}
          fetchLab={() => this.fetchLab()}
        />
      </>
    );
  }
}

export default PackageDetailsV2;
PackageDetailsV2.contextType = AuthContext;
