import axios from 'axios';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import ChannelRefCategory from '../components/ChannelRefCategory';
import HeaderToolbar from '../components/HeaderToolbar';
import { AuthContext } from '../context/AuthContext';
import { Spinner } from 'react-bootstrap';
import Pagination from '../components/Pagination/Pagination';
import BtnLoading from '../components/BtnLoading';

class Channels extends Component {
  state = {
    channels: null,
    limit: 10,
    pagination: 1,
    next: null,
    count: 0,
    loadingChannel: false,

    searchText: '',

    showCreateForm: false,
    channelName: '',
    channelCategoryMain: '',
    referenceType: '',
    channelStatus: true,
    channelEditId: null,
    channelApiLoading: false,

    channelAddModal: false,
    channelCategory: [],
    channelCatName: '',
    channelIsActive: true,
    channelCatId: null,
    addLoadingChan: false,
    deleteLoadingChan: false,
    deleteChanId: '',

    referenceAddModal: false,
    referenceCategory: [],
    referenceCatName: '',
    referenceIsActive: true,
    referenceCatId: '',
    addLoadingRefe: false,
    deleteLoadingRefe: false,
    deleteRefeId: '',
  };

  componentDidMount() {
    this.context.setPageTitle('Channels');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Channels', link: '/channels' },
    ]);

    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.getChannelCategory();
      this.getReferenceCategory();
      this.fetchChannel(this.state);
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Lab Partner' ||
        userRole.groups[0].name === 'External'
      ) {
        this.props.history.push('/orders');
      } else {
        this.getChannelCategory();
        this.getReferenceCategory();
        this.fetchChannel(this.state);
      }
    }
  }

  fetchChannel = ({ limit, pagination }, seatchText, otherfilters = '') => {
    let fetchuserUrl = `${
      process.env.REACT_APP_BASE_URL
    }/order/channel-get/?page=1&limit=${limit}&offset=${
      (pagination - 1) * limit
    }${otherfilters}`;
    if (!!seatchText) {
      fetchuserUrl = `${
        process.env.REACT_APP_BASE_URL
      }/order/channel-get/?page=1&search=${seatchText}&limit=${limit}&offset=${
        (pagination - 1) * limit
      }${otherfilters}`;
    }

    this.setState({ loadingChannel: true });
    axios
      .get(fetchuserUrl)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ channels: resp.data.results });
        this.setState({ next: resp.data.next, count: resp.data.count });
        this.setState({ loadingChannel: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ loadingChannel: false });
      });
  };

  getChannelCategory = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/channel-category/?limit=1000`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ channelCategory: resp.data.results });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  getReferenceCategory = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/reference-type/?limit=1000`)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ referenceCategory: resp.data.results });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  postChannelCategory = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/channel-category/`, data)
      .then((resp) => {
        // console.log(resp.data);
        // this.setState({ channelAddModal: false });
        toast.success('Category added successfully.');
        this.getChannelCategory();
        this.handleClearCatForm();
        this.setState({ addLoadingChan: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ addLoadingChan: false });
      });
  };

  putChannelCategory = (data, id) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/channel-category/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        // this.setState({ channelAddModal: false });
        toast.success('Category updated successfully.');
        this.getChannelCategory();
        this.handleClearCatForm();
        this.setState({ addLoadingChan: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ addLoadingChan: false });
      });
  };

  handleCategoryAdd = () => {
    if (this.state.channelCatName.trim() === '') {
      toast.error('Please enter category name.');
    } else {
      const data = {
        name: this.state.channelCatName,
        status: this.state.channelIsActive,
        created_by: this.context.user_details.id,
      };

      const putData = {
        name: this.state.channelCatName,
        status: this.state.channelIsActive,
      };

      this.setState({ addLoadingChan: true });

      if (!this.state.channelCatId) {
        this.postChannelCategory(data);
      } else {
        this.putChannelCategory(putData, this.state.channelCatId);
      }

      // console.log(data);
    }
  };

  deleteChannelCategory = (c) => {
    // console.log(c);
    this.setState({ deleteLoadingChan: true, deleteChanId: c.id });
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/order/channel-category/${c.id}`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.getChannelCategory();
        toast.success('Category deleted successfully.');
        this.setState({ deleteLoadingChan: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ deleteLoadingChan: false });
      });
  };

  editChannelCategory = (c) => {
    // console.log(c);
    this.setState({
      channelCatName: c.name,
      channelIsActive: c.status,
      channelCatId: c.id,
    });
  };

  editReferenceCategory = (c) => {
    // console.log(c);
    this.setState({
      referenceCatName: c.name,
      referenceIsActive: c.status,
      referenceCatId: c.id,
    });
  };

  handleClearCatForm = () => {
    this.setState({
      channelCatName: '',
      channelIsActive: true,
      channelCatId: null,
    });
  };

  handleClearRefForm = () => {
    this.setState({
      referenceCatName: '',
      referenceIsActive: true,
      referenceCatId: '',
    });
  };

  postReferenceCategory = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/reference-type/`, data)
      .then((resp) => {
        // console.log(resp.data);
        // this.setState({ channelAddModal: false });
        toast.success('Reference Type added successfully.');
        this.getReferenceCategory();
        this.handleClearRefForm();
        this.setState({ addLoadingRefe: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ addLoadingRefe: false });
      });
  };

  putReferenceCategory = (data, id) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/reference-type/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        // this.setState({ channelAddModal: false });
        toast.success('Reference Type updated successfully.');
        this.getReferenceCategory();
        this.handleClearRefForm();
        this.setState({ addLoadingRefe: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ addLoadingRefe: false });
      });
  };

  handleReferenceAdd = () => {
    if (this.state.referenceCatName.trim() === '') {
      toast.error('Please enter category name.');
    } else {
      const data = {
        name: this.state.referenceCatName,
        status: this.state.referenceIsActive,
        created_by: this.context.user_details.id,
      };

      const putData = {
        name: this.state.referenceCatName,
        status: this.state.referenceIsActive,
      };

      this.setState({ addLoadingRefe: true });

      if (!this.state.referenceCatId) {
        this.postReferenceCategory(data);
      } else {
        this.putReferenceCategory(putData, this.state.referenceCatId);
      }

      // console.log(data);
    }
  };

  deleteReferenceCategory = (c) => {
    // console.log(c);
    this.setState({ deleteLoadingRefe: true, deleteRefeId: c.id });
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/order/reference-type/${c.id}`)
      .then((resp) => {
        // console.log(resp.data);
        this.getReferenceCategory();
        toast.success('Reference Type deleted successfully.');
        this.setState({ deleteLoadingRefe: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ deleteLoadingRefe: false });
      });
  };

  handleClearChannelForm = () => {
    this.setState({
      channelName: '',
      channelCategoryMain: '',
      referenceType: '',
      channelStatus: true,
      channelEditId: null,
    });
  };

  postChannel = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/channel/`, data)
      .then((resp) => {
        // console.log(resp.data);
        // this.setState({ channelAddModal: false });
        toast.success('Channel added successfully.');
        this.fetchChannel(this.state);
        this.handleClearChannelForm();
        this.setState({ showCreateForm: false });
        this.setState({ channelApiLoading: false });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ showCreateForm: false });
        this.setState({ channelApiLoading: false });
      });
  };

  putChannel = (data, id) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/channel/${id}/`, data)
      .then((resp) => {
        console.log(resp.data);
        // this.setState({ channelAddModal: false });
        toast.success('Channel updated successfully.');
        // this.fetchChannel(this.state);
        this.handleClearChannelForm();
        this.setState({ showCreateForm: false });
        this.setState({ channelApiLoading: false });

        this.fetchChannel(this.state);

        // const newChannels = this.state.channels.map((obj) => {
        //   if (obj.id === resp.data.id) {
        //     return { ...resp.data };
        //   }
        //   return obj;
        // });

        // this.setState({ channels: newChannels });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ showCreateForm: false });
        this.setState({ channelApiLoading: false });
      });
  };

  handleNewChannelAdd = () => {
    if (this.state.channelName === '') {
      toast.error('Please enter channel name.');
    } else if (this.state.channelCategoryMain === '') {
      toast.error('Please select channel category.');
    } else if (this.state.referenceType === '') {
      toast.error('Please select reference type.');
    } else {
      const postData = {
        name: this.state.channelName,
        status: this.state.channelStatus,
        channel_category: this.state.channelCategoryMain,
        channel_type: this.state.referenceType,
        created_by: this.context.user_details.id,
      };

      const putData = {
        name: this.state.channelName,
        status: this.state.channelStatus,
        channel_category: this.state.channelCategoryMain,
        channel_type: this.state.referenceType,
      };

      this.setState({ channelApiLoading: true });

      if (!this.state.channelEditId) {
        this.postChannel(postData);
      } else {
        this.putChannel(putData, this.state.channelEditId);
      }

      // console.log(postData);
    }
  };

  handleChannelEdit = (channel) => {
    // console.log(channel);
    this.setState({
      showCreateForm: true,
      channelName: channel.name,
      channelCategoryMain: channel.channel_category.id,
      referenceType: channel.channel_type.id,
      channelStatus: channel.status,
      channelEditId: channel.id,
    });
  };

  handleChannelStatus = (channel) => {
    // console.log(channel);
    if (channel.status) {
      this.putChannel({ status: false }, channel.id);
    } else {
      this.putChannel({ status: true }, channel.id);
    }
  };

  handlePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    if (this.state.searchText)
      this.fetchChannel(
        { limit: this.state.limit, pagination: pageno },
        this.state.searchText
      );
    else this.fetchChannel({ limit: this.state.limit, pagination: pageno });
  };

  handleSearch = () => {
    this.setState({ pagination: 1 });
    if (!this.state.searchText) {
      this.fetchChannel({ limit: this.state.limit, pagination: 1 });
    } else {
      this.fetchChannel(
        { limit: this.state.limit, pagination: 1 },
        this.state.searchText
      );
    }
    return;
    if (this.state.searchText === '') {
      toast.error('Please type something and search.');
    } else {
      // console.log(this.state.searchText);
      // this.setState({ pagination: 1 });
    }
  };

  render() {
    const { showCreateForm, channels, loadingChannel, channelApiLoading } =
      this.state;

    return (
      <>
        <HeaderToolbar title='Channels' />

        <div className='content d-flex flex-column flex-column-fluid ref-page channel-p ref-add-page'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-header border-0 pt-6'>
                    <div className='card-title head-item'>
                      <div className='d-flex align-items-start flex-column'>
                        <div className='d-flex flex-column align-items-start position-relative my-1'>
                          <div>All Channels</div>
                        </div>
                      </div>
                    </div>
                    <div className='right d-flex align-items-center'>
                      <form
                        className='search-box'
                        onSubmit={(e) => {
                          this.handleSearch();
                          e.preventDefault();
                        }}
                      >
                        <div className='input-group'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Search By Name'
                            value={this.state.searchText}
                            onChange={(e) =>
                              this.setState({ searchText: e.target.value })
                            }
                          />
                          <button className='input-group-text'>
                            <i className='fas fa-search'></i>
                          </button>
                        </div>
                      </form>
                      <div className='create-channle'>
                        <button
                          className='btn btn-primary ms-3'
                          onClick={() =>
                            this.setState({ showCreateForm: true })
                          }
                        >
                          Create Channel
                        </button>
                        {showCreateForm && (
                          <div className='create-form'>
                            <div className='head d-flex justify-content-end'>
                              <div
                                className='button btn btn-light mb-10'
                                onClick={() => {
                                  this.handleClearChannelForm();
                                  this.setState({ showCreateForm: false });
                                }}
                              >
                                Close
                              </div>
                            </div>
                            <div className='body row mb-10'>
                              <div className='col-md-6 col-f'>
                                <div className='mb-7'>
                                  <p className='item-title'>Channel Name*</p>
                                  <input
                                    type='text'
                                    className='form-control form-control-solid'
                                    placeholder='Channel Name'
                                    value={this.state.channelName}
                                    onChange={(e) =>
                                      this.setState({
                                        channelName: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className=''>
                                  <p className='item-title'>
                                    Channel Category*
                                  </p>
                                  <div className='category-add'>
                                    <select
                                      className='form-select form-select-sloid'
                                      onChange={(e) =>
                                        this.setState({
                                          channelCategoryMain: e.target.value,
                                        })
                                      }
                                      value={this.state.channelCategoryMain}
                                    >
                                      <option value=''>Select Category</option>
                                      {this.state.channelCategory.map(
                                        (category, i) => (
                                          <option value={category.id} key={i}>
                                            {category.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <button
                                      className='btn btn light'
                                      onClick={() =>
                                        this.setState({ channelAddModal: true })
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-6 col-l'>
                                <div className='mb-7'>
                                  <p className='item-title'>Reference Type*</p>
                                  <div className='category-add'>
                                    <select
                                      className='form-select form-select-sloid'
                                      onChange={(e) =>
                                        this.setState({
                                          referenceType: e.target.value,
                                        })
                                      }
                                      value={this.state.referenceType}
                                    >
                                      <option value=''>Select Type</option>
                                      {this.state.referenceCategory.map(
                                        (category, i) => (
                                          <option value={category.id} key={i}>
                                            {category.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <button
                                      className='btn btn light'
                                      onClick={() =>
                                        this.setState({
                                          referenceAddModal: true,
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                                <div className='switc'>
                                  <p className='item-title mb-0'>
                                    Channel Status
                                  </p>
                                  <label className='form-check form-switch form-check-custom form-check-solid'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      checked={this.state.channelStatus}
                                      onChange={() =>
                                        this.setState({
                                          channelStatus:
                                            !this.state.channelStatus,
                                        })
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className='head d-flex justify-content-end'>
                              {!channelApiLoading ? (
                                <div
                                  className='button btn btn-primary'
                                  onClick={this.handleNewChannelAdd}
                                >
                                  {this.state.channelEditId
                                    ? 'UPDATE'
                                    : 'CREATE'}{' '}
                                  CHANNEL
                                </div>
                              ) : (
                                <BtnLoading
                                  name='Loading.....'
                                  btnClass='primary button'
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    <div className='mt-2'>
                      <table className='table align-start table-row-dashed fs-7 gy-5'>
                        <thead>
                          <tr className='text-start text-dark fw-bolder fs-7 text-uppercase gs-0'>
                            <th className='min-w-150px sorting ps-3'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>
                                  CHANNEL NAME
                                </div>
                              </div>
                            </th>
                            <th className='min-w-150px sorting mt'>
                              <div className='d-flex flex-row'>
                                <div className='text-truncate'>
                                  REFERENCE TYPE
                                </div>
                              </div>
                            </th>
                            <th className='min-w-150px sorting mt'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>CATEGORY</div>
                              </div>
                            </th>
                            <th className='min-w-150px sorting ps-3'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'></div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='fw-bold text-gray-600'>
                          {channels &&
                            channels.map((channel, i) => (
                              <TableRowItem
                                key={i}
                                handleEditBtn={this.handleChannelEdit}
                                channel={channel}
                                handleEditStatus={this.handleChannelStatus}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div className='row justify-content-end mt-3 mb-10'>
                      {loadingChannel && (
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                          <Spinner animation='border' size='sm' />{' '}
                          <span className='ms-2 text-gray-600'>
                            loading, Please wait...
                          </span>
                        </div>
                      )}
                      <Pagination
                        onPageChange={(pageno) =>
                          this.handlePaginationPress(pageno)
                        }
                        totalCount={this.state.count}
                        pageSize={this.state.limit}
                        currentPage={this.state.pagination}
                        siblingCount={1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ChannelRefCategory
          setShowModal={(v) => this.setState({ channelAddModal: v })}
          showModal={this.state.channelAddModal}
          title={this.state.channelCatId ? 'Update Category' : 'Add Category'}
          name={this.state.channelCatName}
          setName={(v) => this.setState({ channelCatName: v })}
          isActive={this.state.channelIsActive}
          setIsActive={() =>
            this.setState({ channelIsActive: !this.state.channelIsActive })
          }
          handleSave={this.handleCategoryAdd}
          lists={this.state.channelCategory}
          handleDelete={this.deleteChannelCategory}
          handleEdit={this.editChannelCategory}
          editMode={this.state.channelCatId ? true : false}
          clearForm={this.handleClearCatForm}
          addLoading={this.state.addLoadingChan}
          deleteLoading={this.state.deleteLoadingChan}
          deleteId={this.state.deleteChanId}
        />

        <ChannelRefCategory
          setShowModal={(v) => this.setState({ referenceAddModal: v })}
          showModal={this.state.referenceAddModal}
          title={this.state.referenceCatId ? 'Update Type' : 'Add Type'}
          name={this.state.referenceCatName}
          setName={(v) => this.setState({ referenceCatName: v })}
          isActive={this.state.referenceIsActive}
          setIsActive={() =>
            this.setState({ referenceIsActive: !this.state.referenceIsActive })
          }
          lists={this.state.referenceCategory}
          editMode={this.state.referenceCatId ? true : false}
          handleSave={this.handleReferenceAdd}
          handleEdit={this.editReferenceCategory}
          clearForm={this.handleClearRefForm}
          handleDelete={this.deleteReferenceCategory}
          addLoading={this.state.addLoadingRefe}
          deleteLoading={this.state.deleteLoadingRefe}
          deleteId={this.state.deleteRefeId}
        />
      </>
    );
  }
}

export default Channels;
Channels.contextType = AuthContext;

const TableRowItem = ({ handleEditBtn, channel, handleEditStatus }) => (
  <tr>
    <td className='ps-3'>
      {/* <div className='fw-normal text-dark d-flex flex-row align-items-center'>
        #10056
      </div> */}
      <div className='fw-bolder text-dark d-flex flex-row align-items-center'>
        {channel.name}
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center'>
        {channel.channel_type.name}
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center'>
        {channel.channel_category.name}
      </div>
    </td>
    <td className='d-flex border-white'>
      <div className='d-flex align-items-center'>
        <div className='form-check form-switch form-check-custom form-check-solid'>
          {channel.status ? (
            <input
              className='form-check-input w-50px'
              type='checkbox'
              name='is_active'
              onChange={() => {
                if (window.confirm('Are you sure?')) handleEditStatus(channel);
              }}
              checked={channel.status}
            />
          ) : (
            <input
              className='form-check-input w-50px'
              type='checkbox'
              name='is_active'
              onChange={() => {
                if (window.confirm('Are you sure?')) handleEditStatus(channel);
              }}
              checked={channel.status}
            />
          )}
        </div>
      </div>
      <button
        className='btn btn-light btn-sm px-5 ms-3'
        onClick={() => handleEditBtn(channel)}
      >
        <i
          className={`fas fa-pencil-alt pointer me-0 text-dim-dark opacity-25`}
        ></i>
        Edit
      </button>
    </td>
  </tr>
);
