import { Link } from 'react-router-dom';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { useState } from 'react';

const AccountHomepage = ({
  dateFilterName,
  todayTotal,
  weekTotal,
  subTotal,
  ordersInRange,
  series2,
}) => {
  const [options1] = useState({
    chart: {
      height: '100%',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  });
  const [options2] = useState({
    chart: {
      id: 'todaycompletionchart',
    },
    plotOptions: {
      radialBar: {
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 15,
          size: '70%',
          background: 'transparent',
          image: undefined,
          imageWidth: 150,
          imageHeight: 150,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 1,
            left: 2,
            blur: 3,
            opacity: 0.5,
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: '#f2f2f2',
          strokeWidth: '98%',
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.1,
          },
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: undefined,
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: undefined,
            offsetY: 16,
            formatter: function (val) {
              return val + '%';
            },
          },
          total: {
            show: false,
            label: 'Total',
            color: '#373d3f',
            fontSize: '16px',
            fontFamily: undefined,
            fontWeight: 600,
            formatter: function (w) {
              return (
                w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0) /
                  w.globals.series.length +
                '%'
              );
            },
          },
        },
      },
    },

    stroke: {
      lineCap: 'round',
    },
    labels: ['Rate'],
  });

  const addZero = (string) => {
    if (0 <= string && string < 10) {
      return `0${string}`;
    } else {
      return string;
    }
  };

  return (
    <div id='kt_content_container' className='container-xxl'>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <Link
            to='#'
            className='card bg-danger hoverable card-xl-stretch mb-xl-8'
          >
            <div className='card-body'>
              <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z'
                    fill='black'
                  />
                  <path
                    opacity='0.3'
                    d='M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z'
                    fill='black'
                  />
                  <path
                    opacity='0.3'
                    d='M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z'
                    fill='black'
                  />
                </svg>
              </span>

              <div className='text-white fw-bolder fs-2 mb-2 mt-5'>
                Total Orders
                {dateFilterName === 'daily' && (
                  <span className='fw-bolder fs-3'>
                    {todayTotal ? `  (${addZero(todayTotal.order)})` : `  (00)`}
                  </span>
                )}
                {dateFilterName === 'weekly' && (
                  <span className='fw-bolder fs-3'>
                    {weekTotal ? `  (${addZero(weekTotal.order)})` : `  (00)`}
                  </span>
                )}
                {dateFilterName === 'yearly' && (
                  <span className='fw-bolder fs-3'>
                    {subTotal ? `  (${addZero(subTotal.order)})` : `  (00)`}
                  </span>
                )}
                {dateFilterName === 'custom' && (
                  <span className='fw-bolder fs-3'>
                    {ordersInRange
                      ? `  (${addZero(ordersInRange.order)})`
                      : `  (00)`}
                  </span>
                )}
              </div>
              <div className='fw-bold text-white'>
                Total orders that have been confirmed from amarlab operations
              </div>
            </div>
          </Link>
        </div>
        <div className='col-xl-4'>
          <Link
            to='#'
            className='card bg-primary hoverable card-xl-stretch mb-xl-8'
          >
            <div className='card-body'>
              <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    opacity='0.3'
                    d='M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z'
                    fill='black'
                  />
                  <path
                    d='M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z'
                    fill='black'
                  />
                  <path
                    d='M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z'
                    fill='black'
                  />
                </svg>
              </span>

              <div className='text-white fw-bolder fs-2 mb-2 mt-5'>
                Total Users
                {dateFilterName === 'daily' && (
                  <span className='fw-bolder fs-3'>
                    {todayTotal
                      ? `  (${addZero(todayTotal.ordered_user)})`
                      : `  (00)`}
                  </span>
                )}
                {dateFilterName === 'weekly' && (
                  <span className='fw-bolder fs-3'>
                    {weekTotal
                      ? `  (${addZero(weekTotal.ordered_user)})`
                      : `  (00)`}
                  </span>
                )}
                {dateFilterName === 'yearly' && (
                  <span className='fw-bolder fs-3'>
                    {subTotal
                      ? `  (${addZero(subTotal.ordered_user)})`
                      : `  (00)`}
                  </span>
                )}
                {dateFilterName === 'custom' && (
                  <span className='fw-bolder fs-3'>
                    {ordersInRange
                      ? `  (${addZero(ordersInRange.ordered_user)})`
                      : `  (00)`}
                  </span>
                )}
              </div>
              <div className='fw-bold text-white'>Total ordered users</div>
            </div>
          </Link>
        </div>
        <div className='col-xl-4'>
          <Link
            to='#'
            className='card bg-success hoverable card-xl-stretch mb-5 mb-xl-8'
          >
            <div className='card-body'>
              <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    opacity='0.3'
                    d='M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z'
                    fill='black'
                  />
                  <path
                    d='M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z'
                    fill='black'
                  />
                </svg>
              </span>

              <div className='text-white fw-bolder fs-2 mb-2 mt-5'>
                Total Patients
                {dateFilterName === 'daily' && (
                  <span className='fw-bolder fs-3'>
                    {todayTotal
                      ? `  (${addZero(todayTotal.ordered_patient)})`
                      : `  (00)`}
                  </span>
                )}
                {dateFilterName === 'weekly' && (
                  <span className='fw-bolder fs-3'>
                    {weekTotal
                      ? `  (${addZero(weekTotal.ordered_patient)})`
                      : `  (00)`}
                  </span>
                )}
                {dateFilterName === 'yearly' && (
                  <span className='fw-bolder fs-3'>
                    {subTotal
                      ? `  (${addZero(subTotal.ordered_patient)})`
                      : `  (00)`}
                  </span>
                )}
                {dateFilterName === 'custom' && (
                  <span className='fw-bolder fs-3'>
                    {ordersInRange
                      ? `  (${addZero(ordersInRange.ordered_patient)})`
                      : `  (00)`}
                  </span>
                )}
              </div>
              <div className='fw-bold text-white'>Total ordered patients</div>
            </div>
          </Link>
        </div>
      </div>

      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <div className='card card-xl-stretch mb-xl-8'>
            <div className='card-header border-0 py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Sales Overview
                </span>
                <span className='text-muted fw-bold fs-7'>
                  Salse overview by filter
                </span>
              </h3>
            </div>

            <div className='card-body p-0 d-flex flex-column'>
              <div className='card-p pt-5 bg-body flex-grow-1'>
                <div className='row g-0'>
                  <div className='col mr-8'>
                    <div className='fs-7 text-muted fw-bold'>Total MRP</div>

                    <div className='d-flex align-items-center'>
                      {dateFilterName === 'daily' && (
                        <div className='fs-4 fw-bolder'>
                          {todayTotal
                            ? `BDT ${addZero(Number(todayTotal.price))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'weekly' && (
                        <div className='fs-4 fw-bolder'>
                          {weekTotal
                            ? `BDT ${addZero(Number(weekTotal.price))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'yearly' && (
                        <div className='fs-4 fw-bolder'>
                          {subTotal
                            ? `BDT ${addZero(
                                Number(
                                  subTotal.price -
                                    subTotal.total_collection -
                                    subTotal.total_material
                                )
                              )}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'custom' && (
                        <div className='fs-4 fw-bolder'>
                          {ordersInRange
                            ? `BDT ${addZero(Number(ordersInRange.price))}`
                            : `00`}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col'>
                    <div className='fs-7 text-muted fw-bold'>
                      Total Discount
                    </div>

                    {dateFilterName === 'daily' && (
                      <div className='fs-4 fw-bolder'>
                        {todayTotal
                          ? `BDT ${addZero(
                              Number(todayTotal.price - todayTotal.discount)
                            )}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'weekly' && (
                      <div className='fs-4 fw-bolder'>
                        {weekTotal
                          ? `BDT ${addZero(
                              Number(weekTotal.price - weekTotal.discount)
                            )}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'yearly' && (
                      <div className='fs-4 fw-bolder'>
                        {subTotal
                          ? `BDT ${addZero(Number(subTotal.discount))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'custom' && (
                      <div className='fs-4 fw-bolder'>
                        {ordersInRange
                          ? `BDT ${addZero(
                              Number(
                                ordersInRange.price - ordersInRange.discount
                              )
                            )}`
                          : `00`}
                      </div>
                    )}
                  </div>
                </div>

                <div className='row g-0 mt-8'>
                  <div className='col mr-8'>
                    <div className='fs-7 text-muted fw-bold'>
                      Total Collection Charge
                    </div>

                    {dateFilterName === 'daily' && (
                      <div className='fs-4 fw-bolder'>
                        {todayTotal
                          ? `BDT ${addZero(Number(todayTotal.due))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'weekly' && (
                      <div className='fs-4 fw-bolder'>
                        {weekTotal
                          ? `BDT ${addZero(Number(weekTotal.due))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'yearly' && (
                      <div className='fs-4 fw-bolder'>
                        {subTotal
                          ? `BDT ${addZero(Number(subTotal.total_collection))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'custom' && (
                      <div className='fs-4 fw-bolder'>
                        {ordersInRange
                          ? `BDT ${addZero(Number(ordersInRange.due))}`
                          : `00`}
                      </div>
                    )}
                  </div>

                  <div className='col'>
                    <div className='fs-7 text-muted fw-bold'>
                      Total Collection Materials
                    </div>

                    <div className='d-flex align-items-center'>
                      {dateFilterName === 'daily' && (
                        <div className='fs-4 fw-bolder'>
                          {todayTotal
                            ? `BDT ${addZero(Number(todayTotal.discount))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'weekly' && (
                        <div className='fs-4 fw-bolder'>
                          {weekTotal
                            ? `BDT ${addZero(Number(weekTotal.discount))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'yearly' && (
                        <div className='fs-4 fw-bolder'>
                          {subTotal
                            ? `BDT ${addZero(Number(subTotal.total_material))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'custom' && (
                        <div className='fs-4 fw-bolder'>
                          {ordersInRange
                            ? `BDT ${addZero(Number(ordersInRange.discount))}`
                            : `00`}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row g-0 mt-8'>
                  <div className='col mr-8'>
                    <div className='fs-7 text-muted fw-bold'>Total GMV</div>

                    <div className='d-flex align-items-center'>
                      {dateFilterName === 'daily' && (
                        <div className='fs-4 fw-bolder'>
                          {todayTotal
                            ? `BDT ${addZero(Number(todayTotal.price))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'weekly' && (
                        <div className='fs-4 fw-bolder'>
                          {weekTotal
                            ? `BDT ${addZero(Number(weekTotal.price))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'yearly' && (
                        <div className='fs-4 fw-bolder'>
                          {subTotal
                            ? `BDT ${addZero(Number(subTotal.price))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'custom' && (
                        <div className='fs-4 fw-bolder'>
                          {ordersInRange
                            ? `BDT ${addZero(Number(ordersInRange.price))}`
                            : `00`}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col'>
                    <div className='fs-7 text-muted fw-bold'>
                      Total Collected
                    </div>

                    {dateFilterName === 'daily' && (
                      <div className='fs-4 fw-bolder'>
                        {todayTotal
                          ? `BDT ${addZero(
                              Number(todayTotal.price - todayTotal.discount)
                            )}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'weekly' && (
                      <div className='fs-4 fw-bolder'>
                        {weekTotal
                          ? `BDT ${addZero(
                              Number(weekTotal.price - weekTotal.discount)
                            )}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'yearly' && (
                      <div className='fs-4 fw-bolder'>
                        {subTotal
                          ? `BDT ${addZero(
                              Number(subTotal.payment_collected_client)
                            )}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'custom' && (
                      <div className='fs-4 fw-bolder'>
                        {ordersInRange
                          ? `BDT ${addZero(
                              Number(
                                ordersInRange.price - ordersInRange.discount
                              )
                            )}`
                          : `00`}
                      </div>
                    )}
                  </div>
                </div>

                <div className='row g-0 mt-8'>
                  <div className='col mr-8'>
                    <div className='fs-7 text-muted fw-bold'>
                      Total Due from Client
                    </div>

                    {dateFilterName === 'daily' && (
                      <div className='fs-4 fw-bolder'>
                        {todayTotal
                          ? `BDT ${addZero(Number(todayTotal.due))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'weekly' && (
                      <div className='fs-4 fw-bolder'>
                        {weekTotal
                          ? `BDT ${addZero(Number(weekTotal.due))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'yearly' && (
                      <div className='fs-4 fw-bolder'>
                        {subTotal
                          ? `BDT ${addZero(Number(subTotal.client_due))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'custom' && (
                      <div className='fs-4 fw-bolder'>
                        {ordersInRange
                          ? `BDT ${addZero(Number(ordersInRange.due))}`
                          : `00`}
                      </div>
                    )}
                  </div>

                  <div className='col'>
                    <div className='fs-7 text-muted fw-bold'>Total Revenue</div>

                    <div className='d-flex align-items-center'>
                      {dateFilterName === 'daily' && (
                        <div className='fs-4 fw-bolder'>
                          {todayTotal
                            ? `BDT ${addZero(Number(todayTotal.discount))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'weekly' && (
                        <div className='fs-4 fw-bolder'>
                          {weekTotal
                            ? `BDT ${addZero(Number(weekTotal.discount))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'yearly' && (
                        <div className='fs-4 fw-bolder'>
                          {subTotal
                            ? `BDT ${addZero(Number(subTotal.revenue))}`
                            : `00`}
                        </div>
                      )}
                      {dateFilterName === 'custom' && (
                        <div className='fs-4 fw-bolder'>
                          {ordersInRange
                            ? `BDT ${addZero(Number(ordersInRange.discount))}`
                            : `00`}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row g-0 mt-8'>
                  <div className='col mr-8'>
                    <div className='fs-7 text-muted fw-bold'>
                      Total Due to Diagnostics
                    </div>

                    {dateFilterName === 'daily' && (
                      <div className='fs-4 fw-bolder'>
                        {todayTotal
                          ? `BDT ${addZero(Number(todayTotal.due))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'weekly' && (
                      <div className='fs-4 fw-bolder'>
                        {weekTotal
                          ? `BDT ${addZero(Number(weekTotal.due))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'yearly' && (
                      <div className='fs-4 fw-bolder'>
                        {subTotal
                          ? `BDT ${addZero(Number(subTotal.lab_due))}`
                          : `00`}
                      </div>
                    )}
                    {dateFilterName === 'custom' && (
                      <div className='fs-4 fw-bolder'>
                        {ordersInRange
                          ? `BDT ${addZero(Number(ordersInRange.due))}`
                          : `00`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-4'>
          <div className='card card-xl-stretch mb-xl-8'>
            <div className='card-header border-0 py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Sales Chart
                </span>
                <span className='text-muted fw-bold fs-7'>
                  Salse Chart by filter
                </span>
              </h3>
            </div>

            <div className='card-body p-0 d-flex flex-column'>
              <div className='card-p pt-5 bg-body flex-grow-1'></div>
              <div
                className='mixed-widget-3-chart card-rounded-bottom mb-6 mr-2'
                data-kt-chart-color='primary'
                style={{ height: 300 }}
              >
                {subTotal && (
                  <Chart
                    options={{
                      ...options1,
                      xaxis: {
                        categories: subTotal.price_cart.map((item) =>
                          moment(item.month).format('MMM YY')
                        ),
                      },
                    }}
                    series={[
                      {
                        name: 'price',
                        data: subTotal.price_cart.map((item) => item.price),
                      },
                    ]}
                    type='area'
                    width='100%'
                    height='100%'
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-4'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Todays Completion Rate
                </span>
                <span className='text-muted fw-bold fs-7'>
                  Today’s confirmed order from Amarlab operations
                </span>
              </h3>
            </div>

            <div className='card-body d-flex flex-column'>
              <div className='flex-grow-1 d-flex justify-content-center align-items-center'>
                <div
                  className='mixed-widget-4-chart circle-ch'
                  data-kt-chart-color='primary'
                  id='hide-translate'
                >
                  <Chart
                    options={options2}
                    series={series2}
                    type='radialBar'
                    width='100%'
                    height='100%'
                  />
                </div>
              </div>
              <div className='pt-5'>
                <p className='text-center fs-7 pb-5'>
                  <span className='badge badge-light-danger fs-8'>Notes:</span>
                  &#160; Completed order rate that came today and has been
                  completed today
                </p>
                <Link to='/orders' className='btn btn-primary w-100 py-3'>
                  Orders
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xl-6'>
          <div
            className='card card-xl-stretch mb-5 mb-xl-8 pb-3'
            style={{ maxHeight: '520px' }}
          >
            <div className='card-header align-items-center border-0 mt-3'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Total Service by individual MT
                </span>
                <span className='text-muted fw-bold fs-7'>
                  Total Service by individual MT
                </span>
              </h3>
            </div>

            <div className='card-body pt-5 overflow-auto '>
              {subTotal &&
                subTotal.mt_user.map((item, index) => (
                  <div key={index} className='d-flex mb-7'>
                    <div className='symbol symbol-60px symbol-2by3 flex-shrink-0 me-4 '>
                      <i
                        className='fas fa-file-medical-alt mw-100 d-flex justify-content-center align-items-center border border-2'
                        style={{
                          fontSize: '15px',
                          borderRadius: 5,
                        }}
                      />
                    </div>

                    <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                      <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                        <Link
                          to='#'
                          className='fs-5 text-gray-800 text-hover-primary fw-bolder'
                        >
                          {item.username}
                        </Link>
                        <span className='text-gray-400 fw-bold fs-7 my-1'>
                          Name: {`${item.first_name} ${item.last_name}`}
                        </span>
                        {item.email && (
                          <span className='text-gray-400 fw-bold fs-7'>
                            Email: {item.email}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='d-flex align-items-center flex-wrap min-w-75px max-w-75px justify-content-end'>
                      <div className='text-end py-lg-0 py-2'>
                        <span className='text-gray-800 fw-boldest fs-3'>
                          {item.count}
                        </span>
                        <span className='text-gray-400 fs-7 fw-bold d-block'>
                          Service
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div
            className='card card-xl-stretch mb-5 mb-xl-8 pb-3'
            style={{ maxHeight: '520px' }}
          >
            <div className='card-header align-items-center border-0 mt-3'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Total Revenue by Individual MT
                </span>
                <span className='text-muted fw-bold fs-7'>
                  Total Revenue by Individual MT
                </span>
              </h3>
            </div>

            <div className='card-body pt-5 overflow-auto '>
              {subTotal &&
                subTotal.mt_user.map((item, index) => (
                  <div key={index} className='d-flex mb-7'>
                    <div className='symbol symbol-60px symbol-2by3 flex-shrink-0 me-4 '>
                      <i
                        className='fas fa-file-medical-alt mw-100 d-flex justify-content-center align-items-center border border-2'
                        style={{
                          fontSize: '15px',
                          borderRadius: 5,
                        }}
                      />
                    </div>

                    <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                      <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                        <Link
                          to='#'
                          className='fs-5 text-gray-800 text-hover-primary fw-bolder'
                        >
                          {item.username}
                        </Link>
                        <span className='text-gray-400 fw-bold fs-7 my-1'>
                          Name: {`${item.first_name} ${item.last_name}`}
                        </span>
                        {item.email && (
                          <span className='text-gray-400 fw-bold fs-7'>
                            Email: {item.email}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='d-flex align-items-center flex-wrap min-w-75px max-w-75px justify-content-end'>
                      <div className='text-end py-lg-0 py-2'>
                        <span className='text-gray-800 fw-boldest fs-3'>
                          {item.revenue}
                        </span>
                        <span className='text-gray-400 fs-7 fw-bold d-block'>
                          Revenue
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col'>
          <div>
            {[...Array(5)].map((_, i) => (
              <span key={i}>
                <span>
                  {_}
                  {i}
                </span>
              </span>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AccountHomepage;
