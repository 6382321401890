import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderToolbar from '../components/HeaderToolbar';
import { AuthContext } from '../context/AuthContext';
import AngleDown from '../assets/img/angle-down.png';
import FilterPopover from '../components/Dropdown/FilterPopover';
import axios from 'axios';

class ReferenceAnalytic extends Component {
  state = {
    sortedBy: '',
    sortedAsc: true,

    filterJoining: '',
    filterManager: '',
  };

  setOnboardDate = (v) => {
    this.setState({ onboardDate: v });
  };

  setDob = (v) => {
    this.setState({ dob: v });
  };

  setMarriageDate = (v) => {
    this.setState({ marriageDate: v });
  };

  componentDidMount() {
    this.context.setPageTitle('Reference Analytic');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Reference', link: '/reference' },
      { name: 'Reference Analytic', link: '/reference-add' },
    ]);
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      // this.fetchReference(this.state);
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Lab Partner' ||
        userRole.groups[0].name === 'External'
      ) {
        this.props.history.push('/orders');
      } else {
        // this.fetchReference(this.state);
      }
    }
  }

  render() {
    const { sortedBy, sortedAsc, filterJoining, filterManager } = this.state;

    return (
      <>
        <HeaderToolbar title='Reference Analytic' />
        <div className='content d-flex flex-column flex-column-fluid ref-add-page ref-det-page'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row head-details'>
                      <div className='col-md-6 col-f-item'>
                        <Link
                          to='/reference'
                          className='btn btn-light-primary back-btn'
                        >
                          <i className='fas fa-chevron-left me-2'></i>
                          Back
                        </Link>
                        <div className='details'>
                          <div className='head'>
                            <div className='analytic'>
                              <h5>Analytic</h5>
                              <p>#006545</p>
                            </div>
                            <button className='btn btn-light'>Export</button>
                          </div>
                          <div className='body'>
                            <div className='single'>
                              <p className='title'>756</p>
                              <p className='desc'>Total Orders</p>
                            </div>
                            <div className='single'>
                              <p className='title'>Active</p>
                              <p className='desc'>Status</p>
                            </div>
                            <div className='single'>
                              <p className='title'>৳ 7,50,0000</p>
                              <p className='desc'>Order Amount</p>
                            </div>
                          </div>
                          <div className='reference'>
                            <p className='title'>REFERENCE CODES:</p>
                            <p className='desc'>
                              BLKSP101 - BLKSP125, BLKSP101 - BLKSP125, BLKSP101
                              - BLKSP125
                            </p>
                          </div>
                          <div className='foot'>
                            <p className='title'>DETAILS:</p>
                            <p className='desc'>
                              Lorem Ipsum Is Simply Dummy Text Of The Printing
                              And Typesetting Industry. Lorem Ipsum Has Been The
                              Industry’s Standard Dummy Text Ever Since The
                              1500S.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 col-l-item'>
                        <div className='details info'>
                          <div className='left'>
                            <div className='individual'>
                              <p className='title'>Name</p>
                              <p className='desc'>Dr. Kabir Chowdhury</p>
                            </div>
                            <div className='individual'>
                              <p className='title'>CHANNEL</p>
                              <p className='desc'>DMF Doctor; Direct</p>
                            </div>
                            <div className='individual'>
                              <p className='title'>CONTACT</p>
                              <p className='desc'>
                                +88 017651131313 <br /> product@amarlab.com
                              </p>
                            </div>
                            <div className='individual'>
                              <p className='title'>BIRTH DAY</p>
                              <p className='desc'>31 Oct 1996</p>
                            </div>
                            <div className='individual'>
                              <p className='title'>MARRIAGE DAY</p>
                              <p className='desc'>03 June 2023</p>
                            </div>
                          </div>
                          <div className='right'>
                            <div className='individual'>
                              <p className='title'>DATE OF JOINING</p>
                              <p className='desc'>25 DEC 2021; 10am</p>
                            </div>
                            <div className='individual'>
                              <p className='title'>ACCOUNT MANAGER</p>
                              <p className='desc'>
                                Mr. Arif Ahmed <br /> arif@amarlab.com
                              </p>
                            </div>
                          </div>
                        </div>
                        <button className='btn btn-primary'>Update</button>
                      </div>
                    </div>
                    <div className='mt-2 table-responsive'>
                      <table className='table align-start table-row-dashed fs-7 gy-5'>
                        <thead>
                          <tr className='text-start text-dark fw-bolder fs-7 text-uppercase gs-0'>
                            <th className='min-w-125px sorting mt'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>ORDER INFO.</div>
                                <div className='d-flex flex-row ms-2'>
                                  <div className='d-flex align-items-center fs-8'>
                                    <FilterPopover
                                      customIcon={() => (
                                        <i
                                          className='fas fa-sort-amount-down pointer text-dark'
                                          style={{
                                            opacity:
                                              filterJoining === '' ? 0.2 : 0.6,
                                          }}
                                        ></i>
                                      )}
                                      value={filterJoining}
                                      setValue={(stat) =>
                                        this.setState({
                                          filterJoining: stat,
                                        })
                                      }
                                      handleSave={this.handleByServiceStatus}
                                      options={this.orderServiceStatus}
                                      placement='bottom-end'
                                      popOverId='filter-drop'
                                      editAccess={true}
                                      onBlurHide={true}
                                    />
                                  </div>
                                  <div
                                    className={`px-0 border border-0 d-flex flex-column align-items-center ms-1`}
                                    onClick={() => this.handleSorting('date')}
                                    style={{
                                      marginTop: '2px',
                                      transform: 'scaleX(0.8) scaleY(0.9)',
                                    }}
                                  >
                                    <img
                                      className='pointer'
                                      src={AngleDown}
                                      alt=''
                                      style={{
                                        width: '15px',
                                        height: '8px',
                                        opacity:
                                          sortedBy === 'date' && !sortedAsc
                                            ? 1
                                            : 0.3,
                                        transform: 'rotate(180deg)',
                                      }}
                                    />
                                    <img
                                      className='pointer'
                                      src={AngleDown}
                                      alt=''
                                      style={{
                                        width: '15px',
                                        height: '8px',
                                        opacity:
                                          sortedBy === 'date' && sortedAsc
                                            ? 1
                                            : 0.3,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className='min-w-100px sorting ps-3'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>
                                  ORDER STATUS
                                </div>
                              </div>
                            </th>
                            <th className='min-w-150px sorting ps-3'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>
                                  PATIENT INFO.
                                </div>
                              </div>
                            </th>
                            <th className='min-w-125px sorting ps-3'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>LAB INFO.</div>
                              </div>
                            </th>
                            <th className='min-w-80px sorting ps-3'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>COUPON CODE</div>
                              </div>
                            </th>
                            <th className='min-w-80px sorting mt'>
                              <div className='d-flex flex-row position-relative'>
                                <div className='text-truncate'>MRP</div>
                                <div className='d-flex flex-row ms-2'>
                                  <div className='d-flex align-items-center fs-8'>
                                    <FilterPopover
                                      customIcon={() => (
                                        <i
                                          className='fas fa-sort-amount-down pointer text-dark'
                                          style={{
                                            opacity:
                                              filterManager === '' ? 0.2 : 0.6,
                                          }}
                                        ></i>
                                      )}
                                      value={filterManager}
                                      setValue={(stat) =>
                                        this.setState({
                                          filterManager: stat,
                                        })
                                      }
                                      handleSave={this.handleByServiceStatus}
                                      options={this.orderServiceStatus}
                                      placement='bottom-end'
                                      popOverId='filter-drop'
                                      editAccess={true}
                                      onBlurHide={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className='min-w-80px sorting mt'>
                              <div className='d-flex flex-row'>
                                <div className='text-truncate'>DISCOUNT</div>
                                <div className='d-flex flex-row ms-2'>
                                  <div className='d-flex align-items-center fs-8'>
                                    <FilterPopover
                                      customIcon={() => (
                                        <i
                                          className='fas fa-sort-amount-down pointer text-dark'
                                          style={{
                                            opacity:
                                              filterManager === '' ? 0.2 : 0.6,
                                          }}
                                        ></i>
                                      )}
                                      value={filterManager}
                                      setValue={(stat) =>
                                        this.setState({
                                          filterManager: stat,
                                        })
                                      }
                                      handleSave={this.handleByServiceStatus}
                                      options={this.orderServiceStatus}
                                      placement='bottom-end'
                                      popOverId='filter-drop'
                                      editAccess={true}
                                      onBlurHide={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className='min-w-80px sorting mt'>
                              <div className='d-flex flex-row'>
                                <div className='text-truncate'>TOTAL</div>
                                <div className='d-flex flex-row ms-2'>
                                  <div className='d-flex align-items-center fs-8'>
                                    <FilterPopover
                                      customIcon={() => (
                                        <i
                                          className='fas fa-sort-amount-down pointer text-dark'
                                          style={{
                                            opacity:
                                              filterManager === '' ? 0.2 : 0.6,
                                          }}
                                        ></i>
                                      )}
                                      value={filterManager}
                                      setValue={(stat) =>
                                        this.setState({
                                          filterManager: stat,
                                        })
                                      }
                                      handleSave={this.handleByServiceStatus}
                                      options={this.orderServiceStatus}
                                      placement='bottom-end'
                                      popOverId='filter-drop'
                                      editAccess={true}
                                      onBlurHide={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='fw-bold text-gray-600'>
                          <TableRowItem />
                          <TableRowItem />
                          <TableRowItem />
                          <TableRowItem />
                          <TableRowItem />
                          <TableRowItem />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReferenceAnalytic;
ReferenceAnalytic.contextType = AuthContext;

const TableRowItem = () => (
  <tr>
    <td className='ps-0'>
      <div className='text-dark d-flex flex-row align-items-center'>#10056</div>
      <div className='text-gray-500 d-flex flex-row align-items-center'>
        25 DEC 2021; 10am
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center fw-500'>
        Canceled
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center fw-500'>
        +88 01765113131
      </div>
      <div className='text-gray-500 d-flex flex-row align-items-center'>
        Mr. Bishwamitra Ch. (M, 34y)
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center fw-500'>
        Popular Diagnostics Ltd.
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center fw-500'>
        AMRG566
      </div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center'>৳7,500</div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center'>৳7,500</div>
    </td>
    <td>
      <div className='text-dark d-flex flex-row align-items-center'>৳7,500</div>
    </td>
  </tr>
);
