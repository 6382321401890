import axios from 'axios';
import AsyncSelect from 'react-select/async';

const SearchTests = ({
  testType,
  setAllLabs,
  setTestName,
  testName,
  selectedLabName,
}) => {
  const handleChange = (e) => {
    // console.log(e);
    if (setTestName) {
      setTestName(e);
    }
    if (e.order_type === 'package') {
      const labs = e.packageitem
        .filter((item) => {
          return item.test_item.branch !== null;
        })
        .map((item) => {
          return {
            ...item,
            order_type: 'package',
          };
        });
      // console.log(labs);
      if (selectedLabName.length !== 0) {
        // console.log(selectedLabName);
        // console.log(labs);
        const lab = labs.filter((lab) => {
          // return lab.test_item.branch.lab.id === selectedLabName[0];
          return selectedLabName.some(
            (id) => parseInt(id) === lab.test_item.branch.lab.id
          );
        });
        setAllLabs(lab);
      } else {
        setAllLabs(labs);
      }
    } else if (e.order_type === 'diagnostic') {
      const labs = e.testitem
        .filter((item) => {
          return item.branch !== null;
        })
        .map((item) => {
          return {
            ...item,
            order_type: 'diagnostic',
          };
        });
      if (selectedLabName.length !== 0) {
        // console.log(labs);
        // console.log(selectedLabName);
        const lab = labs.filter((lab) => {
          return selectedLabName.some(
            (id) => parseInt(id) === lab.branch.lab.id
          );
          // return lab.branch.lab.id === selectedLabName[0];
        });
        setAllLabs(lab);
        // console.log(lab);
      } else {
        setAllLabs(labs);
      }
    }
    // setUserDetails(e);
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      // resolve(filterUsers(inputValue));
      let url;
      if (testType === 'diagnostic') {
        url = 'diagnostic-test-ops';
      } else if (testType === 'package') {
        url = 'diagnostic-package';
      }
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/${url}/?search=${inputValue}&limit=200&locked=false`
        )
        .then((resp) => {
          // console.log(resp.data.results);
          const tests = resp.data.results.map((test) => {
            return {
              ...test,
              value: test.name,
              label: test.name,
              order_type: testType === 'diagnostic' ? 'diagnostic' : 'package',
            };
          });
          resolve(tests);
          // console.log(tests);
        });
    });

  return (
    <AsyncSelect
      onChange={handleChange}
      // defaultOptions={users}
      loadOptions={promiseOptions}
      placeholder={'Search & Select Test'}
      className='mb-5'
      value={testName}
    />
  );
};

export default SearchTests;
