/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { Component } from 'react';
import { Modal, ModalBody, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderToolbar from '../components/HeaderToolbar';

import Pagination from '../components/Pagination/Pagination';
import { AuthContext } from '../context/AuthContext';
import MedicineDetailsForm from './MedicineDetailsForm';
import MedicineEditModal from './MedicineEditModal';

class Medicines extends Component {
  state = {
    loading: false,
    deleting: false,
    searchText: '',
    searchedMedicine: [],
    categories: null,

    medicines: [],
    showMedicines: null,
    pageMedicine: [],
    next: '',
    count: 0,
    limit: 10,
    pagination: 1,

    showEditModal: false,
    editableMedicine: {
      id: null,
      name: '',
      strength: '',
      form: '',
      unit: '',
      rx_req: '',
      rob: '',
      comment: '',
      price: 0,
      discount_price: 0,
      generic: '',
      company: '',
      image: null,
      category: 0,
    },
    uploadMedicineImage: null,
    medicineAddEditLoading: false,
  };

  searchRef = React.createRef();

  fetchMedicines = ({ limit, pagination }, seatchText) => {
    // const { limit, pagination } = this.state;
    let url = `${
      process.env.REACT_APP_BASE_URL
    }/medicine/store-get/?ordering=-id&limit=${limit}&offset=${
      (pagination - 1) * limit
    }`;
    if (!!seatchText) {
      url = `${
        process.env.REACT_APP_BASE_URL
      }/medicine/store-get/?ordering=-id&search=${seatchText}&limit=${limit}&offset=${
        (pagination - 1) * limit
      }`;
    }

    this.setState({ loading: true });
    setTimeout(() => {
      axios
        .get(url)
        .then((resp) => {
          this.setState({
            pageMedicine: resp.data.results,
            showMedicines: resp.data.results,
            next: resp.data.next,
            count: resp.data.count,
          });
          this.setState({ loading: false });
          // console.log(this.searchRef.current)
          // if(this.state.searchText) setTimeout(() => { this.searchRef.current.focus(); }, 10);
        })
        .catch((err) => {
          console.log('med err: ', err);
          this.setState({ loading: false });
        });
    }, 9);
  };

  goThroughSearch = (searchText) => {
    const { pageMedicine } = this.state;

    if (!searchText) {
      this.setState({ showMedicines: pageMedicine });
    } else {
      this.setState({
        showMedicines: pageMedicine.filter((med) =>
          med.name.includes(searchText)
        ),
      });
    }
  };

  // operations other page and modal

  handleEditMedicine = () => {
    const {
      name,
      strength,
      form,
      unit,
      rx_req,
      rob,
      comment,
      price,
      discount_price,
      generic,
      company,
      image,
      category,
    } = this.state.editableMedicine;

    if (!name) toast.error('Please enter medicine name.', { autoClose: 3000 });
    else if (!form)
      toast.error('Please enter medicine form.', { autoClose: 3000 });
    else if (!price)
      toast.error('Please enter medicine price.', { autoClose: 3000 });
    else if (!discount_price)
      toast.error('Please enter medicine discount price.', { autoClose: 3000 });
    else if (!generic)
      toast.error('Please enter medicine generic.', { autoClose: 3000 });
    else if (!company)
      toast.error('Please enter medicine company.', { autoClose: 3000 });
    else if (!category)
      toast.error('Please enter medicine category.', { autoClose: 3000 });
    else if (
      this.state.uploadMedicineImage &&
      this.state.uploadMedicineImage.size > 1024000
    )
      toast.error('Image can not be more than 1MB.', { autoClose: 3000 });
    else {
      const { limit, pagination } = this.state;

      let putData = {
        name: name,
        strength: null,
        form: form,
        unit: null,
        rx_req: null,
        rob: null,
        comment: null,
        price: price,
        discount_price: discount_price,
        generic: generic,
        company: company,
        category: parseInt(category),
      };

      if (strength) putData.strength = strength;
      if (unit) putData.unit = unit;
      if (rx_req) putData.rx_req = rx_req;
      if (rob) putData.rob = rob;
      if (comment) putData.comment = comment;

      // console.log(putData);
      const medId = this.state.editableMedicine.id;

      this.setState({ medicineAddEditLoading: true });

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/medicine/store/${medId}/`,
          putData
        )
        .then((resp) => {
          // console.log('medicine edit response: ', resp);
          this.fetchMedicines({ limit, pagination });
          toast.success('Medicine edited Successfully.', { autoClose: 3000 });
          this.setState({ medicineAddEditLoading: false });

          if (!this.state.uploadMedicineImage) {
            this.setState({ showEditModal: false });
          }
        })
        .catch((error) => {
          console.log('medicine edit error: ', error);
          toast.error('Unable to edit medicine, try again.', {
            autoClose: 3000,
          });
          this.setState({ medicineAddEditLoading: false });
        });

      // console.log(this.state.uploadMedicineImage);
      // console.log(!!this.state.uploadMedicineImage);

      if (!!this.state.uploadMedicineImage) {
        let medImage = new FormData();
        medImage.append('image', this.state.uploadMedicineImage);
        this.setState({ medicineAddEditLoading: true });
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/medicine/store/${medId}/`,
            medImage
          )
          .then((response) => {
            this.setState({
              medicineAddEditLoading: false,
              editableMedicine: {
                ...this.state.editableMedicine,
                image: response.data.image,
              },
            });
            // console.log('Medicine image edit response: ', response);
            toast.success('Image changed to the medicine.', {
              autoClose: 3000,
            });
            this.setState({ showEditModal: false });
          })
          .catch((error) => {
            this.setState({ medicineAddEditLoading: false });
            // console.log('medicine edit image error: ', error);
            toast.error('Unable to change image to the mdicine.', {
              autoClose: 3000,
            });
            this.setState({ showEditModal: false });
          });
      }
    }
  };

  deleteMedicine = (medId) => {
    const { showMedicines, pageMedicine } = this.state;
    this.setState({ deleting: true });
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/medicine/store/${medId}/`)
      .then((resp) => {
        // console.log(resp);
        this.setState({ deleting: false });

        if (resp.status === 201 || resp.status === 204) {
          toast.success(`Medicine deleted successfully.`, { autoClose: 3000 });
          this.setState({
            showMedicines: showMedicines.filter((item) => item.id !== medId),
          });
          this.setState({
            pageMedicine: pageMedicine.filter((item) => item.id !== medId),
          });
        } else
          toast.error(`Unable to delete Medicine, try again..`, {
            autoClose: 3000,
          });
      })
      .catch((error) => {
        this.setState({ deleting: false });
        console.log(error.response);
        toast.success(`Unable to delete Medicine, try again..`, {
          autoClose: 3000,
        });
      });
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Medicines', link: '/medicines' },
    ]);
    this.context.setPageTitle('Medicines');
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchMedicines(this.state);
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (userRole.groups[0].name !== 'Admin') {
        this.props.history.push('/orders');
      } else {
        this.fetchMedicines(this.state);
      }
    }
  }

  handlePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    if (this.state.searchText)
      this.fetchMedicines(
        { limit: this.state.limit, pagination: pageno },
        this.state.searchText
      );
    else this.fetchMedicines({ limit: this.state.limit, pagination: pageno });
    // console.log(pageno);
  };

  // handleLoadMore = () => {
  //   this.setState({ offset: this.state.offset + 8 });

  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/?ordering=-id&limit=${this.state.limit}&offset=${this.state.offset}&ofset=0&page=1`
  //     )
  //     .then((resp) => {
  //       // console.log(resp.data);
  //       this.setState({ tests: [...this.state.tests, ...resp.data.results] });
  //       this.setState({ next: resp.data.next });
  //     });
  // };

  handleSearch = (searchText) => {
    this.setState({ searchText: searchText, pagination: 1 });
    if (!searchText)
      this.fetchMedicines({ limit: this.state.limit, pagination: 1 });
    else
      this.fetchMedicines(
        { limit: this.state.limit, pagination: 1 },
        searchText
      );
    // this.fetchMedicines({this.st})
  };

  render() {
    const { count, limit, pagination, loading, deleting } = this.state;

    return (
      <>
        <HeaderToolbar title='Medicines' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='card'>
              <div className='card-header border-0 pt-6'>
                <div className='card-title align-items-start flex-column'>
                  <div className='d-flex align-items-center position-relative my-1'>
                    <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <rect
                          opacity='0.5'
                          x='17.0365'
                          y='15.1223'
                          width='8.15546'
                          height='2'
                          rx='1'
                          transform='rotate(45 17.0365 15.1223)'
                          fill='black'
                        ></rect>
                        <path
                          d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                          fill='black'
                        ></path>
                      </svg>
                    </span>
                    <input
                      type='text'
                      data-kt-customer-table-filter='search'
                      className='form-control form-control-solid w-250px ps-15'
                      placeholder='Search Medicine'
                      ref={this.searchRef}
                      // disabled={loading}
                      value={this.state.searchText}
                      onChange={(e) => this.handleSearch(e.target.value)}
                    />
                  </div>
                  <div className='text-muted mt-3 font-weight-bold fs-7 ps-1'>
                    {`Total  ${count ? count + '+ ' : '00'} ${
                      count ? `medicines` : `medicine`
                    } ${this.state.searchText && `found`}`}
                  </div>
                  {/* <div className="d-flex align-items-start flex-column" >
                <span className='card-label font-weight-bolder text-dark'>
                  All Medicine
                </span>
                <span className='text-muted mt-3 font-weight-bold font-size-sm'>
                  Total medicine {count || 0}+
                </span>
              </div> */}
                </div>
                <div className='card-toolbar'>
                  <div
                    className='d-flex justify-content-end'
                    data-kt-customer-table-toolbar='base'
                  >
                    {/* <button type="button" className="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                  <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="black"></path>
                    </svg>
                  </span>ilter
                </button> */}
                    {/* <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true" id="kt-toolbar-filter">
                  <div className="px-7 py-5">
                    <div className="fs-4 text-dark fw-bolder">Filter Options</div>
                  </div>
                  <div className="separator border-gray-200"></div>
                  <div className="px-7 py-5">
                    <div className="mb-10">
                      <label className="form-label fs-5 fw-bold mb-3">Month:</label>
                      <select className="form-select form-select-solid fw-bolder select2-hidden-accessible" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-customer-table-filter="month" data-dropdown-parent="#kt-toolbar-filter" data-select2-id="select2-data-7-k95e" tabindex="-1" aria-hidden="true">
                        <option data-select2-id="select2-data-9-nj3e"></option>
                        <option value="aug">August</option>
                        <option value="sep">September</option>
                        <option value="oct">October</option>
                        <option value="nov">November</option>
                        <option value="dec">December</option>
                      </select><span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-8-au20" style={{ width: '100%' }}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid fw-bolder" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-wn7v-container" aria-controls="select2-wn7v-container"><span className="select2-selection__rendered" id="select2-wn7v-container" role="textbox" aria-readonly="true" title="Select option"><span className="select2-selection__placeholder">Select option</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                    </div>
                    <div className="mb-10">
                      <label className="form-label fs-5 fw-bold mb-3">Payment Type:</label>
                      <div className="d-flex flex-column flex-wrap fw-bold" data-kt-customer-table-filter="payment_type">
                        <label className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                          <input className="form-check-input" type="radio" name="payment_type" value="all" checked="checked" />
                          <span className="form-check-label text-gray-600">All</span>
                        </label>
                        <label className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                          <input className="form-check-input" type="radio" name="payment_type" value="visa" />
                          <span className="form-check-label text-gray-600">Visa</span>
                        </label>
                        <label className="form-check form-check-sm form-check-custom form-check-solid mb-3">
                          <input className="form-check-input" type="radio" name="payment_type" value="mastercard" />
                          <span className="form-check-label text-gray-600">Mastercard</span>
                        </label>
                        <label className="form-check form-check-sm form-check-custom form-check-solid">
                          <input className="form-check-input" type="radio" name="payment_type" value="american_express" />
                          <span className="form-check-label text-gray-600">American Express</span>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button type="reset" className="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" data-kt-customer-table-filter="reset">Reset</button>
                      <button type="submit" className="btn btn-primary" data-kt-menu-dismiss="true" data-kt-customer-table-filter="filter">Apply</button>
                    </div>
                  </div>
                </div> */}
                    {/* <button type="button" className="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_customers_export_modal">
                  <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black"></rect>
                      <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black"></path>
                      <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4"></path>
                    </svg>
                  </span>xport
                </button> */}
                    <Link to='/medicine-add'>
                      <button
                        type='button'
                        className='btn btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_add_customer'
                      >
                        Add Medicine
                      </button>
                    </Link>
                  </div>
                  {/* for delete selected */}
                  {/* <div className="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                <div className="fw-bolder me-5">
                  <span className="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                <button type="button" className="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
              </div> */}
                </div>
              </div>
              <div className='card-body pt-0'>
                <div
                  id='kt_customers_table_wrapper'
                  className='dataTables_wrapper dt-bootstrap4 no-footer'
                >
                  <div className='table-responsive pe-5' id='resized_scrollbar'>
                    <table
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                      id='kt_customers_table'
                    >
                      <thead>
                        <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                          {/* <th className="w-10px pe-2" rowSpan="1" colSpan="1" aria-label="" style={{ width: '29.25px' }}>
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                          <input onClick={e => e.preventDefault()} className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                        </div>
                      </th> */}
                          <th
                            className='w-10px pe-3'
                            rowSpan='1'
                            colSpan='1'
                            aria-label=''
                            style={{ width: '35px' }}
                          >
                            Index
                          </th>
                          <th
                            className='min-w-150px'
                            tabIndex='0'
                            aria-controls='kt_customers_table'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Name: activate to sort column ascending'
                            style={{ width: '205px' }}
                          >
                            Name
                          </th>
                          <th
                            className='min-w-75px'
                            tabIndex='1'
                            aria-controls='kt_customers_table'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Image: nothing to do, just watch image.'
                            style={{ width: '150px' }}
                          >
                            Image
                          </th>
                          <th
                            className='min-w-100px'
                            tabIndex='1'
                            aria-controls='kt_customers_table'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Form: activate to sort column ascending'
                            style={{ width: '150px' }}
                          >
                            Form
                          </th>
                          <th
                            className='min-w-100px'
                            tabIndex='2'
                            aria-controls='kt_customers_table'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Price: activate to sort column ascending'
                            style={{ width: '150px' }}
                          >
                            Price
                          </th>
                          <th
                            className='min-w-125px'
                            tabIndex='3'
                            aria-controls='kt_customers_table'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Discount Price: activate to sort column ascending'
                            style={{ width: '150px' }}
                          >
                            Discount Price
                          </th>
                          <th
                            className='min-w-125px'
                            tabIndex='4'
                            aria-controls='kt_customers_table'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Generic: activate to sort column ascending'
                            style={{ width: '150px' }}
                          >
                            Generic
                          </th>
                          <th
                            className='min-w-200px'
                            tabIndex='5'
                            aria-controls='kt_customers_table'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Company: activate to sort column ascending'
                            style={{ width: '205px' }}
                          >
                            Company
                          </th>
                          <th
                            className='min-w-125px'
                            tabIndex='5'
                            aria-controls='kt_customers_table'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Category: activate to sort column ascending'
                            style={{ width: '150px' }}
                          >
                            Category
                          </th>
                          <th
                            className='min-w-100px'
                            tabIndex='5'
                            aria-controls='kt_customers_table'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Category: activate to sort column ascending'
                            style={{ width: '150px' }}
                          >
                            Action
                          </th>
                          {/* <th className="text-end min-w-70px" rowSpan="1" colSpan="1" aria-label="Actions" style={{ width: '118.422px' }}>Category</th> */}
                        </tr>
                      </thead>
                      <tbody className='fw-bold text-gray-600'>
                        {this.state.showMedicines &&
                          this.state.showMedicines.map((medicine, index) => (
                            <tr key={index} className='odd'>
                              <td>
                                {/* <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input onClick={e => e.preventDefault()} className="form-check-input" type="checkbox" value="1" />
                          </div> */}
                                <div className='text-gray-600 mb-0 text-center'>
                                  #{index + 1}
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-600 text-hover-primary mb-0'>
                                  {medicine.name}
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-600 text-hover-primary mb-0'>
                                  <div
                                    className='w-50px h-50px rounded d-flex justify-content-center align-items-center'
                                    style={{
                                      border: '1px solid #7C839950',
                                      padding: '1px',
                                    }}
                                  >
                                    {medicine.image ? (
                                      <img
                                        src={medicine.image}
                                        alt='No med'
                                        className='w-100 h-100 rounded'
                                      />
                                    ) : (
                                      <i className='fas fa-pills fs-2' />
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-600 text-hover-primary mb-0'>
                                  {medicine.form}
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-600 text-hover-primary mb-0'>
                                  {medicine.price}
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-600 text-hover-primary mb-0'>
                                  {medicine.discount_price}
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-600 text-hover-primary mb-0'>
                                  {medicine.generic}
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-600 text-hover-primary mb-0'>
                                  {medicine.company}
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-600 text-hover-primary mb-0'>
                                  {medicine.category
                                    ? medicine.category.name
                                    : '--'}
                                </div>
                              </td>
                              <td>
                                {/* <Link to="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">

                              <span className="svg-icon svg-icon-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none">
                                  <path
                                    d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                    fill="black" />
                                  <path opacity="0.3"
                                    d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                    fill="black" />
                                </svg>
                              </span>

                            </Link> */}
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (loading) console.log('wait');
                                    else {
                                      this.setState({
                                        showEditModal: true,
                                        editableMedicine: {
                                          ...medicine,
                                          category: medicine.category.id,
                                        },
                                      });
                                    }
                                    // console.log('click2: ', this.state);
                                  }}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_edit'
                                >
                                  <span className='svg-icon svg-icon-3'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                    >
                                      <path
                                        opacity='0.3'
                                        d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                                        fill='black'
                                      />
                                      <path
                                        d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                                        fill='black'
                                      />
                                    </svg>
                                  </span>
                                </div>
                                <div
                                  onClick={(e) => {
                                    if (loading) console.log('wait');
                                    else {
                                      if (
                                        window.confirm(
                                          ' Are you sure to delete this medicine?'
                                        )
                                      )
                                        this.deleteMedicine(medicine.id);
                                    }
                                  }}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                >
                                  <span className='svg-icon svg-icon-3'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                    >
                                      <path
                                        d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                                        fill='black'
                                      />
                                      <path
                                        opacity='0.5'
                                        d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                                        fill='black'
                                      />
                                      <path
                                        opacity='0.5'
                                        d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                                        fill='black'
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='row justify-content-end mt-4'>
                    {loading && (
                      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                        <Spinner animation='border' size='sm' />{' '}
                        <span className='ms-2 text-gray-600'>
                          loading, Please wait...
                        </span>
                      </div>
                    )}
                    {deleting && (
                      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                        <Spinner animation='border' size='sm' />{' '}
                        <span
                          className='ms-2 text-gray-600'
                          style={{ fontFamily: 'Poppins' }}
                        >
                          deleting...
                        </span>
                      </div>
                    )}
                    <Pagination
                      onPageChange={(pageno) =>
                        this.handlePaginationPress(pageno)
                      }
                      totalCount={count}
                      pageSize={limit}
                      currentPage={pagination}
                      siblingCount={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*******Edit Modal******/}
        <Modal
          show={this.state.showEditModal}
          onHide={() => this.setState({ showEditModal: false })}
          backdrop={false}
          animation={true}
          scrollable={true}
          size='xl'
          style={{ backgroundColor: '#7C839950' }}
        >
          <ModalBody className='custom_scrollbar'>
            <div className='pl-4 mb-0 d-flex justify-content-between align-items-center'>
              <h5 className='mb-0'>Update Medicine Details</h5>
              <button
                type='button'
                className='btn font-weight-bolder btn-danger px-9 py-4'
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showEditModal: false });
                }}
              >
                Close
              </button>
            </div>{' '}
            <hr />
            <div className=''>
              <div className='card py-8 px-8 py-lg-8 px-lg-8'>
                <div className='row justify-content-center'>
                  <div className='col-xl-9'>
                    <MedicineDetailsForm
                      medicine={this.state.editableMedicine}
                      uploadMedicineImage={this.state.uploadMedicineImage}
                      setState={(stateObj) => {
                        if (stateObj.medicine)
                          this.setState({
                            editableMedicine: stateObj.medicine,
                          });
                        else if (stateObj.uploadMedicineImage)
                          this.setState({
                            uploadMedicineImage: stateObj.uploadMedicineImage,
                          });
                        // console.log('state ',this.state);
                      }}
                      onSubmitOp={() => this.handleEditMedicine()}
                      submitBtnText='Update Medicine'
                      loading={this.state.medicineAddEditLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Medicines;
Medicines.contextType = AuthContext;
