import axios from 'axios';
import { useContext, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import TestCategoryAddModal from './TestCategory/TestCategoryAddModal';
import { AuthContext } from '../context/AuthContext';
import SelectCrossSell from './SelectCrossSell';
import BtnLoading from './BtnLoading';

const DiagnosticAddModal = ({
  showTestModal,
  setShowTestModal,
  categories,
  fetchTests,
  fetchCategories,
}) => {
  const { user_details } = useContext(AuthContext);
  const [Name, setName] = useState('');
  const [Description, setDescription] = useState(null);
  const [TestImg, setTestImg] = useState(null);
  const [Category, setCategory] = useState([]);
  const [IsActive, setIsActive] = useState(true);
  const [IsCovid, setIsCovid] = useState(false);
  const [whatIsTest, setWhatIsTest] = useState(null);
  const [testPreparation, setTestPreparation] = useState(null);
  const [testRemarks, setTestRemarks] = useState(null);
  const [testOverview, setTestOverview] = useState(null);
  const [understandingTest, setUnderstandingTest] = useState(null);
  const [crossSellDetails, setCrossSellDetails] = useState(null);
  const [testMeasure, settestMeasure] = useState(null);
  const [whenGetTested, setWhenGetTested] = useState(null);

  const [loading, setLoading] = useState(false);

  // category modal
  const [showTestCategoryModal, setShowTestCategoryModal] = useState(false);

  const postCat = (id) => {
    Category.forEach((category) => {
      const postData = {
        diagnostic_test: id,
        category: parseInt(category.category),
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/category-list/`,
          postData
        )
        .then((resp) => {
          // console.log(resp.data);
          setCategory([]);
        })
        .catch((error) => {
          // console.log(error.response);
        });
    });
  };

  const putTestImg = (id, data) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        setTestImg(null);
        fetchTests();
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Name.trim() === '') {
      toast.error(`Please enter test name.`, {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);

    const postData = {
      name: Name,
      description: Description,
      is_active: IsActive,
      is_covid: IsCovid,
      what_is_the_test: whatIsTest,
      test_preparation: testPreparation,
      remarks: testRemarks,
      test_overview: testOverview,
      understanding_test_result: understandingTest,
      created_by: user_details.id,
      cross_sell: crossSellDetails ? crossSellDetails.id : null,
      test_measure: testMeasure,
      when_to_get_tested: whenGetTested,
    };

    const imgg = new FormData();
    imgg.append('image', TestImg);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/`,
        postData
      )
      .then((resp) => {
        // console.log(resp.data);
        if (TestImg) {
          putTestImg(resp.data.id, imgg);
        }
        if (Category.length !== 0) {
          postCat(resp.data.id);
        }
        fetchTests();
        setName('');
        setDescription(null);
        setTestImg(null);
        setIsActive(true);
        setIsCovid(false);
        // setCategory('');
        setWhatIsTest(null);
        setTestPreparation(null);
        setTestRemarks(null);
        setTestOverview(null);
        setUnderstandingTest(null);
        setCrossSellDetails(null);
        settestMeasure(null);
        setWhenGetTested(null);
        setShowTestModal(false);
        toast.success(`Test added successfully.`, {
          autoClose: 3000,
        });
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response);
        setLoading(false);
      });
  };

  const handleCategory = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    const newVal = value.map((val) => {
      return {
        category: val,
      };
    });
    // this.setState({ ccl: newVal });
    setCategory(newVal);
  };

  return (
    <>
      <Modal
        show={showTestModal}
        onHide={() => setShowTestModal(false)}
        animation={false}
        size='lg'
        scrollable={true}
      >
        <ModalBody>
          <div className=''>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
              <h5 className='mb-0'>Add New Test</h5>
              <button
                className='btn btn-sm btn-danger'
                onClick={() => setShowTestModal(false)}
              >
                Close
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='form-group mb-3'>
                <input
                  type='text'
                  className='form-control fw-normal'
                  placeholder='Enter test name *'
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className='form-group mb-3'>
                <select
                  className='form-control fw-normal custom_scrollbar'
                  // onChange={(e) => setCategory(e.target.value)}
                  onChange={handleCategory}
                  multiple
                >
                  <option value=''>Select Category</option>
                  {categories &&
                    categories.map((category, i) => (
                      <option value={category.id} key={i}>
                        {category.name}{' '}
                      </option>
                    ))}
                </select>
              </div>
              <div className='mb-3 d-flex justify-content-end'>
                <button
                  className='btn btn-primary fs-4 px-6 py-2 h-100 justify-content-center'
                  onClick={(e) => {
                    e.preventDefault();
                    setShowTestCategoryModal(true);
                  }}
                >
                  Add Category
                </button>
              </div>
              <div className='form-group mb-4'>
                <div className='custom-file'>
                  <input
                    type='file'
                    className='custom-file-input'
                    accept='image/png, image/gif, image/jpeg'
                    onChange={(e) => setTestImg(e.target.files[0])}
                  />
                  <label className='custom-file-label'>Select Test Image</label>
                </div>
              </div>
              <div className='form-group mb-3 d-flex align-items-center'>
                <h5 className='mb-0 me-2'>Is Active</h5>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-50px'
                    type='checkbox'
                    name='is_active'
                    onChange={() => setIsActive(!IsActive)}
                    defaultChecked={IsActive}
                  />
                </div>
              </div>
              <div className='form-group mb-3 d-flex align-items-center'>
                <h5 className='mb-0 me-2'>Is Covid</h5>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-50px'
                    type='checkbox'
                    name='is_active'
                    onChange={() => setIsCovid(!IsCovid)}
                    defaultChecked={IsCovid}
                  />
                </div>
              </div>
              <div className='form-group mb-3'>
                <div className='form-control fw-normal px-1 py-1'>
                  <SelectCrossSell
                    setCrossSellDetails={setCrossSellDetails}
                    editId={null}
                  />
                </div>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='Enter test description'
                  rows='3'
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='Enter test overview'
                  rows='3'
                  value={testOverview}
                  onChange={(e) => setTestOverview(e.target.value)}
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='What is the test'
                  rows='3'
                  value={whatIsTest}
                  onChange={(e) => setWhatIsTest(e.target.value)}
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='Enter test measure'
                  rows='3'
                  value={testMeasure}
                  onChange={(e) => settestMeasure(e.target.value)}
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='When to get tested?'
                  rows='3'
                  value={whenGetTested}
                  onChange={(e) => setWhenGetTested(e.target.value)}
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='Enter test remarks'
                  rows='3'
                  value={testRemarks}
                  onChange={(e) => setTestRemarks(e.target.value)}
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='Enter test preparation'
                  rows='3'
                  value={testPreparation}
                  onChange={(e) => setTestPreparation(e.target.value)}
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='Understanding test result'
                  rows='3'
                  value={understandingTest}
                  onChange={(e) => setUnderstandingTest(e.target.value)}
                ></textarea>
              </div>
              <div className='form-group'>
                {!loading ? (
                  <button className='btn btn-primary'>Submit</button>
                ) : (
                  <BtnLoading name='Submit...' btnClass='primary ' />
                )}
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      <TestCategoryAddModal
        showTestCategoryModal={showTestCategoryModal}
        setShowTestCategoryModal={(stateObj) =>
          setShowTestCategoryModal(stateObj)
        }
        testCategories={categories}
        fetchTestCategories={fetchCategories}
      />
    </>
  );
};

export default DiagnosticAddModal;
