import axios from 'axios';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import HeaderToolbar from '../components/HeaderToolbar';
import { AuthContext } from '../context/AuthContext';
import MedicineDetailsForm from './MedicineDetailsForm';

export class MedicineAdd extends Component {
  constructor(props) {
    super(props);

    // if (this.props.medicine) {
    //   this.state = {
    //     medicine: {
    //       name: this.props.medicine.name,
    //       strength: this.props.medicine.strength,
    //       form: this.props.medicine.form,
    //       unit: this.props.medicine.unit,
    //       rx_req: this.props.medicine.rx_req,
    //       rob: this.props.medicine.rob,
    //       comment: this.props.medicine.comment,
    //       price: this.props.medicine.price,
    //       discount_price: this.props.medicine.discount_price,
    //       generic: this.props.medicine.generic,
    //       company: this.props.medicine.company,
    //       image: this.props.medicine.image,
    //       category: this.props.category.id,
    //     },
    //     uploadMedicineImage: null,
    //   }
    // }
    this.state = {
      medicine: {
        name: '',
        strength: '',
        form: '',
        unit: '',
        rx_req: '',
        rob: '',
        comment: '',
        price: '',
        discount_price: '',
        generic: '',
        company: '',
        image: '',
        category: 0,
      },
      uploadMedicineImage: null,
      medicineAddEditLoading: false,
    };
  }

  resetMedicineData = () => {
    this.setState({
      medicine: {
        name: '',
        strength: '',
        form: '',
        unit: '',
        rx_req: '',
        rob: '',
        comment: '',
        price: '',
        discount_price: '',
        generic: '',
        company: '',
        image: '',
        category: 0,
      },
      uploadMedicineImage: null,
      medicineAddEditLoading: false,
    });
  };

  redirectTo = (loc) => {
    setTimeout(() => {
      this.props.history.push(loc);
    }, 2500);
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Medicines', link: '/medicines' },
      { name: 'Add Medicine', link: '' },
    ]);
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (!token) {
      this.props.history.push('/login');
      return;
    }

    if (userRole.is_superuser) {
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (userRole.groups[0].name !== 'Admin') {
        this.props.history.push('/orders');
      } else {
      }
    }
  }

  handleAddMedicine = () => {
    const {
      name,
      strength,
      form,
      unit,
      rx_req,
      rob,
      comment,
      price,
      discount_price,
      generic,
      company,
      image,
      category,
    } = this.state.medicine;

    if (!name) toast.error('Please enter medicine name.', { autoClose: 3000 });
    else if (!form)
      toast.error('Please enter medicine form.', { autoClose: 3000 });
    else if (!price)
      toast.error('Please enter medicine price.', { autoClose: 3000 });
    else if (!discount_price)
      toast.error('Please enter medicine discount price.', { autoClose: 3000 });
    else if (!generic)
      toast.error('Please enter medicine generic.', { autoClose: 3000 });
    else if (!company)
      toast.error('Please enter medicine company.', { autoClose: 3000 });
    else if (!category)
      toast.error('Please enter medicine category.', { autoClose: 3000 });
    else if (
      this.state.uploadMedicineImage &&
      this.state.uploadMedicineImage.size > 1024000
    )
      toast.error('Image can not be more than 1MB.', { autoClose: 3000 });
    else {
      let postData = {
        name: name,
        // strength: strength,
        form: form,
        // unit: unit,
        // rx_req: rx_req,
        // rob: rob,
        // comment: comment,
        price: price,
        discount_price: discount_price,
        generic: generic,
        company: company,
        category: parseInt(category),
      };

      if (strength) postData.strength = strength;
      if (unit) postData.unit = unit;
      if (rx_req) postData.rx_req = rx_req;
      if (rob) postData.rob = rob;
      if (comment) postData.comment = comment;

      // console.log(postData);

      this.setState({ medicineAddEditLoading: true });

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/medicine/store/`, postData)
        .then((resp) => {
          // console.log('medicine response: ', resp);
          this.setState({ medicineAddEditLoading: false });

          if (resp.status === 201) {
            toast.success('Medicine Added Successfully.', { autoClose: 3000 });
            this.resetMedicineData();

            const medId = resp.data.id;
            if (
              this.state.uploadMedicineImage &&
              this.state.uploadMedicineImage.size <= 1024000
            ) {
              toast.info('Please wait for image to load.', { autoClose: 3000 });
              let medImage = new FormData();
              medImage.append('image', this.state.uploadMedicineImage);

              this.setState({ medicineAddEditLoading: true });

              axios
                .put(
                  `${process.env.REACT_APP_BASE_URL}/medicine/store/${medId}/`,
                  medImage
                )
                .then((response) => {
                  this.setState({ medicineAddEditLoading: false });
                  // console.log('Medicine image response: ', response);
                  toast.success('Image added to recently created medicine.', {
                    autoClose: 3000,
                  });
                  this.redirectTo('/medicines');
                })
                .catch((error) => {
                  this.setState({ medicineAddEditLoading: false });
                  console.log(error);
                  toast.error(
                    'Unable to add image to recently created mdicine.',
                    { autoClose: 3000 }
                  );
                  this.redirectTo('/medicines');
                });
            } else {
              this.redirectTo('/medicines');
            }
          } else {
            console.log(resp);
            toast.error('Unable to add medicine, try again.', {
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          this.setState({ medicineAddEditLoading: false });
          console.log(error);
          toast.error('Unable to add medicine, try again.', {
            autoClose: 3000,
          });
        });
    }
  };

  render() {
    const { medicine, uploadMedicineImage } = this.state;

    return (
      <>
        <HeaderToolbar title='Medicine Add' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card py-8 px-8 py-lg-15 px-lg-10'>
                  <div className='row justify-content-center'>
                    <div className='col-xl-9'>
                      <h5 className='text-dark font-weight-bold mb-10'>
                        Provide Medicine Details:
                      </h5>
                      <MedicineDetailsForm
                        medicine={medicine}
                        uploadMedicineImage={uploadMedicineImage}
                        setState={(stateObj) => this.setState(stateObj)}
                        onSubmitOp={() => this.handleAddMedicine()}
                        loading={this.state.medicineAddEditLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MedicineAdd;
MedicineAdd.contextType = AuthContext;
