import { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

const AddressPopover = ({ address, area, city, title, handleIClick }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (e) => {
    setShow(!show);
    setTarget(e.target);
    if (handleIClick) handleIClick();
  };

  return (
    <div ref={ref} className='select-pop'>
      <div
        className='pointer'
        //   onClick={(e) => handleClick(e, mainOrder, 'order_status')}
        onClick={(e) => handleClick(e)}
      >
        <div className='text-dim-dark'>
          <u>
            {area}, {city}
          </u>
        </div>
      </div>

      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover
          id='popover-contained'
          style={{ boxShadow: '0rem 0.1rem 1rem #00000025' }}
        >
          <Popover.Content>
            <p className='font-semibold mb-3'>{title}</p>
            <div
              style={{ whiteSpace: 'pre-wrap' }}
              className='fw-bold fs-7 text-dim-dark'
            >
              {address},
            </div>
            <div className='fw-bold fs-7 text-dim-dark'>
              {area}, {city}
            </div>
            <div className='footer'>
              <div></div>
              <button onClick={() => setShow(false)}>Cancel</button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default AddressPopover;
