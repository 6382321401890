import axios from 'axios';
import React, { Component } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import warningLogo from '../assets/img/warning.svg';
import moment from 'moment';
import Switch from 'react-switch';
import { AuthContext } from '../context/AuthContext';

import Pagination from '../components/Pagination/Pagination';
import HeaderToolbar from './HeaderToolbar';

class Users extends Component {
  state = {
    cupons: null,
    user_id: null,
    group: [],
    showModal: false,
    deleteId: null,
    next: null,
    offset: 10,
    limit: 10,
    switchChecked: false,
    currentGroup: 'Order Manager',

    count: 0,
    pagination: 1,
    searchText: '',
    loading: false,
  };

  handleSwitchChecked = (cupon, value) => {
    // console.log(cupon.id);
    this.handleModalDlt(cupon.id, value);
    // const cupons = this.state.cupons.map((cup) => {
    //   return cup.id === cupon.id ? { ...cup, is_active: !cup.is_active } : cup;
    // });
    // // console.log(cupons);
    // this.setState({ cupons: cupons });
  };

  fetchCupons = ({ limit, pagination }, seatchText) => {
    this.setState({ loading: true });
    let url = `${
      process.env.REACT_APP_BASE_URL
    }/diagnostic/cupon-get/?page=1&ordering=-id&limit=${limit}&offset=${
      (pagination - 1) * limit
    }`;
    if (!!seatchText) {
      url = `${
        process.env.REACT_APP_BASE_URL
      }/diagnostic/cupon-get/?page=1&ordering=-id&search=${seatchText}&limit=${limit}&offset=${
        (pagination - 1) * limit
      }`;
    }

    axios.get(url).then((resp) => {
      // console.log(resp.data);
      this.setState({ loading: false });
      this.setState({ cupons: resp.data.results });
      this.setState({ next: resp.data.next, count: resp.data.count });
    });
  };

  fetchGroup = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user_management/group/`)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ group: resp.data.results });
      });
  };

  groupIdToName = (grp_id) => {
    const array = this.state.group.filter((group) => {
      return group.id === grp_id;
    });
    const name = array[0];
    return name ? name.name : 'User';
  };

  componentDidMount() {
    this.context.setPageTitle('Coupon');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Cupons', link: '/cupon' },
    ]);
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);
    const token = localStorage.getItem('token');

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchCupons(this.state);
      this.fetchGroup();
      if (user_details) {
        const user = JSON.parse(user_details);
        this.setState({ user_id: user.id });
      }
      this.setState({ currentGroup: 'Admin' });
    } else {
      // alert('hi');
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Lab Partner' ||
        userRole.groups[0].name === 'External'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchCupons(this.state);
        this.fetchGroup();
        if (user_details) {
          const user = JSON.parse(user_details);
          this.setState({ user_id: user.id });
        }

        if (userRole.groups[0].name === 'Order Manager') {
          this.setState({ currentGroup: 'Order Manager' });
        } else if (userRole.groups[0].name === 'Supervisor') {
          this.setState({ currentGroup: 'Supervisor' });
        } else if (userRole.groups[0].name === 'Admin') {
          this.setState({ currentGroup: 'Admin' });
        }
      }
    }
  }

  deleteCupon = (id) => {
    // console.log(user.id);
    this.setState({ showModal: !this.state.showModal });
    this.setState({ deleteId: id });
  };

  handleModalDlt = (id, value) => {
    // console.log(this.state.deleteId);
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/diagnostic/cupon-single/${id}/`, {
        is_active: value,
      })
      .then((resp) => {
        // console.log(resp.data);
        this.fetchCupons({ limit: this.state.limit, pagination: 1 });
        this.setState({ showModal: false, pagination: 1 });
        if (resp.data.is_active) {
          toast.success(`Coupon has been enable successfully.`, {
            autoClose: 3000,
          });
        } else {
          toast.success(`Coupon has been disable successfully.`, {
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  loadMore = () => {
    this.setState({ offset: this.state.offset + 10 });

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/cupon-get/?limit=${this.state.limit}&offset=${this.state.offset}&ofset=0&page=1&ordering=-id`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ cupons: [...this.state.cupons, ...resp.data.results] });
        this.setState({ next: resp.data.next });
      });
  };

  handlePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    // if(this.state.searchText) this.fetchMedicines({ limit: this.state.limit, pagination: pageno }, this.state.searchText);
    // else this.fetchMedicines({ limit: this.state.limit, pagination: pageno });
    this.fetchCupons({ limit: this.state.limit, pagination: pageno });
  };

  render() {
    const {
      cupons,
      showModal,
      currentGroup,
      count,
      limit,
      pagination,
      loading,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Coupon' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label font-weight-bolder text-dark'>
                        All Cupons
                      </span>
                      <span className='text-muted mt-3 font-weight-bold font-size-sm'>
                        More than {cupons && cupons.length}+ Cupons
                      </span>
                    </h3>
                    {(currentGroup === 'Admin' ||
                      currentGroup === 'Supervisor') && (
                      <div className='card-toolbar'>
                        <Link to='/cupon-add' className='btn btn-primary'>
                          Add New Cupon
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className='card-body pt-2 pb-0 mt-3'>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle fs-6 no-footer gy-5'>
                        <thead>
                          <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                            <th className='p-3 w-50px'>Index</th>
                            <th className='p-3 min-w-150px'>Title</th>
                            <th className='p-3 min-w-140px'>Created At</th>
                            <th className='p-3 min-w-140px'>Code</th>
                            <th className='p-3 min-w-140px'>Status</th>
                            <th className='p-3 min-w-150px'>Expired At</th>
                            <th className='p-3 min-w-50px'>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cupons &&
                            cupons.map((cupon, i) => (
                              <tr key={i}>
                                <td className='align-middle fs-6 fw-bold text-center'>
                                  <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                    #{i + 1}
                                  </span>
                                </td>
                                <td className='align-middle fs-6 fw-bold text-left'>
                                  <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                    {cupon.name || ''}
                                  </span>
                                </td>
                                <td className='align-middle fs-6 fw-bold text-left'>
                                  <span className='text-muted font-weight-bolder mb-0 font-size-lg'>
                                    {cupon.created_at && (
                                      <>
                                        Careted At:{' '}
                                        {moment(cupon.created_at).format(
                                          'DD MMM YYYY'
                                        )}
                                      </>
                                    )}
                                  </span>
                                </td>
                                <td className='align-middle fs-6 fw-bold text-left'>
                                  <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                    Code: {cupon.code || ''}
                                  </span>
                                </td>
                                {currentGroup === 'Order Manager' && (
                                  <td className='align-middle fs-6 fw-bold text-left'>
                                    <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                      {cupon.is_active ? (
                                        <span className='text-green'>
                                          Active
                                        </span>
                                      ) : (
                                        <span className='text-danger'>
                                          Inactive
                                        </span>
                                      )}
                                    </span>
                                  </td>
                                )}
                                {(currentGroup === 'Admin' ||
                                  currentGroup === 'Supervisor') && (
                                  <td className='align-middle fs-6 fw-bold text-left'>
                                    {cupon.is_active ? (
                                      <Switch
                                        onChange={() =>
                                          this.handleSwitchChecked(cupon, false)
                                        }
                                        checked={cupon.is_active}
                                        onColor='#1BC5BD'
                                      />
                                    ) : (
                                      <Switch
                                        onChange={() =>
                                          this.handleSwitchChecked(cupon, true)
                                        }
                                        checked={cupon.is_active}
                                      />
                                    )}
                                  </td>
                                )}
                                <td className='align-middle fs-6 fw-bold text-left'>
                                  <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                    {cupon.expired_at && (
                                      <>
                                        Expire Date:{' '}
                                        {moment(cupon.expired_at).format(
                                          'DD MMM YYYY'
                                        )}
                                      </>
                                    )}
                                  </span>
                                </td>
                                <td className='align-middle fs-6 fw-bold text-right'>
                                  <span className='text-gray-600 font-weight-bolder mb-0 font-size-lg'>
                                    <Link
                                      to={`/cupon-details/${cupon.id}`}
                                      className='btn btn-sm btn-light-primary'
                                    >
                                      Details
                                    </Link>
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {/* <p className='text-center mb-15 mt-5'>
                <button
                  className='btn btn-primary btn-sm mb-0'
                  disabled={next ? '' : 'disabled'}
                  onClick={this.loadMore}
                >
                  SHow More
                </button>
              </p> */}
                    <div className='row justify-content-end mt-3 mb-10'>
                      {loading && (
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                          <Spinner animation='border' size='sm' />{' '}
                          <span
                            className='ms-2 text-gray-600'
                            style={{ fontFamily: 'Poppins' }}
                          >
                            loading, Please wait...
                          </span>
                        </div>
                      )}
                      <Pagination
                        onPageChange={(pageno) =>
                          this.handlePaginationPress(pageno)
                        }
                        totalCount={count}
                        pageSize={limit}
                        currentPage={pagination}
                        siblingCount={1}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                show={showModal}
                onHide={() => this.setState({ showModal: !showModal })}
                animation={false}
                centered
                className='delete_confirmation'
              >
                <div className='modal-body mx-auto'>
                  <p className='text-center icon mb-0'>
                    <img src={warningLogo} alt='' />
                  </p>
                  <div className='details'>
                    <h5>Are you sure?</h5>
                    <p>Once delete, it will be permanently deleted.</p>
                  </div>
                  <p className='buttons mb-0 text-center'>
                    <button
                      className='btn btn-primary mr-1'
                      onClick={() => this.setState({ showModal: !showModal })}
                    >
                      No
                    </button>
                    <button
                      className='btn btn-danger ml-1'
                      onClick={this.handleModalDlt}
                    >
                      Yes
                    </button>
                  </p>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Users;
Users.contextType = AuthContext;
