import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  single: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 3,
    paddingVertical: 5,
  },
  textDesign: {
    fontSize: 11,
    fontFamily: 'Serif',
  },
  textDesignGrand: {
    fontSize: 8,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'darkslateblue',
    fontFamily: 'Serif',
  },
  break: {
    flexDirection: 'row',
    padding: 0.4,
    backgroundColor: 'slategray',
    marginTop: 0,
  },
});

const getTestItemsPrice = (array) => {
  const price = array.reduce(
    (total, item) => total + parseInt(item.purchasable_order_item.sell_price),
    0
  );

  return parseInt(price);
};

export default function PriceSection({
  order,
  materialPerson,
  discountPrice,
  patient,
  i,
  discountPercentage,
}) {
  const [showDiscount, setShowDiscount] = useState(false);
  useEffect(() => {
    if (discountPercentage && discountPercentage > 0) {
      setShowDiscount(true);
    }
  }, [discountPercentage]);
  // console.log(typeof discountPercentage);
  // console.log(discountPercentage > 1);

  return (
    <View style={styles.main}>
      <View style={{ flex: 1 }}></View>
      <View style={{ flex: 1 }}>
        <Single
          title='Total Service Price'
          price={getTestItemsPrice(patient.testItem)}
        />
        {i === 0 && (
          <>
            <View style={styles.break}></View>
            <Single
              title={`Pick up charges`}
              price={parseInt(order.collection_fee)}
            />
          </>
        )}
        <View style={styles.break}></View>
        <Single
          title={`Material fee`}
          price={
            order.orderitem.length !== 0
              ? order.orderitem[0].meterial_fee
              : '00 '
          }
        />
        {showDiscount && (
          <>
            <View style={styles.break}></View>
            <Single
              title='Discount'
              price={Math.round(
                (discountPercentage / 100) * getTestItemsPrice(patient.testItem)
              )}
            />
          </>
        )}

        <View
          style={[styles.break, { backgroundColor: 'darkslateblue' }]}
        ></View>
        <View style={styles.single}>
          <Text style={styles.textDesignGrand}>Grand Total</Text>
          <Text style={styles.textDesignGrand}>
            {i === 0 &&
              (discountPercentage
                ? getTestItemsPrice(patient.testItem) +
                  parseInt(order.orderitem[0].meterial_fee) +
                  parseInt(order.collection_fee) -
                  (discountPercentage / 100) *
                    getTestItemsPrice(patient.testItem)
                : getTestItemsPrice(patient.testItem) +
                  parseInt(order.orderitem[0].meterial_fee) +
                  parseInt(order.collection_fee))}
            {i !== 0 &&
              (discountPercentage
                ? getTestItemsPrice(patient.testItem) +
                  parseInt(order.orderitem[0].meterial_fee) -
                  (discountPercentage / 100) *
                    getTestItemsPrice(patient.testItem)
                : getTestItemsPrice(patient.testItem) +
                  parseInt(order.orderitem[0].meterial_fee))}
            Tk
          </Text>
        </View>
      </View>
    </View>
  );
}

const Single = ({ title, price }) => (
  <View style={styles.single}>
    <Text style={styles.textDesign}>{title}</Text>
    <Text style={styles.textDesign}>{price} Tk</Text>
    {/* <Text>Hello</Text>
    <Text>500 tk</Text> */}
  </View>
);
