import { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import DateSelect from '../../components/DateSelect';
import DueDateSelect from '../DueDateSelect';

const SelectPopover = ({
  title,
  value,
  setValue,
  options,
  handleSave,
  handleIClick,
  textArea,
  textAreaValue,
  setTextAreaValue,
  textAreaPlaceholder,
  staticIcon,
  iconStyle,
  isButton,
  selectedName,
  selectedClassName,
  selectedClassStyle,
  placeholder,
  date,
  setDate,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (e) => {
    setShow(!show);
    setTarget(e.target);
    handleIClick();
  };

  return (
    <div ref={ref} className='select-pop'>
      {isButton ? (
        <div
          className='d-flex flex-row align-items-center pointer'
          onClick={(e) => handleClick(e)}
        >
          <div
            style={
              selectedClassStyle
                ? {
                    textTransform: 'capitalize',
                    ...selectedClassStyle,
                  }
                : {
                    textTransform: 'capitalize',
                  }
            }
            className={`text-truncate text-center fs-8 mt-2 border border-0 rounded ${selectedClassName} status-btn`}
          >
            {selectedName || placeholder || `Select one`}
          </div>
        </div>
      ) : (
        <button
          className='icn-btn'
          //   onClick={(e) => handleClick(e, mainOrder, 'order_status')}
          onClick={(e) => handleClick(e)}
        >
          <i
            style={iconStyle || {}}
            className={`fas fa-pencil-alt pointer ms-1${
              !staticIcon ? ` edit-icn` : ``
            }`}
          ></i>
        </button>
      )}

      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover id='popover-contained'>
          <Popover.Content>
            <p className='font-semibold mb-3'>{title}</p>
            <select
              value={value || ''}
              className='form-select form-select-sm'
              onChange={(e) => setValue(e.target.value)}
            >
              {options &&
                options.map((option, i) => (
                  <option value={option.id} key={i}>
                    {option.name}
                  </option>
                ))}
            </select>
            {textArea && (
              <textarea
                value={textAreaValue}
                onChange={(e) => setTextAreaValue(e.target.value)}
                className='form-control mt-3'
                placeholder={textAreaPlaceholder}
              ></textarea>
            )}
            {value === 'soft_copy_due' || value === 'hard_copy_due' ? (
              <div className='mt-3 due_date'>
                <DueDateSelect
                  placeholderText='Due Date and Time'
                  sampleDate={date}
                  setSampleDate={setDate}
                  showTimeSelect
                />
              </div>
            ) : (
              ''
            )}
            <div className='new-footer d-flex justify-content-between mt-5'>
              <button
                className='btn btn-sm btn-cancel'
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
              <button
                className='btn btn-sm btn-primary btn-save'
                onClick={() => {
                  handleSave(setShow);
                }}
              >
                Save
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default SelectPopover;
