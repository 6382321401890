import axios from 'axios';
import React, { Component } from 'react';
import DiagnosticAddModal from '../components/DiagnosticAddModal';
import PackageAddModal from '../components/PackageAddModal';
import ShowPackages from '../components/ShowPackages';
import ShowTest from '../components/ShowTest';
import { AuthContext } from '../context/AuthContext';

import Pagination from '../components/Pagination/Pagination';
import { Spinner } from 'react-bootstrap';
import HeaderToolbar from '../components/HeaderToolbar';

class Tests extends Component {
  state = {
    showItem: 'test',
    showTestModal: false,
    showPackageModal: false,
    categories: null,

    tests: null,
    testNext: null,
    testCount: 0,

    pagination: 1,
    searchText: '',
    loading: false,
    limit: 8,
    offset: 8,

    packages: null,
    packageNext: null,
    packageCount: 0,
  };

  setTests = (data) => {
    this.setState({ tests: data });
  };

  setPackages = (data) => {
    this.setState({ packages: data });
  };

  fetchIniPackages = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-package/?ordering=-id`
      )
      .then((resp) => {
        this.setState({ packages: resp.data.results });
        this.setState({
          packageNext: resp.data.next,
          packageCount: resp.data.count,
        });
      });
  };

  fetchIniTests = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/?ordering=-id`
      )
      .then((resp) => {
        this.setState({ tests: resp.data.results });
        this.setState({ testNext: resp.data.next, testCount: resp.data.count });
      });
  };

  fetchTests = ({ limit, pagination }, seatchText) => {
    this.setState({ loading: true });
    let url = `${
      process.env.REACT_APP_BASE_URL
    }/diagnostic/diagnostic-add/?ordering=-id&limit=${limit}&offset=${
      (pagination - 1) * limit
    }`;
    if (!!seatchText) {
      url = `${
        process.env.REACT_APP_BASE_URL
      }/diagnostic/diagnostic-add/?ordering=-id&search=${seatchText}&limit=${limit}&offset=${
        (pagination - 1) * limit
      }`;
    }

    axios.get(url).then((resp) => {
      this.setState({ loading: false });
      this.setState({ tests: resp.data.results });
      this.setState({ testNext: resp.data.next, testCount: resp.data.count });
    });
  };

  fetchPackages = ({ limit, pagination }, seatchText) => {
    let url = `${
      process.env.REACT_APP_BASE_URL
    }/diagnostic/diagnostic-package/?ordering=-id&limit=${limit}&offset=${
      (pagination - 1) * limit
    }`;
    if (!!seatchText) {
      url = `${
        process.env.REACT_APP_BASE_URL
      }/diagnostic/diagnostic-package/?ordering=-id&search=${seatchText}&limit=${limit}&offset=${
        (pagination - 1) * limit
      }`;
    }

    axios.get(url).then((resp) => {
      this.setState({ packages: resp.data.results });
      this.setState({
        packageNext: resp.data.next,
        packageCount: resp.data.count,
      });
      // this.setState({ next: resp.data.next });
      // console.log(resp.data.results);
    });
  };

  fetchCategories = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/category/`)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ categories: resp.data.results });
      });
  };

  setShowTestModal = (value) => {
    this.setState({ showTestModal: value });
  };

  setShowPackageModal = (value) => {
    this.setState({ showPackageModal: value });
  };

  componentDidMount() {
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Tests & Packages', link: '/tests' },
    ]);
    this.context.setPageTitle('Diagnostic Test & Package');
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    const userRole = JSON.parse(user_details);

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });
    }

    if (userRole.is_superuser) {
      this.fetchCategories();
      this.fetchTests(this.state);
      this.fetchPackages(this.state);
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name !== 'Admin' &&
        userRole.groups[0].name !== 'Supervisor'
      ) {
        this.props.history.push('/orders');
      } else {
        this.fetchCategories();
        this.fetchTests(this.state);
        this.fetchPackages(this.state);
      }
    }
  }

  handleLoadMore = () => {
    this.setState({ offset: this.state.offset + 8 });

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/?ordering=-id&limit=${this.state.limit}&offset=${this.state.offset}&ofset=0&page=1`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ tests: [...this.state.tests, ...resp.data.results] });
        this.setState({ next: resp.data.next });
      });
  };

  updateStateTest = (id, data) => {
    const testsCopy = [...this.state.tests];
    const targetIndex = this.state.tests.findIndex((test) => test.id === id);
    testsCopy[targetIndex] = data;
    this.setState({ tests: testsCopy });
  };

  updateStatePackage = (id, data) => {
    const testsCopy = [...this.state.packages];
    const targetIndex = this.state.packages.findIndex((test) => test.id === id);
    testsCopy[targetIndex] = data;
    this.setState({ packages: testsCopy });
  };

  deleteStateTest = (id) => {
    this.setState({
      tests: this.state.tests.filter((test) => test.id !== id),
    });
  };

  deleteStatePackage = (id) => {
    this.setState({
      packages: this.state.packages.filter((pac) => pac.id !== id),
    });
  };

  resetOnShowItemChange = (showItem) => {
    // if (showItem === 'test') this.fetchTests({ limit: this.state.limit, pagination: 1 }, this.state.searchText)
    // else this.fetchCategories({ limit: this.state.limit, pagination: 1 }, this.state.searchText)
    if (showItem === 'test') {
      if (!this.state.searchText)
        this.fetchTests({ limit: this.state.limit, pagination: 1 });
      else
        this.fetchTests(
          { limit: this.state.limit, pagination: 1 },
          this.state.searchText
        );
    } else {
      if (!this.state.searchText)
        this.fetchPackages({ limit: this.state.limit, pagination: 1 });
      else
        this.fetchPackages(
          { limit: this.state.limit, pagination: 1 },
          this.state.searchText
        );
    }

    this.setState({
      // tests: null,
      // testNext: null,
      // testCount: 0,

      pagination: 1,
      // searchText: '',

      // packages: null,
      // packageNext: null,
      // packageCount: 0,
    });
  };

  handleSearch = (searchText) => {
    this.setState({ searchText: searchText, pagination: 1 });
    if (this.state.showItem === 'test') {
      if (!searchText)
        this.fetchTests({ limit: this.state.limit, pagination: 1 });
      else
        this.fetchTests({ limit: this.state.limit, pagination: 1 }, searchText);
    } else {
      if (!searchText)
        this.fetchPackages({ limit: this.state.limit, pagination: 1 });
      else
        this.fetchPackages(
          { limit: this.state.limit, pagination: 1 },
          searchText
        );
    }
  };

  handleTestPaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    console.log(pageno);
    if (this.state.searchText)
      this.fetchTests(
        { limit: this.state.limit, pagination: pageno },
        this.state.searchText
      );
    else this.fetchTests({ limit: this.state.limit, pagination: pageno });
    // this.fetchTests({ limit: this.state.limit, pagination: pageno });
  };

  handlePackagePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    console.log(pageno);
    if (this.state.searchText)
      this.fetchPackages(
        { limit: this.state.limit, pagination: pageno },
        this.state.searchText
      );
    else this.fetchPackages({ limit: this.state.limit, pagination: pageno });
    // this.fetchPackages({ limit: this.state.limit, pagination: pageno });
  };

  render() {
    const {
      showItem,
      showTestModal,
      showPackageModal,
      loading,
      pagination,
      limit,
      testCount,
      packageCount,
      searchText,
    } = this.state;

    return (
      <>
        <HeaderToolbar title='Diagnostic Test & Package' />
        <div className='content d-flex flex-column flex-column-fluid'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row test-page'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b'>
                  <div className='card-header border-0 pt-5'>
                    <div className='d-flex align-items-center'>
                      <h3 className='card-title align-items-start flex-column min-w-150px'>
                        <span className='card-label font-weight-bolder text-dark'>
                          Chose Test Type
                        </span>
                      </h3>
                      <select
                        className='form-select'
                        onChange={(e) => {
                          this.setState({ showItem: e.target.value });
                          this.resetOnShowItemChange(e.target.value);
                        }}
                      >
                        <option value='test'>Test</option>
                        <option value='package'>Package</option>
                      </select>

                      <div className='d-flex align-items-center position-relative my-1 mx-3'>
                        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <rect
                              opacity='0.5'
                              x='17.0365'
                              y='15.1223'
                              width='8.15546'
                              height='2'
                              rx='1'
                              transform='rotate(45 17.0365 15.1223)'
                              fill='black'
                            ></rect>
                            <path
                              d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                              fill='black'
                            ></path>
                          </svg>
                        </span>
                        <input
                          type='text'
                          data-kt-customer-table-filter='search'
                          className='form-control form-control-solid w-250px ps-15'
                          placeholder={
                            showItem === 'test'
                              ? 'Search Test'
                              : 'Search Package'
                          }
                          // ref={this.searchRef}
                          // disabled={loading}
                          value={searchText}
                          onChange={(e) => this.handleSearch(e.target.value)}
                        />
                      </div>
                      <div className='text-muted mt-3 font-weight-bold fs-7 ps-2 min-w-200px'>
                        {searchText &&
                          (showItem === 'test'
                            ? `${testCount ? testCount + '+ ' : '00'} ${
                                testCount ? `tests` : `test`
                              } ${searchText && `found`}`
                            : `${packageCount ? packageCount + '+ ' : '00'} ${
                                packageCount ? `packages` : `package`
                              } ${searchText && `found`}`)}
                      </div>
                    </div>
                    <div className='card-toolbar'>
                      {showItem === 'test' ? (
                        <button
                          className='btn btn-primary mr-3'
                          onClick={() => this.setState({ showTestModal: true })}
                        >
                          Add Test
                        </button>
                      ) : (
                        <button
                          className='btn btn-primary mr-3'
                          onClick={() =>
                            this.setState({ showPackageModal: true })
                          }
                        >
                          Add Package
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='card-body pt-2 pb-0 mt-3'>
                    {showItem === 'test' ? (
                      <ShowTest
                        tests={this.state.tests}
                        next={this.state.next}
                        handleLoadMore={this.handleLoadMore}
                        updateStateTest={this.updateStateTest}
                        deleteStateTest={this.deleteStateTest}
                        setTests={this.setTests}
                      />
                    ) : (
                      <ShowPackages
                        packages={this.state.packages}
                        updateStatePackage={this.updateStatePackage}
                        deleteStatePackage={this.deleteStatePackage}
                        setPackages={this.setPackages}
                      />
                    )}
                    {showItem === 'test' ? (
                      <div className='row justify-content-end mt-5 mb-10'>
                        {loading && (
                          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                            <Spinner animation='border' size='sm' />{' '}
                            <span
                              className='ms-2 text-gray-600'
                              style={{ fontFamily: 'Poppins' }}
                            >
                              loading, Please wait...
                            </span>
                          </div>
                        )}
                        {this.state.tests && (
                          <Pagination
                            onPageChange={(pageno) =>
                              this.handleTestPaginationPress(pageno)
                            }
                            totalCount={testCount}
                            pageSize={limit}
                            currentPage={pagination}
                            siblingCount={1}
                          />
                        )}
                      </div>
                    ) : (
                      <div className='row justify-content-end mt-3 mb-10'>
                        {loading && (
                          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                            <Spinner animation='border' size='sm' />{' '}
                            <span
                              className='ms-2 text-gray-600'
                              style={{ fontFamily: 'Poppins' }}
                            >
                              loading, Please wait...
                            </span>
                          </div>
                        )}
                        <Pagination
                          onPageChange={(pageno) =>
                            this.handlePackagePaginationPress(pageno)
                          }
                          totalCount={packageCount}
                          pageSize={limit}
                          currentPage={pagination}
                          siblingCount={1}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DiagnosticAddModal
          showTestModal={showTestModal}
          setShowTestModal={this.setShowTestModal}
          categories={this.state.categories}
          fetchTests={this.fetchIniTests}
          fetchCategories={this.fetchCategories}
        />

        <PackageAddModal
          showPackageModal={showPackageModal}
          setShowPackageModal={this.setShowPackageModal}
          categories={this.state.categories}
          fetchPackages={this.fetchIniPackages}
          fetchCategories={this.fetchCategories}
        />
      </>
    );
  }
}

export default Tests;
Tests.contextType = AuthContext;
