import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import 'bootstrap-daterangepicker/daterangepicker.css';
import amarlabLogo from '../assets/img/logo.png';
import ToggleSvg from '../assets/img/toggle.svg';
import DemoAvatar from '../assets/img/blank-avatar.png';
import AddUser from '../pages/AddUser';
import EditUser from '../pages/EditUser';
import Homepage from '../pages/Homepage';
import MyProfile from '../pages/MyProfile';
import Branch from '../pages/BranchLab';
import Orders from '../pages/Orders';
// import OrderDetails from '../pages/OrderDetails';
import Aside from './Aside';
import Users from './Users';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

import Cupons from './Cupon';
import AddCupon from '../pages/AddCupon';
import CuponDetails from '../pages/CuponDetails';
import LabDiscount from '../pages/LabDiscount';
import LabDiscountAdd from '../pages/LabDiscountAdd';
import LabDisSngl from '../pages/LabDiscountSingle';
import Tests from '../pages/Tests';
import TestDetails from '../pages/TestDetails';
import PackageDetails from '../pages/PackageDetails';
import ProfileDropdown from './Dropdown/ProfileDropdown';
import Medicines from '../pages/Medicines';
import MedicineAdd from '../pages/MedicineAdd';
import moment from 'moment';
import NotificationCenter from './NotificationCenter';
import NotificationCenterMt from './NotificationCenterMt';
import Press from '../pages/Press';
import QuickOrder from '../pages/QuickOrder';
import OrderEdit from '../pages/OrderEdit';
import BulkUploader from '../pages/BulkUploader';
import Reference from '../pages/Reference';
import ReferenceAdd from '../pages/ReferenceAdd';
import Channels from '../pages/Channels';
import ReferenceAnalytic from '../pages/ReferenceAnalytic';
import OrderAddEdit from '../pages/OrderAddEdit';
import PackageDetailsV2 from '../pages/PackageDetailsV2';
import AppPromotion from '../pages/AppPromotion';

class MainApp extends Component {
  state = {
    asideOpen: false,
    showOrderSummery: false,
    avatarDropdown: false,

    daterange_start: moment().subtract(29, 'days'),
    daterange_end: moment(),
    ordersInRange: null,

    showNotifiableOrderStat: false,
    showNotifiableOrderStatMt: false,
  };

  fetchOrdersInRange = (start, end) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-summery/${start}/${end}`
      )
      .then((resp) => {
        // console.log('range ', resp.data);
        this.setState({ ordersInRange: resp.data });
      })
      .catch((error) => console.log(error.response));
  };

  handleDateRangeApply = (start, end) => {
    this.setState({ start, end });
    this.fetchOrdersInRange(
      start.format('YYYY-MM-DD'),
      end.format('YYYY-MM-DD')
    );
  };

  resetStartEnd = () => {
    this.setState({
      daterange_start: moment().subtract(29, 'days'),
      daterange_end: moment(),
    });
    this.fetchOrdersInRange(
      moment().subtract(29, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    );
  };

  setAvatarDropdown = (value) => {
    this.setState({ avatarDropdown: value });
  };

  setAsideClose = () => {
    this.setState({ asideOpen: false });
    this.context.fetchOrderStatus();
  };

  handleLogout = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/logout/`)
      .then((resp) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user_details');
        localStorage.removeItem('is_superuser');
        window.location.href = '/login';
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  componentDidMount() {
    const user_details = localStorage.getItem('user_details');
    this.context.fetchOrderStatusRecursive();
    if (user_details) {
      const userRole = JSON.parse(user_details);
      if (userRole.is_superuser) {
        this.setState({ showOrderSummery: true });
      } else {
        if (userRole.groups.length === 0) {
          this.setState({ showOrderSummery: false });
        } else if (
          userRole.groups[0].name === 'Medical Technologist' ||
          userRole.groups[0].name === 'User' ||
          userRole.groups[0].name === 'Lab Partner' ||
          (userRole.groups[0].name === 'External') |
            (userRole.groups[0].name === 'Accounts')
        ) {
          this.setState({ showOrderSummery: false });
        } else {
          this.setState({ showOrderSummery: true });
        }
      }
    }
  }

  changeState = (e, value) => {
    e.preventDefault();
    this.context.setShowState(value);
  };

  render() {
    const { showOrderSummery } = this.state;
    const {
      proccessing_order,
      order_report_due,
      quick_order,
      order_payment_due,

      pending_order_mt,
      ongoing_order_mt,
      go_to_lab_order_mt,
    } = this.context;
    const {
      avatarDropdown,
      showNotifiableOrderStat,
      asideOpen,
      showNotifiableOrderStatMt,
    } = this.state;

    return (
      <div className='header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed'>
        <div className='d-flex flex-column flex-root'>
          <div className='page d-flex flex-row flex-column-fluid'>
            <Aside
              asideOpen={this.state.asideOpen}
              setAsideClose={this.setAsideClose}
            />

            <div className={`wrapper d-flex flex-column flex-row-fluid`}>
              <div id='kt_header' className='header align-items-stretch'>
                <div className='container-fluid d-flex align-items-stretch justify-content-between'>
                  {/* mobile toggle */}
                  <div
                    className='d-flex align-items-center d-lg-none ms-n1 me-2'
                    title='Show aside menu'
                  >
                    <div
                      className={`btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px ${
                        asideOpen ? 'active' : ''
                      }`}
                      onClick={() => this.setState({ asideOpen: true })}
                    >
                      <span className='svg-icon svg-icon-2x mt-1'>
                        <img src={ToggleSvg} alt='' />
                      </span>
                    </div>
                  </div>
                  {/* end mobile toggle */}
                  {/* Mobile logo */}
                  <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                    <Link to='/' className='d-lg-none'>
                      <img src={amarlabLogo} alt='' className='h-30px' />
                    </Link>
                  </div>
                  {/* End Mobile logo */}
                  <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
                    {/* Left Side */}
                    <div className='d-flex align-items-stretch'>
                      <div className='header-menu align-items-stretch'>
                        <div className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'>
                          {this.context.pageTitle === 'Dashboard' ? (
                            <>
                              <div className='menu-item here show menu-lg-down-accordion me-lg-1'>
                                <Link className='menu-link active py-3' to='/'>
                                  <span className='menu-title'>Dashboards</span>
                                  <span className='menu-arrow d-lg-none'></span>
                                </Link>
                              </div>
                              <div className='menu-item menu-lg-down-accordion me-lg-1'>
                                <span className='menu-link py-3'>
                                  <span className='menu-title'>
                                    Business Intellengence
                                  </span>
                                  <span className='menu-arrow d-lg-none'></span>
                                </span>
                              </div>
                            </>
                          ) : (
                            this.context.breadcrumArr.length && (
                              <>
                                {this.context.breadcrumArr
                                  .slice(0, -1)
                                  .map((item, index) => (
                                    <div
                                      key={index}
                                      className='menu-item here show menu-lg-down-accordion me-lg-1'
                                    >
                                      <Link
                                        className='menu-link active py-3'
                                        to={item.link}
                                      >
                                        <span className='menu-title'>
                                          {item.name}
                                        </span>
                                        <span className='menu-arrow d-lg-none'></span>
                                      </Link>
                                    </div>
                                  ))}
                                {this.context.breadcrumArr
                                  .slice(-1)
                                  .map((item, index) => (
                                    <div
                                      key={index}
                                      className='menu-item menu-lg-down-accordion me-lg-1'
                                    >
                                      <span className='menu-link disabled py-3 muted'>
                                        <span className='menu-title'>
                                          {item.name}
                                        </span>
                                      </span>
                                    </div>
                                  ))}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Left Side */}
                    {/* Right side */}
                    <div className='d-flex align-items-stretch flex-shrink-0'>
                      <div className='d-flex align-items-stretch flex-shrink-0'>
                        <div className='d-flex align-items-stretch ms-1 ms-lg-3'>
                          <div className='d-flex align-items-stretch'>
                            <div className='d-flex align-items-center'>
                              <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
                                <i className='fas fa-search fs-2'></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {showOrderSummery && (
                          <div className='d-flex align-items-center ms-1 ms-lg-3'>
                            <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
                              <i
                                className='fas fa-bell fs-2 position-relative'
                                onClick={() => {
                                  if (
                                    pending_order_mt != null &&
                                    ongoing_order_mt != null &&
                                    go_to_lab_order_mt != null &&
                                    pending_order_mt +
                                      ongoing_order_mt +
                                      go_to_lab_order_mt >
                                      0
                                  )
                                    this.setState({
                                      showNotifiableOrderStatMt:
                                        !showNotifiableOrderStatMt,
                                    });
                                }}
                              >
                                {pending_order_mt != null &&
                                  ongoing_order_mt != null &&
                                  go_to_lab_order_mt != null &&
                                  pending_order_mt +
                                    ongoing_order_mt +
                                    go_to_lab_order_mt >
                                    0 && (
                                    <span
                                      className='position-absolute top-0 start-100 translate-middle  badge badge-circle badge-danger'
                                      style={{
                                        padding: '2px 5px',
                                        fontWeight: 400,
                                        height: 18,
                                        width: 18,
                                        fontSize: 11,
                                      }}
                                    >
                                      {pending_order_mt +
                                        ongoing_order_mt +
                                        go_to_lab_order_mt >
                                      99
                                        ? '99+'
                                        : pending_order_mt +
                                          ongoing_order_mt +
                                          go_to_lab_order_mt}
                                    </span>
                                  )}
                              </i>

                              <NotificationCenterMt
                                orderStatus={{
                                  pending_order_mt,
                                  ongoing_order_mt,
                                  go_to_lab_order_mt,
                                }}
                                showOrderStat={
                                  this.state.showNotifiableOrderStatMt
                                }
                                hideNotification={() =>
                                  this.setState({
                                    showNotifiableOrderStatMt: false,
                                  })
                                }
                              />
                            </div>
                          </div>
                        )} */}
                        {showOrderSummery && (
                          <div className='d-flex align-items-center ms-1 ms-lg-3'>
                            <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
                              <i
                                className='fas fa-bell fs-2 position-relative'
                                onClick={() => {
                                  if (
                                    proccessing_order != null &&
                                    order_payment_due != null &&
                                    order_report_due != null &&
                                    quick_order != null &&
                                    proccessing_order +
                                      order_report_due +
                                      order_payment_due +
                                      quick_order >
                                      0
                                  )
                                    this.setState({
                                      showNotifiableOrderStat:
                                        !showNotifiableOrderStat,
                                    });
                                }}
                              >
                                {proccessing_order != null &&
                                  order_report_due != null &&
                                  order_payment_due != null &&
                                  quick_order != null &&
                                  proccessing_order +
                                    order_report_due +
                                    order_payment_due +
                                    quick_order >
                                    0 && (
                                    <span
                                      className='position-absolute top-0 start-100 translate-middle  badge badge-circle badge-danger'
                                      style={{
                                        padding: '2px 5px',
                                        fontWeight: 400,
                                        height: 18,
                                        width: 18,
                                        fontSize: 11,
                                      }}
                                    >
                                      {proccessing_order + order_payment_due >
                                      99
                                        ? '99+'
                                        : proccessing_order +
                                          quick_order +
                                          order_payment_due +
                                          order_report_due}
                                    </span>
                                  )}
                              </i>

                              <NotificationCenter
                                orderStatus={{
                                  proccessing_order,
                                  quick_order,
                                  order_report_due,
                                  order_payment_due,
                                }}
                                showOrderStat={
                                  this.state.showNotifiableOrderStat
                                }
                                hideNotification={() =>
                                  this.setState({
                                    showNotifiableOrderStat: false,
                                  })
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className='d-flex align-items-center ms-1 ms-lg-3'>
                          <div
                            className={`cursor-pointer symbol symbol-30px symbol-md-40px ${
                              avatarDropdown ? 'show menu-dropdown' : ''
                            }`}
                          >
                            <img
                              src={DemoAvatar}
                              alt=''
                              onClick={() => {
                                this.setState({
                                  avatarDropdown: !this.state.avatarDropdown,
                                });
                              }}
                            />
                          </div>
                          <ProfileDropdown
                            avatarDropdown={avatarDropdown}
                            setAvatarDropdown={this.setAvatarDropdown}
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Right side */}
                  </div>
                </div>
              </div>

              <Route exact path='/' component={Homepage} />
              <Route path='/orders' component={Orders} />
              <Route path='/quick-orders' component={QuickOrder} />
              <Route path='/users' component={Users} />
              <Route exact path='/branch' component={Branch} />
              <Route path='/user-add' component={AddUser} />
              <Route path='/cupon-details/:id' component={CuponDetails} />
              <Route path='/cupon' component={Cupons} />
              <Route path='/cupon-add' component={AddCupon} />
              <Route exact path='/lab-discount' component={LabDiscount} />
              <Route path='/lab-discount/:id' component={LabDisSngl} />
              <Route path='/lab-discount-add' component={LabDiscountAdd} />
              <Route path='/my-profile' component={MyProfile} />
              <Route path='/user/:id' component={EditUser} />
              <Route path='/tests' exact component={Tests} />
              <Route path='/app-promotion' exact component={AppPromotion} />
              <Route
                path='/tests/diagnostic-test/:id'
                component={TestDetails}
              />
              <Route
                path='/tests/diagnostic-package/:id'
                component={PackageDetails}
              />
              <Route path='/medicines' exact component={Medicines} />
              <Route path='/medicine-add' component={MedicineAdd} />
              <Route path='/order-add' component={OrderAddEdit} />
              <Route path='/order-edit/:id' component={OrderEdit} />
              <Route path='/press' component={Press} />
              <Route path='/bulk-uploader' component={BulkUploader} />
              <Route path='/reference' component={Reference} />
              <Route path='/reference-add' component={ReferenceAdd} />
              <Route path='/reference-edit/:id' component={ReferenceAdd} />
              <Route path='/reference-analytic' component={ReferenceAnalytic} />
              <Route path='/channels' component={Channels} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainApp;

MainApp.contextType = AuthContext;


