import { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

const CsMtPopover = ({
  title,
  value,
  setValue,
  options,
  handleSave,
  handleIClick,
  staticIcon,
  iconStyle,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (e) => {
    setShow(!show);
    setTarget(e.target);
    handleIClick();
  };

  return (
    <div ref={ref} className='select-pop'>
      <button
        className='icn-btn'
        //   onClick={(e) => handleClick(e, mainOrder, 'order_status')}
        onClick={(e) => handleClick(e)}
      >
        <i style={iconStyle || {}} className={`fas fa-pencil-alt pointer ms-1${staticIcon ? ` edit-icn`: ``}`}></i>
      </button>

      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover id='popover-contained'>
          <Popover.Content>
            <p className='font-semibold mb-3'>{title}</p>
            <select
              value={value || ''}
              className='form-select form-select-sm'
              onChange={(e) => setValue(e.target.value)}
            >
              <option value=''>Select</option>
              {options &&
                options.map((option, i) => (
                  <option value={option.id} key={i}>
                    {option.first_name
                      ? `${option.first_name} ${option.last_name}`
                      : option.username}
                  </option>
                ))}
            </select>
            <div className='new-footer d-flex justify-content-between mt-5'>
              <button className='btn btn-sm btn-cancel' onClick={() => setShow(false)}>Cancel</button>
              <button className='btn btn-sm btn-primary btn-save'
                onClick={() => {
                  handleSave(setShow);
                }}
              >
                Save
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default CsMtPopover;
