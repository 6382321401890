import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import AmarlabLogo from '../../assets/img/logo.png';
import InvoiceInfo from './InvoiceInfo';
import moment from 'moment';
import TestsTable from './TestsTable';
import PriceSection from './PriceSection';
import NewFont from './../../assets/SourceSerif4-Regular.otf';

Font.register({
  family: 'Serif',
  src: NewFont,
});

const styles = StyleSheet.create({
  page: {
    paddingVertical: 25,
    paddingHorizontal: 20,
  },
  logo: {
    height: 48,
    width: 44,
  },
  infoText: {
    fontSize: 12,
    color: '#666',
  },
  orderDetailsInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  orderDetailsInfoSingle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoLeft: {
    marginRight: 10,
  },
  infoRight: {
    marginLeft: 10,
  },
  break: {
    flexDirection: 'row',
    padding: 0.4,
    backgroundColor: 'slategray',
    // marginTop: 0,
  },
});

const InvoicePdf = ({
  order,
  materialPerson,
  discountPrice,
  patientOrders,
  discountPercentage,
}) => (
  <Document>
    {patientOrders.map((patient, index) => (
      <Page style={styles.page} key={index} size='A4'>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Image style={styles.logo} src={AmarlabLogo} />
          </View>
          <View>
            <Text
              style={{
                fontSize: 30,
                color: '#323232',
                fontFamily: 'Serif',
              }}
            >
              Amarlab
            </Text>
            <Text
              style={{
                position: 'absolute',
                bottom: -10,
                fontSize: 9,
                color: '#323232',
                fontFamily: 'Serif',
                left: 7,
              }}
            >
              Invoice Date: {moment().format('DD MMM YYYY')}
            </Text>
          </View>
        </View>
        <View style={[styles.break, { marginVertical: 20 }]}></View>

        <InvoiceInfo order={order} patient={patient} />

        <View style={styles.break}></View>
     
        <View
          style={{
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: 10,
            marginBottom: 3,
          }}
        >
          <Text
            style={{ fontSize: 10, color: 'slategray', fontFamily: 'Serif' }}
          >
            Sample Collection Date : {moment(order.date).format('DD MMM YYYY')}
          </Text>
        </View>


        <View
          style={{
            justifyContent: 'flex-end',
            flexDirection: 'row',
            // marginTop: 20,
            marginBottom: 10,
          }}
        >
          {order.time && (
              <Text style={{ fontSize: 10, color: 'slategray', fontFamily: 'Serif' }}>
                <span className='fw-bold'>
                Time:{' '}
                </span>
                {moment(order.time, [
                  'HH:mm:ss',
                ]).format('hh:mm A')}{' '}
                -{' '}
                {moment(order.time, 'HH:mm:ss')
                  .add(120, 'minutes')
                  .format('hh:mm A')}
              </Text>
            )}
        </View>

        <TestsTable order={order} patient={patient} />

        <View style={{ padding: 3 }}></View>

       <PriceSection
          order={order}
          discountPrice={discountPrice}
          materialPerson={materialPerson}
          patient={patient}
          i={index}
          discountPercentage={discountPercentage}
        /> 

        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              padding: 0.4,
              backgroundColor: 'slategray',
              width: '100%',
            }}
          ></Text>
          <Text
            style={{
              fontSize: 10,
              fontFamily: 'Serif',
              color: 'slategray',
              marginTop: 10,
            }}
          >
            This is a system generated statement. No signature is required.
          </Text>
        </View>

      </Page>
    ))}
  </Document>
);

export default InvoicePdf;
