import axios from 'axios';
import React, { Component } from 'react'
import { Modal, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import MedicineDetailsForm from './MedicineDetailsForm';

export class MedicineEditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      medicine: {
        name: !this.props.medicine ? '' : this.props.medicine.name,
        strength: !this.props.medicine ? '' : this.props.medicine.strength,
        form: !this.props.medicine ? '' : this.props.medicine.form,
        unit: !this.props.medicine ? '' : this.props.medicine.unit,
        rx_req: !this.props.medicine ? '' : this.props.medicine.rx_req,
        rob: !this.props.medicine ? '' : this.props.medicine.rob,
        comment: !this.props.medicine ? '' : this.props.medicine.comment,
        price: !this.props.medicine ? 0 : this.props.medicine.price,
        discount_price: !this.props.medicine ? 0 : this.props.medicine.discount_price,
        generic: !this.props.medicine ? '' : this.props.medicine.generic,
        company: !this.props.medicine ? '' : this.props.medicine.company,
        image: !this.props.medicine ? null : this.props.medicine.image,
        category: !this.props.medicine ? 0 : this.props.medicine.category.id,
      },
      uploadMedicineImage: null,
    }
  }

  componentDidMount(){
    console.log(this.state);
  }

  handleEditMedicine = () => {

    const { name, strength, form, unit, rx_req, rob, comment, price, discount_price, generic, company, image, category } = this.state.medicine;

    if (!name) toast.error('Fill medicine name.', { autoClose: 3000 });

    else if (!form) toast.error('Fill medicine form.', { autoClose: 3000 });

    else if (!price) toast.error('Fill medicine price.', { autoClose: 3000 });

    else if (!discount_price) toast.error('Fill medicine discount price.', { autoClose: 3000 });

    else if (!generic) toast.error('Fill medicine generic.', { autoClose: 3000 });

    else if (!company) toast.error('Fill medicine company.', { autoClose: 3000 });

    else if (!category) toast.error('Fill medicine category.', { autoClose: 3000 });

    else if (this.state.uploadMedicineImage && this.state.uploadMedicineImage.size > 1024000) toast.error('Image can not be more than 1MB.', { autoClose: 3000 });

    else {
      let putData = {
        name: name,
        strength: null,
        form: form,
        unit: null,
        rx_req: null,
        rob: null,
        comment: null,
        price: price,
        discount_price: discount_price,
        generic: generic,
        company: company,
        category: parseInt(category),
      }

      if (strength) putData.strength = strength;
      if (unit) putData.unit = unit;
      if (rx_req) putData.rx_req = rx_req;
      if (rob) putData.rob = rob;
      if (comment) putData.comment = comment;

      // console.log(putData);
      const medId = this.props.medicine.id;

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/medicine/store/${medId}/`,
          putData
        )
        .then((resp) => {
          // console.log('medicine response: ', resp);
          toast.success('Medicine Added Successfully.', { autoClose: 3000 });
          this.resetMedicineData();

        })
        .catch((error) => {
          console.log(error);
          toast.error('Unable to add medicine, try again.', { autoClose: 3000 });
        });


      if (!!this.state.uploadMedicineImage) {
        let medImage = new FormData();
        medImage.append('image', this.state.uploadMedicineImage);
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/medicine/store/${medId}/`,
            medImage
          ).then(response => {
            // console.log('Medicine image response: ', response);
            toast.success('Image added to recently created medicine.', { autoClose: 3000 });
          }).catch(error => {
            console.log(error);
            toast.error('Unable to add image to recently created mdicine.', { autoClose: 3000 });
          });
      }

    }
  }




  render() {
    const { ShowEditModal, setShowEditModal } = this.props;
    const { medicine, uploadMedicineImage } = this.state;

    return (
      <>
        <Modal
          show={ShowEditModal}
          onHide={() => {setShowEditModal(0)}}
          animation={true}
          size='xl'
        >
          <ModalBody>
            <div className='pl-4 mb-3 d-flex justify-content-between align-items-center'>
              <h5 className='mb-0'>Download Order</h5>
              <button
              type='button'
                className='btn btn-sm btn-danger'
                onClick={(e) => {e.preventDefault(); setShowEditModal(0)}}
              >
                Close
              </button>
            </div>{' '}
            <hr />
            <div className=''>
              <div className='card py-8 px-8 py-lg-15 px-lg-10'>
                <div className='row justify-content-center'>
                  <div className='col-xl-9'>
                    <h5 className='text-dark font-weight-bold mb-10'>
                      Edit Medicine Details:
                    </h5>
                    <MedicineDetailsForm
                      medicine={medicine}
                      uploadMedicineImage={uploadMedicineImage}
                      setState={stateObj => this.setState(stateObj)}
                      onSubmitOp={() => this.handleEditMedicine()}
                      submitBtnText='Edit Medicine'
                    />
                  </div>
                </div>
              </div>
            </div>
            
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export default MedicineEditModal;
