import moment from 'moment';
import { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import AngleDown from '../../assets/img/angle-down.png';

const RemarksPopover = ({
  title,
  orderNotes,
  value,
  setValue,
  handleAddNew,
  handleIClick,
  staticIcon,
  iconStyle,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (e) => {
    setShow(!show);
    setTarget(e.target);
    handleIClick();
  };

  return (
    <div ref={ref} className='select-pop'>
      {orderNotes.length > 0 && (
        <div
          className='d-flex flex-row align-items-center pointer'
          onClick={(e) => handleClick(e)}
        >
          <div className='text-dark text-center'>Show All</div>
          <div style={{ marginLeft: '0px', marginBottom: '1px' }}>
            <img
              className='pointer'
              src={AngleDown}
              alt=''
              style={{
                marginLeft: 3,
                height: '8px',
                opacity: 0.8,
                transform: 'rotate(-90deg)',
              }}
            />
          </div>
        </div>
      )}

      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover id='popover-contained'>
          <Popover.Content>
            <p className='font-semibold mb-3'>{title}</p>
            <div className='fs-8 text-dim-dark'>
              {orderNotes.map((orderNote, i) => (
                <div className='mb-2' key={i}>
                  <div className='fw-bold'>{orderNote.note}</div>
                  <div className='fs-9'>
                    {moment(orderNote.created_at).format(
                      'DD MMM YYYY; hh:mm A'
                    )}
                  </div>
                  <div className='fs-9'>
                    {orderNote.marked ? 'Colored: True' : 'Colored: False'}
                  </div>
                </div>
              ))}
            </div>
            <div className='new-footer d-flex justify-content-between mt-10'>
              <button
                className='btn btn-sm btn-cancel'
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
              <button
                className='btn btn-sm btn-primary btn-save'
                onClick={() => {
                  setShow(false);
                  handleAddNew();
                }}
              >
                Add New
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default RemarksPopover;
