import axios from 'axios';
import React, { Component } from 'react';
import { Modal, ModalBody, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TestCategoryAddModal from './TestCategory/TestCategoryAddModal';
import { AuthContext } from '../context/AuthContext';
import SelectCrossSell from './SelectCrossSell';
import BtnLoading from './BtnLoading';

class ShowTest extends Component {
  state = {
    showEditModal: false,
    name: '',
    IsActive: true,
    IsCovid: false,
    description: '',
    whatIsTest: '',
    testPreparation: '',
    testRemarks: '',
    testOverview: '',
    understandingTest: '',
    testMeasure: '',
    whenGetTested: '',
    category: [],
    categories: null,
    testId: null,
    existingCategories: null,
    image: null,
    newImage: null,
    crossSellDetails: null,
    existingCrossSellDetails: null,
    crossSellDetailsLoading: false,

    count: 0,
    pagination: 1,
    searchText: '',
    loading: false,

    isSupervisor: false,

    showTestCategoryModal: false,
  };

  setCrossSellDetails = (value) => {
    this.setState({ crossSellDetails: value });
  };

  fetchCategories = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/category/`)
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ categories: resp.data.results });
      });
  };

  componentDidMount() {
    const user_details = localStorage.getItem('user_details');
    if (user_details) {
      const userRole = JSON.parse(user_details);
      if (userRole.groups.length !== 0) {
        if (userRole.groups[0].name === 'Supervisor')
          this.setState({ isSupervisor: true });
      }
    }

    this.fetchCategories();
  }

  handleDelete = (test) => {
    // console.log(test.id);

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/${test.id}/`
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`Test deleted successfully.`, {
          autoClose: 3000,
        });
        this.props.deleteStateTest(test.id);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  fetchTestCategories = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/category-list-get/?diagnostic_test=${id}&limit=100`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        const checkCategoryNull = resp.data.results.filter((c) => c.category);
        this.setState({ existingCategories: checkCategoryNull });
      });
  };

  postCat = (id) => {
    this.state.category.forEach((category) => {
      const postData = {
        diagnostic_test: id,
        category: parseInt(category.category),
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/category-list/`,
          postData
        )
        .then((resp) => {
          // console.log(resp.data);
          this.setState({ category: [] });
        })
        .catch((error) => {
          // console.log(error.response);
        });
    });
  };

  putTestImg = (id, data) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ newImage: null });

        const testsCopy = [...this.props.tests];
        const targetIndex = testsCopy.findIndex((f) => f.id === resp.data.id);
        testsCopy[targetIndex] = resp.data;
        this.props.setTests(testsCopy);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  fetchTestById = (tid) => {
    this.setState({ crossSellDetailsLoading: true });

    const url = `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-test/${tid}/`;

    axios.get(url).then((resp) => {
      if (resp.data) this.handleItemEdit(resp.data);
      setTimeout(() => {
        this.setState({ crossSellDetailsLoading: false });
      }, 150);
    });
  };

  handleItemEdit = (test) => {
    console.log(test);
    // console.log(test.category !== null ? test.category.id : null);
    this.setState({ testId: test.id });
    this.setState({ name: test.name });
    this.setState({
      description: test.description,
      whatIsTest: test.what_is_the_test,
      testPreparation: test.test_preparation,
      testRemarks: test.remarks,
      testOverview: test.test_overview,
      understandingTest: test.understanding_test_result,
      testMeasure: test.test_measure,
      whenGetTested: test.when_to_get_tested,
    });
    this.setState({ image: test.image });
    this.setState({ IsActive: test.is_active });
    this.setState({ IsCovid: test.is_covid });
    this.setState({ showEditModal: true });

    if (test.cross_sell) {
      this.setState({
        crossSellDetails: test.cross_sell,
        existingCrossSellDetails: test.cross_sell,
      });
    }

    if (test.cross_sell !== null && typeof test.cross_sell !== 'object')
      this.fetchTestById(test.id);

    this.fetchTestCategories(test.id);
  };

  handleEdit = (e) => {
    e.preventDefault();

    if (this.state.name.trim() === '') {
      toast.error(`Please enter test name.`, {
        autoClose: 3000,
      });
      return;
    }

    this.setState({ loading: true });

    const putData = {
      name: this.state.name,
      description: this.state.description,
      is_active: this.state.IsActive,
      is_covid: this.state.IsCovid,
      what_is_the_test: this.state.whatIsTest,
      test_preparation: this.state.testPreparation,
      remarks: this.state.testRemarks,
      test_overview: this.state.testOverview,
      understanding_test_result: this.state.understandingTest,
      test_measure: this.state.testMeasure,
      when_to_get_tested: this.state.whenGetTested,
      updated_by: this.context.user_details.id,
      cross_sell: this.state.crossSellDetails
        ? this.state.crossSellDetails.id
        : '',
    };

    const imgg = new FormData();
    imgg.append('image', this.state.newImage);

    // console.log(putData);

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/${this.state.testId}/`,
        putData
      )
      .then((resp) => {
        // console.log(resp.data);
        if (this.state.newImage) {
          this.putTestImg(resp.data.id, imgg);
        }
        this.postCat(resp.data.id);
        this.props.updateStateTest(resp.data.id, resp.data);
        this.setState({ testId: null });
        this.setState({ name: '' });
        this.setState({ description: '' });
        this.setState({ remarks: '' });
        this.setState({ IsActive: true });
        this.setState({ IsCovid: false });
        this.setState({ showEditModal: false });
        toast.success(`Test updated successfully.`, {
          autoClose: 3000,
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        // console.log(error.response);
        this.setState({ loading: false });
      });
  };

  handleCategoryDelete = (category) => {
    // const id = category.id;
    // console.log(id);

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/diagnostic/category-list-get/${category.id}/`
      )
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`Category removed successfully.`, {
          autoClose: 3000,
        });
        this.fetchTestCategories(this.state.testId);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleCategory = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    const newVal = value.map((val) => {
      return {
        category: val,
      };
    });
    this.setState({ category: newVal });
  };

  render() {
    const {
      showEditModal,
      name,
      description,
      categories,
      existingCategories,
      IsActive,
      IsCovid,
      isSupervisor,
      showTestCategoryModal,
      existingCrossSellDetails,
      crossSellDetailsLoading,
    } = this.state;

    const { tests, next, handleLoadMore, count, limit, pagination } =
      this.props;

    return (
      <>
        <div>
          <table className='table table-row-dashed table-row-gray-300 align-middle fs-6 no-footer gy-5'>
            <thead>
              <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                <th className='min-w-40px sorting'>Index</th>
                <th className='min-w-100px sorting'>Image</th>
                <th className='min-w-200px sorting'>Name</th>
                <th className='min-w-100px sorting'>Is Active</th>
                <th className='min-w-250px sorting'>Actions</th>
              </tr>
            </thead>
            <tbody className='fw-bold text-gray-600'>
              {tests &&
                tests.map((test, i) => (
                  <tr key={i}>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      #{i + 1}
                    </td>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      {test.image ? (
                        <img
                          src={test.image}
                          alt=''
                          width='50px'
                          height='30px'
                          className='shadow'
                        />
                      ) : (
                        <div
                          className='d-flex justify-content-center align-items-center border border-2 rounded'
                          style={{ width: '50px', height: '30px' }}
                        >
                          <i
                            className='fas fa-file-medical-alt'
                            style={{ fontSize: '12px', borderRadius: 5 }}
                          />
                        </div>
                      )}
                    </td>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      {test.name}
                    </td>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      {test.is_active ? (
                        <span className='text-green'>Activated</span>
                      ) : (
                        <span className='text-danger'>Deactivated</span>
                      )}
                    </td>
                    <td className='text-gray-600 text-hover-primary mb-0'>
                      <button
                        className='btn btn-primary mr-2 btn-sm me-2'
                        onClick={() => this.handleItemEdit(test)}
                      >
                        Edit test
                      </button>
                      <Link
                        to={`/tests/diagnostic-test/${test.id}`}
                        className='btn btn-info mr-2 btn-sm me-2'
                      >
                        Edit test item
                      </Link>
                      {/* {!isSupervisor && (
                        <button
                          className='btn btn-danger btn-sm me-1'
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure to delete this test?'
                              )
                            )
                              this.handleDelete(test);
                          }}
                          disabled
                        >
                          Delete
                        </button>
                      )} */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* <button
            className='btn btn-primary mt-10 mb-10 d-block mx-auto'
            disabled={!next ? 'disabled' : ''}
            onClick={handleLoadMore}
          >
            See More
          </button> */}
        </div>

        <Modal
          show={showEditModal}
          onHide={() => this.setState({ showEditModal: false })}
          animation={true}
          size='lg'
          scrollable={true}
        >
          <ModalBody>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
              <h5 className='mb-0'>Edit Test</h5>
              <button
                className='btn btn-sm btn-danger'
                onClick={() => this.setState({ showEditModal: false })}
              >
                Close
              </button>
            </div>
            <form onSubmit={this.handleEdit}>
              <div className='form-group mb-3'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter test name'
                  value={name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className='form-group mb-3'>
                {existingCategories && existingCategories.length !== 0 && (
                  <p className='mb-2 font-weight-bold'>Existing Categories</p>
                )}
                {existingCategories &&
                  existingCategories.map((category, i) => (
                    <p key={i} className='mb-0 d-flex align-items-center'>
                      #{i + 1}. {category.category.name}{' '}
                      <i
                        onClick={() => {
                          if (
                            window.confirm('Are you sure to delete this item?')
                          )
                            this.handleCategoryDelete(category);
                        }}
                        className='fas fa-times text-danger pointer ml-2'
                      ></i>
                    </p>
                  ))}
              </div>
              <div className='form-group mb-3'>
                <select
                  className='form-control custom_scrollbar'
                  onChange={this.handleCategory}
                  multiple
                >
                  <option value=''>Select Category</option>
                  {categories &&
                    categories.map((category, i) => (
                      <option value={category.id} key={i}>
                        {category.name}{' '}
                      </option>
                    ))}
                </select>
              </div>
              <div className='mb-3 d-flex justify-content-end'>
                <button
                  className='btn btn-primary fs-4 px-6 py-2 h-100 justify-content-center'
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ showTestCategoryModal: true });
                  }}
                >
                  Add Category
                </button>
              </div>
              {this.state.image && (
                <div className='form-group mb-3'>
                  <img src={this.state.image} alt='' width='100' />
                </div>
              )}
              <div className='form-group mb-3'>
                <div className='custom-file'>
                  <input
                    type='file'
                    className='custom-file-input'
                    accept='image/png, image/gif, image/jpeg'
                    onChange={(e) =>
                      this.setState({ newImage: e.target.files[0] })
                    }
                  />
                  <label className='custom-file-label'>Change Test Image</label>
                </div>
              </div>
              <div className='form-group mb-3 d-flex align-items-center'>
                <h5 className='mb-0 me-2'>Is Active</h5>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-50px'
                    type='checkbox'
                    name='is_active'
                    onChange={() => this.setState({ IsActive: !IsActive })}
                    defaultChecked={IsActive}
                  />
                </div>
              </div>
              <div className='form-group mb-3 d-flex align-items-center'>
                <h5 className='mb-0 me-2'>Is Covid</h5>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-50px'
                    type='checkbox'
                    name='is_active'
                    onChange={() => this.setState({ IsCovid: !IsCovid })}
                    defaultChecked={IsCovid}
                  />
                </div>
              </div>
              <div className='form-group mb-3 d-flex'>
                <div className='form-control fw-normal px-1 py-1'>
                  <SelectCrossSell
                    setCrossSellDetails={this.setCrossSellDetails}
                    editId={null}
                  />
                </div>
                {existingCrossSellDetails && (
                  <div
                    className='ms-3 min-w-150px px-5 border rounded border-1 border-secondary d-flex justify-content-between align-items-center'
                    style={{ maxWidth: '200px', fontWeight: 500 }}
                  >
                    {!crossSellDetailsLoading ? (
                      <>
                        <div className='text-truncate text-muted'>
                          {typeof existingCrossSellDetails === 'object'
                            ? existingCrossSellDetails.name
                            : `ID: ${existingCrossSellDetails}`}
                        </div>
                        {/* <div className='ms-3'>
                        <i className='fas fa-times text-muted pointer'
                          onClick={() => { this.setState({ existingCrossSellDetails: null }) }}
                        ></i>
                      </div> */}
                      </>
                    ) : (
                      <div className='text-muted'>
                        <Spinner animation='border' size='sm' />{' '}
                        <span className='ms-2'>loading...</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control'
                  placeholder='Enter test description'
                  rows='3'
                  value={description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control'
                  placeholder='Enter test overview'
                  rows='3'
                  value={this.state.testOverview}
                  onChange={(e) =>
                    this.setState({ testOverview: e.target.value })
                  }
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control'
                  placeholder='What is the test'
                  rows='3'
                  value={this.state.whatIsTest}
                  onChange={(e) =>
                    this.setState({ whatIsTest: e.target.value })
                  }
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='Enter test measure'
                  rows='3'
                  value={this.state.testMeasure}
                  onChange={(e) =>
                    this.setState({ testMeasure: e.target.value })
                  }
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                <textarea
                  className='form-control fw-normal'
                  placeholder='When to get tested?'
                  rows='3'
                  value={this.state.whenGetTested}
                  onChange={(e) =>
                    this.setState({ whenGetTested: e.target.value })
                  }
                ></textarea>
              </div>

              <div className='form-group mb-3'>
                <textarea
                  className='form-control'
                  placeholder='Enter test remarks'
                  rows='3'
                  value={this.state.testRemarks}
                  onChange={(e) =>
                    this.setState({ testRemarks: e.target.value })
                  }
                ></textarea>
              </div>

              <div className='form-group mb-3'>
                <textarea
                  className='form-control'
                  placeholder='Enter test preparation'
                  rows='3'
                  value={this.state.testPreparation}
                  onChange={(e) =>
                    this.setState({ testPreparation: e.target.value })
                  }
                ></textarea>
              </div>

              <div className='form-group mb-3'>
                <textarea
                  className='form-control'
                  placeholder='Understanding test result'
                  rows='3'
                  value={this.state.understandingTest}
                  onChange={(e) =>
                    this.setState({ understandingTest: e.target.value })
                  }
                ></textarea>
              </div>
              <div className='form-group mb-3'>
                {!this.state.loading ? (
                  <button className='btn btn-primary'>Update</button>
                ) : (
                  <BtnLoading name='Updating...' btnClass='primary ' />
                )}
              </div>
            </form>
          </ModalBody>
        </Modal>

        <TestCategoryAddModal
          showTestCategoryModal={showTestCategoryModal}
          setShowTestCategoryModal={(stateObj) =>
            this.setState({ showTestCategoryModal: stateObj })
          }
          testCategories={categories}
          fetchTestCategories={this.fetchCategories}
        />
      </>
    );
  }
}

export default ShowTest;
ShowTest.contextType = AuthContext;
