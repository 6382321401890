import axios from 'axios';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import DemoAvatar from '../../assets/img/blank-avatar.png';

const ProfileDropdown = ({ avatarDropdown, setAvatarDropdown }) => {
  const { user_details } = useContext(AuthContext);

  const handleLogout = (e) => {
    e.preventDefault();

    localStorage.clear();
    window.location.href = '/login';

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/logout/`)
      .then((resp) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user_details');
        localStorage.removeItem('is_superuser');
        localStorage.removeItem('userPatients');
        window.location.href = '/login';
      })
      .catch((error) => {
        console.log(error.response);
        localStorage.clear();
        // window.location.reload();
      });
  };

  const getUserRole = (array) => {
    if (array.length === 0) {
      return 'User';
    } else {
      if (array[0].name === 'Lab Partner') {
        return 'Partner';
      } else {
        return array[0].name;
      }
    }
  };

  return (
    <div
      className={`avatar menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px ${
        avatarDropdown ? 'show' : ''
      }`}
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={DemoAvatar} />
          </div>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex flex-column fs-5'>
              {user_details
                ? user_details.first_name
                  ? user_details.first_name + ' ' + user_details.last_name
                  : user_details.username
                : ''}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1'>
                {user_details && user_details.is_superuser ? (
                  'Admin'
                ) : (
                  <>{user_details && getUserRole(user_details.groups)}</>
                )}
              </span>
            </div>
            {user_details && user_details.username.startsWith('01') && (
              <p className='fw-bold text-muted fs-7 mb-0 mt-1'>
                {user_details.username}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link
          to='/my-profile'
          className='menu-link px-5'
          onClick={() => setAvatarDropdown(false)}
        >
          My Profile
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a href='?#' className='menu-link px-5'>
          Account Settings
        </a>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <a href='?#' onClick={handleLogout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  );
};

export default ProfileDropdown;
