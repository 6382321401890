import { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

const SelAreaInpPop = ({
  title,
  value,
  setValue,
  options,
  thanaOptions,
  handleSave,
  handleIClick,
  textArea,
  setTextArea,
  input,
  setInput,
  thana,
  setThana,
  handleOrderDisSource,
  handleOuterCancel,
  staticIcon,
  iconStyle,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (e) => {
    setShow(!show);
    setTarget(e.target);
    handleIClick();
  };

  return (
    <div ref={ref} className='select-pop'>
      <button
        className='icn-btn'
        //   onClick={(e) => handleClick(e, mainOrder, 'order_status')}
        onClick={(e) => handleClick(e)}
      >
        <i
          className={`fas fa-pencil-alt pointer ms-1${staticIcon ? ` edit-icn` : ``}`}
          style={iconStyle ? iconStyle : {}}
        ></i>
      </button>

      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover id='popover-contained'>
          <Popover.Content>
            <p className='fs-7 font-semibold mb-3'>{title}</p>
            <select
              value={value || ''}
              className='form-select form-select-sm fw-normal fs-7'
              onChange={
                setValue
                  ? (e) => setValue(e.target.value)
                  : handleOrderDisSource
              }
            >
              {options &&
                options.map((option, i) => (
                  <option disabled={!option.id.trim()} value={option.id} key={i}>
                    {option.name}
                  </option>
                ))}
            </select>
            {/* <input
              type='text'
              className='fs-7 form-control mt-3 form-select-sm'
              value={input}
              onChange={(e) => setInput(e.target.value)}
            /> */}
            <select
              value={thana || ''}
              className='form-select form-select-sm fw-normal fs-7 mt-3'
              onChange={
                setThana
                  ? (e) => setThana(e.target.value)
                  : () => console.log('Provide setThana function.')
              }
            >
              <option disabled value='' > </option>
              {thanaOptions &&
                thanaOptions.map((option, i) => (
                  <option disabled={!option} value={option} key={i}>
                    {option}
                  </option>
                ))}
            </select>
            <textarea
              value={textArea}
              onChange={(e) => setTextArea(e.target.value)}
              className='fs-7 form-control mt-3'
            //   placeholder='Enter payment note'
            ></textarea>
            <div className='new-footer d-flex justify-content-between mt-5'>
              <button className='btn btn-sm btn-cancel' onClick={() => {
                setShow(false);
                handleOuterCancel && handleOuterCancel();
              }}>Cancel</button>
              <button className='btn btn-sm btn-primary btn-save'
                onClick={() => {
                  handleSave(setShow);
                }}
              >
                Save
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export default SelAreaInpPop;
