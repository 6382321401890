import * as firebase from 'firebase/app';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyC0bcQW8Kx0e9WG4ZvA1XTONsRveX0uV70',
  authDomain: 'com-amarlab-amarlab.firebaseapp.com',
  projectId: 'com-amarlab-amarlab',
  storageBucket: 'com-amarlab-amarlab.appspot.com',
  messagingSenderId: '418041576258',
  appId: '1:418041576258:web:4500cd237308da31f1a6db',
  measurementId: 'G-QFFSWXLKC7',
};

// Initialize Firebase

let new_app = firebase.initializeApp(firebaseConfig);
// if (!firebase.getApps().length)
//   new_app = firebase.initializeApp(firebaseConfig);
// else
//   new_app = firebase.getApp();

export const db = getFirestore(new_app);

export const app = new_app;

export const getUserToken = async (userId, callback) => {
  const userRef = doc(db, 'users', `${userId}`);

  try {
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const prev_data = docSnap.data();

      const prev_expoTokens = prev_data.expoTokens;
      if (callback) callback(prev_expoTokens);
    } else {
      if (callback) callback(null);
    }
  } catch (error) {
    console.log(error.message);
    if (callback) callback(null);
  }
};


export const getUserTokens = async (userId, callback) => {
  const userRef = doc(db, 'users', `${userId}`);

  try {
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const prev_data = docSnap.data();

      const loginStatByDevice = prev_data.loginStatByDevice;
      const expoTokens = prev_data.expoTokens;

      let tokens = [];
      for (const deviceName in expoTokens) {
        if (loginStatByDevice[deviceName] === true) {
          tokens.push(expoTokens[deviceName]);
        }
      }

      if (callback) callback(tokens);
    } else {
      if (callback) callback([]);
    }
  } catch (error) {
    console.log(error.message);
    if (callback) callback([]);
  }
};

// export default firebase;
