import axios from 'axios';
import AsyncSelect from 'react-select/async';

const SelectCrossSell = ({
  crossSellDetails,
  setCrossSellDetails,
  editId,
}) => {

  const handleChange = (e) => {
    setCrossSellDetails(e);

  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/diagnostic/diagnostic-add/?search=${inputValue}`
        )
        .then((resp) => {
          const tests = resp.data.results.map((test) => {
            return {
              ...test,
              value: test.id,
              label: test.name,
            };
          });
          resolve(tests);
        });
    });


  const customStyles = {
    control: () => ({
      alignItems: 'center',
      backgroundcolor: 'hsl(0, 0%, 100%)',
      borderRadius: '4px',
      borderStyle: 'solid',
      borderWidth: '1px',
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: '38px',
      outline: '0 !important',
      position: 'relative',
      transition: 'all 100ms',
      boxSizing: 'border-box',
      borderColor: '#E4E6EF00',
    }),
  }

  return (
    <AsyncSelect
      onChange={handleChange}
      // defaultOptions={tests}
      value={crossSellDetails}
      loadOptions={promiseOptions}
      placeholder={'Search Cross Sell'}
      isDisabled={editId}
      styles={customStyles}
    />
  );
};

export default SelectCrossSell;
