import axios from 'axios';
import id from 'date-fns/esm/locale/id/index.js';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal, ModalBody, Spinner } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const CsvDownloadModal = ({ showCSVModal, setShowCSVModal }) => {
  const [orders, setOrders] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [csvDataLoading, setCsvDataLoading] = useState(false);
  const [orderCount, setOrderCount] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [limit, setLimit] = useState(300000);

  const [daterangeStart, setDaterangeStart] = useState(
    moment().subtract(31, 'days')
  );
  const [daterangeEnd, setDaterangeEnd] = useState(moment());
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [pagination, setPagination] = useState(1);

  useEffect(() => {
    handleDateRangeApply(moment().subtract(31, 'days'), moment());
  }, []);

  const fetchToStateOrder = (results) => {
    let newOrders = [];
    const orders = results;
    orders.forEach((order) => {
      // Check if the order is blank or empty
      if (!order || Object.keys(order).length === 0) {
        return; // Skip this iteration
      }

      console.log("The result is = ", order);
      let discountPercentAmount = null;

      if (order.orderdiscount) {
        const discountPercentage =
          (parseInt(order.orderdiscount.discount_price) /
            parseInt(order.total_price)) *
          100;
        discountPercentAmount = discountPercentage;
      } else {
        discountPercentAmount = null;
      }

      const itemArray = order.orderitem;
      const patientDetails = itemArray.map((item) => item.patient);
      const filteredPatient = patientDetails.filter((patient) => patient !== null); // Add a null check here
      const ids = filteredPatient.map(({ id }, index) => id); // Update to use filteredPatient
      const uniquePatientIds = ids.filter((id, index) => ids.indexOf(id) === index);
      let patientOrders = [];

      uniquePatientIds.forEach((id, i) => {
        const result = itemArray.filter((o1) => {
          return o1 && o1.patient && o1.patient.id === id;
        });
        const patient = patientDetails.find((patient) => patient.id === id); // Find patient by id
        const newArray = {
          patient: patient,
          testItem: result,
          mainOrder: order,
          count: i + 1,
          discountPercentAmount: discountPercentAmount,
        };
        patientOrders.push(newArray);
      });
      newOrders.push(...patientOrders);
    });

    setOrders(newOrders);
    // console.log(newOrders);
  };

  const fetchOrdersDate = (filter_op = '', limit, pagination, callback) => {
    setTableDataLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/order/order-tree/?page=1&limit=${limit}&offset=${
          (pagination - 1) * limit
        }&${filter_op}`
      )
      .then((resp) => {
        // console.log(resp.data);
        fetchToStateOrder(resp.data.results);
        setOrderCount(resp.data.count);
        setNextUrl(resp.data.next);
        setTableDataLoading(false);

        if (callback) callback();
      })
      .catch((err) => {
        console.log('order tree csv err:', err);
        setTableDataLoading(false);
      });
  };

  const getPatientByOrder = (orderitem) => {
    let patientSet = [];
    orderitem.map((item) => {
      if (item.patient && !patientSet.includes(item.patient.full_name)) {
        patientSet.push(item.patient.full_name);
      }
      return null;
    });
    return patientSet.length ? patientSet.join(' \\ ') : '-';
  };

  const getPatientLengthByOrder = (orderitem) => {
    let patientSet = [];
    orderitem.map((item) => {
      if (item.patient && !patientSet.includes(item.patient.full_name)) {
        patientSet.push(item.patient.full_name);
      }
      return null;
    });
    return patientSet.length;
  };

  const contactDetails = (order) => {
    let email = '';
    let phone = '';

    if (order.orderdetail) {
      if (order.orderdetail.address) {
        if (order.orderdetail.address.mobile) {
          phone = order.orderdetail.address.mobile;
        }
        if (order.orderdetail.address.email) {
          email = order.orderdetail.address.email;
        }
      }
    }

    if (email) {
      return `${phone} | ${email}`;
    } else if (phone) {
      return phone;
    } else {
      return '-';
    }
  };

  const getLabName = (order) => {
    if (order.orderitem.length !== 0) {
      if (order.orderitem[0]) {
        if (order.orderitem[0].purchasable_order_item) {
          if (
            order.orderitem[0].purchasable_order_item.testitem_purchasable_oi
          ) {
            if (
              order.orderitem[0].purchasable_order_item.testitem_purchasable_oi
                .branch
            ) {
              if (
                order.orderitem[0].purchasable_order_item
                  .testitem_purchasable_oi.branch.lab
              ) {
                if (
                  order.orderitem[0].purchasable_order_item
                    .testitem_purchasable_oi.branch.lab.name
                ) {
                  return order.orderitem[0].purchasable_order_item
                    .testitem_purchasable_oi.branch.lab.name;
                } else {
                  return '-';
                }
              } else {
                return '-';
              }
            } else {
              return '-';
            }
          } else {


            if (
              order.orderitem[0].purchasable_order_item.testitem_purchasable_order[0]
            ) {

              if (
                order.orderitem[0].purchasable_order_item.testitem_purchasable_order[0].branch
              ) {

                if (
                  order.orderitem[0].purchasable_order_item
                    .testitem_purchasable_order[0].branch.lab
                ) {
                  if (
                    order.orderitem[0].purchasable_order_item
                      .testitem_purchasable_order[0].branch.lab.name
                  ) {
                    return order.orderitem[0].purchasable_order_item
                      .testitem_purchasable_order[0].branch.lab.name;
                  } else {
                    return '-';
                  }
                } else {
                  return '-';
                }
              } else {
                return '-';
              }

            } else {
              return '-';
            }



            return '-';
          }




        } else {
          return '-';
        }
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  };

  const downloadCSVFile = (csv, filename) => {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: 'text/csv' });

    download_link = document.createElement('a');

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = 'none';

    document.body.appendChild(download_link);

    download_link.click();
    setTimeout(() => {
      setCsvDataLoading(false);
    }, 2000);
  };

  const htmlToCSV = (html, filename) => {
    var data = [];
    var rows = document.querySelectorAll('#downtable tr');

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll('td, th');

      for (var j = 0; j < cols.length; j++) {
        if (cols[j] && cols[j].innerText) {
          row.push(
            cols[j].innerText.replaceAll(/\n|\r|\t/g, ' ').replaceAll(',', ' ')
          );
        }
        // else if(cols[j] && !cols[j].innerText){
        //   row.push(',');
        // }
      }

      data.push(row.join(','));
    }

    downloadCSVFile(data.join('\n'), filename);
  };

  const handleDownloadCSVFile = () => {
    setCsvDataLoading(true);
    const html = document.querySelector('#downtable').outerHTML;
    const now = moment().format('DD_MMM_YYYY-H_mm_s');
    htmlToCSV(html, `Orders_${now}.csv`);
  };

  const handleDownloadCSV = () => {
    const date_ft = `&created_at__gt=${daterangeStart.format(
      'YYYY-MM-DD'
    )}+00:00:00&created_at__lt=${daterangeEnd.format('YYYY-MM-DD')}+23:59:59`;

    fetchOrdersDate(date_ft, limit, 1, () => {
      setTimeout(() => {
        handleDownloadCSVFile();
      }, 15000);
    });
  };

  const _toSpace = (string) => {
    let newStr = string.replace(/_/g, ' ');
    return newStr;
  };

  const paymentSourceName = (string) => {
    if (string === 'bkash') {
      return 'bKash';
    } else if (string === 'sslc') {
      return 'SSL Commerz';
    } else {
      return 'Cash On Delivery';
    }
  };

  const handleDateRangeApply = (start, end) => {
    setDaterangeStart(start);
    setDaterangeEnd(end);
    setShowDateFilter(true);
  };

  const resetCsv = () => {
    setDaterangeStart(moment().subtract(31, 'days'));
    setDaterangeEnd(moment());
  };

  const getAge = (dob) => {
    const year = moment().diff(`${dob}`, 'years');

    if (0 < year) {
      return year;
    } else {
      const month = moment().diff(`${dob}`, 'months');
      return month + ' Months';
    }
  };

  const getTestNames = (orderItem, type) => {
    if (orderItem.length !== 0) {
      const newArray = orderItem
        .filter((i) => i.order_type === type)
        .map(
          (i) => {
            if (i.purchasable_order_item) {
              if (i.purchasable_order_item.testitem_purchasable_oi) {
                if (
                  i.purchasable_order_item.testitem_purchasable_oi
                    .diagnostic_test
                ) {
                  return i.purchasable_order_item.testitem_purchasable_oi
                    .diagnostic_test.name;
                }

              }


              else if (i.purchasable_order_item.testitem_purchasable_order[0]) {
                if (
                  i.purchasable_order_item.testitem_purchasable_order[0]
                    .diagnostic_test
                ) {
                  return i.purchasable_order_item.testitem_purchasable_order[0]
                    .diagnostic_test.name;
                }

              }

               else {
                 return "";
               }



            }
          }
          // i.purchasable_order_item.testitem_purchasable_oi.diagnostic_test
          //   ? i.purchasable_order_item.testitem_purchasable_oi.diagnostic_test
          //       .name
          //   : '-'
        );
      return newArray.join(' | ');
    } else {
      return [];
    }
  };

  const getTestCount = (orderItem) => {
    // if (orderItem.length !== 0) {
    //   const newArray = orderItem.map((i) => {
    //     if (i.purchasable_order_item) {
    //       if (i.purchasable_order_item.testitem_purchasable_oi) {
    //         if (
    //           i.purchasable_order_item.testitem_purchasable_oi.diagnostic_test
    //         ) {
    //           return i.purchasable_order_item.testitem_purchasable_oi
    //             .diagnostic_test.name;
    //         }
    //       }
    //     }
    //   });
    //   return newArray.length;
    // } else {
    //   return 0;
    // }
    return orderItem.length;
  };

  const getPatientId = (order) => {
    if (order.count === 1) {
      return order.mainOrder.order_id + 'A';
    } else if (order.count === 2) {
      return order.mainOrder.order_id + 'B';
    } else if (order.count === 3) {
      return order.mainOrder.order_id + 'C';
    } else if (order.count === 4) {
      return order.mainOrder.order_id + 'D';
    } else if (order.count === 5) {
      return order.mainOrder.order_id + 'E';
    } else if (order.count === 6) {
      return order.mainOrder.order_id + 'F';
    } else if (order.count === 7) {
      return order.mainOrder.order_id + 'G';
    } else if (order.count === 8) {
      return order.mainOrder.order_id + 'H';
    } else if (order.count === 9) {
      return order.mainOrder.order_id + 'I';
    } else if (order.count === 10) {
      return order.mainOrder.order_id + 'J';
    } else if (order.count === 11) {
      return order.mainOrder.order_id + 'K';
    } else if (order.count === 12) {
      return order.mainOrder.order_id + 'L';
    } else if (order.count === 13) {
      return order.mainOrder.order_id + 'M';
    } else if (order.count === 14) {
      return order.mainOrder.order_id + 'N';
    } else if (order.count === 15) {
      return order.mainOrder.order_id + 'O';
    } else if (order.count === 16) {
      return order.mainOrder.order_id + 'P';
    } else if (order.count === 17) {
      return order.mainOrder.order_id + 'Q';
    } else if (order.count === 18) {
      return order.mainOrder.order_id + 'R';
    } else if (order.count === 19) {
      return order.mainOrder.order_id + 'S';
    } else if (order.count === 20) {
      return order.mainOrder.order_id + 'T';
    } else if (order.count === 21) {
      return order.mainOrder.order_id + 'U';
    } else if (order.count === 22) {
      return order.mainOrder.order_id + 'V';
    } else if (order.count === 23) {
      return order.mainOrder.order_id + 'W';
    } else if (order.count === 24) {
      return order.mainOrder.order_id + 'X';
    } else if (order.count === 25) {
      return order.mainOrder.order_id + 'Y';
    } else if (order.count === 26) {
      return order.mainOrder.order_id + 'Z';
    } else {
      return order.mainOrder.order_id;
    }
  };

  const getTestItemsPrice = (array) => {
    if (array.length !== 0) {
      const price = array.reduce((total, item) => {
        if (item.purchasable_order_item) {
          return total + parseInt(item.purchasable_order_item.sell_price);
        } else {
          return 0;
        }
      }, 0);
      return parseInt(price);
    } else {
      return 0;
    }
  };

  const getAmarlabSource = (item) => {
    if (item.length !== 0) {
      if (item[0].order && item[0].order.url) {
        const sou =  item[0].order.url;
        if (sou === 'http://localhost:3000' || sou === 'http://ops.amarlab.com:8080' || sou === 'http://18.188.76.126:8080' || sou === 'https://ops.amarlab.com') {
          return 'Ops Dashboard';
        } else if (sou === 'http://amarlab.com:8080' || sou === 'http://3.142.245.74:8080' || sou === 'https://amarlab.com') {
          return 'Web';
        } else {
          return 'App';
        }
      }
    } else {
      return '-';
    }
  }

  const getUserSex = (patient) => {
    if(patient) {
       if (patient.sex === 'male') {
        return 'M';
       } else if(patient.sex === 'female') {
        return 'F';
       } else {
        return 'T';
       }
    } else {
      return '-';
    }
  }

  const getPatientSex = (sex) => {
    if(sex) {
       if (sex === 'male') {
        return 'M';
       } else if(sex === 'female') {
        return 'F';
       } else {
        return 'T';
       }
    } else {
      return '-';
    }
  }

  return (
    <Modal
      show={showCSVModal}
      onHide={() => setShowCSVModal(false)}
      animation={false}
      size='lg'
    >
      <ModalBody>
        <div className='pl-4 mb-3 d-flex justify-content-between align-items-center'>
          <h5 className='mb-0'>Download Order</h5>
          <div className='d-flex'>
            <button
              className='btn btn-sm btn-danger px-8'
              onClick={() => setShowCSVModal(false)}
            >
              Close
            </button>
          </div>
        </div>{' '}
        <hr />
        <div className='mt-5 mb-8 min-h-125px'>
          <div className='fs-5 fw-bold mb-3 ms-2'> Chose Date Range: </div>
          <div className='d-flex d-column mb3 ms-1'>
            <DateRangePicker
              initialSettings={{
                startDate: daterangeStart.toDate(),
                endDate: daterangeEnd.toDate(),
                ranges: {
                  All: [
                    moment().subtract(5, 'year').toDate(),
                    moment().toDate(),
                  ],
                  Today: [moment().toDate(), moment().toDate()],
                  Yesterday: [
                    moment().subtract(1, 'days').toDate(),
                    moment().subtract(1, 'days').toDate(),
                  ],
                  'Last 3 Days': [
                    moment().subtract(2, 'days').toDate(),
                    moment().toDate(),
                  ],
                  'Last 30 Days': [
                    moment().subtract(29, 'days').toDate(),
                    moment().toDate(),
                  ],
                  'This Month': [
                    moment().startOf('month').toDate(),
                    moment().endOf('month').toDate(),
                  ],
                  'Last Month': [
                    moment().subtract(1, 'month').startOf('month').toDate(),
                    moment().subtract(1, 'month').endOf('month').toDate(),
                  ],
                  'This Year': [
                    moment().startOf('year').toDate(),
                    moment().endOf('year').toDate(),
                  ],
                  'Last Year': [
                    moment().subtract(1, 'year').startOf('year').toDate(),
                    moment().subtract(1, 'year').endOf('year').toDate(),
                  ],
                },
              }}
              onApply={(event, picker) => {
                handleDateRangeApply(picker.startDate, picker.endDate);
              }}
            >
              <button
                className='btn btn-light-primary me-5 d-none d-lg-block'
                title='Select dashboard daterange'
              >
                {!showDateFilter ? (
                  <span>Filter By Date</span>
                ) : (
                  <>
                    <span className='me-1'>Range:</span>
                    <span className='me-1'>
                      {daterangeStart.format('DD MMM YYYY') +
                        ' - ' +
                        daterangeEnd.format('DD MMM YYYY')}
                    </span>
                  </>
                )}
              </button>
            </DateRangePicker>

            <button
              className='btn btn-sm btn-success px-8 me-5'
              onClick={resetCsv}
            >
              Reset
            </button>
          </div>
        </div>
        <div className='table-responsive d-none' style={{ maxHeight: '70vh' }}>
          <p className='mb-0 text-center fs-1'>
            {/* <p>pagination-{pagination}</p> */}
            {/* <p>orderCount-{orderCount}</p> */}
            {/* <p>limit-{limit}</p> */}
            {/* <p>pagination-{pagination}</p> */}
            {/* {orderCount > 100 ? 'show pagination' : 'not show'} */}
            {/* {Math.ceil(orderCount / limit)} */}
            ----- Showing {(pagination - 1) * limit + 1} to{' '}
            {Math.ceil(orderCount / limit) === pagination ? (
              orderCount
            ) : (
              <>{orderCount > 100 ? limit * pagination : orderCount} </>
            )}
            {' of '}
            <strong>Total {orderCount || '00'}</strong> ------
          </p>
          <table
            className='table align-start table-row-dashed fs-6 gy-5 dataTable no-footer'
            id='downtable'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                <th className='min-w-125px sorting'>#ID</th>
                <th className='min-w-150px sorting'>Source</th>
                <th className='min-w-150px sorting'>Amarlab Source</th>
                <th className='min-w-150px sorting'>Customer</th>
                <th className='min-w-150px sorting'>User Age</th>
                <th className='min-w-150px sorting'>User Sex</th>
                <th className='min-w-150px sorting'>User Mobile</th>
                <th className='min-w-150px sorting'>User Email</th>
                <th className='min-w-125px sorting'>Order Date & Time</th>
                <th className='min-w-125px sorting'>User Type</th>
                <th className='min-w-125px sorting'>Order Status</th>
                <th className='min-w-125px sorting'>CS Agent</th>
                <th className='min-w-125px sorting'>MT</th>
                <th className='min-w-125px sorting'>Type</th>
                <th className='min-w-125px sorting'>Reference</th>
                <th className='min-w-175px sorting'>Contact</th>
                <th className='min-w-150px sorting'>Address</th>
                <th className='min-w-150px sorting'>City</th>
                <th className='min-w-150px sorting'>Area</th>
                <th className='min-w-150px sorting'>Patient Order ID</th>
                <th className='min-w-150px sorting'>Patient Name</th>
                <th className='min-w-150px sorting'>Patient Age</th>
                <th className='min-w-150px sorting'>Patient Sex</th>
                {/* <th className='min-w-150px sorting'>Check Comment</th> */}
                <th className='min-w-250px sorting'>Test Names</th>
                <th className='min-w-250px sorting'>Packages</th>

                <th className='min-w-150px sorting'>Sample Collection</th>
                <th className='min-w-125px sorting'>Cart Value</th>
                <th className='min-w-175px sorting'>Service Charge</th>
                <th className='min-w-175px sorting'>Material Charge</th>
                <th className='min-w-175px sorting'>Discount</th>
                <th className='min-w-150px sorting'>Coupon Code</th>
                <th className='min-w-175px sorting'>Total Payment</th>
                <th className='min-w-175px sorting'>Payment Status</th>
                <th className='min-w-150px sorting'>Payment Source</th>
                <th className='min-w-175px sorting'>Order Note</th>
                <th className='min-w-150px sorting'>Delivery Copy</th>
                <th className='min-w-250px sorting'>Service Status</th>
                <th className='min-w-250px sorting'>Report Delivery Status</th>
                <th className='min-w-200px sorting'>Estimate Delivery Date</th>
                <th className='min-w-200px sorting'>Lab</th>
                <th className='min-w-200px sorting'>Test Count</th>
              </tr>
            </thead>
            <tbody>
              {orders &&
                orders.map((order, i) => (
                  <tr key={i}>
                    <td>#{order.mainOrder.order_id}</td>
                    <td>
                      {order && order.mainOrder.external_source_user ? (
                        <span className='text-info fs-7 mx-3'>
                          {order.mainOrder.external_source_user.first_name
                            ? order.mainOrder.external_source_user.first_name +
                              ' ' +
                              order.mainOrder.external_source_user.last_name
                            : order.mainOrder.external_source_user.username}
                        </span>
                      ) : (
                        <span className='text-info fs-7 mx-3'>AmarLab</span>
                      )}
                    </td>
                    <td>
                      {order && order.mainOrder.external_source_user ? (
                        <span className='text-info fs-7 mx-3'>
                          {order.mainOrder.external_source_user.first_name
                            ? order.mainOrder.external_source_user.first_name +
                              ' ' +
                              order.mainOrder.external_source_user.last_name
                            : order.mainOrder.external_source_user.username}
                        </span>
                      ) : (
                        // <span className='text-info fs-7 mx-3'>AmarLab</span>
                        getAmarlabSource(order.testItem) || '-'
                      )}
                    </td>
                    <td>
                      {order.mainOrder && order.mainOrder.user ? (
                        <>
                          {order.mainOrder.user.first_name
                            ? `${
                                order.mainOrder.user.first_name.trim() || '-'
                              } ${order.mainOrder.user.last_name}`
                            : order.mainOrder.user.username}
                        </>
                      ) : (
                        <p>&mdash;</p>
                      )}
                    </td>
                    <td>
                      {order.mainOrder && order.mainOrder.user ? (
                        <>
                          {order.mainOrder.user.dob
                            ? `${moment().diff(
                                order.mainOrder.user.dob,
                                'years'
                              )}`
                            : order.mainOrder.orderdetail &&
                              order.mainOrder.orderdetail.address
                            ? order.mainOrder.orderdetail.address.patient
                              ? `${moment().diff(
                                  order.mainOrder.orderdetail.address.patient
                                    .dob,
                                  'years'
                                )}`
                              : order.mainOrder.orderitem[0]
                              ? `${moment().diff(
                                  order.mainOrder.orderitem[0].patient.dob,
                                  'years'
                                )}`
                              : '-'
                            : order.mainOrder.orderitem[0]
                            ? `${moment().diff(
                                order.mainOrder.orderitem[0].patient.dob,
                                'years'
                              )}`
                            : '-'}
                        </>
                      ) : (
                        <p>&mdash;</p>
                      )}
                    </td>
                    <td className='text-capitalize'>
                      {order.mainOrder &&
                      order.mainOrder.user &&
                      order.mainOrder.user.patient.length !== 0 ? (
                        <>
                          {getUserSex(order.mainOrder.user.patient[0])}
                        </>
                      ) : (
                        <p>&mdash;</p>
                      )}
                    </td>
                    <td>
                      {order && order.mainOrder && order.mainOrder.user ? (
                        <>
                          {order.mainOrder.user.username
                            ? order.mainOrder.user.username
                            : '-'}
                        </>
                      ) : (
                        <p>&mdash;</p>
                      )}
                    </td>
                    <td>
                        {
                          (order?.mainOrder?.user?.email?.trim() ??
                          (
                            order?.mainOrder?.orderdetail?.address?.email?.trim() ??
                            (
                              order?.mainOrder?.orderitem[0]?.address?.email ??
                              order?.mainOrder?.orderitem[0]?.patient?.email ??
                              '-'
                            )
                          )) || '-'
                        }
                    </td>
                    <td>
                      {moment(order.mainOrder.created_at).format('DD MMM YYYY')}
                      ,
                      <br />
                      {moment(order.mainOrder.created_at).format('hh:mm A')}
                    </td>
                    <td>
                      {order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.user_type
                        ? order.mainOrder.orderdetail.user_type.trim() || '-'
                        : '-'}
                    </td>
                    <td>
                      {order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.order_status
                        ? order.mainOrder.orderdetail.order_status.replace(
                            /_/g,
                            ' '
                          )
                        : '-'}
                    </td>
                    <td>
                      {order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.cs_agent ? (
                        <>
                          {order.mainOrder.orderdetail.cs_agent.first_name
                            ? `${order.mainOrder.orderdetail.cs_agent.first_name} ${order.mainOrder.orderdetail.cs_agent.last_name}`
                            : order.mainOrder.orderdetail.cs_agent.username}
                        </>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      {order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.mt ? (
                        <>
                          {order.mainOrder.orderdetail.mt.first_name
                            ? `${order.mainOrder.orderdetail.mt.first_name} ${order.mainOrder.orderdetail.mt.last_name}`
                            : order.mainOrder.orderdetail.mt.username}
                        </>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      {order.mainOrder && order.mainOrder.order_type
                        ? _toSpace(order.mainOrder.order_type)
                        : '-'}
                    </td>
                    <td>
                      {order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.references
                        ? order.mainOrder.orderdetail.references
                        : '-'}
                    </td>
                    <td>
                      {/* {order.orderdetail ? (
                        <>
                          {order.orderdetail.address &&
                          order.orderdetail.address.mobile
                            ? order.orderdetail.address.mobile
                            : order.orderitem[0]
                            ? order.orderitem[0].address
                              ? order.orderitem[0].address.mobile
                              : order.orderitem[0].patient.mobile || '-'
                            : '-'}
                        </>
                      ) : order.orderitem[0] ? (
                        order.orderitem[0].address ? (
                          order.orderitem[0].address.mobile
                        ) : (
                          order.orderitem[0].patient.mobile || '-'
                        )
                      ) : (
                        '-'
                      )}
                      | */}
                      {contactDetails(order.mainOrder)}
                    </td>
                    {/* address */}
                    <td>
                      {/* {order.mainOrder.orderdetail ? (
                        <>
                          {order.mainOrder.orderdetail &&
                          order.mainOrder.orderdetail.address
                            ? `${
                                order.mainOrder.orderdetail.address.address.trim() ||
                                '-'
                              }`
                            : `${
                                order.mainOrder.orderitem[0].address.address.trim() ||
                                '-'
                              }`}
                        </>
                      ) : (
                        '-'
                      )} */}
                      {order.mainOrder.orderdetail && order.mainOrder.orderdetail.address ? (
                        <>
                          {order.mainOrder.orderdetail.address.address || '-'}
                        </>
                      ) : '-'}
                    </td>
                    {/* city */}
                    <td>
                      {/* {order.mainOrder.orderdetail ? (
                        <>
                          {order.mainOrder.orderdetail &&
                          order.mainOrder.orderdetail.address
                            ? `${
                                order.mainOrder.orderdetail.address.district.trim() ||
                                '-'
                              }`
                            : `${
                                order.mainOrder.orderitem[0].address.district.trim() ||
                                '-'
                              }`}
                        </>
                      ) : (
                        '-'
                      )} */}
                      {order.mainOrder.orderdetail && order.mainOrder.orderdetail.address ? (
                        <>
                          {order.mainOrder.orderdetail.address.district || '-'}
                        </>
                      ) : '-'}
                    </td>
                    {/* area */}
                    <td>
                      {order.mainOrder.orderdetail && order.mainOrder.orderdetail.address ? (
                          <>
                            {order.mainOrder.orderdetail.address.thana || '-'}
                          </>
                        ) : '-'}
                      {/* {order.mainOrder.orderdetail ? (
                        <>
                          {order.mainOrder.orderdetail &&
                          order.mainOrder.orderdetail.address
                            ? `${
                                order.mainOrder.orderdetail.address.thana.trim() ||
                                '-'
                              }`
                            : `${
                                order.mainOrder.orderitem[0].address.thana.trim() ||
                                '-'
                              }`}
                        </>
                      ) : (
                        '-'
                      )} */}
                    </td>
                    {/* Patient Id  */}
                    <td>#{getPatientId(order)}</td>
                    {/* Patient Name */}
                    <td>{order.patient.full_name || '-'}</td>
                    {/* Patient Age */}
                    <td>{getAge(order.patient.dob)}</td>
                    {/* Patient Sex */}
                    <td className='text-capitalizer'>{getPatientSex(order.patient.sex)}</td>
                    {/* Check Comment */}
                    {/* <td>-</td> */}
                    <td>
                      {getTestNames(order.testItem, 'diagnostic').length !== 0
                        ? getTestNames(order.testItem, 'diagnostic')
                        : '-'}
                    </td>
                    <td>
                      {getTestNames(order.testItem, 'package').length !== 0
                        ? getTestNames(order.testItem, 'package')
                        : '-'}
                    </td>
                    {/* Sample Collection Date Time */}
                    <td>
                      {moment(order.mainOrder.date).format('D/M/YYYY')}{' '}
                      <br />
                      {order.mainOrder.time
                        ? moment(order.mainOrder.time, ['HH:mm:ss']).format(
                            'H:mm'
                          )
                        : ''}
                    </td>
                    <td>
                      {order.mainOrder.orderitem.length !== 0 &&
                        getTestItemsPrice(order.testItem)}
                    </td>
                    <td>
                      {order.count === 1
                        ? parseInt(order.mainOrder.collection_fee)
                        : '0'}
                    </td>
                    <td>
                      {order.mainOrder.orderitem.length !== 0 &&
                        order.mainOrder.orderitem[0].meterial_fee}
                    </td>
                    <td>
                      {order.mainOrder.orderdiscount ? (
                        <>
                          {order.discountPercentAmount
                            ? (
                                (order.discountPercentAmount / 100) *
                                getTestItemsPrice(order.testItem)
                              ).toFixed(2)
                            : '00'}
                        </>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      {order.mainOrder.orderdiscount &&
                      order.mainOrder.orderdiscount.cupon
                        ? order.mainOrder.orderdiscount.cupon.code
                        : '-'}
                    </td>
                    <td>
                      {order.count === 1 ? (
                        <>
                          {order.mainOrder.orderdiscount
                            ? (
                                getTestItemsPrice(order.testItem) +
                                parseInt(
                                  order.mainOrder.orderitem[0].meterial_fee
                                ) +
                                parseInt(order.mainOrder.collection_fee) -
                                (order.discountPercentAmount / 100) *
                                  getTestItemsPrice(order.testItem)
                              ).toFixed(2)
                            : (
                                getTestItemsPrice(order.testItem) +
                                parseInt(
                                  order.mainOrder.orderitem[0].meterial_fee
                                ) +
                                parseInt(order.mainOrder.collection_fee)
                              ).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {order.mainOrder.orderdiscount
                            ? (
                                getTestItemsPrice(order.testItem) +
                                order.mainOrder.orderitem[0].meterial_fee -
                                (order.discountPercentAmount / 100) *
                                  getTestItemsPrice(order.testItem)
                              ).toFixed(2)
                            : (
                                getTestItemsPrice(order.testItem) +
                                order.mainOrder.orderitem[0].meterial_fee
                              ).toFixed(2)}
                        </>
                      )}
                    </td>
                    <td>
                      {order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.payment_status
                        ? order.mainOrder.orderdetail.payment_status
                        : '-'}
                    </td>
                    <td>
                      {order.mainOrder &&
                      order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.payment_source
                        ? paymentSourceName(
                            order.mainOrder.orderdetail.payment_source
                          )
                        : '-'}
                    </td>
                    <td>
                      {order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.order_note
                        ? order.mainOrder.orderdetail.order_note
                        : '-'}
                    </td>
                    <td>
                      {order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.delivery_copy_status
                        ? order.mainOrder.orderdetail.delivery_copy_status
                        : '-'}
                    </td>
                    <td>
                      {order.mainOrder &&
                      order.mainOrder.orderdelivery &&
                      order.mainOrder.orderdelivery.length !== 0 ? (
                        <>
                          {order.mainOrder.orderdelivery.map(
                            (orderdelivery, i) => (
                              <div key={i} className='mb-2'>
                                <p className='mb-0 font-weight-bold text-capitalize'>
                                  {_toSpace(orderdelivery.name)}
                                </p>
                                <p className='mb-0 font-weight-bold text-capitalize'>
                                  {moment(orderdelivery.created_at).format(
                                    'DD MMM YYYY, h:mm A'
                                  )}
                                </p>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      {order.mainOrder &&
                      order.mainOrder.orderreportdelivery &&
                      order.mainOrder.orderreportdelivery.length !== 0 ? (
                        <>
                          {order.mainOrder.orderreportdelivery.map(
                            (orderdelivery, i) => (
                              <div key={i} className='mb-2'>
                                <p className='mb-0 font-weight-bold text-capitalize'>
                                  {_toSpace(orderdelivery.name)}
                                </p>
                                <p className='mb-0 font-weight-bold text-capitalize'>
                                  {moment(orderdelivery.created_at).format(
                                    'DD MMM YYYY, h:mm A'
                                  )}
                                </p>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      {order.mainOrder.orderdetail &&
                      order.mainOrder.orderdetail.estimate_date
                        ? moment(
                            order.mainOrder.orderdetail.estimate_date
                          ).format('DD MMM YYYY')
                        : '-'}
                    </td>
                    <td>{getLabName(order.mainOrder)}</td>
                    <td>{getTestCount(order.testItem)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <p className='d-none'></p>
        <div className='d-flex align-items-center justify-content-start mt-5 ms-1 '>
          <button
            className='btn btn-primary me-3'
            onClick={handleDownloadCSV}
            disabled={tableDataLoading || csvDataLoading}
          >
            {(tableDataLoading || csvDataLoading) && (
              <Spinner animation='border' size='sm' />
            )}
            <span className='ms-3'>Download CSV</span>
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CsvDownloadModal;
