import axios from 'axios';
import React, { Component } from 'react';
import OrderTable from '../components/OrderTable';
import OrderAddModal from '../components/OrderAddModal';
import moment from 'moment';
import BtnLoading from '../components/BtnLoading';
import CsvDownloadModal from '../components/CsvDownloadModal';
import CsvDownloadModalV2 from '../components/CsvDownloadModalV2';
// import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoadingContent from '../components/LoadingContent';
import HeaderToolbar from '../components/HeaderToolbar';
import { toast } from 'react-toastify';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import AccountOrderTable from '../components/AccountOrderTable';
import AngleDown from '../assets/img/angle-down.png';
// import SortDown from '../assets/img/sort.png';
import FilterPopover from '../components/Dropdown/FilterPopover';
import { Spinner } from 'react-bootstrap';
import Pagination from '../components/Pagination/Pagination';

class Orders extends Component {
  state = {
    pageInitialLoad: true,

    orders: [],
    orderCount: null,
    loading: false,
    initialLoadd: false,
    next: null,
    offset: 15,
    limit: 15,
    pagination: 1,
    users: null,
    showAddModal: false,
    showCSVModal: false,
    adminAccess: false,
    allAccess: false,
    createAccess: false,
    rolePaginate: null,
    roleId: null,

    sortedBy: '',
    sortedAsc: true,

    loadingOrder: false,
    seeMoreLoading: false,

    showFilterDrop: false,

    orderManager: null,
    medicalTech: null,

    daterange_start: moment(),
    daterange_end: moment(),
    showDateFilter: false,
    dateFilterName: null,

    showAchieve: false,

    sample_coll_start: moment(),
    sample_coll_end: moment(),

    searchBy: 'order_id',
    searchText: '',
    searchId: '',
    searchMt: '',
    searchPtn: '',
    searchPhone: '',
    searchDeliPhone: '',

    searchPaymentSource: '',
    searchAchieved: '',

    fltrOrdrType: '',
    fltrStat: '',
    fltrPayStat: '',
    fltrServiceStat: '',
    fltrReport: '',
    fltrReportHard: '',
    fltrArch: '',
    fltrDate: '',
    fltrApproval: '',

    searchData: {
      created_at__gt: '',
      created_at__gte: '',
      created_at__lt: '',
      created_at__lte: '',
      date: '',
      date__gte: '',
      date__lte: '',
      external_source_user: '',
      is_archived: 'false',
      limit: 15,
      pagination: 1,
      order_type: '',
      orderapproval__is_approved: '',
      orderdelivery__name: '',
      orderdetail__delivery_copy_status: '',
      orderdetail__mt: '',
      orderdetail__order_status: '',
      orderdetail__payment_source: '',
      orderdetail__payment_status: '',
      orderdetail__user_type: '',
      orderreportdelivery__name: '',
      orderreporthardcopydelivery__name: '',
      user: '',
      user__username__iexact: '',
      search: '',
      ordering: '',
      orderdetail__address__mobile__iexact: '',
      orderassignmt__mt__patient__full_name__iexact: '',
      orderitem__patient__full_name__iexact: '',
    },
    urlParamObj: {},
    showAccounts: false,
    ourAllLabs: [],
  };

  // filter data start

  orderStatusOptions = [
    {
      name: 'Reset',
      id: '',
    },
    {
      name: 'Pending',
      id: 'pending',
    },
    {
      name: 'Waiting for Confirmation',
      id: 'waiting_for_confirmation',
    },
    {
      name: 'Service on Hold',
      id: 'service_on_hold',
    },
    {
      name: 'Confirmed',
      id: 'confirmed',
    },
    {
      name: 'Cancel',
      id: 'cancel',
    },
    {
      name: 'Completed',
      id: 'completed',
    },
  ];

  orderTypeOptions = [
    {
      name: 'Reset',
      id: '',
    },
    {
      name: 'Covid',
      id: 'covid',
    },
    {
      name: 'Non Covid',
      id: 'non_covid',
    },
  ];

  orderDeilveryCopy = [
    {
      name: 'Reset',
      id: '',
    },
    {
      name: 'Soft Copy',
      id: 'soft',
    },
    {
      name: 'Hard Copy',
      id: 'hard',
    },
    {
      name: 'Both',
      id: 'both',
    },
  ];

  orderServiceStatus = [
    {
      name: 'Reset',
      id: '',
    },
    {
      name: 'Pending',
      id: 'pending',
    },
    {
      name: 'Order Received',
      id: 'order_received',
    },
    {
      name: 'Sample Collected',
      id: 'sample_collected',
    },
    {
      name: 'Sample Delivered to Lab',
      id: 'sample_delivered_to_lab',
    },
  ];

  reportDeliveryOptionsSoft = [
    {
      name: 'Reset',
      id: '',
    },
    {
      name: 'Soft copy due',
      id: 'soft_copy_due',
    },
    {
      name: 'Soft copy sent',
      id: 'soft_copy_sent',
    },
    {
      name: 'No soft copy',
      id: 'no_soft_copy',
    },
  ];

  reportDeliveryOptionsHard = [
    {
      name: 'Reset',
      id: '',
    },
    {
      name: 'No hard copy',
      id: 'no_hard_copy',
    },
    {
      name: 'Hard copy due',
      id: 'hard_copy_due',
    },
    {
      name: 'Hard copy sent',
      id: 'hard_copy_sent',
    },
  ];

  paymentStatusOptions = [
    {
      name: 'Reset',
      id: '',
    },
    {
      name: 'Paid',
      id: 'paid',
    },
    {
      name: 'Unpaid',
      id: 'unpaid',
    },
  ];

  // filter data end

  adjustCapitalize = (strng) => {
    return strng
      .split(' ')
      .map(
        (text) => text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase()
      )
      .join(' ');
  };

  paramsMod = (key, value, ispush = true) => {
    let newUrlParam = { ...this.state.urlParamObj };
    newUrlParam[key] = value;
    this.setState({ urlParamObj: newUrlParam });

    let paramstring = '';
    for (const key in newUrlParam) {
      if (newUrlParam[key]) paramstring += `&${key}=${newUrlParam[key]}`;
    }

    if (ispush) this.props.history.push(`/orders?${paramstring.slice(1)}`);
  };

  paramsModSearch = (key, value) => {
    let newUrlParam = { ...this.state.urlParamObj };
    newUrlParam['search-order-id'] = '';
    newUrlParam['search-user-phone'] = '';
    newUrlParam['search-deli-phone'] = '';
    newUrlParam['search-mt'] = '';
    newUrlParam['search-patient'] = '';
    newUrlParam['payment-source'] = '';
    newUrlParam['achieved'] = '';
    newUrlParam[key] = value;

    this.setState({ urlParamObj: newUrlParam });

    let paramstring = '';
    for (const key in newUrlParam) {
      if (newUrlParam[key]) paramstring += `&${key}=${newUrlParam[key]}`;
    }

    this.props.history.push(`/orders?${paramstring.slice(1)}`);
  };

  setDateFilterName = (value) => {
    this.setState({ dateFilterName: value });
  };

  fetchOrderManager = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/?groups__name__in=Order%20Manager,Supervisor&page=1&limit=1000000&ofset=0`
      )
      .then((resp) => {
        // console.log('oms: ',resp.data.results);
        this.setState({ orderManager: resp.data.results });
      });
  };

  fetchMedicalTechnologist = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/?groups__name=Medical%20Technologist&page=1&limit=1000000&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ medicalTech: resp.data.results });
      });
  };

  fetchUsers = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user_management/user/`)
      .then((resp) => {
        this.setState({ users: resp.data.results });
        // console.log(resp.data.results);
      })
      .catch((err) => {
        // console.log(err.response);
        if (err.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_details');
          localStorage.removeItem('is_superuser');
          window.location.href = '/login';
        }
      });
  };

  fetchLabs = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab/?limit=200`)
      .then((resp) => {
        this.setState({ ourAllLabs: resp.data.results });
      });
  };

  fetchOrders = () => {
    setTimeout(() => {
      this.setState({
        daterange_start: moment().subtract(5, 'year'),
        daterange_end: moment(),
        // showDateFilter: true,
      });

      this.fetchProcessedOrders({
        ...this.state.searchData,
        created_at__gt: moment().subtract(5, 'year'),
        created_at__lt: moment(),
      });
    }, 100);
  };

  handleInitialDaterange = () => {
    this.setState({
      daterange_start: moment().subtract(5, 'year'),
      daterange_end: moment(),
      // showDateFilter: true,
    });
  };

  fetchProcessedOrders = ({
    created_at__gt = '',
    created_at__gte = '',
    created_at__lt = '',
    created_at__lte = '',
    date = '',
    date__gte = '',
    date__lte = '',
    external_source_user = '',
    is_archived = 'false',
    limit = 15,
    pagination = 1,
    order_type = '',
    orderapproval__is_approved = '',
    orderdelivery__name = '',
    orderdetail__delivery_copy_status = '',
    orderdetail__mt = '',
    orderdetail__order_status = '',
    orderdetail__payment_source = '',
    orderdetail__payment_status = '',
    orderdetail__user_type = '',
    orderreportdelivery__name = '',
    orderreporthardcopydelivery__name = '',
    user = '',
    user__username__iexact = '',
    search = '',
    ordering = '',
    orderdetail__address__mobile__iexact = '',
    orderassignmt__mt__patient__full_name__iexact = '',
    orderitem__patient__full_name__iexact = '',
  }) => {
    this.setState({ loading: true });

    setTimeout(() => {
      const url = `${
        process.env.REACT_APP_BASE_URL
      }/order/order-tree/?page=1&limit=${limit}&offset=${
        (pagination - 1) * limit
      }&created_at=&created_at__gt=${
        created_at__gt ? created_at__gt.format('YYYY-MM-DD') + '+00:00:00' : ''
      }&created_at__gte=${created_at__gte}&created_at__lt=${
        created_at__lt ? created_at__lt.format('YYYY-MM-DD') + '+23:59:59' : ''
      }&created_at__lte=${created_at__lte}&date=${date}&date__gt=&date__gte=${date__gte}&date__lt=&date__lte=${date__lte}&external_source_user=${external_source_user}&is_archived=${is_archived}&order_type=${order_type}&orderapproval__is_approved=${orderapproval__is_approved}&orderdelivery__name=${orderdelivery__name}&orderdetail__delivery_copy_status=${orderdetail__delivery_copy_status}&orderdetail__mt=${orderdetail__mt}&orderdetail__order_status=${orderdetail__order_status}&orderdetail__payment_source=${orderdetail__payment_source}&orderdetail__payment_status=${orderdetail__payment_status}&orderdetail__user_type=${orderdetail__user_type}&orderreportdelivery__name=${orderreportdelivery__name}&orderreporthardcopydelivery__name=${orderreporthardcopydelivery__name}&user=${user}&user__username__iexact=${user__username__iexact}&search=${search}&ordering=${ordering}&orderdetail__address__mobile__iexact=${orderdetail__address__mobile__iexact}&orderassignmt__mt__patient__full_name__iexact=${orderassignmt__mt__patient__full_name__iexact}&orderitem__patient__full_name__iexact=${orderitem__patient__full_name__iexact}`;

      // console.log('passed url: ', url);

      axios.get(url).then((resp) => {
        // console.log(resp.data.results.length);
        this.setState({ orders: [] });
        this.setState({ orders: resp.data.results });
        this.setState({ orderCount: resp.data.count });
        this.setState({ next: resp.data.next });
        this.setState({ loadingOrder: false });
        this.setState({ loading: false });
        this.setState({ initialLoadd: true });

        this.setState({ pageInitialLoad: false });
      });
    }, 100);
  };

  fetchNextOrder = (nextUrl) => {
    axios
      .get(nextUrl)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ orders: [...this.state.orders, ...resp.data.results] });
        this.setState({ next: resp.data.next });
        this.setState({ loadingOrder: false });
        this.setState({ loading: false, seeMoreLoading: false });
      })
      .catch((e) => {
        this.setState({ loading: false, seeMoreLoading: false });
      });
  };

  fetchUserOrders = (id) => {
    this.fetchProcessedOrders({ ...this.state.searchData, user: id });
    this.setState({ searchData: { ...this.state.searchData, user: id } });
  };

  fetchExternalOrders = (id) => {
    this.fetchProcessedOrders({
      ...this.state.searchData,
      external_source_user: id,
    });
    this.setState({
      searchData: { ...this.state.searchData, external_source_user: id },
    });
  };

  fetchMtOrders = (id) => {
    this.fetchProcessedOrders({
      ...this.state.searchData,
      orderdetail__mt: id,
    });
    this.setState({
      searchData: { ...this.state.searchData, orderdetail__mt: id },
    });
  };

  paramsFilterOrder = (status) => {
    this.filterByStatus('orderdetail__order_status', status);
  };

  paramsFilterApproval = (status) => {
    this.filterByStatus('orderapproval__is_approved', status);
  };

  componentDidMount() {
    this.context.setPageTitle('Orders');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Orders', link: '/orders' },
    ]);
    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    if (!token) {
      this.props.history.push('/login');
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });

      this.fetchOrderManager();
      this.fetchMedicalTechnologist();

      let param_collection = {};

      let params = new URLSearchParams(this.props.location.search);
      let searchObj = {};

      for (const param of params.entries()) {
        param_collection[param[0]] = param[1];
        if (param[0] === 'status') {
          searchObj.orderdetail__order_status = param[1];
          this.setState({ fltrStat: param[1] });
        } else if (param[0] === 'order-type') {
          searchObj.order_type = param[1];
          this.setState({ fltrOrdrType: param[1] });
        } else if (param[0] === 'payment-status') {
          searchObj.orderdetail__payment_status = param[1];
          this.setState({ fltrPayStat: param[1] });
        } else if (param[0] === 'service-status') {
          searchObj.orderdelivery__name = param[1];
          this.setState({ fltrServiceStat: param[1] });
        } else if (param[0] === 'report-delivery') {
          searchObj.orderreportdelivery__name = param[1];
          this.setState({ fltrReport: param[1] });
        } else if (param[0] === 'report-delivery-hard') {
          searchObj.orderreporthardcopydelivery__name = param[1];
          this.setState({ fltrReportHard: param[1] });
        } else if (param[0] === 'archieved') {
          searchObj.orderdetail__order_status = param[1];
          this.setState({ fltrArch: param[1] });
        } else if (param[0] === 'date') {
          searchObj.date = param[1];
          this.setState({ fltrDate: param[1] });
        } else if (param[0] === 'search-order-id') {
          searchObj.search = param[1];
          this.setState({ searchId: param[1], searchText: param[1] });
        } else if (param[0] === 'search-user-phone') {
          searchObj.user__username__iexact = param[1];
          this.setState({ searchPhone: param[1], searchText: param[1] });
        } else if (param[0] === 'search-deli-phone') {
          searchObj.orderdetail__address__mobile__iexact = param[1];
          this.setState({ searchDeliPhone: param[1], searchText: param[1] });
        } else if (param[0] === 'search-mt') {
          searchObj.orderassignmt__mt__patient__full_name__iexact = param[1];
          this.setState({ searchMt: param[1], searchText: param[1] });
        } else if (param[0] === 'search-patient') {
          searchObj.orderitem__patient__full_name__iexact = param[1];
          this.setState({ searchPtn: param[1], searchText: param[1] });
        } else if (param[0] === 'approval') {
          searchObj.orderapproval__is_approved = param[1];
          this.setState({ fltrApproval: param[1] });
        } else if (param[0] === 'collection-start') {
          searchObj.date__gte = param[1];
          this.setState({ sample_coll_start: moment(param[1]) });
        } else if (param[0] === 'collection-end') {
          searchObj.date__lte = param[1];
          this.setState({ sample_coll_end: moment(param[1]) });
        } else if (param[0] === 'ordering') {
          searchObj.ordering = param[1];
          this.setState({
            sortedBy: param[1].replace('-', ''),
            sortedAsc: !param[1].startsWith('-'),
          });
        }
      }
      this.setState({ urlParamObj: param_collection });

      let status = params.get('status');
      let approval = params.get('approval');

      const userRole = JSON.parse(user_details);

      this.handleInitialDaterange();

      // get admin access only
      if (
        userRole.is_superuser ||
        (userRole.groups.length && userRole.groups[0].name === 'Admin')
      )
        this.setState({ adminAccess: true });

      if (userRole.is_superuser) {
        this.setState({ allAccess: true });
        // if (status) {
        //   this.paramsFilterOrder(status);
        // } else if (approval) {
        //   this.paramsFilterApproval(approval);
        // } else {
        //   this.fetchOrders();
        // }
        if (Object.keys(param_collection).length) {
          this.setState({
            searchData: { ...this.state.searchData, ...searchObj },
          });
          this.fetchProcessedOrders({ ...this.state.searchData, ...searchObj });
        } else {
          this.fetchOrders();
        }

        this.fetchUsers();
        this.setState({ createAccess: true });
      } else {
        if (userRole.groups.length === 0) {
          // console.log('User');
          if (Object.keys(param_collection).length) {
            this.setState({
              searchData: {
                ...this.state.searchData,
                ...searchObj,
                user: userRole.id,
              },
            });
            this.fetchProcessedOrders({
              ...this.state.searchData,
              ...searchObj,
              user: userRole.id,
            });
          } else this.fetchUserOrders(userRole.id);

          this.setState({ rolePaginate: 'user' });
          this.setState({ roleId: userRole.id });
        } else if (userRole.groups[0].name === 'User') {
          // console.log('User');
          if (Object.keys(param_collection).length) {
            this.setState({
              searchData: {
                ...this.state.searchData,
                ...searchObj,
                user: userRole.id,
              },
            });
            this.fetchProcessedOrders({
              ...this.state.searchData,
              ...searchObj,
              user: userRole.id,
            });
          } else this.fetchUserOrders(userRole.id);

          this.setState({ rolePaginate: 'user' });
          this.setState({ roleId: userRole.id });
        } else if (userRole.groups[0].name === 'Lab Partner') {
          if (Object.keys(param_collection).length) {
            this.setState({
              searchData: {
                ...this.state.searchData,
                ...searchObj,
                external_source_user: userRole.id,
              },
            });
            this.fetchProcessedOrders({
              ...this.state.searchData,
              ...searchObj,
              external_source_user: userRole.id,
            });
          } else this.fetchExternalOrders(userRole.id);

          this.setState({ rolePaginate: 'user' });
          this.setState({ roleId: userRole.id });
          this.setState({ createAccess: true });
        } else if (userRole.groups[0].name === 'Medical Technologist') {
          // console.log('Medical Technologist');
          if (Object.keys(param_collection).length) {
            this.setState({
              searchData: {
                ...this.state.searchData,
                ...searchObj,
                orderdetail__mt: userRole.id,
              },
            });
            this.fetchProcessedOrders({
              ...this.state.searchData,
              ...searchObj,
              orderdetail__mt: userRole.id,
            });
          } else this.fetchMtOrders(userRole.id);

          this.setState({ rolePaginate: 'mt' });
          this.setState({ roleId: userRole.id });
        } else if (userRole.groups[0].name === 'External') {
          if (Object.keys(param_collection).length) {
            this.setState({
              searchData: {
                ...this.state.searchData,
                ...searchObj,
                external_source_user: userRole.id,
              },
            });
            this.fetchProcessedOrders({
              ...this.state.searchData,
              ...searchObj,
              external_source_user: userRole.id,
            });
          } else this.fetchExternalOrders(userRole.id);

          this.setState({ rolePaginate: 'external' });
          this.setState({ roleId: userRole.id });
        } else if (userRole.groups[0].name === 'Accounts') {
          // console.log('Accounts');
          this.setState({ showAccounts: true, allAccess: true });
          const searchData = {
            created_at__gt: '',
            created_at__gte: '',
            created_at__lt: '',
            created_at__lte: '',
            date: '',
            date__gte: '',
            date__lte: '',
            external_source_user: '',
            is_archived: 'false',
            limit: 15,
            offset: 0,
            order_type: '',
            orderapproval__is_approved: '',
            orderdelivery__name: '',
            orderdetail__delivery_copy_status: '',
            orderdetail__mt: '',
            orderdetail__order_status: 'confirmed',
            orderdetail__payment_source: '',
            orderdetail__payment_status: '',
            orderdetail__user_type: '',
            orderreportdelivery__name: '',
            orderreporthardcopydelivery__name: '',
            user: '',
            user__username__iexact: '',
            search: '',
            ordering: '',
            orderdetail__address__mobile__iexact: '',
            orderassignmt__mt__patient__full_name__iexact: '',
            orderitem__patient__full_name__iexact: '',
          };

          this.setState({ searchData: searchData });

          setTimeout(() => {
            this.fetchOrders();
          }, 100);

          this.fetchLabs();
        } else {
          // console.log('Show all');
          this.setState({ allAccess: true });
          // if (status) {
          //   this.paramsFilterOrder(status);
          // } else {
          //   this.fetchOrders();
          // }
          if (Object.keys(param_collection).length) {
            this.setState({
              searchData: { ...this.state.searchData, ...searchObj },
            });
            this.fetchProcessedOrders({
              ...this.state.searchData,
              ...searchObj,
            });
          } else {
            this.fetchOrders();
          }

          this.setState({ createAccess: true });
          // this.fetchOrders();
          this.fetchUsers();
        }
      }

      if (userRole.is_superuser) {
        this.setState({ showAchieve: true });
      } else {
        if (userRole.groups.length === 0) {
          this.setState({ showAchieve: false });
        } else if (userRole.groups[0].name === 'Admin') {
          this.setState({ showAchieve: true });
        } else {
          this.setState({ showAchieve: false });
        }
      }
    }
  }

  loadMore = () => {
    this.setState({ offset: this.state.offset + 15 });
    this.setState({ seeMoreLoading: true });

    if (this.state.next) {
      this.fetchNextOrder(this.state.next);
    } else {
      toast.error('No more data available.', { autoClose: 3000 });
    }
  };

  // Final filter functions

  filterByStatus = (term, value) => {
    this.setState({ loading: true });
    this.setState({ offset: 15, pagination: 1 });

    let passsing_data = { ...this.state.searchData };
    passsing_data[term] = value;

    this.setState({ searchData: passsing_data });

    this.fetchProcessedOrders({
      ...passsing_data,
      created_at__gt: this.state.daterange_start,
      created_at__lt: moment(),
    });
  };

  filterBySearch = (term, value) => {
    this.setState({ loading: true });

    const nullifyOthers = {
      search: '',
      user__username__iexact: '',
      orderdetail__address__mobile__iexact: '',
      orderassignmt__mt__patient__full_name__iexact: '',
      orderitem__patient__full_name__iexact: '',
      orderdetail__payment_source: '',
      orderdetail__payment_status: '',
      is_archived: 'false',
    };
    nullifyOthers[term] = value;

    let passsing_data = { ...this.state.searchData, ...nullifyOthers };

    this.setState({ searchData: passsing_data });

    this.fetchProcessedOrders({
      ...passsing_data,
      created_at__gt: this.state.daterange_start,
      created_at__lt: moment(),
    });
  };

  // handling individual filter

  handleByOrderType = (fltrVal) => {
    this.setState({ showFilterDrop: false, fltrOrdrType: fltrVal });

    this.paramsMod('order-type', fltrVal);
    this.filterByStatus('order_type', fltrVal);
  };

  handleByStatus = (fltrVal) => {
    this.setState({ showFilterDrop: false, fltrStat: fltrVal });

    this.paramsMod('status', fltrVal);
    this.filterByStatus('orderdetail__order_status', fltrVal);
  };

  handleByPaymentStatus = (fltrVal) => {
    this.setState({ showFilterDrop: false, fltrPayStat: fltrVal });

    this.paramsMod('payment-status', fltrVal);
    this.filterByStatus('orderdetail__payment_status', fltrVal);
  };

  handleByServiceStatus = (fltrVal) => {
    this.setState({ showFilterDrop: false, fltrServiceStat: fltrVal });

    this.paramsMod('service-status', fltrVal);
    this.filterByStatus('orderdelivery__name', fltrVal);
  };

  handleByReportDelivery = (fltrVal) => {
    this.setState({ showFilterDrop: false, fltrReport: fltrVal });

    this.paramsMod('report-delivery', fltrVal);
    this.filterByStatus('orderreportdelivery__name', fltrVal);
  };

  handleByReportDeliveryHard = (fltrVal) => {
    this.setState({ showFilterDrop: false, fltrReportHard: fltrVal });

    this.paramsMod('report-delivery-hard', fltrVal);
    this.filterByStatus('orderreporthardcopydelivery__name', fltrVal);
  };

  handleByArchieved = (e) => {
    this.setState({ showFilterDrop: false, fltrArch: e.target.value });

    this.paramsMod('archieved', e.target.value);
    this.filterByStatus('is_archived', e.target.value);
  };

  filterByDay = (value) => {
    this.setState({ loading: true });

    let passsing_data = { ...this.state.searchData, date: value };
    this.setState({ searchData: passsing_data });

    this.fetchProcessedOrders({
      ...passsing_data,
      created_at__gt: this.state.daterange_start,
      created_at__lt: moment(),
    });
  };

  handleByDay = (e) => {
    this.setState({ showFilterDrop: false });

    if (e.target.value === '') {
      this.props.history.push(`/orders`);
      this.fetchOrders();
    } else {
      this.paramsMod('date', e.target.value);
      this.filterByDay(e.target.value);
    }
  };

  setShowAddModal = (value) => {
    this.setState({ showAddModal: value });
  };

  setShowCSVModal = (value) => {
    this.setState({ showCSVModal: value });
  };

  // Reset & Refresh

  refreshPage = () => {
    this.setDateFilterName(null);
    this.setState({ offset: 15, pagination: 1 });
    this.setState({ loadingOrder: true });
    this.setState({
      searchText: '',
      searchId: '',
      searchMt: '',
      searchPtn: '',
      searchPhone: '',
      searchDeliPhone: '',
    });
    this.setState({
      daterange_start: moment().subtract(5, 'year'),
      daterange_end: moment(),
    });
    this.resetFilters();

    setTimeout(() => {
      this.setState({ showDateFilter: false });
      this.fetchOrders();
      this.props.history.push('/orders');
    }, 250);

    this.context.fetchOrderStatus();
  };

  refreshPageAccount = () => {
    this.setDateFilterName(null);
    // console.log('fetch order');
    this.setState({ offset: 15 });
    this.setState({ loadingOrder: true });
    this.setState({
      searchText: '',
      searchId: '',
      searchMt: '',
      searchPtn: '',
      searchPhone: '',
      searchDeliPhone: '',
    });
    this.setState({
      daterange_start: moment().subtract(5, 'year'),
      daterange_end: moment(),
    });
    this.resetFilters();

    const searchData = {
      created_at__gt: '',
      created_at__gte: '',
      created_at__lt: '',
      created_at__lte: '',
      date: '',
      date__gte: '',
      date__lte: '',
      external_source_user: '',
      is_archived: 'false',
      limit: 15,
      offset: 0,
      order_type: '',
      orderapproval__is_approved: '',
      orderdelivery__name: '',
      orderdetail__delivery_copy_status: '',
      orderdetail__mt: '',
      orderdetail__order_status: 'confirmed',
      orderdetail__payment_source: '',
      orderdetail__payment_status: '',
      orderdetail__user_type: '',
      orderreportdelivery__name: '',
      orderreporthardcopydelivery__name: '',
      user: '',
      user__username__iexact: '',
      search: '',
      ordering: '',
      orderdetail__address__mobile__iexact: '',
      orderassignmt__mt__patient__full_name__iexact: '',
      orderitem__patient__full_name__iexact: '',
    };
    this.setState({ searchData: searchData });

    setTimeout(() => {
      this.setState({ showDateFilter: false });
      this.fetchOrders();
      this.props.history.push('/orders');
    }, 100);

    this.context.fetchOrderStatus();
  };

  resetFilters = () => {
    this.setState({
      sortedBy: '',
      sortedAsc: true,

      showFilterDrop: false,

      daterange_start: moment(),
      daterange_end: moment(),
      showDateFilter: false,
      dateFilterName: null,

      showAchieve: false,

      sample_coll_start: moment(),
      sample_coll_end: moment(),

      searchBy: 'order_id',
      searchText: '',
      searchId: '',
      searchMt: '',
      searchPtn: '',
      searchPhone: '',
      searchDeliPhone: '',

      fltrOrdrType: '',
      fltrStat: '',
      fltrPayStat: '',
      fltrServiceStat: '',
      fltrReport: '',
      fltrReportHard: '',
      fltrArch: '',
      fltrDate: '',
      fltrApproval: '',

      searchData: {
        created_at__gt: '',
        created_at__gte: '',
        created_at__lt: '',
        created_at__lte: '',
        date: '',
        date__gte: '',
        date__lte: '',
        external_source_user: '',
        is_archived: 'false',
        limit: 15,
        offset: 0,
        order_type: '',
        orderapproval__is_approved: '',
        orderdelivery__name: '',
        orderdetail__delivery_copy_status: '',
        orderdetail__mt: '',
        orderdetail__order_status: '',
        orderdetail__payment_source: '',
        orderdetail__payment_status: '',
        orderdetail__user_type: '',
        orderreportdelivery__name: '',
        orderreporthardcopydelivery__name: '',
        user: '',
        user__username__iexact: '',
        search: '',
        ordering: '',
        orderdetail__address__mobile__iexact: '',
        orderassignmt__mt__patient__full_name__iexact: '',
        orderitem__patient__full_name__iexact: '',
      },
      urlParamObj: {},
    });
  };

  // handling individual search

  handleSearchId = (searchTxt) => {
    this.paramsModSearch('search-order-id', searchTxt);
    this.filterBySearch('search', searchTxt);
  };

  handleSearchByPhone = (searchTxt) => {
    let numberPattern = /^01\d{9}$/;

    if (searchTxt && !numberPattern.test(searchTxt)) {
      toast.error('Please enter a valid mobile number', { autoClose: 2000 });
    } else {
      this.paramsModSearch('search-user-phone', searchTxt);
      this.filterBySearch('user__username__iexact', searchTxt);
    }
  };

  handleSearchByDeliPhone = (searchTxt) => {
    let numberPattern = /^01\d{9}$/;

    if (searchTxt && !numberPattern.test(searchTxt)) {
      toast.error('Please enter a valid mobile number', { autoClose: 2000 });
    } else {
      this.paramsModSearch('search-deli-phone', searchTxt);
      this.filterBySearch('orderdetail__address__mobile__iexact', searchTxt);
    }
  };

  handleSearchMT = (searchTxt) => {
    this.paramsModSearch('search-mt', searchTxt);
    this.filterBySearch(
      'orderassignmt__mt__patient__full_name__iexact',
      searchTxt
    );
  };

  handleSearchPtn = (searchTxt) => {
    this.paramsModSearch('search-patient', searchTxt);
    this.filterBySearch('orderitem__patient__full_name__iexact', searchTxt);
  };

  handleSearch = (searchTxt) => {
    const { searchBy } = this.state;

    this.setState({ pagination: 1 });

    if (searchBy === 'order_id') {
      this.handleSearchId(searchTxt);
      this.setState({
        searchId: searchTxt,
        searchMt: '',
        searchPtn: '',
        searchPhone: '',
        searchDeliPhone: '',
        searchPaymentSource: '',
        searchAchieved: '',
      });
    } else if (searchBy === 'mt_name') {
      this.handleSearchMT(searchTxt);
      this.setState({
        searchId: '',
        searchMt: searchTxt,
        searchPtn: '',
        searchPhone: '',
        searchDeliPhone: '',
        searchPaymentSource: '',
        searchAchieved: '',
      });
    } else if (searchBy === 'patient_name') {
      this.handleSearchPtn(searchTxt);
      this.setState({
        searchId: '',
        searchMt: '',
        searchPtn: searchTxt,
        searchPhone: '',
        searchDeliPhone: '',
        searchPaymentSource: '',
        searchAchieved: '',
      });
    } else if (searchBy === 'user_phone') {
      this.handleSearchByPhone(searchTxt);
      this.setState({
        searchId: '',
        searchMt: '',
        searchPtn: '',
        searchPhone: searchTxt,
        searchDeliPhone: '',
        searchPaymentSource: '',
        searchAchieved: '',
      });
    } else if (searchBy === 'delivery_number') {
      this.handleSearchByDeliPhone(searchTxt);
      this.setState({
        searchId: '',
        searchMt: '',
        searchPtn: '',
        searchPhone: '',
        searchDeliPhone: searchTxt,
        searchPaymentSource: '',
        searchAchieved: '',
      });
    }
  };

  handleSearchText = (searchTxt) => {
    this.setState({ searchText: searchTxt });

    // const { searchBy } = this.state;

    // if (searchBy === 'order_id') {
    //   this.setState({ searchId: searchTxt, });
    // } else if (searchBy === 'mt_name') {
    //   this.setState({ searchMt: searchTxt, });
    // } else if (searchBy === 'patient_name') {
    //   this.setState({ searchPtn: searchTxt, });
    // } else if (searchBy === 'user_phone') {
    //   this.setState({ searchPhone: searchTxt, });
    // } else if (searchBy === 'delivery_number') {
    //   this.setState({ searchDeliPhone: searchTxt });
    // }
  };

  // handling header date range

  handleDateRangeApply = (start, end) => {
    this.setState({
      daterange_start: start,
      daterange_end: end,
      showDateFilter: true,
      pagination: 1,
    });

    setTimeout(() => {
      this.fetchProcessedOrders({
        ...this.state.searchData,
        created_at__gt: start,
        created_at__lt: end,
      });
    }, 100);
  };

  fetchSampleCollectionOrders = (start, end, chosenLabel) => {
    this.setState({ loading: true });
    this.setState({ pagination: 1 });

    if (chosenLabel === 'Reset') {
      this.paramsMod('collection-start', '', false);
      this.paramsMod('collection-end', '');
    } else {
      this.paramsMod('collection-start', start, false);
      this.paramsMod('collection-end', end);
    }

    let passsing_data = {
      ...this.state.searchData,
      date__gte: start,
      date__lte: end,
    };
    this.setState({ searchData: passsing_data });

    this.fetchProcessedOrders({ ...passsing_data });
  };

  handleDateRangeCollection = (start, end, chosenLabel) => {
    this.setState({
      sample_coll_start: start,
      sample_coll_end: end,
      showFilterDrop: false,
    });

    this.fetchSampleCollectionOrders(
      start.format('YYYY-MM-DD'),
      end.format('YYYY-MM-DD'),
      chosenLabel
    );
  };

  handleBySort = (sortParam) => {
    this.paramsMod('ordering', sortParam);
    this.filterByStatus('ordering', sortParam);
  };

  handleSorting = (sortBy) => {
    const { sortedBy, sortedAsc } = this.state;

    if (sortBy === sortedBy) {
      sortBy !== 'collection_status' &&
        this.handleBySort(`${sortedAsc ? '-' : ''}${sortBy}`);
      this.setState({ sortedAsc: !sortedAsc });
    } else {
      this.setState({ sortedAsc: false, sortedBy: sortBy });
      sortBy !== 'collection_status' && this.handleBySort(`-${sortBy}`);
    }
  };

  handlePaymentSource = (e) => {
    let paymentSource = '';
    let paymentStatus = '';
    this.setState({ searchPaymentSource: e.target.value });
    // console.log(e.target.value);

    if (e.target.value === 'other') {
      paymentSource = 'cod';
      paymentStatus = 'paid';
    } else if (e.target.value === 'ssl') {
      paymentSource = 'sslc';
      paymentStatus = 'paid';
    } else {
      paymentSource = '';
      paymentStatus = '';
    }

    this.setState({ pagination: 1 });

    this.setState({
      searchId: '',
      searchMt: '',
      searchPtn: '',
      searchPhone: '',
      searchDeliPhone: '',
      searchAchieved: '',
    });

    let newUrlParam = {};
    newUrlParam['payment-source'] = e.target.value;
    this.setState({ urlParamObj: newUrlParam });
    let paramstring = '';
    for (const key in newUrlParam) {
      if (newUrlParam[key]) paramstring += `&${key}=${newUrlParam[key]}`;
    }
    this.props.history.push(`/orders?${paramstring.slice(1)}`);

    this.setState({ loading: true });
    const nullifyOthers = {
      search: '',
      user__username__iexact: '',
      orderdetail__address__mobile__iexact: '',
      orderassignmt__mt__patient__full_name__iexact: '',
      orderitem__patient__full_name__iexact: '',
    };
    nullifyOthers['orderdetail__payment_source'] = paymentSource;
    nullifyOthers['orderdetail__payment_status'] = paymentStatus;

    let passsing_data = { ...this.state.searchData, ...nullifyOthers };

    this.setState({ searchData: passsing_data });

    this.fetchProcessedOrders({
      ...passsing_data,
      created_at__gt: this.state.daterange_start,
      created_at__lt: moment(),
    });
  };

  handleByAchieved = (e) => {
    this.setState({ searchAchieved: e.target.value });
    let name;

    if (e.target.value === 'true') {
      name = true;
    } else if (e.target.value === 'false') {
      name = false;
    } else {
      name = '';
    }

    this.setState({ pagination: 1 });

    this.setState({
      searchId: '',
      searchMt: '',
      searchPtn: '',
      searchPhone: '',
      searchDeliPhone: '',
      searchPaymentSource: '',
    });

    let newUrlParam = {};
    newUrlParam['achieved'] = e.target.value;
    this.setState({ urlParamObj: newUrlParam });
    let paramstring = '';
    for (const key in newUrlParam) {
      if (newUrlParam[key]) paramstring += `&${key}=${newUrlParam[key]}`;
    }
    this.props.history.push(`/orders?${paramstring.slice(1)}`);

    this.setState({ loading: true });
    const nullifyOthers = {
      search: '',
      user__username__iexact: '',
      orderdetail__address__mobile__iexact: '',
      orderassignmt__mt__patient__full_name__iexact: '',
      orderitem__patient__full_name__iexact: '',
      orderdetail__payment_source: '',
      orderdetail__payment_status: '',
    };
    nullifyOthers['is_archived'] = e.target.value;

    let passsing_data = { ...this.state.searchData, ...nullifyOthers };

    this.setState({ searchData: passsing_data });

    this.fetchProcessedOrders({
      ...passsing_data,
      created_at__gt: this.state.daterange_start,
      created_at__lt: moment(),
    });
  };

  handlePaginationPress = (pageno) => {
    this.setState({ pagination: pageno });
    const pagination = { pagination: pageno };
    this.fetchProcessedOrders({ ...this.state.searchData, ...pagination });
    // console.log(pageno);
  };

  render() {
    const {
      orders,
      orderCount,
      next,
      showAddModal,
      allAccess,
      adminAccess,
      createAccess,
      loadingOrder,
      showCSVModal,
      showFilterDrop,
      searchId,
      searchPhone,
      rolePaginate,
      seeMoreLoading,
      showAccounts,
    } = this.state;

    const {
      fltrOrdrType,
      fltrStat,
      fltrPayStat,
      fltrServiceStat,
      fltrReport,
      fltrReportHard,
      fltrArch,
      fltrDate,
      fltrApproval,
      fltrSearch,
      sortedBy,
      sortedAsc,
      pageInitialLoad,
      searchBy,
      searchText,
      searchMt,
      searchDeliPhone,
      searchPtn,
    } = this.state;

    return (
      <>
        <HeaderToolbar
          title='Orders'
          dateFilter={true}
          startDate={moment().subtract(31, 'days')}
          startDatePlaceholder={this.state.daterange_start}
          endDate={this.state.daterange_end}
          handleDateRangeApply={this.handleDateRangeApply}
          showDateFilter={this.state.showDateFilter}
          dateFilterName={this.state.dateFilterName}
          setDateFilterName={this.setDateFilterName}
          createAccess={allAccess}
        />
        {!showAccounts ? (
          <div className='content d-flex flex-column flex-column-fluid order-page'>
            <div id='kt_content_container' className='container-xxl'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header border-0 pt-6'>
                      {allAccess ? (
                        <div className='card-title head-item'>
                          <div className='d-flex align-items-start flex-column'>
                            <div className='d-flex flex-column align-items-start position-relative my-1'>
                              <div>Orders</div>
                              <div className='fs-7 text-muted'>
                                Total {this.state.orderCount || '00'}{' '}
                                {this.state.orderCount > 1 ? 'Orders' : 'Order'}{' '}
                              </div>
                            </div>
                          </div>
                          <div className='right'>
                            <div className='ms-4 d-flex align-items-start flex-column justify-content-center'>
                              <select
                                className='form-select form-select-md form-select-solid fw-normal fs-7 text-truncate select-search-ot'
                                value={searchBy}
                                onChange={(e) =>
                                  this.setState({ searchBy: e.target.value })
                                }
                                disabled={
                                  !this.state.initialLoadd ? 'disabled' : ''
                                }
                              >
                                <option value='order_id'>
                                  Search by Order ID
                                </option>
                                <option value='mt_name'>Search By MT</option>
                                <option value='patient_name'>
                                  Search by Patient Name
                                </option>
                                <option value='user_phone'>
                                  Search by User Number
                                </option>
                                <option value='delivery_number'>
                                  Search by Delivery Number
                                </option>
                                <option value='filter_by_payment'>
                                  Filter By Payment
                                </option>
                                <option value='filter_by_achieved'>
                                  Filter By Achieved
                                </option>
                              </select>
                            </div>
                            <div className='ms-4 d-flex align-items-start flex-column'>
                              {this.state.searchBy === 'filter_by_payment' ||
                              this.state.searchBy === 'filter_by_achieved' ? (
                                <>
                                  {this.state.searchBy ===
                                    'filter_by_payment' && (
                                    <select
                                      className='min-w-200px form-select form-select-md form-select-solid fw-normal fs-7 text-truncate select-search-ot'
                                      value={this.state.searchPaymentSource}
                                      onChange={this.handlePaymentSource}
                                      disabled={
                                        !this.state.initialLoadd
                                          ? 'disabled'
                                          : ''
                                      }
                                    >
                                      <option value='all'>All</option>
                                      <option value='ssl'>
                                        Online (SSL Commerce)
                                      </option>
                                      <option value='other'>Other</option>
                                    </select>
                                  )}

                                  {this.state.searchBy ===
                                    'filter_by_achieved' && (
                                    <select
                                      className='min-w-200px form-select form-select-md form-select-solid fw-normal fs-7 text-truncate select-search-ot'
                                      value={this.state.searchAchieved}
                                      onChange={this.handleByAchieved}
                                      disabled={
                                        !this.state.initialLoadd
                                          ? 'disabled'
                                          : ''
                                      }
                                    >
                                      <option value='all'>All</option>
                                      <option value='true'>Achieved</option>
                                      <option value='false'>UnAchieved</option>
                                    </select>
                                  )}
                                </>
                              ) : (
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    // this.handleSearch(searchBy === 'order_id' ? searchId : searchBy === 'mt_name' ? searchMt : searchBy === 'patient_name' ? searchPtn : searchBy === 'user_phone' ? searchPhone : searchBy === 'delivery_number' ? searchDeliPhone : '');
                                    this.handleSearch(searchText);
                                  }}
                                >
                                  <div className='d-flex align-items-center position-relative my-1'>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control form-control-solid fs-7 px-5 pe-17 input-search-ot'
                                        placeholder={`Search By ${this.adjustCapitalize(
                                          searchBy.replaceAll('_', ' ')
                                        )}`}
                                        // value={searchBy === 'order_id' ? searchId : searchBy === 'mt_name' ? searchMt : searchBy === 'patient_name' ? searchPtn : searchBy === 'user_phone' ? searchPhone : searchBy === 'delivery_number' ? searchDeliPhone : ''}
                                        value={searchText}
                                        onChange={(e) => {
                                          this.handleSearchText(e.target.value);
                                        }}
                                        disabled={
                                          !this.state.initialLoadd
                                            ? 'disabled'
                                            : ''
                                        }
                                      />
                                    </div>
                                    <div
                                      className='pointer position-absolute d-flex justify-content-center align-items-center me-4'
                                      style={{ right: 0, height: '100%' }}
                                    >
                                      <div
                                        style={{
                                          height: '65%',
                                          width: '1px',
                                          backgroundColor: '#7C839940',
                                          margin: '0 8px',
                                        }}
                                      ></div>
                                      <div
                                        className='svg-icon svg-icon-1'
                                        onClick={() => {
                                          // this.handleSearch(searchBy === 'order_id' ? searchId : searchBy === 'mt_name' ? searchMt : searchBy === 'patient_name' ? searchPtn : searchBy === 'user_phone' ? searchPhone : searchBy === 'delivery_number' ? searchDeliPhone : '');
                                          this.handleSearch(searchText);
                                        }}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width={24}
                                          height={24}
                                          viewBox='0 0 24 24'
                                          fill='none'
                                        >
                                          <rect
                                            opacity='0.5'
                                            x='17.0365'
                                            y='15.1223'
                                            width='8.15546'
                                            height={2}
                                            rx={1}
                                            transform='rotate(45 17.0365 15.1223)'
                                            fill='black'
                                          />
                                          <path
                                            d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                            fill='black'
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </div>

                            {adminAccess && (
                              <div
                                className='btn ms-4 my-1 btn btn-primary btn-sm fw-normal fs-7 d-flex align-items-start flex-row justify-content-center align-items-center'
                                onClick={() => this.setShowCSVModal(true)}
                              >
                                <span className='svg-icon svg-icon-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                  >
                                    <rect
                                      opacity='1'
                                      x='12.75'
                                      y='4.25'
                                      width={12}
                                      height={2}
                                      rx={1}
                                      transform='rotate(90 12.75 4.25)'
                                      fill='black'
                                    />
                                    <path
                                      d='M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z'
                                      fill='black'
                                    />
                                    <path
                                      d='M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z'
                                      fill='#C4C4C4'
                                    />
                                  </svg>
                                </span>
                                Export
                              </div>
                            )}

                            <button
                              className='ms-4 my-1 fw-normal fs-8 btn btn-danger btn-sm'
                              onClick={() => {
                                this.refreshPage();
                              }}
                            >
                              <i
                                style={{ marginLeft: '5px' }}
                                className={`fas fa-sync-alt`}
                              ></i>
                            </button>

                            <button
                              className='ms-4 my-1 fw-normal btn btn-input btn-sm'
                              onClick={() => {
                                this.props.history.push('/order-add');
                              }}
                            >
                              <i className='fas fa-plus'></i> Add Order
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className='card-title flex-grow-1 justify-content-between'>
                          <div className='d-flex align-items-start flex-column'>
                            <div className='d-flex flex-column align-items-start position-relative my-1'>
                              <div>Orders</div>
                              <div className='fs-7 text-muted'>
                                Total order {this.state.orderCount || '00'}+
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='card-body pt-0'>
                      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                        {false ? (
                          <LoadingContent name='Loading Orders...' />
                        ) : (
                          <div>
                            <div
                              className='ordr-tab-new mt-2'
                              style={{ overflow: 'auto' }}
                            >
                              {!pageInitialLoad && (
                                <table
                                  className={`table align-start table-row-dashed fs-7 gy-5 ${
                                    rolePaginate === 'mt' ? 'mt-hide' : ''
                                  }`}
                                >
                                  <thead>
                                    <tr className='text-start text-dark fw-bolder fs-7 text-uppercase gs-0'>
                                      <th
                                        style={{ width: '10%' }}
                                        className='min-w-80px sorting ps-3'
                                      >
                                        <div className='d-flex flex-row position-relative'>
                                          <div
                                            className='text-truncate'
                                            // style={{
                                            //   fontSize: '12px',
                                            //   fontWeight: 500,
                                            //   fontStretch: 'condensed',
                                            // }}
                                          >
                                            Order Info
                                          </div>

                                          <div className='d-flex flex-row ms-2'>
                                            <div className='d-flex align-items-center fs-8'>
                                              <FilterPopover
                                                customIcon={() => (
                                                  <i
                                                    className='fas fa-sort-amount-down pointer text-dark'
                                                    style={{
                                                      opacity:
                                                        fltrOrdrType === ''
                                                          ? 0.2
                                                          : 0.6,
                                                    }}
                                                  ></i>
                                                )}
                                                value={fltrOrdrType}
                                                setValue={(type) =>
                                                  this.setState({
                                                    fltrOrdrType: type,
                                                  })
                                                }
                                                handleSave={
                                                  this.handleByOrderType
                                                }
                                                options={this.orderTypeOptions}
                                                placement='bottom-end'
                                                popOverId='filter-drop-left'
                                                editAccess={allAccess}
                                                onBlurHide={true}
                                              />
                                            </div>
                                            <div
                                              className={`px-0 border border-0 d-flex flex-column align-items-center ms-1`}
                                              onClick={() =>
                                                this.handleSorting('created_at')
                                              }
                                              style={{
                                                marginTop: '2px',
                                                transform:
                                                  'scaleX(0.8) scaleY(0.9)',
                                              }}
                                            >
                                              <img
                                                className='pointer'
                                                src={AngleDown}
                                                alt=''
                                                style={{
                                                  width: '15px',
                                                  height: '8px',
                                                  opacity:
                                                    sortedBy === 'created_at' &&
                                                    !sortedAsc
                                                      ? 1
                                                      : 0.3,
                                                  transform: 'rotate(180deg)',
                                                }}
                                              />
                                              <img
                                                className='pointer'
                                                src={AngleDown}
                                                alt=''
                                                style={{
                                                  width: '15px',
                                                  height: '8px',
                                                  opacity:
                                                    sortedBy === 'created_at' &&
                                                    sortedAsc
                                                      ? 1
                                                      : 0.3,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </th>

                                      <th
                                        style={{ width: '12%' }}
                                        className='min-w-125px sorting mt'
                                      >
                                        <div className='d-flex flex-row'>
                                          <div
                                            className='text-truncate'
                                            // style={{
                                            //   fontSize: '12px',
                                            //   fontWeight: 500,
                                            //   fontStretch: 'condensed',
                                            // }}
                                          >
                                            Patient Info
                                          </div>
                                          <div className='d-flex flex-row ms-2'>
                                            {/* <div className='d-flex align-items-center fs-8'>
                                            <FilterPopover
                                              customIcon={() => (
                                                <i className='fas fa-sort-amount-down pointer text-dark' style={{ opacity: 0.6 }}></i>
                                              )}
                                              value={fltrOrdrType}
                                              setValue={(type) => this.setState({ fltrOrdrType: type })}
                                              handleSave={this.handleByOrderType}
                                              options={this.orderTypeOptions}
                                              placement='bottom-end'
                                              popOverId='filter-drop'
                                              editAccess={allAccess}
                                              onBlurHide={false}
                                            />
                                          </div> */}
                                            {/* <div className={`px-1 border border-0 d-flex align-items-center ms-1`} >
                                            <img className='pointer' src={AngleDown} alt='' style={{ width: '15px', height: '8px', opacity: 1, }} />
                                          </div> */}
                                          </div>
                                        </div>
                                      </th>

                                      <th
                                        style={{ width: '12%' }}
                                        className='min-w-125px sorting mt'
                                      >
                                        <div className='d-flex flex-row'>
                                          <div
                                            className='text-truncate'
                                            // style={{
                                            //   fontSize: '12px',
                                            //   fontWeight: 500,
                                            //   fontStretch: 'condensed',
                                            // }}
                                          >
                                            Schedule
                                          </div>
                                          <div className='d-flex flex-row ms-2'>
                                            <div className='d-flex align-items-center fs-8'>
                                              <DateRangePicker
                                                initialSettings={{
                                                  startDate:
                                                    this.state.sample_coll_start.toDate(),
                                                  endDate:
                                                    this.state.sample_coll_end.toDate(),
                                                  ranges: {
                                                    Reset: [
                                                      moment()
                                                        .subtract(5, 'year')
                                                        .toDate(),
                                                      moment()
                                                        .add(5, 'year')
                                                        .toDate(),
                                                    ],
                                                    Today: [
                                                      moment().toDate(),
                                                      moment().toDate(),
                                                    ],
                                                    Tomorrow: [
                                                      moment()
                                                        .add(1, 'days')
                                                        .toDate(),
                                                      moment()
                                                        .add(1, 'days')
                                                        .toDate(),
                                                    ],
                                                    'Next 3 Days': [
                                                      moment().toDate(),
                                                      moment()
                                                        .add(2, 'days')
                                                        .toDate(),
                                                    ],
                                                    'Next 7 Days': [
                                                      moment().toDate(),
                                                      moment()
                                                        .add(7, 'days')
                                                        .toDate(),
                                                    ],
                                                    'This Month': [
                                                      moment()
                                                        .startOf('month')
                                                        .toDate(),
                                                      moment()
                                                        .endOf('month')
                                                        .toDate(),
                                                    ],
                                                    'Last Month': [
                                                      moment()
                                                        .subtract(1, 'month')
                                                        .startOf('month')
                                                        .toDate(),
                                                      moment()
                                                        .subtract(1, 'month')
                                                        .endOf('month')
                                                        .toDate(),
                                                    ],
                                                  },
                                                }}
                                                onApply={(event, picker) => {
                                                  console.log(event);
                                                  console.log(picker);

                                                  this.handleDateRangeCollection(
                                                    picker.startDate,
                                                    picker.endDate,
                                                    picker.chosenLabel
                                                  );
                                                }}
                                              >
                                                <i
                                                  className='fas fa-sort-amount-down pointer text-dark'
                                                  style={{
                                                    opacity:
                                                      this.state.searchData
                                                        .date__gte === ''
                                                        ? 0.2
                                                        : 0.6,
                                                  }}
                                                ></i>
                                              </DateRangePicker>
                                            </div>
                                            <div
                                              className={`px-0 border border-0 d-flex flex-column align-items-center ms-1`}
                                              onClick={() =>
                                                this.handleSorting('date')
                                              }
                                              style={{
                                                marginTop: '2px',
                                                transform:
                                                  'scaleX(0.8) scaleY(0.9)',
                                              }}
                                            >
                                              <img
                                                className='pointer'
                                                src={AngleDown}
                                                alt=''
                                                style={{
                                                  width: '15px',
                                                  height: '8px',
                                                  opacity:
                                                    sortedBy === 'date' &&
                                                    !sortedAsc
                                                      ? 1
                                                      : 0.3,
                                                  transform: 'rotate(180deg)',
                                                }}
                                              />
                                              <img
                                                className='pointer'
                                                src={AngleDown}
                                                alt=''
                                                style={{
                                                  width: '15px',
                                                  height: '8px',
                                                  opacity:
                                                    sortedBy === 'date' &&
                                                    sortedAsc
                                                      ? 1
                                                      : 0.3,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </th>

                                      <th
                                        style={{ width: '12%' }}
                                        className='min-w-125px sorting mt'
                                      >
                                        <div className='d-flex flex-row position-relative'>
                                          <div
                                            className='text-truncate'
                                            // style={{
                                            //   fontSize: '12px',
                                            //   fontWeight: 500,
                                            //   fontStretch: 'condensed',
                                            // }}
                                          >
                                            Collection
                                          </div>
                                          <div className='d-flex flex-row ms-2'>
                                            <div className='d-flex align-items-center fs-8'>
                                              <FilterPopover
                                                customIcon={() => (
                                                  <i
                                                    className='fas fa-sort-amount-down pointer text-dark'
                                                    style={{
                                                      opacity:
                                                        fltrServiceStat === ''
                                                          ? 0.2
                                                          : 0.6,
                                                    }}
                                                  ></i>
                                                )}
                                                value={fltrServiceStat}
                                                setValue={(stat) =>
                                                  this.setState({
                                                    fltrServiceStat: stat,
                                                  })
                                                }
                                                handleSave={
                                                  this.handleByServiceStatus
                                                }
                                                options={
                                                  this.orderServiceStatus
                                                }
                                                placement='bottom-end'
                                                popOverId='filter-drop'
                                                editAccess={allAccess}
                                                onBlurHide={true}
                                              />
                                            </div>
                                            {/* <div className={`px-0 border border-0 d-flex flex-column align-items-center ms-1`} onClick={() => this.handleSorting('collection_status')} style={{ marginTop: '2px', transform: 'scaleX(0.8) scaleY(0.9)', }} >
                                              <img className='pointer' src={AngleDown} alt='' style={{ width: '15px', height: '8px', opacity: (sortedBy === 'collection_status' && !sortedAsc) ? 1 : 0.3, transform: 'rotate(180deg)' }} />
                                              <img className='pointer' src={AngleDown} alt='' style={{ width: '15px', height: '8px', opacity: (sortedBy === 'collection_status' && sortedAsc) ? 1 : 0.3, }} />
                                            </div> */}
                                          </div>
                                        </div>
                                      </th>

                                      <th
                                        style={{ width: '13%' }}
                                        className='min-w-125px sorting mt'
                                      >
                                        <div className='d-flex flex-row position-relative'>
                                          <div
                                            className='text-truncate'
                                            // style={{
                                            //   fontSize: '12px',
                                            //   fontWeight: 500,
                                            //   fontStretch: 'condensed',
                                            // }}
                                          >
                                            Report (Soft)
                                          </div>
                                          <div className='d-flex flex-row ms-2'>
                                            <div className='d-flex align-items-center fs-8'>
                                              <FilterPopover
                                                customIcon={() => (
                                                  <i
                                                    className='fas fa-sort-amount-down pointer text-dark'
                                                    style={{
                                                      opacity:
                                                        fltrReport === ''
                                                          ? 0.2
                                                          : 0.6,
                                                    }}
                                                  ></i>
                                                )}
                                                value={fltrReport}
                                                setValue={(type) =>
                                                  this.setState({
                                                    fltrReport: type,
                                                  })
                                                }
                                                handleSave={
                                                  this.handleByReportDelivery
                                                }
                                                options={
                                                  this.reportDeliveryOptionsSoft
                                                }
                                                placement='bottom-end'
                                                popOverId='filter-drop'
                                                editAccess={allAccess}
                                                onBlurHide={true}
                                              />
                                            </div>
                                            {/* <div className={`px-1 border border-0 d-flex align-items-center ms-1`} >
                                            <img className='pointer' src={AngleDown} alt='' style={{ width: '15px', height: '8px', opacity: 1, }} />
                                          </div> */}
                                          </div>
                                        </div>
                                      </th>
                                      <th
                                        style={{ width: '13%' }}
                                        className='min-w-125px sorting mt'
                                      >
                                        <div className='d-flex flex-row position-relative'>
                                          <div
                                            className='text-truncate'
                                            // style={{
                                            //   fontSize: '12px',
                                            //   fontWeight: 500,
                                            //   fontStretch: 'condensed',
                                            // }}
                                          >
                                            Report (Hard)
                                          </div>
                                          <div className='d-flex flex-row ms-2'>
                                            <div className='d-flex align-items-center fs-8'>
                                              <FilterPopover
                                                customIcon={() => (
                                                  <i
                                                    className='fas fa-sort-amount-down pointer text-dark'
                                                    style={{
                                                      opacity:
                                                        fltrReportHard === ''
                                                          ? 0.2
                                                          : 0.6,
                                                    }}
                                                  ></i>
                                                )}
                                                value={fltrReportHard}
                                                setValue={(type) =>
                                                  this.setState({
                                                    fltrReportHard: type,
                                                  })
                                                }
                                                handleSave={
                                                  this
                                                    .handleByReportDeliveryHard
                                                }
                                                options={
                                                  this.reportDeliveryOptionsHard
                                                }
                                                placement='bottom-end'
                                                popOverId='filter-drop'
                                                editAccess={allAccess}
                                                onBlurHide={true}
                                              />
                                            </div>
                                            {/* <div className={`px-1 border border-0 d-flex align-items-center ms-1`} >
                                            <img className='pointer' src={AngleDown} alt='' style={{ width: '15px', height: '8px', opacity: 1, }} />
                                          </div> */}
                                          </div>
                                        </div>
                                      </th>
                                      <th
                                        style={{ width: '11.5%' }}
                                        className='min-w-125px sorting mt'
                                      >
                                        <div className='d-flex flex-row position-relative'>
                                          <div
                                            className='text-truncate'
                                            // style={{
                                            //   fontSize: '12px',
                                            //   fontWeight: 500,
                                            //   fontStretch: 'condensed',
                                            // }}
                                          >
                                            Payment
                                          </div>
                                          <div className='d-flex flex-row ms-2'>
                                            <div className='d-flex align-items-center fs-8'>
                                              <FilterPopover
                                                customIcon={() => (
                                                  <i
                                                    className='fas fa-sort-amount-down pointer text-dark'
                                                    style={{
                                                      opacity:
                                                        fltrPayStat === ''
                                                          ? 0.2
                                                          : 0.6,
                                                    }}
                                                  ></i>
                                                )}
                                                value={fltrPayStat}
                                                setValue={(type) =>
                                                  this.setState({
                                                    fltrPayStat: type,
                                                  })
                                                }
                                                handleSave={
                                                  this.handleByPaymentStatus
                                                }
                                                options={
                                                  this.paymentStatusOptions
                                                }
                                                placement='bottom-end'
                                                popOverId='filter-drop'
                                                editAccess={allAccess}
                                                onBlurHide={true}
                                              />
                                            </div>
                                            <div
                                              className={`px-0 border border-0 d-flex flex-column align-items-center ms-1`}
                                              onClick={() =>
                                                this.handleSorting(
                                                  'total_price'
                                                )
                                              }
                                              style={{
                                                marginTop: '2px',
                                                transform:
                                                  'scaleX(0.8) scaleY(0.9)',
                                              }}
                                            >
                                              <img
                                                className='pointer'
                                                src={AngleDown}
                                                alt=''
                                                style={{
                                                  width: '15px',
                                                  height: '8px',
                                                  opacity:
                                                    sortedBy ===
                                                      'total_price' &&
                                                    !sortedAsc
                                                      ? 1
                                                      : 0.3,
                                                  transform: 'rotate(180deg)',
                                                }}
                                              />
                                              <img
                                                className='pointer'
                                                src={AngleDown}
                                                alt=''
                                                style={{
                                                  width: '15px',
                                                  height: '8px',
                                                  opacity:
                                                    sortedBy ===
                                                      'total_price' && sortedAsc
                                                      ? 1
                                                      : 0.3,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                      <th
                                        style={{ width: '16.5%' }}
                                        className='min-w-150px sorting mt'
                                      >
                                        <div className='d-flex flex-row position-relative'>
                                          <div
                                            className='text-truncate'
                                            // style={{
                                            //   fontSize: '12px',
                                            //   fontWeight: 500,
                                            //   fontStretch: 'condensed',
                                            // }}
                                          >
                                            Remarks
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  {orders &&
                                    orders.length > 0 &&
                                    !this.state.loading && (
                                      <tbody className='fw-bold text-gray-600'>
                                        {orders.map((order, i) => (
                                          <OrderTable
                                            key={i}
                                            order={order}
                                            orderManager={
                                              this.state.orderManager
                                            }
                                            medicalTechnologist={
                                              this.state.medicalTech
                                            }
                                            showAchieve={this.state.showAchieve}
                                            refreshPage={this.refreshPage}
                                          />
                                        ))}
                                      </tbody>
                                    )}
                                </table>
                              )}
                            </div>
                            <div className='row justify-content-end mt-3 mb-10'>
                              {this.state.loading && (
                                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                                  <Spinner animation='border' size='sm' />{' '}
                                  <span
                                    className='ms-2 text-gray-600'
                                    style={{ fontFamily: 'Poppins' }}
                                  >
                                    Loading Orders.....
                                  </span>
                                </div>
                              )}
                              <Pagination
                                onPageChange={(pageno) =>
                                  this.handlePaginationPress(pageno)
                                }
                                totalCount={this.state.orderCount}
                                pageSize={this.state.limit}
                                currentPage={this.state.pagination}
                                siblingCount={1}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-xxl'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header border-0 pt-6'>
                      <div className='card-title'>
                        <div className='d-flex align-items-start flex-column'>
                          <div className='d-flex align-items-center position-relative my-1'>
                            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                              >
                                <rect
                                  opacity='0.5'
                                  x='17.0365'
                                  y='15.1223'
                                  width='8.15546'
                                  height={2}
                                  rx={1}
                                  transform='rotate(45 17.0365 15.1223)'
                                  fill='black'
                                />
                                <path
                                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                  fill='black'
                                />
                              </svg>
                            </span>
                            <div>
                              <input
                                type='text'
                                className='form-control form-control-solid w-250px ps-15'
                                placeholder='Search By Order ID'
                                value={searchId}
                                onChange={(e) =>
                                  this.handleSearch(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className='ms-5 d-flex align-items-start flex-column'>
                          <form
                            onSubmit={this.handleSearchByPhone}
                            className='d-flex align-items-center position-relative my-1'
                          >
                            <div>
                              <input
                                type='text'
                                className='form-control form-control-solid w-250px pe-15'
                                placeholder='Search By Phone Number'
                                value={searchPhone}
                                onChange={(e) =>
                                  this.setState({
                                    searchPhone: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <span
                              onClick={this.handleSearchByPhone}
                              className='pointer svg-icon svg-icon-1 position-absolute me-6'
                              style={{ right: '0' }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                              >
                                <rect
                                  opacity='0.5'
                                  x='17.0365'
                                  y='15.1223'
                                  width='8.15546'
                                  height={2}
                                  rx={1}
                                  transform='rotate(45 17.0365 15.1223)'
                                  fill='black'
                                />
                                <path
                                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                  fill='black'
                                />
                              </svg>
                            </span>
                          </form>
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        {!loadingOrder ? (
                          <button
                            onClick={this.refreshPageAccount}
                            className='btn btn-primary me-3'
                          >
                            Refresh
                          </button>
                        ) : (
                          <BtnLoading btnClass={'primary'} />
                        )}
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                        <span className='text-muted mt-3 fw-bold fs-7 ps-1'>
                          Total {orderCount || '00'}+ orders found
                        </span>
                        {this.state.loading ? (
                          <LoadingContent name='Loading Orders...' />
                        ) : (
                          <div>
                            <div className='table-responsive account-tab mt-5'>
                              <table
                                className={`table align-start table-row-dashed fs-7 gy-5 ${
                                  rolePaginate === 'mt' ? 'mt-hide' : ''
                                }`}
                              >
                                <thead>
                                  <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                                    <th className='min-w-125px sorting ps-3'>
                                      ID
                                    </th>
                                    <th className='min-w-125px sorting mt'>
                                      Date
                                    </th>
                                    <th className='min-w-125px sorting mt'>
                                      Total MRP
                                    </th>
                                    <th className='min-w-150px sorting mt'>
                                      Discount
                                    </th>
                                    <th className='min-w-150px sorting mt'>
                                      Collection Fees
                                    </th>
                                    <th className='min-w-125px sorting'>
                                      Material Fees
                                    </th>
                                    <th className='min-w-200px sorting mt'>
                                      Collectible From Client
                                    </th>
                                    <th className='min-w-225px sorting mt'>
                                      Payment Method by client
                                    </th>
                                    <th className='min-w-250px sorting'>
                                      Payment Collected from client
                                    </th>
                                    <th className='min-w-125px sorting mt'>
                                      Ref
                                    </th>
                                    <th className='min-w-200px sorting'>
                                      Type
                                    </th>
                                    <th className='min-w-225px sorting mt'>
                                      Diagnostic/Partner
                                    </th>
                                    <th className='min-w-150px sorting'>
                                      Lab Invoice No
                                    </th>
                                    <th className='min-w-200px sorting mt'>
                                      Payable to Diagnostic
                                    </th>
                                    <th className='min-w-225px sorting mt'>
                                      Payment Method by Amarlab
                                    </th>
                                    <th className='min-w-175px sorting mt'>
                                      Paid to Diagnostic
                                    </th>
                                    <th className='min-w-150px sorting mt'>
                                      Revenue
                                    </th>
                                    <th className='min-w-150px sorting'>MT</th>
                                  </tr>
                                </thead>
                                <tbody className='fw-bold text-gray-600'>
                                  {orders &&
                                    orders.map((order, i) => (
                                      <AccountOrderTable
                                        key={i}
                                        order={order}
                                        labs={this.state.ourAllLabs}
                                      />
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            <div className='row'>
                              <div className='col-12 mt-10'>
                                {next && (
                                  <p className='m-0 text-center'>
                                    {!seeMoreLoading ? (
                                      <button
                                        className='btn btn-primary btn-lg'
                                        onClick={this.loadMore}
                                        disabled={next ? '' : 'disabled'}
                                      >
                                        See More
                                      </button>
                                    ) : (
                                      <BtnLoading
                                        name='Loading...'
                                        btnClass='primary btn-lg'
                                      />
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <OrderAddModal
          showAddModal={showAddModal}
          setShowAddModal={this.setShowAddModal}
        />
        {/* <CsvDownloadModal
          showCSVModal={showCSVModal}
          setShowCSVModal={this.setShowCSVModal}
        /> */}
        <CsvDownloadModalV2
          showCSVModal={showCSVModal}
          setShowCSVModal={this.setShowCSVModal}
        />
      </>
    );
  }
}

export default Orders;
Orders.contextType = AuthContext;
